import fileIcon from 'assets/svg/file-icon.svg'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import { useMemo } from 'react'
import { Field } from 'redux-form'
import { findObjectByField } from 'utils/commonFunctions'
import { getReviewFileTabs } from '../helpers'

const UploadedFiles = ({ uploads }) => {
	const tabs = useMemo(() => getReviewFileTabs(uploads), [uploads])
	const step = useMemo(() => uploads[0], [uploads])
	const tab = useMemo(() => findObjectByField(tabs, 'value', step?.client_type), [tabs, step])
	const files = useMemo(() => step?.files || [], [step])

	return (
		<div className='mt-[130px]'>
			<div className='mb-3 bg-white px-4 py-[11px] text-sm font-bold'>
				<div>
					<span>Additional Requirements</span>
					<span className='ml-3 rounded-md bg-main p-1 text-[10px] text-white'>
						{files.length.toLocaleString('en-US', {
							minimumIntegerDigits: 2,
							useGrouping: false,
						})}
					</span>
				</div>
			</div>

			<div className='main-wrapper'>
				<div className='mb-3 flex flex-col space-y-3'>
					{files.length > 0 ? (
						files.map((file, idx) => (
							<div key={idx} className='bg-white px-4 py-3'>
								<div className='flex flex-col'>
									<a
										href={file?.files?.path}
										style={{
											fontSize: 11,
										}}
										title='Click Here to View'
										target='_blank'
										rel='noreferrer'>
										<div className='btn-link link w-f-content flex items-center'>
											<div>
												<img src={fileIcon} alt='no-image' />
											</div>
											<div className='ml-[10px] flex-col'>
												<strong className='text-sm font-bold text-gray-700'>
													{file?.title}
												</strong>
												<span className='text-sm text-[#74788D]'>
													{file?.files?.original_name}
												</span>
											</div>
										</div>
									</a>
									<div className='!my-3 border-b'>
										<Field
											name={`files[${idx}].title`}
											value={file?.title}
											type='hidden'
											component='input'
										/>
										<Field
											name={`files[${idx}].user_type`}
											value={tab?.label}
											type='hidden'
											component='input'
										/>
									</div>
									<div className='flex items-center space-x-4'>
										<Field
											name={`files[${idx}].status`}
											value='approved'
											label='Approve'
											type='radio'
											component={RadioComponent}
											defaultChecked={true}
											ignoreBottomMargin={true}
										/>
										<Field
											name={`files[${idx}].status`}
											value='reject'
											label='Reject'
											type='radio'
											variant='red'
											component={RadioComponent}
											ignoreBottomMargin={true}
										/>
									</div>
								</div>
							</div>
						))
					) : (
						<p className='text-center text-sm text-gray-500'>
							No file was supposed to upload in this workflow.
						</p>
					)}
				</div>

				{files.length > 0 ? (
					<Field
						label='Add a note about the review for the client'
						type='textarea'
						name={`files_notes.${tab?.value}`}
						component={TextField}
						rows={3}
					/>
				) : undefined}
			</div>

			<div className='flex justify-end'>
				<button type='submit' className='btn-primary-1'>
					Save
				</button>
			</div>
		</div>
	)
}

export default UploadedFiles
