import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'

import { getDuration } from 'modules/subscriptions/subscription-listing'
import { useLazyGetMyAllSubscriptionQuery } from 'modules/subscriptions/user-subscriptions-api'
import Badge from 'theme/ui/badge'
import { ReactDataTable } from 'theme/ui/data-table'
import { dateFormat } from 'utilities/helpers'

const SubscriptionHistory = () => {
	const [fetchMyAllSubscriptions, { data, isLoading }] = useLazyGetMyAllSubscriptionQuery()
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { pageIndex, pageSize } = pagination
	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize }
		return obj
	}, [pageIndex, pageSize])

	useEffect(() => {
		fetchMyAllSubscriptions(filters)
	}, [])

	const { meta, subscriptions } = useMemo(
		() => ({
			subscriptions: data?.subscriptions || [],
			meta: data?.meta,
		}),
		[data],
	)

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 200,
			header: () => <span>Plan Name (Type)</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-full text-sm font-bold capitalize !text-gray-700'>
						{info.getValue()?.plan?.name || 'N/A'}
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row, {
			id: 'amount',
			header: () => <span>Amount</span>,
			cell: (info) => (
				<div>
					<span className='font-bold text-main'>
						{info?.getValue()?.price?.amount
							? `${(info.getValue().price.amount / 100).toFixed(2)} ${info
									.getValue()
									.price.currency?.toUpperCase()}`
							: 'N/A'}
					</span>

					<span className='text-xs text-gray-500'>
						{getDuration(info.getValue().plan?.interval)}
					</span>
				</div>
			),
		}),

		// columnHelper.accessor('users_allowed', {
		// 	header: () => <span>Number of users Allowed</span>,
		// 	cell: (info) => (
		// 		<div>
		// 			<span className='mr-1 font-bold'>{info.getValue()}</span>
		// 			<span>Users</span>
		// 		</div>
		// 	),
		// }),

		columnHelper.accessor('status', {
			header: () => <span>Status</span>,
			cell: (info) => (
				<Badge variant={info.getValue() === 'Active' ? 'success' : 'danger'}>
					{info.getValue()}
				</Badge>
			),
		}),

		columnHelper.accessor('created_at', {
			header: () => <span>Started At</span>,
			cell: (info) => <span>{dateFormat(info.getValue())}</span>,
		}),

		columnHelper.accessor('end_date', {
			header: () => <span>Expired At</span>,
			cell: (info) => <span>{dateFormat(info.getValue())}</span>,
		}),
	]

	return (
		<div>
			<ReactDataTable
				columns={columns}
				data={subscriptions}
				meta={meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</div>
	)
}

export default SubscriptionHistory
