import { useParams } from 'react-router-dom'

import CompletedWorkflowDetails from 'modules/workflows/completed-workflow-details'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowOutstandingsDetails = () => {
	const params = useParams()
	const id = params?.id

	return (
		<AppLayout
			meta={{
				title: 'Workflow Details',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Workflow Details'
						hasBackButton={true}
						description='The most important feature in the workflow section is the creating one. When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						<CompletedWorkflowDetails id={id} />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowOutstandingsDetails
