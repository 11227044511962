import experiviseLightLogo from 'assets/images/experivise-logo-transparent.png'
import { Link } from 'react-router-dom'
import IsAuthenticated from 'utils/isAuthenticated'

const PricingHeader = () => {
	const isLoggedIn = IsAuthenticated()

	return (
		<div className='fixed mb-6 w-full !border !border-b !border-gray-200 bg-white shadow-sm'>
			<div className='mx-auto flex items-center justify-between py-1 lg:max-w-screen-xl'>
				<div className='auth-logo flex justify-center'>
					<img
						src={experiviseLightLogo}
						alt=''
						style={{ height: 70 }}
						className='logo logo-dark'
					/>
				</div>
				<div className='flex items-center space-x-6 px-3 py-2 text-base font-medium'>
					{isLoggedIn ? (
						<Link to='/dashboard'>
							<i className='uil-home-alt' />
							<span className='ml-1'>Go To Dashboard</span>
						</Link>
					) : (
						<Link to='/login'>
							<i className='uil-signin' />
							<span className='ml-1'>Login</span>
						</Link>
					)}

					<a href='https://hpbtdmtnm.gleap.help/en' target='_blank' rel='noreferrer'>
						<i className='uil-external-link-alt' />
						<span className='ml-1'>Help</span>
					</a>
				</div>
			</div>
		</div>
	)
}

export default PricingHeader
