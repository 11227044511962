import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'

import DataTable from 'theme/ui/data-table'
import HouseholdsActions from './households-action'

const HouseholdsTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.name, {
			id: 'status',
			size: 300,
			header: () => <span>Name</span>,
			cell: (info) => (
				<>
					<span className='capitalize'>{info.getValue()}</span>
				</>
			),
		}),
		columnHelper.accessor((row) => row.created_at, {
			id: 'created_at',
			size: 100,
			enableSorting: false,
			header: () => (
				<div className='!w-full text-right'>
					<span>Created at</span>
				</div>
			),
			cell: (info) => (
				<div className='!w-full text-right'>
					<span className='capitalize'>{info.getValue()}</span>
				</div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 100,
			enableSorting: false,
			header: () => (
				<div className='!w-full text-right'>
					<span className=''>Actions</span>
				</div>
			),
			cell: (info) => <HouseholdsActions household={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default HouseholdsTable
