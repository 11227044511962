import { useState } from 'react'
import { Link } from 'react-router-dom'

import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import ToolTip from 'theme/ui/tool-tip'
import notification from 'utilities/notification'
import { useDeletePaymentMutation } from '../payments-api'
import { PAYMENTS_APP_ROUTES } from '../routes'

const PaymentsActions = ({ payment }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	// const onOpen = () => setIsOpen(true)

	const [deletePayment, { isLoading: isDeleting }] = useDeletePaymentMutation()
	const onDeletePayment = async (id) => {
		try {
			const response = await deletePayment(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			onClose()
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<div>
			<>
				<DeleteConfirmAlert
					isOpen={isOpen}
					onClose={onClose}
					isLoading={isDeleting}
					onConfirm={() => onDeletePayment(payment?.id)}
				/>
				<div className=''>
					<div className='float-right flex justify-end space-x-2'>
						<ToolTip
							targetId={`view-payment-${payment?.id}`}
							tooltipText='View'
							placement='top'
						/>
						<Link
							id={`view-payment-${payment?.id}`}
							to={PAYMENTS_APP_ROUTES.findOne(payment?.id)}
							className='action-btn btn-gray-outline'>
							<i className='uil uil-eye' style={{ fontSize: 16 }} />
						</Link>
						{/* {payment?.status !== 'completed' ? (
						<Link
							to={PAYMENTS_APP_ROUTES.edit(payment?.id)}
							className='action-btn btn-info-outline'>
							<i className='uil uil-pen' style={{ fontSize: 16 }} />
							<span>Edit</span>
						</Link>
					) : undefined} */}
						{/* <button type='button' className='action-btn btn-red-outline' onClick={onOpen}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						<span>Delete</span>
					</button> */}
					</div>
				</div>
			</>
		</div>
	)
}

export default PaymentsActions
