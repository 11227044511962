import { createColumnHelper } from '@tanstack/react-table'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import { getDocumentIcon } from 'modules/file-manager/my-documents-listing'
import { useState } from 'react'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import Actions from '../actions'
import FoldersColumn from '../folders-column'

const TableRowBody = ({ data }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'info',
			size: 270,
			header: () => <span>Title</span>,
			cell: (info) => (
				<div className='flex items-center space-x-2'>
					{/* <input type='checkbox' name='' className='form-check-input' id='' /> */}
					<div className='flex items-center'>
						{info.getValue()?.type === 'folder' ? (
							<FolderIcon height={15} />
						) : (
							<span>{getDocumentIcon(info.getValue()?.document_type)}</span>
						)}
						<span className='my-1 ml-2 line-clamp-1 text-sm font-medium'>
							{info.getValue()?.title}
						</span>
					</div>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'folders',
			size: 170,
			header: () => <span style={{ minWidth: '60px' }}>Folder</span>,
			cell: (info) => (
				<FoldersColumn
					key={info?.row?.id}
					data={info.getValue()}
					type={info.getValue()?.type}
				/>
			),
		}),

		columnHelper.accessor((row) => row.updated_at, {
			id: 'updated_at',
			size: 160,
			header: () => (
				<div className='w-full text-right'>
					<span>Updated At</span>
				</div>
			),
			cell: (info) => (
				<div className='w-full justify-end text-right'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue())}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue())}
					</span>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 200,
			header: () => (
				<div className='w-full text-right'>
					<span>Actions</span>
				</div>
			),
			cell: (info) => (
				<Actions
					type={info.getValue()?.type}
					data={info?.row}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={false}
			setSorting={setSorting}
			sorting={sorting}
			variant='secondary'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default TableRowBody
