import { Fragment, useEffect, useState } from 'react'

import { FormBuilderProvider } from 'contexts/formbuilder-provider'
import { TabsProvider } from 'contexts/tabs-context'
import { useAppRouter } from 'hooks'
import {
	DocumentTemplateForm,
	DocumentTemplateFormSkeleton,
	DocumentWorkflows,
} from 'modules/documents'
import {
	useLazyCheckEditEligibilityQuery,
	useLazyGetDocumentWorkflowsQuery,
	useLazyGetOneDocumentTemplateQuery,
} from 'modules/documents/document-api'
import { DocumentViewerProvider } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import { ErrorCard } from 'pages/StaticPages'
import { AppLayout, PageHeader } from 'theme/layouts'

const EditDocumentTemplate = () => {
	const { params, parseQuery } = useAppRouter()
	const [title, setTitle] = useState('Document')
	const ID = params?.id

	const [state, setState] = useState({
		isFetching: true,
		isError: false,
		document: null,
		workflows: [],
		count: 0,
	})

	const [fetchDocumentTemplate] = useLazyGetOneDocumentTemplateQuery()
	const [checkEditEligibility] = useLazyCheckEditEligibilityQuery()
	const [fetchWorkflows] = useLazyGetDocumentWorkflowsQuery()

	const getDocument = async (id) => {
		const templateResponse = await fetchDocumentTemplate(id)
		if (templateResponse?.data?.status !== 200) {
			throw new Error('Something went wrong while fetching document template')
		}
		return templateResponse
	}

	const fetchDocument = async (ID) => {
		try {
			setState((prevState) => ({ ...prevState, isFetching: true, isError: false }))
			const eligibilityResponse = await checkEditEligibility(ID)
			const templateResponse = await getDocument(ID)
			if (eligibilityResponse?.data?.status === 200) {
				setState({
					document: templateResponse?.data?.document,
					workflows: [],
					count: 0,
					isFetching: false,
					isError: false,
				})
			} else {
				const workflowsResponse = await fetchWorkflows(ID)
				setState({
					document: null,
					workflows: workflowsResponse?.data?.data?.workflow || [],
					count: workflowsResponse?.data?.data?.count || 0,
					isFetching: false,
					isError: false,
				})
			}
		} catch (error) {
			setState((prevState) => ({
				...prevState,
				isFetching: false,
				isError: true,
			}))
		}
	}

	useEffect(() => {
		if (ID) {
			fetchDocument(ID)
		}
	}, [ID])

	const { isFetching, document, workflows, count } = state

	return (
		<AppLayout
			meta={{
				title:
					ID && !parseQuery?.isCreate
						? `Edit ${title} Template`
						: `Create ${title} Template`,
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title={
							ID && !parseQuery?.isCreate
								? `Edit ${title} Template`
								: `Create ${title} Template`
						}
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton
						innerClassName={'block sm:hidden'}
					/>
					<DocumentViewerProvider>
						<div>
							{isFetching ? (
								<DocumentTemplateFormSkeleton />
							) : (
								<Fragment>
									{state?.isError ? (
										<ErrorCard />
									) : (
										<Fragment>
											{document && workflows.length === 0 ? (
												<FormBuilderProvider>
													<TabsProvider>
														<DocumentTemplateForm
															document={document}
															setTitle={setTitle}
														/>
													</TabsProvider>
												</FormBuilderProvider>
											) : (
												<DocumentWorkflows
													id={ID}
													workflows={workflows}
													fetchDocument={fetchDocument}
													totalWorkflows={count}
												/>
											)}
										</Fragment>
									)}
								</Fragment>
							)}
						</div>
					</DocumentViewerProvider>
				</div>
			</div>
		</AppLayout>
	)
}

export default EditDocumentTemplate
