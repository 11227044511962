import { useEffect, useState } from 'react'

import apiEndpoints from 'helpers/apiEndpoints'
import { useAppRouter } from 'hooks'
import Filters from 'modules/documents/completed-documents/filters'
import { useLazyTrashedDocumentPackageQuery } from 'modules/documents/document-package-api'
import { FileManagerLayout } from 'modules/file-manager'
import TrashListingTable from 'modules/file-manager/trash-listing/trash-listing-table'
import { TableProvider } from 'theme/ui/data-table'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'

const Trash = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchDocumentPackages, { data, isFetching }] = useLazyTrashedDocumentPackageQuery()

	useEffect(() => {
		const stringUrl = `${apiEndpoints.documentPackage}/trashed`
		if (location?.search) {
			fetchDocumentPackages({
				params: getParseQueryString(location?.search),
				url: stringUrl,
			})
		}
	}, [location?.search])
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - Trash',
			}}
			filterForm={<Filters setPagination={setPagination} heading='Trash' />}>
			<div>
				<TableProvider>
					<TrashListingTable
						data={data?.data}
						pagination={pagination}
						meta={data?.meta}
						setPagination={setPagination}
						isLoading={isFetching}
					/>
				</TableProvider>
			</div>
		</FileManagerLayout>
	)
}

export default Trash
