import classNames from 'classnames'
import { Field } from 'formik'
import { capitalize } from 'lodash'
import Select from 'react-select'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'

const UserTypeField = ({ name }) => {
	const createOption = (value) => ({ label: capitalize(value), value: value })

	return (
		<Field name={`${name}.user_type`}>
			{({ field, form, meta: { touched, error } }) => (
				<div className='mt-3 flex max-w-[250px] items-center space-x-3'>
					<label
						className={classNames(
							'mb-0 flex items-baseline justify-between space-x-2 text-[14px] font-bold text-[#495057]',
						)}>
						Fillable by
					</label>
					<div className='flex flex-1 flex-col'>
						<Select
							{...field}
							value={field?.value ? createOption(field?.value) : null}
							options={[
								{ label: 'Advisor', value: 'advisor' },
								{ label: 'Client', value: 'client' },
							]}
							onChange={(option) => form.setFieldValue(field?.name, option?.value)}
							isSearchable={true}
							isClearable={true}
							isMulti={field?.multiple}
							placeholder={'Select User Role'}
							classNamePrefix={
								touched && error ? 'invalid-select-dropdown' : 'select-dropdown'
							}
							className={classNames('select-dropdown-container')}
							styles={{
								menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
								menu: (provided) => ({ ...provided, zIndex: 9999 }),
							}}
							menuPortalTarget={document.body}
							menuPosition={'fixed'}
							components={{
								DropdownIndicator: DropdownIndicator,
							}}
						/>
						{touched && error ? (
							<span className='error mt-[3px] text-[13px]'>{error}</span>
						) : undefined}
					</div>
				</div>
			)}
		</Field>
	)
}

export default UserTypeField
