import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FaFilePdf } from 'react-icons/fa' 
import PdfViewer from './pdf-viewer'

const DocumentPreview = ({ title, url, isOpen, toggle }) => {
	useEffect(() => {
		const body = document.getElementsByTagName('body')[0].style
		if (isOpen) {
			body.overflow = 'hidden'
		} else {
			body.overflow = 'auto'
		}

		return () => {
			body.overflow = 'auto'
			body.paddingRight = 0
		}
	}, [isOpen])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={()=>{}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all'>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute left-6 top-6 rounded-full !border !border-gray-300 bg-white p-3'>
										<FaFilePdf className='text-blue-500 text-xl' /> {/* Icon */}
									</div>
									<button type='button' className='right-0' onClick={toggle}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-10 flex flex-col'>
									<div className='ms-4 flex flex-col justify-start ps-2'>
										<Dialog.Title
											as='h2'
											className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
											Document Preview
										</Dialog.Title>
										<div>
											<p className='m-0 p-0 text-lg font-medium'>
												You are previewing the document:
												<span className='text-blue-400'> {title || 'Document'}</span>
											</p>
										</div>
									</div>
									<hr className='mx-4 my-3 bg-gray-300' />
									<div className='p-0 mx-4 mb-3'>
										<PdfViewer src={url} />
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default DocumentPreview
