import { Dialog, Transition } from '@headlessui/react'
import { Field, FormikProvider, useFormik } from 'formik'
import { DatabaseIcon } from 'lucide-react'
import { Fragment, useState } from 'react'
import { Button, InputField, SelectField } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import {
	getInitialValues,
	getPayload,
	logonTypeOptions,
	protocolOption,
	pullFrequencyOptions,
	validationSchema,
} from '.'
import {
	useAddSftpConfigMutation,
	useTestSftpConfigMutation,
	useUpdateSftpConfigMutation,
} from '../sftp-config-api'

const SftpConfigForm = ({ isOpen, setIsOpen, handleForm, details, isFetching }) => {
	const [addSftpConfig] = useAddSftpConfigMutation()
	const [updateSftpConfig] = useUpdateSftpConfigMutation()
	const [testSftpConfig, { isLoading: isTesting }] = useTestSftpConfigMutation()
	const [testedOk, setTestedOk] = useState(false)
	const testSftp = async (values) => {
		const errors = await formik.validateForm()

		if (Object.keys(errors).length > 0) {
			notification('warn', 'Please fill all required fields before testing the connection.')
			return
		}

		try {
			const payload = getPayload(values)
			const response = await testSftpConfig(payload).unwrap()

			if (response?.status === 200) {
				notification('success', response?.message)
				setTestedOk(true)
			}
		} catch (error) {
			notification('error', error?.message || 'Connection test failed.')
			setTestedOk(false)
		}
	}

	const formik = useFormik({
		initialValues: getInitialValues(details),
		validationSchema: validationSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			try {
				const payload = getPayload(values)
				const response = details?.id
					? await updateSftpConfig({ payload, id: details?.id })
					: await addSftpConfig(payload)
				if (response?.data?.status === 201 || response?.data?.status === 200) {
					notification('success', response?.data?.message)
					handleForm()
					setSubmitting(false)
				}
			} catch (error) {
				notification('warn', error?.message)
				setSubmitting(false)
			}
		},
	})

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-4xl transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute left-6 top-6 rounded-full !border !border-gray-300 bg-white !p-3'>
										<DatabaseIcon />
									</div>
									<button
										type='button'
										className='right-0'
										onClick={() => setIsOpen(false)}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								{isFetching ? (
									<div className='my-10'>
										<Spinner />
									</div>
								) : (
									<FormikProvider value={formik}>
										<form onSubmit={formik.handleSubmit} className='w-full p-6'>
											<div className='form-heading mb-6'>
												<h3 className='text-lg font-semibold'>
													SFTP Configuration
												</h3>
												<p className='text-gray-600'>
													Provide the details to set up your SFTP
													connection.
												</p>
											</div>
											<Field
												label='Protocol'
												name='protocol'
												options={protocolOption}
												component={SelectField}
												isRequiredField
											/>
											<div className='mt-3 grid grid-cols-1 gap-4 '>
												<div className='grid grid-cols-5 gap-4'>
													<div className='col-span-4'>
														<Field
															label='Host'
															placeholder='Enter SFTP host'
															name='host'
															component={InputField}
															isRequiredField
														/>
													</div>
													<div className='col-span-1'>
														<Field
															label='Port'
															placeholder='Enter SFTP port'
															name='port'
															component={InputField}
															type='number'
															isRequiredField
														/>
													</div>
												</div>
											</div>
											<hr className='my-4 border-gray-300' />

											<div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
												<Field
													label='User Name'
													placeholder='Enter SFTP User name'
													name='username'
													component={InputField}
													isRequiredField
												/>
												{!details && (
													<>
														<Field
															label='Logon Type'
															name='logonType'
															options={logonTypeOptions}
															component={SelectField}
															isRequiredField
														/>
														{formik.values?.logonType?.value ===
															'password' && (
															<Field
																label='Password'
																placeholder='Enter SFTP password'
																name='passkey'
																component={InputField}
																type='password'
																disabled={details}
																isRequiredField
															/>
														)}
														{formik.values?.logonType?.value ===
															'privateKey' && (
															<>
																<Field
																	placeholder='Enter private key'
																	label='Private Key'
																	name='passkey'
																	disabled={details}
																	component={InputField}
																	type='password'
																	isRequiredField
																/>
																<Field
																	label='Passphrase (Optional)'
																	placeholder='Enter passphrase'
																	name='passphrase'
																	component={InputField}
																/>
															</>
														)}
													</>
												)}
											</div>

											<hr className='my-4 border-gray-300' />

											<div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
												<Field
													label='Pull Frequency'
													name='pull_frequency'
													options={pullFrequencyOptions}
													component={SelectField}
													isRequiredField
												/>
												<Field
													label='Retention Policy (Optional)'
													placeholder='e.g., 30 days'
													name='retention_policy'
													type='number'
													component={InputField}
												/>
												<Field
													label='File Path'
													placeholder='Enter file path'
													name='file_path'
													component={InputField}
													isRequiredField
												/>
												<Field
													label='File Type/Pattern (Optional)'
													placeholder='e.g., *.csv'
													name='file_type_pattern'
													component={InputField}
												/>
											</div>

											<div className='mt-6 flex justify-end gap-3'>
												<Button
													variant='primary-outline'
													type='button'
													isLoading={isTesting}
													isLoadingText={'Testing...'}
													onClick={() => testSftp(formik?.values)}>
													Test Connection
												</Button>
												<Button
													disabled={!testedOk}
													type='submit'
													isLoading={formik.isSubmitting}>
													Save
												</Button>
											</div>
										</form>
									</FormikProvider>
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SftpConfigForm
