import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import { useAppRouter, useUser } from 'hooks'
import AdvisorDropdown from 'modules/workflows/workflows-outstandings/filters/advisor-dropdown'
import { useDataTable } from 'theme/ui/data-table'
import { Button, SearchField } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from '.'
import MyTaskField from './my-task-field'
import TagsDropdown from './tags-dropdown'

const Filters = ({ setPagination }) => {
	const { navigate, location } = useAppRouter()
	const parseQueryString = getParseQueryString(location?.search)
	const { user } = useUser()
	const initialValues = getInitialValues(parseQueryString, user)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)

	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues, user),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			if (setPagination) {
				setPagination({
					pageIndex: 0,
					pageSize: 10,
				})
			}
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				if (setPagination) {
					setPagination({
						pageIndex: currentPage - 1,
						pageSize: currentLimit,
					})
				}
			}
		}
	}, [location?.search])

	const activeFilters = getActiveFilters(parseQueryString)

	return (
		<FormikProvider value={formik}>
			<form className='mb-3'>
				<div className='flex flex-col space-y-3'>
					<div className='flex w-full flex-wrap items-center gap-1'>
						<Field
							className='form-control'
							type='search'
							name='search'
							component={SearchField}
							placeholder='Search tasks'
						/>

						<MyTaskField name='mytask' />

						<AdvisorDropdown label='Assignee' name='assignee_id' />

						<TagsDropdown label='Tags' name='tag_id' />

						{activeFilters.length > 0 ? (
							<Button size='sm' variant='ghost' onClick={onResetFilters}>
								Clear filters
							</Button>
						) : undefined}
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default Filters
