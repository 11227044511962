import SearchBar from './search-bar'

import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FormIcon } from 'assets/svg/form-icon.svg'

export const getDocumentIcon = (type = 'document_type') => {
	return type === 'form' ? <FormIcon height={20} /> : <DocumentIcon height={20} />
}

export const getFormValues = (values) => {
	const $values = {}

	if (values?.search) {
		Object.assign($values, {
			search: values?.search,
		})
	}

	if (values?.status?.value) {
		Object.assign($values, {
			status: values?.status?.value,
		})
	}
	return $values
}

export const searchTypes = [
	{
		icon: getDocumentIcon('document'),
		label: 'All',
		value: null,
	},
	{
		icon: getDocumentIcon('document'),
		label: 'Mapped PDF',
		value: 'documents',
	},
	{
		icon: getDocumentIcon('form'),
		label: 'Form',
		value: 'forms',
	},
]

export const getPlaceholder = (type) => {
	if (type === 'documents') {
		return 'Search mapped pdfs only'
	} else if (type === 'forms') {
		return 'Search forms only'
	} else {
		return 'Search mapped pdfs, forms & folders'
	}
}

export default SearchBar
