import { generateTransparentHex } from 'utilities/helpers'
import StatusDropdown from './status-dropdown'

export const createStatusOption = (status) => ({
	label: (
		<div
			className='block max-w-fit rounded px-2 py-0.5 text-xs'
			style={{
				backgroundColor: generateTransparentHex(status?.color, 20),
			}}>
			<span
				className='font-semibold'
				style={{
					color: status?.color,
				}}>
				{status?.title}
			</span>
		</div>
	),
	value: status?.title,
	color: status?.color,
})

export default StatusDropdown
