import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

const API_URL = '/teams'
const REMOVE_USER_URL = apiEndpoints.user.userRemove

export const teamApi = createApi({
	reducerPath: 'Teams',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Team'],
	endpoints: (builder) => ({
		getTeams: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: `${API_URL}/v2`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Team'],
			transformResponse: (response, _, arg) => {
				const teams = Array.isArray(response?.data?.teams)
					? response?.data?.teams.map((team, idx) => ({
							...team,
							team_name: team?.team_name,
							teamOwner: team?.teamOwner,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							subscription: Array.isArray(team?.userHasSubscriptions)
								? team?.userHasSubscriptions[0]
								: null,
					  }))
					: []

				return {
					teams,
					meta: response?.data?.meta,
				}
			},
		}),
		getTeam: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Team'],
		}),
		getTeamDetails: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}/team-details`,
				method: 'GET',
			}),
			providesTags: ['TeamDetails'],
		}),
		toggleStatus: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}/toggle-status`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Team'],
		}),
		getTeamSubscription: builder.query({
			query: (id) => ({
				url: `${API_URL}/${id}/subscription-details`,
				method: 'GET',
			}),
			providesTags: ['Team'],
		}),
		createTeam: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Team'],
		}),
		removeUser: builder.mutation({
			query: (params) => ({
				url: REMOVE_USER_URL,
				method: 'DELETE',
				params: params,
			}),
			invalidatesTags: ['Team', 'TeamDetails'],
		}),
	}),
})

export const {
	useLazyGetTeamsQuery,
	useLazyGetTeamQuery,
	useLazyGetTeamDetailsQuery,
	useToggleStatusMutation,
	useLazyGetTeamSubscriptionQuery,
	useCreateTeamMutation,
	useRemoveUserMutation,
} = teamApi
