import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { statuses } from 'modules/workflows'
import { useMemo } from 'react'

const StatusQuery = () => {
	const URL = `${apiEndpoints.workflow}/statuses`
	const { data, isLoading } = useQuery(URL)

	const statusesList = data?.data || []

	const options = useMemo(
		() =>
			statusesList.map((status) => {
				const matchedStatus = statuses.find((s) => s.name === status)
				const color = matchedStatus ? matchedStatus.color : '#000000'
				return {
					label: (
						<span className='line-clamp-1 flex items-center' title={status}>
							<span
								className='mr-2 h-2.5 w-2.5 rounded-full'
								style={{ backgroundColor: color }}
							/>
							{status}
						</span>
					),
					value: status,
				}
			}),
		[statusesList],
	)

	return {
		options,
		isLoading,
	}
}

export default StatusQuery
