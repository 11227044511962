import { Field } from 'formik'
import { Copy, Trash2 } from 'lucide-react'

import { FIELD_TYPES, OPTION_ABLE_FIELDS } from 'modules/form-designer/helpers'
import { Button, SwitchField } from 'theme/ui/forms'
import SubTypeField from '../field-wrapper/sub-type-field'
import MultipleToggleField from './multiple-toggle-field'

const FieldActions = ({ field, onRemove, onClone, index, name, is_score_able, totalFields }) => {
	return (
		<div className='flex items-center gap-3'>
			{field?.type ? (
				<div className='flex items-center gap-3 '>
					{OPTION_ABLE_FIELDS.includes(field?.type) ? (
						<>
							<MultipleToggleField field={field} name={name} />
							{is_score_able && field?.type === FIELD_TYPES.CHOICE ? (
								<Field
									component={SwitchField}
									label='Scored'
									name={`${name}.is_scored`}
								/>
							) : undefined}
						</>
					) : undefined}

					{field?.type === 'text' ? <SubTypeField name={name} /> : undefined}

					<Field component={SwitchField} label='Required' name={`${name}.required`} />
				</div>
			) : undefined}
			<div className='flex'>
				{field?.type ? (
					<Button
						variant='ghost-white'
						size='sm'
						type='button'
						onClick={() => onClone(field)}>
						<Copy size={20} className='text-gray-700' strokeWidth='1px' />
					</Button>
				) : undefined}

				{totalFields > 1 ? (
					<Button
						variant='ghost-white'
						size='sm'
						type='button'
						onClick={() => onRemove(index)}>
						<Trash2 size={20} className='text-red-600' strokeWidth='1px' />
					</Button>
				) : undefined}

				{/* <Button variant='ghost-white' size='sm' type='button'>
								<EllipsisVertical
									size={20}
									className='text-gray-700'
									strokeWidth={1}
								/>
							</Button> */}
			</div>
		</div>
	)
}

export default FieldActions
