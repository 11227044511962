import { useEffect, useMemo, useState } from 'react'

import { useAppRouter } from 'hooks'
import { useLazyGlobalSearchQuery } from 'modules/documents/document-package-api'
import { useMediaQuery } from 'react-responsive'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import AllFoldersExpandableTable from '../all-folders-templates-expandable-table'
import Filters from './filters'

const SearchFoldersListingContainer = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [globalSearch, { data, isFetching }] = useLazyGlobalSearchQuery()
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			globalSearch(getParseQueryString(location?.search))
		}
	}, [location?.search])

	const folders = useMemo(
		() =>
			Array.isArray(data?.data?.documentPackages)
				? data?.data?.documentPackages.map((f) => ({
						...f,
						type: 'folder',
				  }))
				: [],
		[data?.data?.documentPackages],
	)

	const documents = useMemo(
		() =>
			Array.isArray(data?.data?.documents)
				? data?.data?.documents.map((f) => ({
						...f,
						type: 'document',
				  }))
				: [],
		[data?.data?.documents],
	)

	const files = [...folders, ...documents]

	return (
		<div className='divide-y'>
			<Filters setPagination={setPagination} heading='Search Results' selectedRows={[]} />
			<div className={`pt-4 ${isMobile ? 'min-h-[75vh]' : ''}`}>
				<AllFoldersExpandableTable
					data={files}
					pagination={pagination}
					meta={data?.data?.meta}
					setPagination={setPagination}
					isLoading={isFetching}
				/>
			</div>
		</div>
	)
}

export default SearchFoldersListingContainer
