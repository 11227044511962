import { Field } from 'formik'
import { useState } from 'react'

import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { SelectDropdown } from 'theme/ui/filters'
import { filterOption } from '../helpers'
import ReviewerQuery from './reviewer-query'

const ReviewerDropdown = ({ name }) => {
	const [search, setSearch] = useState('')
	const { options } = ReviewerQuery(search)

	return (
		<Field
			name={name}
			isMulti={true}
			label='Reviewers'
			options={options}
			onInputChange={(value) => setSearch(value)}
			component={SelectDropdown}
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default ReviewerDropdown
