import { capitalize } from 'lodash'

import { FIELD_TYPES } from '../helpers'
import ChoiceField from './choice-field'
import DateField from './date-field'
import DropdownField from './dropdown-field'
import RatingField from './rating-field'
import TextField from './text-field'

const FieldRender = ({
	field,
	mode,
	name,
	onChange,
	onAddOption,
	onRemoveOption,
	onDragEnd,
	onDateChange,
	onRatingChange,
	is_read_mode,
}) => {
	const type = field?.type || FIELD_TYPES.TEXT

	switch (type) {
		case FIELD_TYPES.TEXT:
			return (
				<TextField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
				/>
			)

		case FIELD_TYPES.DATE:
			return (
				<DateField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
					onDateChange={onDateChange}
				/>
			)

		case FIELD_TYPES.RATING:
			return (
				<RatingField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onRatingChange={onRatingChange}
				/>
			)

		case FIELD_TYPES.DROPDOWN:
			return (
				<DropdownField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onAddOption={onAddOption}
					onRemoveOption={onRemoveOption}
					onDragEnd={onDragEnd}
					is_read_mode={is_read_mode}
				/>
			)

		case FIELD_TYPES.CHOICE:
			return (
				<ChoiceField
					mode={mode}
					name={name}
					field={field}
					onChange={onChange}
					onAddOption={onAddOption}
					onRemoveOption={onRemoveOption}
					onDragEnd={onDragEnd}
					is_read_mode={is_read_mode}
				/>
			)

		default:
			return (
				<p className='m-0 text-center'>
					{capitalize(mode)} mode of {field?.type} field is not found
				</p>
			)
	}
}

export default FieldRender
