import { uniqWith } from 'lodash'
import Filters from './filters'

export const getFormikFilterValues = (values, user) => {
	const filters = {}

	const search = values?.search || ''
	if (search) {
		Object.assign(filters, { search })
	}

	const clients = Array.isArray(values?.client_id)
		? values?.client_id.map((client) => client?.value)
		: []
	if (clients.length > 0) {
		Object.assign(filters, {
			client_id: clients,
		})
	}

	const assignees = Array.isArray(values?.assignee_id)
		? values?.assignee_id.map((o) => o?.value)
		: []
	if (assignees.length > 0) {
		Object.assign(filters, {
			assignee_id: assignees,
		})
	}

	const tags = Array.isArray(values?.tag_id) ? values?.tag_id.map((o) => o?.value) : []
	if (tags.length > 0) {
		Object.assign(filters, {
			tag_id: tags,
		})
	}

	const workflows = Array.isArray(values?.workflow_id)
		? values?.workflow_id.map((o) => o?.value)
		: []
	if (workflows.length > 0) {
		Object.assign(filters, {
			workflow_id: workflows,
		})
	}

	if (values?.mytask) {
		Object.assign(filters, { assignee_id: [...assignees, user?.id] })
	}

	if (values?.due_date) {
		Object.assign(filters, { due_date: values?.due_date })
	}

	if (values?.has_attachment) {
		Object.assign(filters, { has_attachment: values?.has_attachment })
	}

	return filters
}

export const getInitialValues = (queryObj, user) => {
	const initialValues = {
		search: '',
		mytask: false,
		client_id: [],
		assignee_id: [],
		tag_id: [],
		workflow_id: [],
		due_date: null,
		has_attachment: false,
	}

	const assignees = queryObj?.assignee_id ? queryObj?.assignee_id.split(',') : []

	if (queryObj?.search) {
		Object.assign(initialValues, { search: queryObj?.search })
	}

	if (queryObj?.mytask) {
		Object.assign(initialValues, { mytask: assignees.includes(user?.id) })
	}

	if (queryObj?.client_id) {
		Object.assign(initialValues, { client_id: queryObj?.client_id.split(',') })
	}

	if (queryObj?.assignee_id) {
		Object.assign(initialValues, { assignee_id: assignees })
	}

	if (queryObj?.tag_id) {
		Object.assign(initialValues, { tag_id: queryObj?.tag_id.split(',') })
	}

	if (queryObj?.workflow_id) {
		Object.assign(initialValues, { workflow_id: queryObj?.workflow_id.split(',') })
	}

	if (queryObj?.due_date) {
		Object.assign(initialValues, { due_date: queryObj?.due_date })
	}

	if (queryObj?.has_attachment) {
		Object.assign(initialValues, { has_attachment: queryObj?.has_attachment })
	}

	return initialValues
}

export const filterKeys = {
	search: 'Search',
	role: 'Role',
	from: 'Date Range',
	to: 'Date Range',
}

const getFitlerKey = (key) => {
	if (['from', 'to'].includes(key)) {
		return 'date_range'
	}
	return key
}

export const getActiveFilters = (filterObj) => {
	const filters = Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: getFitlerKey(key),
				},
				values,
			]
		})

	return uniqWith(filters, (arrayVal, otherVal) => {
		return arrayVal[0]?.name === otherVal[0]?.name
	})
}

export default Filters
