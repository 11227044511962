import { Field } from 'formik'
import { Fragment } from 'react'
import { RadioGroupField } from 'theme/ui/forms'

const FormTemplateInfo = () => {
	return (
		<Fragment>
			<Field
				label='Form Template Purpose'
				id='purpose'
				name='purpose'
				options={[
					{ label: 'Use inside Workflow', value: 'workflow' },
					{ label: 'Use as a public form', value: 'link' },
				]}
				component={RadioGroupField}
			/>
			<Field
				label='Form Template Type'
				id='form_template_type'
				name='form_template_type'
				options={[
					{ label: 'QnA', value: 'qna' },
					{ label: 'Scoring', value: 'scoring' },
				]}
				component={RadioGroupField}
			/>
		</Fragment>
	)
}

export default FormTemplateInfo
