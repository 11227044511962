import Swal from 'sweetalert2';
import notification from 'utilities/notification';
import { useDeleteWorkflowLinkMutation } from '../../workflow-template-apis';

const DeleteConfirmAlert = ({ isOpen, onClose, id }) => {
  const [deleteWorkflow, { isLoading: isDeleting }] = useDeleteWorkflowLinkMutation();

  const onConfirm = async (id) => {
    const res = await deleteWorkflow(id);
    if (res?.data?.status) {
      notification('success', res?.data?.message);
    }
  };

  const showAlert = () => {
    Swal.fire({
      title: 'Delete Workflow Link?',
      text: "Are you sure you want to delete this Workflow Link?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: isDeleting ? 'Deleting...' : 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'swal2-cancel bg-green-600 hover:bg-green-700 text-white border border-green-600 rounded-md',
        cancelButton: 'swal2-cancel bg-red-600 hover:bg-red-700 text-white border border-red-600 rounded-md',
      },
      showLoaderOnConfirm: isDeleting,
      preConfirm: async () => {
        if (isDeleting) return false;
        await onConfirm(id);
        Swal.fire({
          title: 'Deleted!',
          text: 'Your workflow link has been deleted.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        onClose();
      },
      didClose: onClose,
    });
  };

  if (isOpen) {
    showAlert();
  }

  return null;
};

export default DeleteConfirmAlert;