import apiEndpoints from 'helpers/apiEndpoints'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import notification from 'utilities/notification'
import request from 'utilities/request'

const DeleteConfirmAlert = ({
	title,
	subtitle,
	isOpen,
	onClose,
	id,
	mutate,
	isDeleting,
	isLoadingText = 'Deleting...',
	deleteBtnText,
}) => {
	useEffect(() => {
		if (isOpen) {
			const onConfirm = async () => {
				const URL = `${apiEndpoints.systemFields}/${id}`
				try {
					const response = await request.destroy(URL)
					if (response?.status === 200) {
						notification('success', response?.message)
						mutate()
						Swal.fire({
							title: 'Deleted!',
							text: 'Your item has been deleted.',
							icon: 'success',
							confirmButtonText: 'OK',
						}).then(() => {
							onClose()
						})
					}
				} catch (error) {
					Swal.fire({
						title: 'Error!',
						text: 'There was an error deleting the item.',
						icon: 'error',
						confirmButtonText: 'OK',
					}).then(() => {
						onClose()
					})
				}
			}

			Swal.fire({
				title: title || 'Are you sure?',
				text: subtitle || "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				allowOutsideClick: false,
				confirmButtonText: isDeleting ? isLoadingText : deleteBtnText || 'Yes',
				cancelButtonText: 'No',
				customClass: {
					confirmButton: `swal2-confirm bg-green-600 hover:bg-green-700 text-white border border-green-600 rounded-md`,
					cancelButton:
						'swal2-cancel bg-red-600 hover:bg-red-700 text-white border border-red-600 rounded-md',
				},
				showLoaderOnConfirm: isDeleting,
				preConfirm: async () => {
					if (isDeleting) return false
					await onConfirm()
				},
				didClose: onClose,
			})
		}
	}, [isOpen, id, isDeleting, isLoadingText, deleteBtnText, title, subtitle, mutate, onClose])

	return null
}

export default DeleteConfirmAlert
