import { Field } from 'formik'
import { useLazyGetUniqueHouseholdsQuery } from 'modules/households/households-api'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { useEffect, useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import ClientsQuery from './clients-query'

const ClientDropdown = ({ formValues, client_applicants, name }) => {
	const [search, setSearch] = useState('')
	const [tagList, setTagList] = useState([])

	const { clients, isLoading } = ClientsQuery(search, formValues, client_applicants)
	const [fetchHouseholds, { data: tagsData }] = useLazyGetUniqueHouseholdsQuery()
	useEffect(() => {
		if (fetchHouseholds) {
			fetchHouseholds({ limit: 1000, page: 1 })
		}
	}, [fetchHouseholds])

	useEffect(() => {
		if (tagsData?.households?.length > 0) {
			const tagsArr = tagsData?.households?.map((tag) => {
				return { name: tag?.name, count: tag?.clientsCount, id: tag?.id }
			})
			setTagList(tagsArr)
		}
	}, [tagsData])

	const clientOptions = clients.map((client) => ({
		label: <CreateUserOptionLabel user={client} />,
		value: client.value,
		fullName: client.fullName,
		email: client.email,
		type: 'client',
	}))

	const tagOptions = tagList?.map((tag) => ({
		label: `${tag?.name} [${tag?.count}]`,
		value: tag?.id,
		type: 'household',
	}))

	const groupedOptions = [
		...(tagOptions.length > 0 ? [{ label: 'Groups', options: tagOptions }] : []),
		{
			label: 'Clients',
			options: clientOptions,
		},
	]

	const filterOption = ({ data }, search) => {
		if (
			data?.fullName?.toLowerCase()?.includes(search?.toLowerCase()) ||
			data?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
			(typeof data?.label === 'string' &&
				data.label.toLowerCase().includes(search.toLowerCase()))
		) {
			return true
		} else {
			return false
		}
	}

	const handleSearch = (value) => {
		setSearch(value)
	}

	return (
		<Field
			label='Select Clients'
			type='select'
			name={name}
			options={groupedOptions}
			isLoading={isLoading}
			isMulti
			onInputChange={(value) => handleSearch(value)}
			component={SelectField}
			formValues={formValues}
			placeholder='Select clients'
			getOptionLabel={(option) => option.label}
			filterOption={filterOption}
		/>
	)
}

export default ClientDropdown
