import { uniqWith } from 'lodash'
import { searchTypes } from 'modules/file-manager/search-bar'
import { viewByOptions } from 'modules/file-manager/view-by-dropdown'
import Filters from './filters'

export const getHeading = (type) => {
	if (type === 'documents') {
		return 'Mapped PDF'
	} else if (type === 'forms') {
		return 'Forms'
	} else {
		return 'All'
	}
}

export const getParsedRows = (selectedRows) => {
	try {
		return selectedRows.map((row) => JSON.parse(row))
	} catch (error) {
		return []
	}
}

export const getFormikFilterValues = (values) => {
	const filters = {}
	if (values?.search) {
		Object.assign(filters, { search: values?.search })
	}
	if (values?.view_by) {
		Object.assign(filters, { view_by: values?.view_by?.value })
	}
	const status = values.status?.value
	if (status) {
		Object.assign(filters, { status: status })
	}
	return filters
}

export const getInitialValues = (queryObj) => {
	const currentStatus = searchTypes.find((t) => t.value === queryObj?.status)
	const initialValues = {
		view_by: '',
		search: '',
		status: !queryObj?.status ? searchTypes[0] : currentStatus,
	}

	if (queryObj?.view_by) {
		const option = viewByOptions.find((option) => option?.value === queryObj?.view_by)
		if (option) {
			Object.assign(initialValues, { view_by: option })
		}
	}

	if (queryObj?.search) {
		Object.assign(initialValues, { view_by: queryObj?.search })
	}

	return initialValues
}

const getFitlerKey = (key) => {
	if (['from', 'to'].includes(key)) {
		return 'date_range'
	}
	return key
}

export const getActiveFilters = (filterObj) => {
	const filtersArray = Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: getFitlerKey(key),
				},
				values,
			]
		})
	return uniqWith(filtersArray, (arrayVal, otherVal) => {
		return arrayVal[0]?.name === otherVal[0]?.name
	})
}

export const filterKeys = {
	view_by: 'View By',
	search: 'Search',
}

export default Filters
