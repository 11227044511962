import { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'

import { TextField } from 'components/Forms/TextFieldComponent'
import { confirmPassword, required } from 'utils/formValidation'
import PasswordSuggestion from './PasswordSuggestion'

const validate = {
	password: [required('Password is required')],
	confirmPassword: [confirmPassword],
}

let StepForm = (props) => {
	const { handleSubmit, history, password } = props

	return (
		<Fragment>
			<div className='min-w-[384px] self-center py-2'>
				<h3 className='mb-2 text-center text-xl font-bold'>Set up your password</h3>
				<p className='text-muted mb-9 text-center'>
					For secure login, set a strong password
				</p>

				<form className='flex flex-col space-y-7' onSubmit={handleSubmit}>
					<div>
						<Field
							label='Password'
							name='password'
							type='password'
							placeholder='Enter password'
							component={TextField}
							hideMessage={true}
							validate={validate.password}
						/>

						<PasswordSuggestion password={password} />

						<Field
							label='Confirm Password'
							name='confirm_password'
							type='password'
							placeholder='Enter password'
							component={TextField}
							validate={validate.confirmPassword}
						/>
					</div>
					<div className='text-center'>
						<button
							type='submit'
							className='btn-primary-1 mx-auto rounded-[4px] px-10 py-2'>
							Next
						</button>
					</div>
					<div className='text-center'>
						<button type='button' onClick={() => history.goBack()} className='btn-link'>
							<i className='uil uil-arrow-left' /> Go back
						</button>
					</div>
				</form>
			</div>
		</Fragment>
	)
}

StepForm = reduxForm({
	form: 'onboarding_step_three',
	enableReinitialize: true,
})(StepForm)

// Decorate with connect to read form values
const selector = formValueSelector('onboarding_step_three') // <-- same as form name
StepForm = connect((state) => {
	// can select values individually
	const password = selector(state, 'password')

	return {
		password,
	}
})(StepForm)

export default StepForm
