export const subscriptionPlans = (plans) => {
	const yearly = []
	const monthly = []
	const Standalone = []

	plans.forEach((plan) => {
		if (Array.isArray(plan.prices)) {
			plan.prices.forEach((price) => {
				if (price.recurring) {
					const { interval } = price.recurring
					switch (interval) {
						case 'year':
							yearly.push({ ...plan, price })
							break
						case 'month':
							monthly.push({ ...plan, price })
							break
						default:
							break
					}
				}
				if (price?.type === 'one_time') {
					Standalone.push({ ...plan, price })
				}
			})
		}
	})

	const result = {}
	if (yearly.length > 0) result.yearly = yearly
	if (monthly.length > 0) result.monthly = monthly
	if (Standalone.length > 0) result.Standalone = Standalone

	return result
}
