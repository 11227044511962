import classNames from 'classnames'
import { Fragment } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

const Navs = ({ tabState, isEditMode }) => {
	const [activeTab, setactiveTab] = tabState

	const toggleCustomJustified2 = (tab) => {
		if (activeTab !== tab) {
			setactiveTab(tab)
		}
	}

	const menus = [
		{ id: '1', label: 'Info', isEditMode: true },
		// { id: '2', label: 'Documents', isEditMode: false },
		{ id: '3', label: 'Workflows', isEditMode: true },
		{ id: '4', label: 'Additional Files', isEditMode: true },
		{ id: '5', label: 'Notes', isEditMode: true },
	]

	return (
		<Nav tabs className='nav-tabs-custom !border-none flex flex-nowrap'>
			{menus
				.filter((m) => {
					if (isEditMode) {
						return m.isEditMode === true
					} else {
						return m
					}
				})
				.map((menu, idx) => {
					return (
						<Fragment key={idx}>
							<NavItem>
								<NavLink
									style={{ cursor: 'pointer' }}
									className={classNames(
										{
											active: activeTab === menu?.id,
										},
										'py-3 nav_smaller_alignment',
									)}
									onClick={() => {
										toggleCustomJustified2(menu?.id)
									}}>
									<span className='d-sm-block text-md font-bold'>
										{menu?.label}
									</span>
								</NavLink>
							</NavItem>
						</Fragment>
					)
				})}
		</Nav>
	)
}

export default Navs
