import { Fragment, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { useUser } from 'hooks'
import {
	useLazyGetMySubscriptionQuery,
	useLazyGetTeamSubscriptionQuery,
} from 'modules/subscriptions/user-subscriptions-api'
import CurrentSubscription from './current-subscription'
import DangerZone from './danger-zone'
import UsageSubscription from './usage-subscription'

const ActiveSubscription = ({ activeTab, teamId }) => {
	const { user, group } = useUser()
	const [fetchMySubscription, { isLoading, data }] = useLazyGetMySubscriptionQuery()
	const [fetchTeamSubscription, { isLoading: isFetching, data: teamData }] =
		useLazyGetTeamSubscriptionQuery()

	useEffect(() => {
		if (activeTab === 3 && user?.id) {
			fetchMySubscription(user?.id)
		}
		if (activeTab === 4 && teamId) {
			fetchTeamSubscription({ team_id : teamId })
		}
	}, [activeTab, user, teamId])

	const $subscription = useMemo(() => {
		if (activeTab === 3) return data?.data
		if (activeTab === 4) return teamData?.data
		return null
	}, [activeTab, data, teamData])
	const subscription = useMemo(() => $subscription?.subscription, [$subscription])

	return (
		<Fragment>
			{isLoading || isFetching ? (
				<SpinLoader />
			) : (
				<Fragment>
					{subscription ? (
						<div className='grid grid-cols-1 gap-8'>
							<CurrentSubscription subscription={subscription} />

							<UsageSubscription $subscription={$subscription} />

							{/* <PaymentsDetails subscription={subscription} /> */}
							{subscription?.stripeSubscriptionetails?.status !== 'canceled' &&
								group?.slug === 'owner' && (
									<DangerZone subscription={subscription} />
								)}
						</div>
					) : (
						<div>
							{activeTab === 3 && (
								<p className='text-center text-gray-500'>
									No active subscription were found. Would you like to{' '}
									<Link to='/pricing' className='font-medium hover:!underline'>
										subscribe
									</Link>{' '}
									now?
								</p>
							)}
							{activeTab === 4 && (
								<p className='text-center text-gray-500'>
									No active subscription were found
								</p>
							)}
						</div>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

export default ActiveSubscription
