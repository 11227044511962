import { Transition } from '@headlessui/react'
import { capitalize } from 'lodash'
import { useState } from 'react'
import ProductCard from './product-card'

const PricingTable = ({ plans, onSubscribe }) => {
	const plan_types = Array.isArray(Object.keys(plans)) ? Object.keys(plans) : []
	const [billingPeriod, setBillingPeriod] = useState(plan_types[0] || 'monthly')
	const activePlans = Array.isArray(plans[billingPeriod]) ? plans[billingPeriod] : []
	const [isTransitioning, setIsTransitioning] = useState(false)

	const handleBillingPeriodChange = (plan_type) => {
		if (plan_type !== billingPeriod) {
			setIsTransitioning(true)
			setTimeout(() => {
				setBillingPeriod(plan_type)
				setIsTransitioning(false)
			}, 200)
		}
	}

	return (
		<section className='py-4 sm:py-8 xl:px-[13%]'>
			<div className='mx-auto w-full px-4 sm:px-6 lg:px-8'>
				<div className='mb-8 flex w-full flex-col items-center text-center'>
					<h2 className='text-2xl font-medium capitalize leading-6 text-gray-900 sm:text-3xl'>
						Choose your plan
					</h2>
					<div className='mt-2 w-full max-w-2xl'>
						<p className='text-sm text-gray-600 sm:text-base'>
							Our pricing is modular and team-based, designed to accommodate teams of
							all sizes. Smaller teams have some flexibility before needing to
							purchase additional seats. Our goal is to ensure our product scales
							seamlessly with the growth and evolving needs of your team.
						</p>
					</div>
				</div>

				<div className='mx-auto mb-6 flex max-w-fit justify-center rounded-md border border-gray-300 bg-gray-200 p-1'>
					{plan_types.map((plan_type, idx) => (
						<button
							key={idx}
							type='button'
							onClick={() => handleBillingPeriodChange(plan_type)}
							className={`rounded-md px-6 py-1 text-sm sm:text-base ${
								billingPeriod === plan_type
									? 'bg-main font-medium text-white'
									: 'text-gray-500 hover:text-gray-700'
							}`}>
							{capitalize(plan_type)}
						</button>
					))}
				</div>

				<div className='grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3'>
					{activePlans.map((activePlan, idx) => (
						<Transition
							key={activePlan?.priceId || idx}
							show={!isTransitioning}
							appear={true}
							enter='transition-all duration-700 ease-in-out'
							enterFrom='opacity-0 translate-y-8 scale-95'
							enterTo='opacity-100 translate-y-0 scale-100'
							leave='transition-all duration-500 ease-in-out'
							leaveFrom='opacity-100 translate-y-0 scale-100'
							leaveTo='opacity-0 translate-y-4 scale-95'>
							<ProductCard activePlan={activePlan} onSubscribe={onSubscribe} />
						</Transition>
					))}
				</div>
			</div>
		</section>
	)
}

export default PricingTable
