import { createApi } from '@reduxjs/toolkit/query/react'
import { transformResponse } from 'modules/assigned-workflows/helpers'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

export const assignedWorkflowApi = createApi({
	reducerPath: 'AssignedWorkflow',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['AssignedWorkflow'],
	endpoints: (builder) => ({
		getAssignedWorkflows: builder.query({
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['AssignedWorkflow'],
			transformResponse: (response, _, arg) => {
				const workflows = Array.isArray(response?.data?.workflows)
					? response?.data?.workflows.map((workflow, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: workflow?.id,
							title: workflow?.title || workflow?.workflow_template?.title,
							notes: workflow?.notes,
							status: workflow?.status,
							advisor: workflow?.advisor,
							client: workflow?.parentWorkflow?.client?.fullName || 'User',
							assigned_at: workflow?.created_at,
							assigned_by: workflow?.created_by,
							last_updated: workflow?.updated_at,
					  }))
					: []
				return {
					workflows,
					meta: response?.data?.meta,
				}
			},
		}),
		getAssignedWorkflow: builder.query({
			query: (url) => ({
				url: url,
				method: 'GET',
			}),
			providesTags: ['AssignedWorkflow'],
			transformResponse: (response) => transformResponse(response),
		}),
		saveAssignedWorkflow: builder.mutation({
			query: ({ payload, url }) => ({
				url: url,
				method: 'POST',
				data: payload,
			}),
		}),
		getWorkflowDocuments: builder.query({
			query: (url) => ({
				url: url,
				method: 'GET',
			}),
		}),
		uploadFile: builder.mutation({
			query: ({ payload }) => ({
				url: '/client/workflow/file',
				method: 'POST',
				data: payload,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		}),

		getWorkflowAssignPayload: builder.mutation({
			query: (id) => ({
				url: `/short-url/${id}`,
				method: 'GET',
				params: { action: true },
			}),
		}),

		assignPublicWorkflow: builder.mutation({
			query: (payload) => ({
				url: `/workflow/assign-public-link-workflow`,
				method: 'POST',
				data: payload,
			}),
		}),

		assignClientToTeam: builder.mutation({
			query: (teamId) => ({
				url: `/users/assign-client-to-team/${teamId}`,
				method: 'POST',
			}),
		}),
	}),
})

export const {
	useLazyGetAssignedWorkflowsQuery,
	useLazyGetAssignedWorkflowQuery,
	useSaveAssignedWorkflowMutation,
	useGetWorkflowDocumentsQuery,
	useUploadFileMutation,
	useGetWorkflowAssignPayloadMutation,
	useAssignPublicWorkflowMutation,
	useAssignClientToTeamMutation,
} = assignedWorkflowApi
