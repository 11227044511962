import { Header } from 'modules/tasks'
import TaskListingContainer from 'modules/tasks/task-listing'
import AppLayout from 'theme/layouts/app-layout'

const TasksListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Tasks',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<Header />

					<div className='page-body'>
						<TaskListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default TasksListing
