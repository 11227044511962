import classNames from 'classnames'
import { Fragment, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ButtonLink } from 'components/Common'
import { useMediaQuery } from 'react-responsive'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import PackageFormModal from './document-packages/package-form-modal'

const Header = () => {
	const canCreate = isAllowTo(permissions?.documentTemplate?.create)
	const canCreatePackage = isAllowTo(permissions?.documentPackage?.create)
	const location = useLocation()
	const [isOpen, setIsOpen] = useState(false)
	const openModal = () => setIsOpen(true)
	const closeModal = () => setIsOpen(false)
	const tabs = useMemo(
		() => [
			{
				label: 'Documents',
				href: '/documents',
				subTabs: [
					{
						label: 'Document Templates',
						href: '/documents',
						permission: isAllowTo(permissions?.documentTemplate?.list),
					},
					{
						label: 'Deleted Document Templates',
						href: '/deleted-document-templates',
						permission: isAllowTo(permissions?.documentTemplate?.deletedList),
					},
				],
				permission:
					isAllowTo(permissions?.documentTemplate?.list) ||
					isAllowTo(permissions?.documentTemplate?.deletedList),
			},
			{
				label: 'Document Packages',
				href: '/document-packages',
				permission: isAllowTo(permissions?.documentPackage?.list),
			},
			{
				label: 'Completed Documents',
				href: '/completed-documents',
				permission: isAllowTo(permissions?.completedDocument?.list),
			},
		],
		[],
	)
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	const activeTab = useMemo(() => {
		const matchingTab = tabs.find(
			(tab) =>
				tab.href === location.pathname ||
				(tab.subTabs && tab.subTabs.some((subTab) => subTab.href === location.pathname)),
		)
		return matchingTab || null
	}, [location?.pathname, tabs])

	return (
		<Fragment>
			<PackageFormModal open={isOpen} onClose={closeModal} />
			<div className=' page-header bg-white !pt-0'>
				<div className='flex w-full flex-col'>
					<div className='doc_listing_flex mb-3 flex w-full items-center justify-between md:hidden'>
						<div className='max-w-[733px]'>
							<h4 className='page-title mb-3'>{activeTab?.label}</h4>
							{/* <p className='text-xs text-[#74788D]'>
								The most important feature in the workflow section is the creating
								one. When assigning documents/packages here,
								<br />
								do not ignore to fill in all the required fields completely and
								follow the document/package adding rules.
							</p> */}
						</div>

						{canCreate && location?.pathname === tabs[0]?.href && isMobile ? (
							<ButtonLink to='/documents/create' color='primary'>
								Create Document
							</ButtonLink>
						) : undefined}

						{canCreatePackage && location?.pathname === tabs[1]?.href && isMobile && (
							<button className='btn btn-primary' onClick={openModal} color='primary'>
								Create Document Package
							</button>
						)}
					</div>
					<div className='-mb-5 flex space-x-2'>
						{tabs
							.filter((item) => item?.permission)
							.map((tab, i) => (
								<Link
									to={tab.href}
									key={i}
									className={classNames(
										'tabs_alignment h-full border-b-2 border-transparent px-[10px] py-[10px] text-sm font-bold text-gray-400',
										{
											'!border-main text-main': tab?.href == activeTab?.href,
										},
									)}>
									{tab.label}
								</Link>
							))}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Header
