import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as ReadymadeTemplateIcon } from 'assets/svg/readymade-template-icon.svg'
import classNames from 'classnames'
import TeamDropdown from 'components/Common/TeamDropdown'
import { useUser } from 'hooks'
import { snakeCase } from 'lodash'
import MetisMenu from 'metismenujs'
import { FILE_MANAGER_ROUTES } from 'modules/file-manager/routes'
import PropTypes from 'prop-types'
import React, { createElement, Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
const SidebarContent = (props) => {
	const ref = useRef()
	const { isSuperAdmin } = useSelector((state) => state.Permissions)
	const { group } = useUser()
	const currentGroup = useMemo(() => snakeCase(group?.name), [group])

	const activateParentDropdown = useCallback((item) => {
		item.classList.add('active')
		const parent = item.parentElement
		const parent2El = parent.childNodes[1]
		if (parent2El && parent2El.id !== 'side-menu') {
			parent2El.classList.add('mm-show')
		}

		if (parent) {
			parent.classList.add('mm-active')
			const parent2 = parent.parentElement

			if (parent2) {
				parent2.classList.add('mm-show') // ul tag

				const parent3 = parent2.parentElement // li tag

				if (parent3) {
					parent3.classList.add('mm-active') // li
					parent3.childNodes[0].classList.add('mm-active') //a
					const parent4 = parent3.parentElement // ul
					if (parent4) {
						parent4.classList.add('mm-show') // ul
						const parent5 = parent4.parentElement
						if (parent5) {
							parent5.classList.add('mm-show') // li
							parent5.childNodes[0].classList.add('mm-active') // a tag
						}
					}
				}
			}
			scrollElement(item)
			return false
		}
		scrollElement(item)
		return false
	}, [])

	// Use ComponentDidMount and ComponentDidUpdate method symultaniously
	useEffect(() => {
		const pathName = props.location.pathname

		new MetisMenu('#side-menu')
		let matchingMenuItem = null
		const ul = document.getElementById('side-menu')
		const items = ul.getElementsByTagName('a')
		for (let i = 0; i < items.length; ++i) {
			if (pathName === items[i].pathname) {
				matchingMenuItem = items[i]
				break
			}
		}
		if (matchingMenuItem) {
			activateParentDropdown(matchingMenuItem)
		}
	}, [props.location.pathname, activateParentDropdown])

	useEffect(() => {
		ref.current.recalculate()
	})

	function scrollElement(item) {
		if (item) {
			const currentPosition = item.offsetTop
			if (currentPosition > window.innerHeight) {
				ref.current.getScrollElement().scrollTop = currentPosition - 300
			}
		}
	}

	const systemMenu = [
		// {
		// 	label: 'Notes',
		// 	href: '/notes',
		// 	icon: <i className='uil-notes'></i>,
		// 	permission: isAllowTo(permissions.note.list),
		// },
		{
			label: 'Clients',
			href: '/clients',
			icon: <i className='uil-user-square'></i>,
			permission:
				isAllowTo(permissions?.client?.list) || isAllowTo(permissions?.client?.invitedList),
		},
		{
			label: 'File Manager',
			href: '/file-manager',
			icon: <i className='uil-folder'></i>,
			permission: ['owner', 'advisor'].includes(currentGroup),
			items: [
				{
					label: 'Home',
					icon: 'uil-home-alt',
					href: FILE_MANAGER_ROUTES.home(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},

				{
					label: 'All Folders',
					icon: 'uil-folder',
					href: FILE_MANAGER_ROUTES.allFolders(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
				{
					label: 'All Documents',
					iconType: 'svg',
					icon: DocumentIcon,
					href: FILE_MANAGER_ROUTES.myDocuments(),
					permission: ['owner', 'advisor'].includes(group?.slug),
				},
				{
					label: 'Readymade Templates',
					iconType: 'svg',
					icon: ReadymadeTemplateIcon,
					tag: 'By Experivise',
					href: FILE_MANAGER_ROUTES.readyMadeTemplate(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
				{
					label: 'Completed',
					href: '/file-manager/completed-documents?page=1&limit=10',
					icon: 'uil-file-check',
					permission: isAllowTo(permissions?.completedDocument?.list),
				},
				{
					label: 'Starred',
					icon: 'uil-star',
					href: FILE_MANAGER_ROUTES.starred(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
				{
					label: 'Trash',
					icon: 'uil-trash-alt',
					href: FILE_MANAGER_ROUTES.trash(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
			],
		},
		// {
		// 	label: 'Documents',
		// 	href: '/documents',
		// 	icon: <i className='uil-file-alt'></i>,
		// 	permission:
		// 		(isAllowTo(permissions?.documentTemplate?.list) ||
		// 			isAllowTo(permissions?.documentPackage?.list) ||
		// 			isAllowTo(permissions?.completedDocument?.list)) &&
		// 		['owner', 'advisor'].includes(currentGroup),
		// 	items: [
		// 		{
		// 			label: 'Documents',
		// 			href: '/documents',
		// 			icon: <i className='uil-file-alt'></i>,
		// 			permission: isAllowTo(permissions?.documentTemplate?.list),
		// 		},
		// 		{
		// 			label: 'Documents Packages',
		// 			href: '/document-packages',
		// 			icon: <i className='uil-file-alt'></i>,
		// 			permission: isAllowTo(permissions?.documentPackage?.list),
		// 		},
		// 		{
		// 			label: 'Completed Documents',
		// 			href: '/completed-documents?page=1&limit=10',
		// 			icon: <i className='uil-file-check'></i>,
		// 			permission: isAllowTo(permissions?.completedDocument?.list),
		// 		},
		// 	],
		// },

		{
			label: 'Workflows',
			href: '/workflows',
			icon: <i className='uil-channel'></i>,
			permission:
				(isAllowTo(permissions?.workflowTemplate?.list) ||
					isAllowTo(permissions?.workflowOutstandings?.list) ||
					isAllowTo(permissions?.workflowFinalReview?.list) ||
					isAllowTo(permissions?.assignedWorkflow?.list)) &&
				['owner', 'advisor'].includes(currentGroup),
			items: [
				{
					label: 'Templates',
					href: '/workflows?page=1&limit=10',
					icon: 'far fa-file-code',
					permission: isAllowTo(permissions?.workflowTemplate?.list),
				},
				{
					label: 'Assigned',
					href: '/assigned-workflows?page=1&limit=10',
					icon: 'fa fa-tasks !text-[15px]',
					permission: isAllowTo(permissions?.assignedWorkflow?.list),
				},
				{
					label: 'Summary',
					href: '/workflows-outstandings?page=1&limit=10',
					icon: 'uil-document-layout-left',
					permission: isAllowTo(permissions?.workflowOutstandings?.list),
				},
				{
					label: 'In Review',
					href: '/workflows-final-reviews?page=1&limit=10',
					icon: 'fas fa-comment !text-[15px]',
					permission: isAllowTo(permissions?.workflowFinalReview?.list),
				},
			],
		},
		{
			label: 'Portfolio',
			href: '/portfolio',
			icon: <i className='uil-graph-bar'></i>,
			permission: !['client'].includes(currentGroup),
		},

		{
			label: 'Assigned Workflows',
			href: '/assigned-workflows?page=1&limit=10',
			icon: <i className='uil-channel'></i>,
			permission: isAllowTo(permissions?.assignedWorkflow?.list) && currentGroup === 'client',
		},
		{
			label: 'Completed Documents',
			href: '/completed-documents?page=1&limit=10',
			icon: <i className='uil-file-check'></i>,
			permission:
				isAllowTo(permissions?.completedDocument?.list) && currentGroup === 'client',
		},
		{
			label: 'Tasks',
			href: '/tasks',
			icon: <i className='uil-ticket'></i>,
			permission: isAllowTo(permissions?.task?.list),
		},
		{
			type: 'heading',
			label: 'My Team',
			permission:
				isAllowTo(permissions?.teamMember?.list) ||
				isAllowTo(permissions?.teamMember?.inviteList),
		},
		{
			label: 'Team Members',
			href: '/team-members',
			icon: <i className='uil-users-alt'></i>,
			permission:
				isAllowTo(permissions?.teamMember?.list) ||
				isAllowTo(permissions?.teamMember?.inviteList),
		},
	]

	const superAdminMenu = [
		{
			label: 'File Manager',
			href: '/file-manager',
			icon: <i className='uil-folder'></i>,
			permission: isSuperAdmin,
			items: [
				{
					label: 'All Folders',
					icon: 'uil-folder',
					href: FILE_MANAGER_ROUTES.allFolders(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
				{
					label: 'Readymade Templates',
					iconType: 'svg',
					icon: ReadymadeTemplateIcon,
					tag: 'By Experivise',
					href: FILE_MANAGER_ROUTES.readyMadeTemplate(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
				{
					label: 'Starred',
					icon: 'uil-star',
					href: FILE_MANAGER_ROUTES.starred(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
				{
					label: 'Trash',
					icon: 'uil-trash-alt',
					href: FILE_MANAGER_ROUTES.trash(),
					permission: ['owner', 'advisor'].includes(group?.slug) || isSuperAdmin,
				},
			],
		},
		{
			type: 'heading',
			label: 'User Management',
		},
		{
			label: 'Users',
			href: '/users',
			icon: <i className='uil-user'></i>,
		},
		{
			label: 'Roles',
			href: '/roles',
			icon: <i className='uil-chat-bubble-user'></i>,
		},
		{
			label: 'Teams',
			href: '/teams',
			icon: <i className='uil-users-alt'></i>,
		},
		{
			label: 'Subscriptions',
			href: '/subscriptions',
			icon: <i className='uil-file-alt'></i>,
		},
		// {
		// 	label: 'Payments',
		// 	href: '/payments',
		// 	icon: <i className='uil-wallet'></i>,
		// 	items: [
		// 		{
		// 			label: 'Payments',
		// 			href: '/payments',
		// 			icon: <i className='uil-file-alt'></i>,
		// 		},
		// 		{
		// 			label: 'Pending Subscription Payments',
		// 			href: '/pending-subscription-payments',
		// 			icon: <i className='uil-file-alt'></i>,
		// 		},
		// 	],
		// },
		// {
		// 	label: 'Subscription',
		// 	href: '/subscriptions',
		// 	icon: <i className='uil-file-alt'></i>,
		// 	items: [
		// 		{
		// 			label: 'Subscriptions',
		// 			href: '/subscriptions',
		// 			icon: <i className='uil-file-alt'></i>,
		// 		},
		// 		// {
		// 		// 	label: 'Features',
		// 		// 	href: '/features',
		// 		// 	icon: <i className='uil-file-alt'></i>,
		// 		// },
		// 	],
		// },
		{
			type: 'heading',
			label: 'Configurations',
		},
		{
			label: 'System Fields',
			href: '/system-fields',
			icon: <i className='uil-file-edit-alt'></i>,
		},
		{
			label: 'Settings',
			href: '/settings',
			icon: <i className='uil-setting'></i>,
		},
		{
			type: 'heading',
			label: 'Notification Setup',
		},
		{
			label: 'Announcements',
			href: '/announcements',
			icon: <i className='uil-megaphone'></i>,
		},
		{
			label: 'Email Templates',
			href: '/email-templates',
			icon: <i className='uil-envelope-alt'></i>,
		},
		{
			label: 'Notification Templates',
			href: '/notifications-templates',
			icon: <i className='uil-bell'></i>,
		},
	]

	const currentUrlSegment = useMemo(
		() => window.location.pathname.split('/')[1],
		[window.location.pathname],
	)

	const firstSegment = useMemo(() => currentUrlSegment.split('-')[0], [currentUrlSegment])

	return (
		<React.Fragment>
			<SimpleBar
				style={{ maxHeight: !isSuperAdmin ? '85%' : '100%' }}
				ref={ref}
				className='sidebar-menu-scroll'>
				<div id='sidebar-menu'>
					<ul className='metismenu list-unstyled' id='side-menu'>
						{!['client'].includes(currentGroup) ? (
							<li>
								<Link to='/dashboard' className='waves-effect'>
									<i className='uil-home-alt'></i>
									<span>Dashboard</span>
								</Link>
							</li>
						) : undefined}

						{isSuperAdmin ? (
							<Fragment>
								{superAdminMenu.map((menu, idx) => (
									<Fragment key={idx}>
										{menu?.type === 'heading' ? (
											<li className='menu-title border-t'>{menu?.label}</li>
										) : (
											<li
												className={classNames({
													'mm-active':
														menu.href === `/${currentUrlSegment}` ||
														menu.href === `/${firstSegment}`,
												})}>
												<Link
													to={menu.href}
													className={classNames(' waves-effect', {
														active:
															menu.href === `/${currentUrlSegment}` ||
															menu.href === `/${firstSegment}`,
														'has-arrow': Array.isArray(menu.items),
													})}>
													{menu.icon}
													<span>{menu.label}</span>
												</Link>
												{Array.isArray(menu.items) ? (
													<ul className='sub-menu'>
														{menu.items.map((submenu, idx) => (
															<li
																key={idx}
																className='line-clamp-1 leading-6'>
																<Link
																	to={submenu.href}
																	className={classNames(
																		'!line-clamp-1 ',
																		{
																			active:
																				submenu.href ===
																					window.location
																						.pathname ||
																				submenu.href ===
																					`/${currentUrlSegment}`,
																		},
																	)}
																	title={submenu.label}>
																	<div className='!line-clamp-1 flex items-center justify-between gap-2'>
																		{submenu?.iconType ===
																		'svg' ? (
																			createElement(
																				submenu?.icon,
																				{
																					height: 22,
																					style: {
																						display:
																							'inline',
																						marginLeft:
																							-3,
																					},
																				},
																			)
																		) : (
																			<i
																				className={`${submenu?.icon}  hover:text-main`}></i>
																		)}
																		<span className=''>
																			{submenu.label}
																		</span>
																	</div>
																</Link>
															</li>
														))}
													</ul>
												) : undefined}
											</li>
										)}
									</Fragment>
								))}
							</Fragment>
						) : undefined}

						{!isSuperAdmin ? (
							<Fragment>
								{systemMenu.map((menu, i) =>
									menu.permission ? (
										<Fragment key={i}>
											{menu?.type === 'heading' ? (
												<li className='menu-title border-t'>
													{menu?.label}
												</li>
											) : (
												<li
													className={classNames({
														'mm-active':
															menu.href === `/${currentUrlSegment}` ||
															menu.href === `/${firstSegment}`,
													})}>
													<Link
														to={menu.href}
														className={classNames(' waves-effect', {
															active:
																menu.href ===
																	`/${currentUrlSegment}` ||
																menu.href === `/${firstSegment}`,
															'has-arrow': Array.isArray(menu.items),
														})}>
														{menu.icon}
														<span>{menu.label}</span>
													</Link>
													{Array.isArray(menu.items) ? (
														<ul className='sub-menu'>
															{menu.items.map((submenu, idx) => (
																<li
																	key={idx}
																	className='line-clamp-1 leading-6'>
																	<Link
																		to={submenu.href}
																		className={classNames(
																			'!line-clamp-1 ',
																			{
																				active:
																					submenu.href ===
																						window
																							.location
																							.pathname ||
																					submenu.href ===
																						`/${currentUrlSegment}`,
																			},
																		)}
																		title={submenu.label}>
																		<div className='!line-clamp-1 flex items-center justify-between gap-2'>
																			{submenu?.iconType ===
																			'svg' ? (
																				createElement(
																					submenu?.icon,
																					{
																						height: 22,
																						style: {
																							display:
																								'inline',
																							marginTop:
																								-1,
																							marginLeft:
																								-3,
																						},
																					},
																				)
																			) : (
																				<i
																					className={`${submenu?.icon}  hover:text-main`}></i>
																			)}
																			<span className=''>
																				{submenu.label}
																			</span>
																		</div>
																	</Link>
																</li>
															))}
														</ul>
													) : undefined}
												</li>
											)}
										</Fragment>
									) : undefined,
								)}
							</Fragment>
						) : undefined}
					</ul>
				</div>
			</SimpleBar>

			{!isSuperAdmin && (
				<div className='position-absolute  bottom-0 '>
					<TeamDropdown />
				</div>
			)}
		</React.Fragment>
	)
}

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
