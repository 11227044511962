import { FIELD_MODE } from '../../helpers'
import DropdownFieldEdit from './dropdown-field-edit'
import DropdownFieldView from './dropdown-field-view'

const DropdownField = ({
	field,
	mode = 'edit',
	name,
	onChange,
	onAddOption,
	onRemoveOption,
	onDragEnd,
	is_read_mode,
}) => {
	switch (mode) {
		case FIELD_MODE.EDIT:
			return (
				<DropdownFieldEdit
					name={name}
					field={field}
					onAddOption={onAddOption}
					onRemoveOption={onRemoveOption}
					onDragEnd={onDragEnd}
				/>
			)

		case FIELD_MODE.VIEW:
			return (
				<DropdownFieldView
					name={name}
					field={field}
					onChange={onChange}
					is_read_mode={is_read_mode}
				/>
			)
	}
}

export default DropdownField
