import { TextField } from 'components/Forms/TextFieldComponent'
import { routes } from 'modules/system-fields'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'

const FiltersForm = () => {
	return (
		<form>
			<div className='flex justify-between py-2'>
				<div className='sm:min-w-[320px]'>
					<Field
						type='search'
						name='search'
						component={TextField}
						isSearch={true}
						placeholder='Search system fields...'
					/>
				</div>
				<div className=''>
					<Link
						to={routes.create()}
						className='float-right h-fit max-w-fit rounded bg-main px-3 py-2  text-white'>
						Create
					</Link>
				</div>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'system_filters',
})(FiltersForm)
