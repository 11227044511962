import Header from 'modules/settings/header'
import { SettingsListingContainer } from 'modules/settings/settings-listing.js'
import AppLayout from 'theme/layouts/app-layout'

const SettingsList = () => {
	return (
		<AppLayout
			meta={{
				title: 'System Settings',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header block bg-white sm:hidden'>
						<Header />
					</div>
					<div className='page-body'>
						<SettingsListingContainer />{' '}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SettingsList
