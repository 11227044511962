import { Field, FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { useAppRouter, useUser } from 'hooks'
import { useLazyCheckSubscriptionLimitQuery } from 'modules/authentication/auth-api'
import UpdateSubscriptionPopup from 'modules/profile/my-subscriptions/update-subscription-popup/update-subscription-popup'
import { useLazyGetMySubscriptionQuery } from 'modules/subscriptions/user-subscriptions-api'
import { routes } from 'modules/workflows'
import { useMediaQuery } from 'react-responsive'
import { useDataTable } from 'theme/ui/data-table'
import { DateRange } from 'theme/ui/filters'
import { Button, SearchField } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString, isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues } from '.'
import UserDropdown from './user-dropdown'

const Filters = ({ setPagination }) => {
	const history = useHistory()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const isCreatePermission = isAllowTo(permissions?.workflowTemplate?.create)
	const [fetchMySubscription, { isLoading, data }] = useLazyGetMySubscriptionQuery()
	const [checkSubscriptionLimit, { isLoading: isFetching, data: limit }] =
		useLazyCheckSubscriptionLimitQuery()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })
	const { navigate } = useAppRouter()
	const { user } = useUser()
	const $subscription = useMemo(() => data?.data, [data])
	const subscription = useMemo(() => $subscription?.subscription, [$subscription])
	const [isOpen, setIsOpen] = useState(false)
	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			history.push(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const activeFilters = getActiveFilters(parseQueryString)

	useEffect(() => {
		if (user?.id) {
			fetchMySubscription(user?.id)
			checkSubscriptionLimit('workflow')
		}
	}, [])

	const handleClick = () => {
		if (limit?.data?.availableLimit <= 0) {
			setIsOpen(true)
		} else {
			navigate(routes.create())
		}
	}
	return (
		<>
			<UpdateSubscriptionPopup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				subscription={subscription}
				title='Upgrade Your Plan'
				description='You’ve reached the workflow creation limit for your current subscription. Upgrade your plan to create more workflow.'
			/>
			<FormikProvider value={formik}>
				<form className='mb-3'>
					<div className='flex flex-col items-center  justify-between space-y-3 md:!flex-row '>
						<div className='flex w-full flex-wrap items-center gap-1'>
							<div className='alignment_holder min-w-[320px]'>
								<Field
									className='form-control'
									type='search'
									name='search'
									component={SearchField}
									placeholder='Search templates'
								/>
							</div>

							<UserDropdown name='role' />
							<DateRange name='date_range' />

							{activeFilters.length > 0 ? (
								<Button size='sm' variant='ghost' onClick={onResetFilters}>
									Clear filters
								</Button>
							) : undefined}
						</div>
						{isCreatePermission && !isMobile && (
							<Button
								type='button'
								variant='primary'
								onClick={handleClick}
								disabled={isLoading || isFetching}>
								Create
							</Button>
						)}
					</div>
				</form>
			</FormikProvider>
		</>
	)
}

export default Filters
