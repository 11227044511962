import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'theme/ui/forms'

const NylasModal = ({ isOpen, onClose, makePayment, isFetching }) => {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-3xl bg-white p-8 text-left align-middle shadow-xl transition-all'>
								{/* Modal Header */}
								<div className='flex items-center justify-between'>
									<h2 className='text-2xl font-semibold text-gray-800'>
										<i className='fas fa-credit-card mr-2 text-blue-500'></i>
										Payment for Email Integration
									</h2>
									<button
										type='button'
										onClick={onClose}
										className='text-gray-500 hover:text-gray-800'>
										<i className='fas fa-times text-2xl' />
									</button>
								</div>

								{/* Modal Body */}
								<div className='mt-6'>
									<p className='text-sm text-gray-600'>
										To link your email account, please complete the payment.
										This will ensure your workflow-related emails are sent from
										your personal email address and not the Experivise email
										address.
									</p>

									{/* Payment Details Section */}
									<div className='mt-6'>
										<div className='flex items-center space-x-4 '>
											<div className='rounded-full bg-green-100 p-2 text-green-500'>
												<i className='fas fa-check-circle text-2xl'></i>
											</div>
											<div className='flex flex-col'>
												<h4 className='m-0 my-1 p-0 text-lg font-semibold'>
													Total Amount
												</h4>
												<p className='m-0 my-1 p-0 text-xl font-bold text-green-500'>
													CA$5.00
												</p>
											</div>
										</div>
										<p className='mt-2 text-sm text-gray-500'>
											Once the payment is completed, you will be able to
											connect your email account.
										</p>
									</div>
								</div>

								{/* Modal Footer */}

								<div className='mb-1 mt-3 flex w-full flex-col gap-2 '>
									<Button
										variant='primary'
										type='button'
										block={true}
										isLoading={isFetching}
										onClick={makePayment}>
										Proceed to Payment
									</Button>
									<Button
										variant='white'
										type='button'
										block={true}
										onClick={onClose}>
										Cancel
									</Button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default NylasModal
