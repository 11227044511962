import classNames from 'classnames'
import { Field } from 'formik'

const TaskTitleField = () => {
	return (
		<Field name='task_title'>
			{({ field, meta: { touched, error } }) => {
				const onChange = (e) => {
					const textarea = e.target
					textarea.style.height = 'auto'
					textarea.style.minheight = `${Math.max(textarea.scrollHeight, 40)}px`
					field.onChange(e)
				}

				return (
					<div className='flex flex-col'>
						<input
							{...field}
							onChange={onChange}
							type='text'
							style={{ minHeight: '40px' }}
							placeholder='Enter task title'
							autoFocus
							className={classNames(
								'w-full resize-none overflow-hidden rounded !border p-2 text-lg font-bold text-gray-600 transition-all ease-in-out hover:bg-gray-100  focus:bg-white focus:outline-none focus:ring-2 focus:ring-main',
								{
									'!border-red-400': touched && error,
								},
							)}
						/>
						{touched && error ? (
							<div className='text-sm text-red-500'>{error}</div>
						) : undefined}
					</div>
				)
			}}
		</Field>
	)
}

export default TaskTitleField
