import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import { FEATURES_APP_ROUTES } from 'modules/subscriptions/routes'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'
import { useDeleteFeatureMutation } from '../features-api'

const FeatureActions = ({ feature }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const onOpen = () => setIsOpen(true)

	const [deleteFeature, { isLoading: isDeleting }] = useDeleteFeatureMutation()
	const onDeleteFeature = async (id) => {
		try {
			const response = await deleteFeature(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			onClose()
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isOpen={isOpen}
				onClose={onClose}
				isLoading={isDeleting}
				onConfirm={() => onDeleteFeature(feature?.id)}
			/>
			<div className='max-w-fit'>
				<div className='flex space-x-2'>
					<Link
						to={FEATURES_APP_ROUTES.edit(feature?.id)}
						className='action-btn btn-gray-outline'>
						<i className='uil-pen' style={{ fontSize: 16 }} />
						<span>Edit</span>
					</Link>
					<button type='button' className='action-btn btn-red-outline' onClick={onOpen} style={{display: 'none'}}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						<span>Delete</span>
					</button>
				</div>
			</div>
		</Fragment>
	)
}

export default FeatureActions
