import { createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import Status from 'modules/documents/document-templates/Status'
import { useState } from 'react'
import Badge from 'theme/ui/badge'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import { getDocumentIcon } from '.'
import Actions from './actions'
import FoldersColumn from './folders-column'

const DocumentsTable = ({
	pagination,
	setPagination,
	data,
	meta,
	rowSelection,
	setRowSelection,
	isLoading,
}) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const columnHelper = createColumnHelper()

	const columns = [
		{
			id: 'select',
			size: 30,
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<div>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},
		columnHelper.accessor((row) => row, {
			id: 'title',
			size: 200,
			header: () => <span>Title</span>,
			cell: (info) => {
				const elementId = `title-${info.getValue().id}`

				return (
					<div className='flex items-center'>
						<span>{getDocumentIcon(info.getValue()?.document_type)}</span>
						<span id={elementId} className='my-1 ml-2 line-clamp-1 text-sm font-medium'>
							{info.getValue().title}
						</span>
						{info.getValue()?.isStarred ? (
							<span className='ml-1'>
								<i className='bx bxs-star text-main' />
							</span>
						) : undefined}

						<ToolTip targetId={elementId} tooltipText={info.getValue().title} />
					</div>
				)
			},
		}),

		columnHelper.accessor((row) => row.document_type, {
			id: 'document_type',
			size: 170,
			header: () => (
				<div className='w-full text-center'>
					<span>Type</span>
				</div>
			),
			cell: (info) =>
				info.getValue() ? (
					<div className='flex justify-center'>
						{info.getValue() === 'document' ? (
							<Badge variant='secondary'>Mapped PDF</Badge>
						) : undefined}
						{info.getValue() === 'form' ? (
							<Badge variant='secondary'>{capitalize(info.getValue())}</Badge>
						) : undefined}
					</div>
				) : (
					''
				),
		}),

		columnHelper.accessor('status', {
			header: () => (
				<div className='w-full text-center'>
					<span>Status</span>
				</div>
			),
			cell: (info) => (
				<div className='flex w-full justify-center'>
					<Status status={info.getValue() || 0} />
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'folders',
			size: 200,
			header: () => <span>Folder</span>,
			cell: (info) => (
				<FoldersColumn
					key={info?.row?.id}
					folders={info.getValue().folders}
					document={info.getValue()}
				/>
			),
		}),

		columnHelper.accessor('version', {
			header: () => (
				<div className='w-full text-center'>
					<span>Version</span>
				</div>
			),
			size: 50,
			cell: (info) => (
				<div className='w-full text-center'>
					{info.getValue() > 0 ? (
						<span className='font-semibold text-gray-700'>v. {info.getValue()}</span>
					) : undefined}
				</div>
			),
		}),

		columnHelper.accessor((row) => row.last_edit, {
			id: 'last_edit',
			size: 170,
			header: () => (
				<div className='w-full text-right'>
					<span>Updated At</span>
				</div>
			),
			cell: (info) => (
				<div className='w-full justify-end text-right'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue())}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue())}
					</span>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 20,
			header: () => <>Actions</>,
			cell: (info) => (
				<Actions
					document={info.getValue()}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			setSorting={setSorting}
			pagination={pagination}
			setPagination={setPagination}
			sorting={sorting}
			meta={meta}
			variant='secondary'
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			isFullRowSelection={true}
			isOverflow={false}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default DocumentsTable
