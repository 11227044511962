import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import Filters from '../workflows-outstandings/filters'
import WorkflowFinalReviewTable from './wokflow-final-review-table'
import { useLazyGetWorkflowFinalReviewsQuery } from './workflow-final-reviews-apis'

const WorkflowFinalReviewsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchWorkflowFinalReviews, { data, isFetching: isLoading }] =
		useLazyGetWorkflowFinalReviewsQuery()

	useEffect(() => {
		if (location?.search) {
			fetchWorkflowFinalReviews(getParseQueryString(location?.search))
		}
	}, [location?.search])

	return (
		<Fragment>
			<Filters setPagination={setPagination} />
			<WorkflowFinalReviewTable
				meta={data?.meta}
				isLoading={isLoading}
				pagination={{
					pageSize: limit,
					pageIndex: page - 1,
				}}
				outstandings={data?.outstandings || []}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default WorkflowFinalReviewsContainer
