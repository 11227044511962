import { Field, Form, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'

import {
	useCreateDocumentPackageMutation,
	useUpdateDocumentPackageMutation,
} from 'modules/documents/document-package-api'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'

const CreateFolderForm = ({ data, type, onClose, parentId, refetchRecord }) => {
	const [isSubmitting, setSubmitting] = useState(false)
	const [createDocumentPackage] = useCreateDocumentPackageMutation()
	const [updateDocumentPackage] = useUpdateDocumentPackageMutation()

	const formik = useFormik({
		initialValues: {
			name: data?.title || '',
			search_type: {
				icon: 'uil-file-blank',
				label: 'All',
				value: 'all',
			},
		},
		onSubmit: async (values) => {
			if (type !== 'folder') return

			setSubmitting(true)
			try {
				const payload = { title: values.name }
				if (parentId) {
					payload.parentDocumentPackageId = parentId
				}
				const response = data?.id
					? await updateDocumentPackage({ payload, id: data.id })
					: await createDocumentPackage(payload)

				if (response?.data?.status === 200) {
					if (refetchRecord) {
						refetchRecord(parentId)
					}
					notification('success', response?.data?.message)
					onClose()
				}
				setSubmitting(false)
			} catch (error) {
				notification('error', error?.message)
			} finally {
				setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		if (data?.title) {
			formik.setFieldValue('name', data.title)
		}
	}, [data?.title])

	return (
		<FormikProvider value={formik}>
			<Form className='flex flex-col space-y-6 px-6'>
				<Field
					type='text'
					name='name'
					autoComplete='off'
					className='form-control w-full rounded-md !py-3'
					placeholder='Enter name...'
				/>
				<div className='mb-3 flex w-full flex-col gap-2'>
					<button
						type='submit'
						className={`w-full rounded-md border ${
							formik.values.name.length < 3 || isSubmitting
								? 'bg-gray-300'
								: 'border-main bg-main'
						} p-2 font-medium text-white`}
						disabled={formik.values.name.length < 3 || isSubmitting}>
						{isSubmitting ? 'Submitting..' : data?.id ? 'Update' : 'Create'}
					</button>

					<Button type='button' onClick={onClose} variant='primary-outline' block={true}>
						Cancel
					</Button>
				</div>
			</Form>
		</FormikProvider>
	)
}

export default CreateFolderForm
