import * as yup from 'yup'

import { capitalize } from 'lodash'
import notification from 'utilities/notification'
import SubscriptionForm from './subscription-form'

const PLAN_TYPES = {
	INDIVIDUAL: 'individual',
	TEAM: 'team',
	ENTERPRISE: 'enterprise',
}

const createOption = (value, isDisabled = false) => ({
	label: capitalize(value),
	value: value,
	isDisabled,
})

export const planTypeOptions = [
	createOption(PLAN_TYPES.INDIVIDUAL),
	createOption(PLAN_TYPES.TEAM),
	// createOption(PLAN_TYPES.ENTERPRISE),
]

export const DURATIONS = {
	DAILY: 'day',
	WEEKLY: 'week',
	MONTHLY: 'month',
	YEARLY: 'year',
}

export const META_OPTIONS = [
	{ value: 'max_users', label: 'Maximum Number of Users' },
	{ value: 'max_pdf_views', label: 'Maximum PDF Views' },
	{ value: 'max_workflows', label: 'Maximum Number of Workflows' },
	{ value: 'max_document_templates', label: 'Maximum Number of Document Templates' },
]

export const durationOptions = [
	// createOption(DURATIONS.DAILY),
	// createOption(DURATIONS.WEEKLY),
	createOption(DURATIONS.MONTHLY),
	createOption(DURATIONS.YEARLY),
]

export const currencyOptions = [
	{ label: 'Canadian Dollar', value: 'CAD' },
	// 	{ label: 'United States Dollar', value: 'USD' },
	// 	{ label: 'United Arab Emirates Dirham', value: 'AED' },
	// 	{ label: 'Afghan Afghani', value: 'AFN' },
	// 	{ label: 'Albanian Lek', value: 'ALL' },
	// 	{ label: 'Armenian Dram', value: 'AMD' },
	// 	{ label: 'Netherlands Antillean Guilder', value: 'ANG' },
	// 	{ label: 'Angolan Kwanza', value: 'AOA' },
	// 	{ label: 'Argentine Peso', value: 'ARS' },
	// 	{ label: 'Australian Dollar', value: 'AUD' },
	// 	{ label: 'Aruban Florin', value: 'AWG' },
	// 	{ label: 'Azerbaijani Manat', value: 'AZN' },
	// 	{ label: 'Bosnia-Herzegovina Convertible Mark', value: 'BAM' },
	// 	{ label: 'Barbadian Dollar', value: 'BBD' },
	// 	{ label: 'Bangladeshi Taka', value: 'BDT' },
	// 	{ label: 'Bulgarian Lev', value: 'BGN' },
	// 	{ label: 'Burundian Franc', value: 'BIF' },
	// 	{ label: 'Bermudian Dollar', value: 'BMD' },
	// 	{ label: 'Brunei Dollar', value: 'BND' },
	// 	{ label: 'Bolivian Boliviano', value: 'BOB' },
	// 	{ label: 'Brazilian Real', value: 'BRL' },
	// 	{ label: 'Bahamian Dollar', value: 'BSD' },
	// 	{ label: 'Botswana Pula', value: 'BWP' },
	// 	{ label: 'Belarusian Ruble', value: 'BYN' },
	// 	{ label: 'Belize Dollar', value: 'BZD' },

	// 	{ label: 'Congolese Franc', value: 'CDF' },
	// 	{ label: 'Swiss Franc', value: 'CHF' },
	// 	{ label: 'Chilean Peso', value: 'CLP' },
	// 	{ label: 'Chinese Yuan', value: 'CNY' },
	// 	{ label: 'Colombian Peso', value: 'COP' },
	// 	{ label: 'Costa Rican Colón', value: 'CRC' },
	// 	{ label: 'Cape Verdean Escudo', value: 'CVE' },
	// 	{ label: 'Czech Koruna', value: 'CZK' },
	// 	{ label: 'Djiboutian Franc', value: 'DJF' },
	// 	{ label: 'Danish Krone', value: 'DKK' },
	// 	{ label: 'Dominican Peso', value: 'DOP' },
	// 	{ label: 'Algerian Dinar', value: 'DZD' },
	// 	{ label: 'Egyptian Pound', value: 'EGP' },
	// 	{ label: 'Ethiopian Birr', value: 'ETB' },
	// 	{ label: 'Euro', value: 'EUR' },
	// 	{ label: 'Fijian Dollar', value: 'FJD' },
	// 	{ label: 'Falkland Islands Pound', value: 'FKP' },
	// 	{ label: 'British Pound Sterling', value: 'GBP' },
	// 	{ label: 'Georgian Lari', value: 'GEL' },
	// 	{ label: 'Gibraltar Pound', value: 'GIP' },
	// 	{ label: 'Gambian Dalasi', value: 'GMD' },
	// 	{ label: 'Guinean Franc', value: 'GNF' },
	// 	{ label: 'Guatemalan Quetzal', value: 'GTQ' },
	// 	{ label: 'Guyanese Dollar', value: 'GYD' },
	// 	{ label: 'Hong Kong Dollar', value: 'HKD' },
	// 	{ label: 'Honduran Lempira', value: 'HNL' },
	// 	{ label: 'Haitian Gourde', value: 'HTG' },
	// 	{ label: 'Hungarian Forint', value: 'HUF' },
	// 	{ label: 'Indonesian Rupiah', value: 'IDR' },
	// 	{ label: 'Israeli New Shekel', value: 'ILS' },
	// 	{ label: 'Indian Rupee', value: 'INR' },
	// 	{ label: 'Icelandic Króna', value: 'ISK' },
	// 	{ label: 'Jamaican Dollar', value: 'JMD' },
	// 	{ label: 'Japanese Yen', value: 'JPY' },
	// 	{ label: 'Kenyan Shilling', value: 'KES' },
	// 	{ label: 'Kyrgyzstani Som', value: 'KGS' },
	// 	{ label: 'Cambodian Riel', value: 'KHR' },
	// 	{ label: 'Comorian Franc', value: 'KMF' },
	// 	{ label: 'South Korean Won', value: 'KRW' },
	// 	{ label: 'Cayman Islands Dollar', value: 'KYD' },
	// 	{ label: 'Kazakhstani Tenge', value: 'KZT' },
	// 	{ label: 'Lao Kip', value: 'LAK' },
	// 	{ label: 'Lebanese Pound', value: 'LBP' },
	// 	{ label: 'Sri Lankan Rupee', value: 'LKR' },
	// 	{ label: 'Liberian Dollar', value: 'LRD' },
	// 	{ label: 'Lesotho Loti', value: 'LSL' },
	// 	{ label: 'Moroccan Dirham', value: 'MAD' },
	// 	{ label: 'Moldovan Leu', value: 'MDL' },
	// 	{ label: 'Malagasy Ariary', value: 'MGA' },
	// 	{ label: 'Macedonian Denar', value: 'MKD' },
	// 	{ label: 'Myanmar Kyat', value: 'MMK' },
	// 	{ label: 'Mongolian Tögrög', value: 'MNT' },
	// 	{ label: 'Macanese Pataca', value: 'MOP' },
	// 	{ label: 'Mauritian Rupee', value: 'MUR' },
	// 	{ label: 'Maldivian Rufiyaa', value: 'MVR' },
	// 	{ label: 'Malawian Kwacha', value: 'MWK' },
	// 	{ label: 'Mexican Peso', value: 'MXN' },
	// 	{ label: 'Malaysian Ringgit', value: 'MYR' },
	// 	{ label: 'Mozambican Metical', value: 'MZN' },
	// 	{ label: 'Namibian Dollar', value: 'NAD' },
	// 	{ label: 'Nigerian Naira', value: 'NGN' },
	// 	{ label: 'Nicaraguan Córdoba', value: 'NIO' },
	// 	{ label: 'Norwegian Krone', value: 'NOK' },
	// 	{ label: 'Nepalese Rupee', value: 'NPR' },
	// 	{ label: 'New Zealand Dollar', value: 'NZD' },
	// 	{ label: 'Panamanian Balboa', value: 'PAB' },
	// 	{ label: 'Peruvian Sol', value: 'PEN' },
	// 	{ label: 'Papua New Guinean Kina', value: 'PGK' },
	// 	{ label: 'Philippine Peso', value: 'PHP' },
	// 	{ label: 'Pakistani Rupee', value: 'PKR' },
	// 	{ label: 'Polish Złoty', value: 'PLN' },
	// 	{ label: 'Paraguayan Guaraní', value: 'PYG' },
	// 	{ label: 'Qatari Riyal', value: 'QAR' },
	// 	{ label: 'Romanian Leu', value: 'RON' },
	// 	{ label: 'Serbian Dinar', value: 'RSD' },
	// 	{ label: 'Russian Ruble', value: 'RUB' },
	// 	{ label: 'Rwandan Franc', value: 'RWF' },
	// 	{ label: 'Saudi Riyal', value: 'SAR' },
]

export const validationSchema = yup.object().shape({
	name: yup.string().required('Please enter a subscription name'),
	prices_data: yup
		.array()
		.of(
			yup.object().shape({
				unitAmount: yup
					.number()
					.typeError('Amount must be a valid number')
					.min(0, 'Amount cannot be negative')
					.required('Amount is required'),
			}),
		)
		.required('At least one price data entry is required'),
})

export const validationSchemaRecurring = yup.object().shape({
	name: yup.string().required('Please enter a subscription name'),
	prices_data: yup
		.array()
		.of(
			yup.object().shape({
				unitAmount: yup
					.number()
					.typeError('Amount must be a valid number')
					.min(0, 'Amount cannot be negative')
					.required('Amount is required'),
				recurring: yup.object().shape({
					interval: yup.mixed().required('Recurring interval is required'),
					interval_count: yup
						.number()
						.typeError('Interval count must be a number')
						.integer('Interval count must not be an dasimal value')
						.required('Interval count is required'),
				}),
			}),
		)
		.required('At least one price data entry is required'),
})

export const getInitialValues = (subscription) => {
	const mapPricesData = (prices = []) =>
		prices.length > 0
			? prices.map((price) => ({
					unitAmount: price?.unit_amount || 0,
					currency: currencyOptions.find(
						(option) => option.value?.toLowerCase() === price?.currency?.toLowerCase(),
					) || {
						label: 'Canadian Dollar',
						value: 'CAD',
					},
					recurring: price?.recurring
						? {
								interval: {
									value: price.recurring.interval,
									label: capitalize(price.recurring.interval),
								},
								interval_count: price.recurring.interval_count || 1,
						  }
						: undefined,
			  }))
			: [
					{
						unitAmount: 0,
						currency: { label: 'Canadian Dollar', value: 'CAD' },
						recurring: {
							interval: { value: 'month', label: 'Monthly' },
							interval_count: 1,
						},
					},
			  ]

	const mapMetadata = (metadata = {}) =>
		Object.entries(metadata).map(([key, value]) => {
			const option = META_OPTIONS.find((metaOption) => metaOption.value === key)
			return {
				key: option ? option : key,
				value: value || '',
			}
		}) || [{ key: '', value: '' }]
	return {
		name: subscription?.name || '',
		description: subscription?.description || '',
		prices_data: mapPricesData(subscription?.price),
		statement_descriptor: subscription?.statement_descriptor || '',
		metadata: subscription?.metadata
			? mapMetadata(subscription.metadata)
			: [{ key: '', value: '' }],
		marketing_features: subscription?.marketing_features?.length
			? subscription.marketing_features
			: [''],
	}
}

const convertMetadata = (metadataArray) => {
	const seenKeys = new Set()

	for (const item of metadataArray) {
		if (seenKeys.has(item.key.value)) {
			notification('error', `Duplicate key detected: ${item.key.label}`)
			return null
		}

		seenKeys.add(item.key.value)
	}

	return metadataArray.reduce((acc, item) => {
		acc[item.key.value] = item.value
		return acc
	}, {})
}

export const getPayload = (values, type) => {
	const metadata = values?.metadata?.length > 0 ? convertMetadata(values.metadata) : {}
	if (!metadata) return null // Exit if metadata validation fails

	const basePayload = {
		name: values?.name,
		description: values?.description,
		statement_descriptor: values?.statement_descriptor,
		marketing_features: values?.marketing_features,
		metadata,
	}

	// Helper function for default and recurring price data
	const formatPriceData = (price) => ({
		currency: price?.currency?.value,
		unitAmount: price?.unitAmount,
		recurring: price?.recurring
			? {
					interval: price.recurring.interval?.value,
					interval_count: price.recurring.interval_count,
			  }
			: undefined,
	})
	const formatPrice = (price) => ({
		currency: price?.currency?.value,
		unitAmount: price?.unitAmount,
	})

	if (type === 'Recurring') {
		basePayload.prices_data = values?.prices_data?.map(formatPriceData) || []
		basePayload.default_price_data = formatPriceData(values?.prices_data?.[0])
	} else {
		const nonRecurringPrice = formatPrice(values?.prices_data?.[0])
		basePayload.prices_data = [nonRecurringPrice]
		basePayload.default_price_data = nonRecurringPrice
	}
	return basePayload
}

export default SubscriptionForm
