import { Transition } from '@headlessui/react'
import { capitalize } from 'lodash'
import { useState } from 'react'
import ProductCard from './product-card'

const PricingTable = ({ plans, activePriceId, onSubscribe, title, description }) => {
	const plan_types = Array.isArray(Object.keys(plans)) ? Object.keys(plans) : []
	const [billingPeriod, setBillingPeriod] = useState(plan_types[0] || 'monthly')
	const activePlans = Array.isArray(plans[billingPeriod]) ? plans[billingPeriod] : []
	const [isTransitioning, setIsTransitioning] = useState(false)

	const handleBillingPeriodChange = (plan_type) => {
		if (plan_type !== billingPeriod) {
			setIsTransitioning(true)
			setTimeout(() => {
				setBillingPeriod(plan_type)
				setIsTransitioning(false)
			}, 200)
		}
	}
	return (
		<section className='py-1'>
			<div className='mx-auto w-full px-4 sm:px-6 lg:px-8'>
				<div className='mb-12 text-center'>
					<h2 className='text-2xl font-bold capitalize leading-6 text-gray-900'>
						{title}
					</h2>
					<p className='mt-4 text-sm text-gray-600'>{description}</p>
				</div>

				{/* Billing Period Buttons */}
				<div className='mx-auto mb-8 flex max-w-fit justify-center rounded-[8px] !border border-[#dddddd] bg-[#f3f3f3] p-[2px]'>
					{plan_types.map((plan_type, idx) => (
						<button
							key={idx}
							type='button'
							onClick={() => handleBillingPeriodChange(plan_type)}
							className={`rounded-md border border-gray-300 !px-8 !py-2 text-sm ${
								billingPeriod === plan_type
									? 'bg-main font-medium text-white'
									: 'text-gray-500 hover:text-gray-700'
							}`}>
							{capitalize(plan_type)}
						</button>
					))}
				</div>

				{/* Animated Product Cards */}
				<div className='grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3'>
					{activePlans.map((activePlan, idx) => {
						const isActive = activePriceId === activePlan?.price?.priceId
						return (
							<Transition
								key={activePlan?.priceId || idx}
								appear={true}
								show={!isTransitioning}
								enter='transition-all duration-700 ease-in-out'
								enterFrom='opacity-0 translate-y-8 scale-95'
								enterTo='opacity-100 translate-y-0 scale-100'
								leave='transition-all duration-500 ease-in-out'
								leaveFrom='opacity-100 translate-y-0 scale-100'
								leaveTo='opacity-0 translate-y-4 scale-95'>
								<div className='h-full'>
									<ProductCard
										activePlan={activePlan}
										isActive={isActive}
										onSubscribe={onSubscribe}
									/>
								</div>
							</Transition>
						)
					})}
				</div>
			</div>
		</section>
	)
}

export default PricingTable
