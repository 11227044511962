import classNames from 'classnames'
import { Field, getIn } from 'formik'
import { capitalize } from 'lodash'
import { ChevronDown } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import FilterButton from 'theme/ui/filter-button'

const UserDropdown = ({ name }) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggleDropdown = () => setIsOpen(!isOpen)

	const roleOptions = useMemo(
		() => [
			{ label: 'Owner', value: 'owner' },
			{ label: 'Advisor', value: 'advisor' },
		],
		[],
	)

	return (
		<Field name={name}>
			{({ form }) => {
				const value = getIn(form?.values, name)
				const isActive = !!value

				const handleSelect = (option) => {
					form.setFieldValue(name, option)
				}

				return (
					<Dropdown isOpen={isOpen} toggle={() => toggleDropdown()}>
						<FilterButton
							isActive={isActive}
							onClear={() => form.setFieldValue(name, null)}>
							<DropdownToggle
								tag='button'
								type='button'
								className={classNames(
									'btn btn-secondary z-10 max-w-fit',
									isActive ? '!bg-main/20 !text-main' : '',
								)}>
								<div className='flex items-center'>
									<span className='!ml-[17px]'>Role</span>
									{isActive ? (
										<div className='flex items-center space-x-1'>
											<span className='ml-1 text-gray-500'>|</span>
											<span className='space-x-1 font-bold'>
												{capitalize(value?.value)}
											</span>
										</div>
									) : undefined}
									<span className='mt-[2px] pl-[2px]'>
										<ChevronDown size={16} />
									</span>
								</div>
							</DropdownToggle>
						</FilterButton>
						<DropdownMenu className='mt-2 px-0 py-2 shadow-md'>
							<ul className='m-0 p-0'>
								{roleOptions.map((option, idx) => (
									<li
										key={idx}
										className={classNames(
											value?.value === option.value
												? 'exp-dropdown-item-active'
												: 'exp-dropdown-item',
										)}
										onClick={() => handleSelect(option)}>
										<span>{option?.label}</span>
									</li>
								))}
							</ul>
						</DropdownMenu>
					</Dropdown>
				)
			}}
		</Field>
	)
}

export default UserDropdown
