import { Field, Form, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'

import { snakeCase } from 'lodash'
import { SwitchField } from 'theme/ui/forms'
import FormDesignContainer from './form-design-container'
import { getFieldOptions, validationSchema } from './helpers'

const FormDesignerForm = () => {
	const formik = useFormik({
		initialValues: {
			is_view_mode: false,
			fields: [getFieldOptions(`Question 1`, snakeCase(`Question 1`))],
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			console.log(values)
		},
	})
	const fields = useMemo(
		() => (Array.isArray(formik?.values?.fields) ? formik?.values?.fields : []),
		[formik?.values],
	)
	const is_view_mode = useMemo(() => formik?.values?.is_view_mode, [formik?.values])
	const completed_fields = fields.filter(
		(f) => f?.type !== null && f.type !== undefined && f?.type !== '',
	).length

	const $values = useMemo(() => {
		const values = {}
		fields.forEach((field) => {
			Object.assign(values, {
				[field?.name]: field.value,
			})
		})
		return values
	}, [formik?.values])

	return (
		<FormikProvider value={formik}>
			<Form>
				<div className='mb-3 flex justify-between'>
					<div>
						{is_view_mode ? (
							<>
								<span>Values:</span>
								<pre>{JSON.stringify($values, null, 2)}</pre>
							</>
						) : undefined}
					</div>
					<Field
						component={SwitchField}
						disabled={completed_fields === 0}
						label='Preview'
						name='is_view_mode'
					/>
				</div>

				<FormDesignContainer
					formik={formik}
					fieldName='fields'
					fields={fields}
					is_view_mode={is_view_mode}
				/>
			</Form>
		</FormikProvider>
	)
}

export default FormDesignerForm
