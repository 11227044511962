import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useAppRouter, useUser } from 'hooks'
import AddOptions from 'modules/file-manager/all-folders-listing/add-options'
import { useMediaQuery } from 'react-responsive'
import * as actions from 'store/file-manager/actions'
import { popupKeys } from '.'
import OptionsList from './option-list'

const Actions = ({ isActionActive, item }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 1024px' })
	const shouldShowActions = isMobile || isActionActive
	const { isSuperAdmin } = useUser()
	const { navigate } = useAppRouter()
	const dispatch = useDispatch()

	const [popupStates, setPopupStates] = useState({
		[popupKeys.DOCUMENT_RENAME]: false,
		[popupKeys.FOLDER_RENAME]: false,
		[popupKeys.DOCUMENT_DELETE]: false,
		[popupKeys.FOLDER_DELETE]: false,
		[popupKeys.ADD_DOCUMENT_SHORTCUT]: false,
		[popupKeys.COPY_TO_FOLDER]: false,
	})

	const togglePopup = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: !prev[key],
		}))
	}

	const onOpenPopup = (key) => {
		console.log('key', key)

		setPopupStates((prev) => ({
			...prev,
			[key]: true,
		}))
	}

	const onClosePopup = (key) => {
		setPopupStates((prev) => ({
			...prev,
			[key]: false,
		}))
	}

	const attachInWorkflow = (data, type) => {
		const item = {
			title: data?.title,
			value: JSON.stringify({
				id: data?.id,
				minClients: data?.minClients,
			}),
		}
		if (type === 'folder') {
			dispatch(actions.setSelectedFolders([item]))
			navigate(`/workflows/create?folder=true`)
		} else {
			dispatch(actions.setSelectedDocuments([item]))
			navigate(`/workflows/create?document=true`)
		}
	}

	return (
		<div
			className='flex  items-center justify-end space-x-2 text-right'
			onClick={(event) => event.stopPropagation()}>
			{shouldShowActions ? (
				<div>
					{item?.type === 'document' ? (
						<Fragment>
							{!isSuperAdmin ? (
								<button
									type='button'
									onClick={() => attachInWorkflow(item, item?.type)}
									className='text-sm font-medium text-main'>
									<span>Use in Workflow</span>
									<i className='uil-angle-right text-base'></i>
								</button>
							) : undefined}
						</Fragment>
					) : (
						<Fragment>
							{isSuperAdmin ? (
								<AddOptions data={item} />
							) : (
								<button
									type='button'
									onClick={() => attachInWorkflow(item, item?.type)}
									className='text-sm font-medium text-main'>
									<span>Use in Workflow</span>
									<i className='uil-angle-right text-base'></i>
								</button>
							)}
						</Fragment>
					)}

					<OptionsList
						item={item}
						togglePopup={togglePopup}
						onOpenPopup={onOpenPopup}
						onClosePopup={onClosePopup}
						popupStates={popupStates}
					/>
				</div>
			) : undefined}
		</div>
	)
}

export default Actions
