import { getIn } from 'formik'
import AdvisorDropdown from './advisor-dropdown'

export const createAdvisorOption = (advisor, formValues, reviewers, fieldName) => ({
	fullName: `${advisor?.first_name} ${advisor?.last_name}`,
	first_name: advisor?.first_name,
	last_name: advisor?.last_name,
	email: advisor?.email,
	value: advisor?.id,
	avatar: advisor?.avatar,
	isDisabled: reviewers.includes(advisor?.id) && getIn(formValues, fieldName) !== advisor?.id,
})

export const createAdvisorOptionLabel = (advisor) => {
	return (
		<div className='flex items-center space-x-1'>
			<span className='font-bold text-gray-700'>{advisor?.fullName}</span>
			<span className='text-sm text-gray-500'>({advisor?.email})</span>
		</div>
	)
}

export default AdvisorDropdown
