import Header from 'modules/payments/header'
import PendingSubListingContainer from 'modules/payments/pending-subscription/pending-subscription/pending-sub-container'
import AppLayout from 'theme/layouts/app-layout'

const PendingSubscriptionListing = () => {
	return (
		<>
			<AppLayout
				meta={{
					title: 'Pending Subscription Payments',
				}}>
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<div className='page-header  bg-white'>
							<Header />
						</div>
						<div className='page-body'>
							<PendingSubListingContainer />
						</div>
					</div>
				</div>
			</AppLayout>
		</>
	)
}

export default PendingSubscriptionListing
