const Header = () => {
	return (
		<div className='flex w-full flex-col'>
			<div className='alignment mb-3 flex w-full justify-between gap-2'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>Announcements</h4>
				</div>
			</div>
		</div>
	)
}

export default Header
