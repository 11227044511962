import { FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { useAppRouter } from 'hooks'
import { useBatchDeleteDocumentsMutation } from 'modules/documents/document-api'
import AddToFolderPopup from 'modules/file-manager/my-documents-listing/add-to-folder-popup'
import AttachInWorkflowPopup from 'modules/file-manager/my-documents-listing/attach-in-workflow-popup/attach-in-workflow-popup'
import { getParsedRows } from 'modules/file-manager/my-documents-listing/filters'
import { useDataTable } from 'theme/ui/data-table'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { Button } from 'theme/ui/forms'
import { getPaginationParams } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import { getFormikFilterValues, getInitialValues } from '.'

const Filters = ({ setPagination, heading, selectedRows }) => {
	const { parseQuery, navigate, location } = useAppRouter()
	const initialValues = getInitialValues(parseQuery)
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isShortcutOpen, setIsShortcutOpen] = useState(false)
	const [attachWorkflowIsOpen, setAttachWorkflowIsOpen] = useState(false)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const [debounceValues] = useDebounce(formValues, 800)
	const parsedRows = useMemo(() => getParsedRows(selectedRows), [selectedRows])
	const [deleteDocuments, { isLoading: isDeleting }] = useBatchDeleteDocumentsMutation()

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])

	const onDeleteDocuments = async (documentIds) => {
		try {
			const payload = {
				documentIds: documentIds,
			}
			const response = await deleteDocuments(payload).unwrap()
			if (response?.status === 200) {
				setIsDeleteOpen(false)
				notification('success', 'Selected documents successfully deleted')
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			<FormikProvider value={formik}>
				<form>
					<div className='flex flex-col space-y-3'>
						<div className='flex items-center justify-between pb-3'>
							<h3 className='m-0 text-base'>{heading}</h3>

							{selectedRows?.length > 0 ? (
								<div className='flex items-center space-x-3'>
									<span className='text-sm font-semibold'>
										{selectedRows.length} documents selected
									</span>
									<div className='bg-white'>
										<Button
											type='button'
											size='sm'
											variant='primary-outline'
											onClick={() => setIsShortcutOpen(true)}>
											<i className='uil-plus'></i>
											<span>Add to folder</span>
										</Button>
									</div>

									<div className='bg-white'>
										<Button
											type='button'
											size='sm'
											variant='primary-outline'
											onClick={() => setAttachWorkflowIsOpen(true)}>
											<span>Attach in Workflow</span>
										</Button>
									</div>
									<div className='bg-white'>
										<Button
											type='button'
											size='sm'
											onClick={() => setIsDeleteOpen(true)}
											variant='danger-outline'>
											<span>Delete All</span>
										</Button>
									</div>
								</div>
							) : undefined}
						</div>
					</div>
				</form>
			</FormikProvider>
			<DeleteConfirmAlert
				isDeleting={isDeleting}
				isOpen={isDeleteOpen}
				onClose={() => setIsDeleteOpen(false)}
				onConfirm={() => onDeleteDocuments(parsedRows.map((doc) => doc?.id))}
				title={`Delete (${selectedRows.length}) Documents?`}
				subtitle='Are you sure you want to delete the selected documents record?'
			/>

			<AddToFolderPopup
				documents={parsedRows.map((doc) => doc?.id)}
				isOpen={isShortcutOpen}
				onClose={() => setIsShortcutOpen(false)}
			/>

			<AttachInWorkflowPopup
				isOpen={attachWorkflowIsOpen}
				onClose={() => setAttachWorkflowIsOpen(false)}
				selectedRows={parsedRows}
			/>
		</Fragment>
	)
}

export default Filters
