import { Fragment, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { statuses } from '..'
import WorkflowsLinksContainer from '../workflow-template/workflows-links-list/workflows-links-container'

const Heading = ({ open, title, updatesCount, links, id, outstanding }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [allStatusDot, setAllStatusDot] = useState([])
	const toggle = (event) => {
		event.stopPropagation()
		setIsOpen(!isOpen)
	}

	const getStatusColor = (status) => {
		const statusObj = statuses.find((s) => s.name === status)
		return statusObj ? { color: statusObj.color, name: statusObj.name } : '#000'
	}

	useEffect(() => {
		if (outstanding.workflows?.length > 0) {
			const uniqueColors = new Set(
				outstanding.workflows.map((elem) => getStatusColor(elem?.status)),
			)
			setAllStatusDot(Array.from(uniqueColors))
		}
	}, [outstanding.workflows])

	return (
		<Fragment>
			<Modal isOpen={isOpen} toggle={toggle} size='lg' fullscreen='lg' backdrop='static'>
				<ModalHeader toggle={toggle}>Workflow Links</ModalHeader>
				<ModalBody className='p-3'>
					<WorkflowsLinksContainer workflowId={id} />
				</ModalBody>
			</Modal>
			<div className='workflow_columns flex w-full items-center justify-between'>
				<div className='workflow-space-between flex items-center space-x-4 text-left'>
					<div className='flex items-center gap-2'>
						<i className='uil-sliders-v' />
						<span className='line-clamp-1 font-ibmplex text-lg font-bold' title={title}>
							{title}
						</span>
						{allStatusDot.length > 0 &&
							allStatusDot.map((status, ind) => (
								<span
									key={ind}
									className='m-0 p-0'
									title={status?.name}
									style={{
										width: '10px',
										height: '10px',
										backgroundColor: status?.color,
										borderRadius: '50%',
										display: 'inline-block',
									}}></span>
							))}
					</div>
					<div
						className='workflow_header_show'
						style={{
							fontSize: 28,
							display: 'none',
						}}>
						{open ? <i className='uil-angle-down' /> : <i className='uil-angle-up' />}
					</div>
				</div>

				<div className='margin-below flex items-center space-x-4'>
					{links?.length ? (
						<button
							className='workflow-height flex h-6 max-w-fit items-center justify-center space-x-1 rounded !border border-yellow-500 px-2 text-xs text-yellow-500 transition-all hover:bg-yellow-100'
							onClick={toggle}>
							<span className='!h-[6px] !w-[6px] rounded-full bg-yellow-500' />
							<span>{links?.length} Link(s) Available</span>
						</button>
					) : undefined}
					{updatesCount ? (
						<div className='workflow-height flex h-6 max-w-fit items-center justify-center space-x-1 rounded !border border-main px-2 text-xs text-main'>
							<span className='!h-[6px] !w-[6px] rounded-full bg-main' />
							<span>{updatesCount} Update(s) Available</span>
						</div>
					) : undefined}

					<div
						className='workflow_header_hide'
						style={{
							fontSize: 28,
						}}>
						{open ? <i className='uil-angle-up' /> : <i className='uil-angle-down' />}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Heading
