import classNames from 'classnames'
import { CalendarDays, X } from 'lucide-react'
import { createElement } from 'react'
import DatePicker from 'react-date-picker'

const DateFieldView = ({ name, field, onChange, is_read_mode }) => {
	const handleDateChange = (date) => {
		onChange(name, date?.toISOString().split('T')[0] || '')
	}

	return (
		<div className='form-group flex flex-col'>
			{field?.label ? (
				<label className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label}{' '}
						{field?.required ? <span className='text-red-500'>*</span> : undefined}
					</span>
				</label>
			) : undefined}

			<DatePicker
				format={field?.date_format?.value || 'dd-MM-yyyy'}
				// minDate={minDate}
				// maxDate={maxDate}
				value={field?.value}
				className={classNames('form-control rounded-md')}
				onChange={handleDateChange}
				clearIcon={createElement(X, {
					className: classNames('h-4 ', is_read_mode ? 'text-white' : 'text-gray-500'),
				})}
				calendarIcon={createElement(CalendarDays, {
					className: classNames('h-4 ', is_read_mode ? 'text-white' : 'text-gray-500'),
				})}
				disabled={is_read_mode}
			/>

			{field?.description ? (
				<span className='text-[13px] text-gray-400'>{field?.description}</span>
			) : undefined}
		</div>
	)
}

export default DateFieldView
