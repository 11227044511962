import { Tab } from '@headlessui/react'
import { FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo } from 'react'

import { useTabs } from 'contexts/tabs-context'
import { useAppRouter, useUser } from 'hooks'
import { FILE_MANAGER_ROUTES } from 'modules/file-manager/routes'
import { useDocmentViewer } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import { useLocation } from 'react-router-dom'
import { getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import {
	getAutoSavePayload,
	getCreateablePayload,
	getDocumentPublishablePayload,
	getFormPublishablePayload,
	getInitialValues,
	getValidationSchema,
} from '.'
import {
	useAutoSaveDocumentMutation,
	useCreateDocumentMutation,
	useUpdateDocumentMutation,
} from '../document-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from '../routes'
import { getMappedFields, toFormBuilder } from './helpers'
import TabsContent from './tabs-content'
import { savePdfDocument } from './tabs-content/signature-tab'
import TabsFooter from './tabs-footer'
import TabsHeader, { getTabs } from './tabs-header'

const DocumentTemplateForm = ({ document, setTitle }) => {
	const { activeTab, setActiveTab } = useTabs()
	const { params, navigate } = useAppRouter()
	const location = useLocation()
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = useMemo(() => getInitialValues(document), [document])
	const [createDocument] = useCreateDocumentMutation()
	const [updateDocument] = useUpdateDocumentMutation()
	const [autoSaveDocument, { isLoading: isAutoSaving }] = useAutoSaveDocumentMutation()
	// const formBuilder = useFormBuilder()
	const documentInstance = useDocmentViewer()
	const { isSuperAdmin } = useUser()

	const validationSchema = getValidationSchema(document?.document_type === 'form')[activeTab - 1]

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const nextTab = activeTab + 1
				const isFormType = values?.document_type === 'form'
				const tabs = getTabs(values?.signature_required === 'yes', isFormType)
				const totalTabs = tabs.length

				if (nextTab <= totalTabs) {
					if (activeTab === 1) {
						if (document) {
							setActiveTab(nextTab)
						} else {
							// create document for the first time
							const createablePayload = getCreateablePayload(values)
							const response = await createDocument(createablePayload)
							if (response?.data?.status === 200) {
								const document = response?.data?.data
								navigate(DOCUMENT_TEMPLATE_APP_ROUTES.createEdit(document?.id))
							}
						}
					} else {
						setActiveTab(nextTab)
					}
				} else {
					// publish the document

					let publishablePayload = null
					if (values?.document_type === 'form') {
						publishablePayload = getFormPublishablePayload(values)
					} else {
						const document_body = Array.isArray(values?.document_body)
							? values?.document_body
							: []
						const mappedFields = getMappedFields(document_body, formik)
						formik.setFieldValue('document_body', mappedFields)
						let documentBlob = null
						let signature_mapping = null
						if (documentInstance) {
							const docRes = await savePdfDocument(documentInstance)
							documentBlob = docRes.blob
							signature_mapping = docRes.signature_mapping
						}

						publishablePayload = getDocumentPublishablePayload(
							values,
							document,
							mappedFields,
							documentBlob,
							signature_mapping,
						)
					}

					const response = await updateDocument({
						id: params?.id,
						payload: publishablePayload,
					})
					if (response?.data?.status === 200) {
						notification('success', response?.data?.message)

						navigate(
							isSuperAdmin === 1
								? FILE_MANAGER_ROUTES.readyMadeTemplate()
								: FILE_MANAGER_ROUTES.myDocuments(),
						)
					}
				}
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				notification('error', error?.message)
			}
		},
	})
	const formValues = useMemo(() => formik?.values, [formik?.values])
	const documentType = formValues?.document_type
	const form_template_type = formValues?.form_template_type
	useEffect(() => {
		if (activeTab === 1 && parseQueryString?.isCreate) {
			if (formik?.values?.document_type === 'form') {
				setActiveTab(2)
			} else {
				setActiveTab(2)
			}
		}

		const fields = Array.isArray(formValues?.document_body) ? formValues?.document_body : []

		if (
			activeTab === 2 &&
			parseQueryString?.isCreate &&
			fields.length === 1 &&
			documentType === 'form' &&
			form_template_type === 'scoring' &&
			!fields[0]?.type
		) {
			formik.setFieldValue(`document_body[0].type`, 'radio')
		}
	}, [location, activeTab])

	const onAutoSave = async () => {
		if (params?.id) {
			const payload = getAutoSavePayload(formik?.values)
			await autoSaveDocument({ payload, id: params?.id })
		}
	}

	useEffect(() => {
		if (documentType === 'form') {
			formik.setFieldValue('signature_required', 'no')
			setTitle('Form')
		} else {
			setTitle('Documents')
		}
	}, [documentType])

	useEffect(() => {
		const fields = Array.isArray(formValues?.document_body) ? formValues?.document_body : []
		const $fields = fields.map((field) => ({
			...field,
			is_scored: form_template_type === 'scoring',
		}))
		formik.setFieldValue('document_body', [...$fields])
	}, [form_template_type])

	const documentMapping = useMemo(
		() =>
			Array.isArray(formik?.values?.document_mapping) ? formik?.values?.document_mapping : [],
		[formik?.values?.document_mapping],
	)
	const pdfFields = useMemo(
		() => (Array.isArray(document?.pdf_fields) ? document?.pdf_fields : []),
		[document?.pdf_fields],
	)

	useEffect(() => {
		if (document?.document_type === 'document') {
			const formBuilderData = toFormBuilder(documentMapping, pdfFields)
			formik.setFieldValue('document_body', formBuilderData)
		}
	}, [activeTab])

	return (
		<Fragment>
			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit} className='space-y-6'>
					<Tab.Group selectedIndex={activeTab - 1}>
						<TabsHeader formik={formik} />
						<TabsContent
							formik={formik}
							document={document}
							onAutoSave={onAutoSave}
							isAutoSaving={isAutoSaving}
						/>
						<TabsFooter formik={formik} />
					</Tab.Group>
				</form>
			</FormikProvider>
		</Fragment>
	)
}

export default DocumentTemplateForm
