import SubscriptionForm from 'modules/subscriptions/subscription-form'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const SubscriptionCreate = () => {
	return (
		<AppLayout
			meta={{
				title: 'Create Subscriptions',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Create Subscriptions'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>

					<div className='page-body'>
						<SubscriptionForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SubscriptionCreate
