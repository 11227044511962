import { useReactTable } from '@tanstack/react-table'

import { DataTablePagination } from 'components/Common/data-table'
import Header from './header'
import InviteItem from './invite-item'
import InviteItemSkeleton from './invite-item-skeleton'

const OwnerInvitesListing = ({ invites, isLoading, pagination, setPagination, meta }) => {
	const table = useReactTable({
		data: invites,
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
		enableSorting: true,
	})

	return (
		<div className='!p-6'>
			{meta && table ? <Header meta={meta} table={table} /> : undefined}

			{isLoading ? (
				<div className='flex flex-col divide-y rounded-md !border'>
					{new Array(6).fill(0).map((_, idx) => (
						<InviteItemSkeleton key={idx} />
					))}
				</div>
			) : (
				<>
					{invites.length > 0 ? (
						<div className='flex flex-col divide-y rounded-md !border-2'>
							{invites.map((invite, idx) => (
								<InviteItem invite={invite} key={idx} />
							))}
						</div>
					) : (
						<p className='text-center text-gray-500'>
							No pending invitations were found.
						</p>
					)}
				</>
			)}

			{meta && pagination && invites.length > 0 ? (
				<DataTablePagination meta={meta} table={table} showCount={false} />
			) : undefined}
		</div>
	)
}

export default OwnerInvitesListing
