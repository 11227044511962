import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat , formatDate } from 'utilities/helpers'
import { useMediaQuery } from 'react-responsive'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import PopoverButtons from 'theme/ui/popover'
import { getDuration } from '.'
import SubscriptionActions from './subscription-actions'

const SubscriptionTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()
	const isMobile = useMediaQuery({ query: '(max-width: 1024px' })

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor('name', {
			id: 'name',
			size: 200,
			header: () => <span>Name</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-full text-sm font-bold !text-gray-700'>
						{info.getValue()}
					</span>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'price',
			size: 250,
			header: () => <span>Price</span>,
			cell: (info) => {
				const price = info.getValue()?.price || []
				const peiceDetails = price?.map((price) => {
					return `${price?.unit_amount} ${[price?.currency?.toLowerCase()]} ${
						price?.recurring?.interval ? getDuration(price?.recurring?.interval) : ''
					}`
				})
				return (
					<div className='flex items-center gap-1'>
						<Badge variant='primary-outline' className='cursor-pointer' rounded={false}>
							{peiceDetails[0]}
						</Badge>
						{peiceDetails.length > 1 && (
							<PopoverButtons data={peiceDetails} title='Other prices' />
						)}
					</div>
				)
			},
		}),
		columnHelper.accessor('description', {
			id: 'description',
			size: 250,
			header: () => <span>Description</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-full text-sm font-normal !text-gray-500'>
						{info.getValue()}
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row.created_at, {
			id: 'created_at',
			size: 200,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Created At</span>
				</div>
			),
			cell: (info) => (
				<div className='justify-end w-full text-right'>
					<ToolTip
					targetId={`date-${info.row.index}`}
					tooltipText={dateFormat(info.getValue())} 
					placement='top'
					offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
					{formatDate(info.getValue())} 
					</span>
			  </div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 200,
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => <SubscriptionActions subscription={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			isOverflow={isMobile}
		/>
	)
}

export default SubscriptionTable
