import { capitalize } from 'lodash'

// eslint-disable-next-line no-unused-vars
const FilterBadge = ({ label, value, onClose, formik }) => {
	return (
		<span className='me-2 inline-flex items-center rounded bg-blue-100 px-2 py-1 text-sm font-medium text-blue-800 '>
			{capitalize(label)} : {formik?.values?.view_by?.label || value}
			<button
				onClick={onClose}
				type='button'
				className='ms-2 inline-flex items-center rounded bg-blue-200 p-1 text-sm text-blue-400 hover:bg-blue-300 hover:text-blue-900'
				aria-label='Remove'>
				<svg
					className='h-2 w-2'
					aria-hidden='true'
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 14 14'>
					<path
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
					/>
				</svg>
				<span className='sr-only'>Remove badge</span>
			</button>
		</span>
	)
}

export default FilterBadge
