import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { useLazyGetOneClientQuery } from 'modules/clients/client-apis'
import ClientDetailsCard from 'modules/clients/client-details'
import { NotFound } from 'modules/error-sections'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const ClientDetails = () => {
	const params = useParams()
	const [fetchClient, { data, isUninitialized, isLoading, isSuccess }] = useLazyGetOneClientQuery(
		params?.id,
	)

	useEffect(() => {
		if (params?.id) {
			fetchClient(params?.id)
		}
	}, [params?.id])

	return (
		<AppLayout
			meta={{
				title: 'Client Details',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Client Details'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						{isUninitialized || isLoading ? (
							<SpinLoader />
						) : (
							<>
								{isSuccess ? (
									<ClientDetailsCard client={data?.client} role={data?.role} />
								) : (
									<NotFound />
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ClientDetails
