import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

import { useLazyGetTaskTagsQuery } from 'modules/tasks/task-api'

const TagsQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 600)
	const defaultParams = { page: 1, limit: 20 }
	// const searchParams = debounceSearch
	// 	? { ...defaultParams, search: debounceSearch }
	// 	: defaultParams

	const [fetchTags, { data, isUninitialized, isLoading }] = useLazyGetTaskTagsQuery()

	useEffect(() => {
		fetchTags()
	}, [])

	return {
		options: Array.isArray(data?.tags)
			? data?.tags.map((tag) => ({
					...tag,
					value: tag?.id,
			  }))
			: [],
		isLoading: isUninitialized || isLoading,
	}
}

export default TagsQuery
