import { SpinLoader } from 'components/Common'
import SubscriptionForm from 'modules/subscriptions/subscription-form'
import { useLazyGetSubscriptionQuery } from 'modules/subscriptions/subscriptions-api'
import Pages404 from 'pages/StaticPages/Page404'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const SubscriptionEdit = () => {
	const params = useParams()
	const Id = params?.id
	const [fetchSubscription, { data, isFetching }] = useLazyGetSubscriptionQuery()

	useEffect(() => {
		if (Id) {
			fetchSubscription(Id)
		}
	}, [Id])

	const subscription = useMemo(() => data?.data, [data?.data])

	return (
		<AppLayout
			meta={{
				title: 'Edit Subscriptions',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Subscriptions'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>

					<div className='page-body'>
						{isFetching ? (
							<SpinLoader />
						) : (
							<>
								{data?.data ? (
									<SubscriptionForm subscription={subscription} />
								) : (
									<Pages404 />
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SubscriptionEdit
