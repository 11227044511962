import AsyncImage from 'theme/ui/async-image'
import AssigneeDropdown from './AssigneeDropdown'

export default AssigneeDropdown

export const createAssigneeOption = (assignee) => ({
	...assignee,
	fullName: `${assignee?.first_name} ${assignee?.last_name}`,
	email: assignee?.email,
	value: assignee?.id,
})

export const createAssigneeOptionLabel = (assignee) => {
	return (
		<div className='flex items-center space-x-2'>
			<div>
				<AsyncImage height={28} width={28} className='rounded-full !border' />
			</div>
			<div className='flex flex-col'>
				<span className='text-sm font-bold text-gray-600'>{assignee?.fullName}</span>
				<span className='text-xs font-normal text-gray-500'>{assignee?.email}</span>
			</div>
		</div>
	)
}
