// import { SpinLoader } from 'components/Common'
import AnnouncementsForm from 'modules/announcements/announcements-form/announcements-form'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const AnnouncementCreate = () => {
	return (
		<AppLayout
			meta={{
				title: 'Announcement Create',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title={'Announcement Create'}
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						<AnnouncementsForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default AnnouncementCreate
