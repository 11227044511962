/* eslint-disable react-hooks/exhaustive-deps */
import { snakeCase } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { GraphanaDashboard } from 'modules/graphana-dashboard'
import AppLayout from 'theme/layouts/app-layout'

const Dashboard = () => {
	const { isSuperAdmin, defaultGroup } = useSelector((state) => state.Permissions)
	const currentGroup = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])
	useEffect(() => {
		if (!isSuperAdmin && currentGroup === 'client') {
			// history.push('/assigned-workflows')
		}
	}, [currentGroup])

	return (
		<AppLayout
			meta={{
				title: 'Dashboard',
			}}>
			<div className='!pt-[5rem]'>
				{!['client'].includes(currentGroup) && (
					<>
						{/* <Notes /> */}
						<GraphanaDashboard />
					</>
				)}
			</div>
		</AppLayout>
	)
}
export default Dashboard
