import { Link } from 'react-router-dom'
import ToolTip from 'theme/ui/tool-tip'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { ASSIGN_WORKFLOW_APP_ROUTES } from '../../assigned-workflows/routes'

const Actions = ({ id }) => {
	const canFill = isAllowTo(permissions?.assignedWorkflow?.fill)
	return (
		<div className='w-full text-right'>
			<div className='flex w-full justify-end space-x-3'>
				<ToolTip targetId={`view_${id}`} tooltipText='View' />
				{canFill ? (
					<Link
						id={`view_${id}`}
						to={ASSIGN_WORKFLOW_APP_ROUTES.findOne(id)}
						className='action-btn btn-primary-outline'>
						<i className='uil uil-eye' style={{ fontSize: 16 }} />
					</Link>
				) : undefined}
			</div>
		</div>
	)
}

export default Actions
