import { PlusCircle, XCircle } from 'lucide-react'

const FilterButton = ({ isActive, onClear, children }) => {
	return (
		<div className='relative'>
			<div className='absolute left-[15px] top-[12px] z-20 flex items-center'>
				{isActive ? (
					<span
						className='cursor-pointer hover:text-red-600 active:text-red-500'
						onClick={onClear}>
						<XCircle size={15} />
					</span>
				) : (
					<span className='text-gray-500'>
						<PlusCircle size={15} />
					</span>
				)}
			</div>
			{children}
		</div>
	)
}

export default FilterButton
