import { Fragment } from 'react'
import { Col, Row, TabContent, TabPane } from 'reactstrap'

import { Button } from 'theme/ui/forms'
import PdfFieldRender from '../pdf-field-render/pdf-field-render'

const StepByStep = ({
	docs,
	error,
	fields,
	handleNext,
	toggleField,
	activeField,
	total_fields,
	isFieldSaved,
	setIsFieldSaved,
	disableNext,
	currentStatus,
}) => {
	return (
		<Fragment>
			{fields.length > 0 ? (
				<Row className='justify-content-between'>
					<Col sm={6}>
						<div className='wizard clearfix'>
							<div className='content clearfix'>
								<TabContent activeTab={activeField}>
									{fields.map((field, i) => {
										return (
											<TabPane tabId={i + 1} key={i}>
												<PdfFieldRender
													currentStatus={currentStatus}
													field={field}
													i={i}
													error={error}
												/>
											</TabPane>
										)
									})}
								</TabContent>
								<div className='actions clearfix'>
									<ul className='steps-btn'>
										<li>
											<Button
												variant='white'
												disabled={activeField === 1}
												size='sm'
												onClick={() => {
													setIsFieldSaved(false)
													toggleField(activeField - 1)
												}}>
												Previous
											</Button>
										</li>
										<li>
											<Button
												variant={isFieldSaved ? 'success' : 'primary'}
												size='sm'
												onClick={handleNext}
												disabled={isFieldSaved || disableNext}>
												{activeField === total_fields ? (
													<Fragment>
														{isFieldSaved ? 'Saved' : 'Save'}
													</Fragment>
												) : (
													'Next'
												)}
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</Col>
					<Col sm={4}>
						<div className='d-flex justify-content-end'>
							<span className='mb-3' style={{ float: 'right' }}>
								Field {activeField} of {total_fields}
							</span>
						</div>
						<div className='prose'>
							<h6>This field is in the following documents</h6>
							<ol className='text-sm'>
								{docs.length > 0 ? (
									<Fragment>
										{docs.map((item, idx) => (
											<li key={idx} className='active-doc'>
												{item?.title}
											</li>
										))}
									</Fragment>
								) : (
									<Fragment>
										{Array.isArray(fields[0]?.documents) && (
											<Fragment>
												{fields[0]?.documents.map((item, idx) => (
													<li key={idx} className='active-doc'>
														{item?.title}
													</li>
												))}
											</Fragment>
										)}
									</Fragment>
								)}
							</ol>
						</div>
					</Col>
				</Row>
			) : (
				<div className='alert alert-info mx-auto max-w-md'>
					No profile field is required to be filled here. Please proceed.
				</div>
			)}
		</Fragment>
	)
}

export default StepByStep
