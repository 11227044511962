import classNames from 'classnames'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { FormFeedback, Label } from 'reactstrap'

export const SelectField = ({
	meta: { touched, error },
	isSearchable = true,
	isLoading,
	label,
	onInputChange,
	input,
	name,
	options,
	isMulti,
	ignoreBottomMargin = false,
	isRequired = false,
	infoText,
	max_selected_option,
	...custom
}) => {
	const isInvalid = touched && error
	const isOptionDisabled = () => input?.value?.length >= max_selected_option

	return (
		<>
			<div className={classNames({ 'mb-3': !ignoreBottomMargin })}>
				{label && (
					<Label className='flex items-center text-[14px] font-bold text-[#495057]'>
						{label}
						{isRequired && <span className='text-red-500'>&nbsp;*</span>}
					</Label>
				)}
				<Select
					{...input}
					{...custom}
					name={name}
					options={options}
					onInputChange={onInputChange}
					onChange={(value) => input.onChange(value)}
					onBlur={(value) => input.onBlur(value.value)}
					value={input.value}
					isSearchable={isSearchable}
					isClearable
					isMulti={isMulti}
					isLoading={isLoading}
					classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
					className={classNames('select-dropdown-container')}
					isOptionDisabled={isOptionDisabled}
					menuPortalTarget={document.body}
				/>
				{infoText && <span className='text-[13px]  text-gray-400'>{infoText}</span>}
				{isInvalid ? (
					<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
				) : undefined}
			</div>
		</>
	)
}

SelectField.propTypes = {
	meta: PropTypes.object,
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
}

SelectField.defaultProps = {
	meta: null,
	options: [],
	label: '',
	name: '',
	isMulti: false,
}
