import clsx from 'clsx'
import PropTypes from 'prop-types'

const Badge = ({ className, variant, children, rounded, onClick, ...rest }) => {
	return (
		<div
			className={clsx(
				className,
				'block h-fit w-fit',
				rounded ? 'rounded-full' : 'rounded-sm',
				{
					'bg-main/10 px-2.5 py-0.5 text-xs font-medium text-main': variant === 'primary',
					'bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800':
						variant === 'danger',
					'bg-cyan-100 px-2.5 py-0.5 text-xs font-medium text-cyan-800':
						variant === 'info',
					'bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800':
						variant === 'success',
					'bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800':
						variant === 'warning',
					'text-gray-80  bg-gray-100 px-2.5 py-0.5 text-xs font-medium':
						variant === 'secondary',
					'!border bg-white px-2.5 py-0.5 text-xs font-medium text-main':
						variant === 'primary-outline',
				},
			)}
			onClick={onClick}
			{...rest}>
			{children}
		</div>
	)
}

Badge.propTypes = {
	variant: PropTypes.oneOf([
		'primary',
		'danger',
		'success',
		'warning',
		'secondary',
		'info',
		'primary-outline',
	]),
	rounded: PropTypes.bool,
}

Badge.defaultProps = {
	className: '',
	variant: 'primary',
	rounded: true,
}

export default Badge
