import classNames from 'classnames'
import { getIn } from 'formik'
import ReviewerDropdown from './reviewer-dropdown'

export const createAdvisorOption = (reviewer, formValues, advisor_reviewer, fieldName) => ({
	fullName: `${reviewer?.first_name} ${reviewer?.last_name}`,
	email: reviewer?.email,
	first_name: reviewer?.first_name,
	last_name: reviewer?.last_name,
	value: reviewer?.id,
	avatar: reviewer?.avatar,
	isDisabled:
		advisor_reviewer.includes(reviewer?.id) &&
		!getIn(formValues, fieldName).includes(reviewer?.id),
})

export const createAdvisorOptionLabel = (advisor) => {
	return (
		<div
			className={classNames(
				'flex items-center space-x-1',
				advisor?.isDisabled ? 'cursor-not-allowed opacity-50 hover:!bg-transparent' : '',
			)}>
			<span className='font-bold text-gray-700'>{advisor?.fullName}</span>
			<span className='text-sm text-gray-500'>({advisor?.email})</span>
		</div>
	)
}

export default ReviewerDropdown
