import { useMemo } from 'react'
import Badge from 'theme/ui/badge'

const Tags = ({ tags }) => {
	const $tags = useMemo(() => (Array.isArray(tags) ? tags : []), [tags])

	return (
		<>
			{$tags.length > 0 ? (
				<div className='flex flex-wrap gap-1'>
					{$tags.map((tag, idx) => (
						<Badge key={idx} variant='secondary'>
							{tag?.tag}
						</Badge>
					))}
				</div>
			) : undefined}
		</>
	)
}

export default Tags
