import * as base64 from 'base-64'
import * as yup from 'yup'

import apiEndpoints from 'helpers/apiEndpoints'
import { dispatch } from 'store'
import { getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import request from 'utilities/request'
import * as actions from '../../../../store/clients/actions'
import { createClientOptionLabel } from './client-dropdown'
import WorkflowAssignForm from './workflow-assign-form'
import { createWorkflowOption } from './workflow-dropdown'

const coApplicantSchema = yup.object().shape({
	user: yup
		.object()
		.shape({
			email: yup.string(),
			fullName: yup.string(),
			value: yup.number(),
		})
		.required('Please select co applicant'),
	screen_type: yup.string().required('Please choose workflow representation'),
})

export const assignValidationSchema = yup
	.object()
	.shape({
		workflow: yup.mixed().required('Please select a Workflow Template'),
		advisor: yup.mixed().required('Please select an Advisor'),
		tags: yup.array().optional(),
		isCreateLink: yup.boolean().optional(),
		isClientOnbording: yup.boolean().optional(),
		client: yup.object().optional(),
		createPublicURL: yup.boolean().optional(),
		userCount: yup.number(),
		co_applicants: yup.array().of(coApplicantSchema).optional(),
	})
	.test(
		'client-or-url',
		'Either client.user must have at least one user or createPublicURL must be true',
		function (value) {
			const hasUsers = value?.client?.user && value.client.user.length > 0
			const hasPublicURL = value?.createPublicURL

			if (!hasUsers && !hasPublicURL) {
				return this.createError({
					path: 'client.user',
					message:
						'Either a client must be provided, or a Workflow public link should be created.',
				})
			}

			return true
		},
	)

export const getAssignInitialValues = (search, workflow, selectedClients) => {
	const queryString = getParseQueryString(search)
	const queryParams = new URLSearchParams(location.search)
	const checkedKeys = queryParams.getAll('groups')
		? queryParams.getAll('groups')[0]?.split(',')
		: null
	const decodedUser = queryString?.user ? base64.decode(queryString?.user) : '{}'
	const user = JSON.parse(decodedUser)

	try {
		const initValue = {
			workflow: workflow ? createWorkflowOption(workflow) : undefined,
			client: undefined,
			clientGroups: Array.isArray(checkedKeys) && checkedKeys.length > 0 ? checkedKeys : [],
		}
		if (Array.isArray(checkedKeys) && checkedKeys.length > 0) {
			initValue.client = checkedKeys?.map((household) => {
				return { label: household, value: household, type: 'household' }
			})
		}
		if (Array.isArray(selectedClients) && selectedClients?.length > 0 && queryString?.clients) {
			initValue.client = selectedClients?.map((client) => {
				const lableData = {
					email: client?.email,
					fullName: client?.full_name,
				}
				return {
					label: createClientOptionLabel(lableData),
					value: client.id,
					fullName: client.full_name,
					email: client.email,
					type: 'client',
				}
			})
		} else {
			dispatch(actions.selectedClients([]))
		}
		if (user?.id) {
			initValue.client = [{ label: user?.label, value: user?.id, type: 'client' }]
		}
		return initValue
	} catch (_) {
		return {
			workflow: undefined,
			user: undefined,
		}
	}
}

export const getWorkflowAssignPayload = (values) => {
	const userCount = values?.userCount
	const clients = []
	const households = []
	const payload = {
		workflowTemplate: {
			id: values?.workflow?.value,
		},
		advisor: {
			id: values?.advisor?.value,
			signPriority: userCount + 1,
			screen_type: 'same_page',
		},
		reviewers: Array.isArray(values?.reviewers)
			? values?.reviewers.map((reviewer) => ({ id: reviewer?.value }))
			: [],
		receiver_emails: Array.isArray(values?.receiver_emails)
			? values?.receiver_emails.map((email) => email?.value)
			: [],
		co_applicants: [],
		notes: values?.notes || '',
		createPublicURL: values?.createPublicURL,
		isTaskAutomationEnabled: values?.isTaskAutomationEnabled,
	}

	values?.client?.user?.forEach((item) => {
		if (item?.type === 'client') {
			clients.push({
				id: item.value,
				signPriority: 1,
				screen_type: values?.client?.screen_type,
			})
		} else {
			households?.push(item.value.toString())
		}
	})
	payload.client = clients
	payload.clientGroups = households

	if (values?.final_reviewer) {
		Object.assign(payload, {
			finalReviewer: {
				id: values?.final_reviewer?.value,
			},
		})
	}

	if (Array.isArray(values?.co_applicants) && values?.co_applicants.length && userCount > 1) {
		const applicants = values?.co_applicants.map((applicant, idx) => ({
			key: `applicant_${idx + 1}`,
			signPriority: idx + 2,
			value: applicant?.user?.value,
			screen_type: applicant?.screen_type,
		}))

		Object.assign(payload, { co_applicants: applicants })
	}
	return payload
}

export const validateWorkflow = (id) => {
	return request.get(`${apiEndpoints.workflowAssignEligible}/${id}`).then((response) => {
		if (response?.status === 200 && !response?.data) {
			Promise.reject(new Error(response?.message))
		} else {
			Promise.resolve()
		}
	})
}

export const assignWorkflow = (payload) => {
	return request.post(`${apiEndpoints.workflow}/assign`, payload).then((response) => {
		if (response?.status === 200) {
			notification('success', response?.message)
		} else {
			notification('warn', response?.message)
		}
	})
}

export const getWorkflowLink = (workflowLink) => {
	if (!workflowLink) {
		return null
	}
	const url = new URL(workflowLink)
	const pathnames = url?.pathname?.split('/')
	const lastIndex = url?.pathname?.split('/')?.length - 1
	const workflowUUID = pathnames[lastIndex] || null
	return `${window.location.origin}/w/${workflowUUID}`
}

export { default as ClientWorkflowAssignForm } from './client-workflow-assign-form'
export default WorkflowAssignForm
