export const FILE_MANAGER_ROUTES = {
	home: () => '/file-manager/home',
	search: () => '/file-manager/search',
	myDocuments: () => '/file-manager/documents',
	allFolders: () => '/file-manager/folders',
	findOneFolder: (id) => `/file-manager/folders/${id}`,
	readyMadeTemplate: () => '/file-manager/readymade-templates',
	starred: () => '/file-manager/starred',
	trash: () => '/file-manager/trash',
}
