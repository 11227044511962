import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { v4 } from 'uuid'

const API_STAR_URL = '/starred-document'
const API_COPY_FOLDER = '/document-package/readymade-template-clone'

export const documentPackageApi = createApi({
	reducerPath: 'DocumentPackages',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['DocumentPackages', 'ReadymadeTemplates', 'DocumentDetails'],
	endpoints: (builder) => ({
		getDocumentPackages: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: apiEndpoints.documentPackage,
				method: 'GET',
				params: params,
			}),
			providesTags: ['DocumentPackages'],
			transformResponse: (response) => {
				const folders = Array.isArray(response?.data?.documentPackages)
					? response?.data?.documentPackages.map((d) => ({
							...d,
							uuid: v4(),
							type: 'folder',
					  }))
					: []
				return {
					data: folders,
					meta: response?.data?.meta,
				}
			},
		}),
		deleteDocumentPackage: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DocumentPackages', 'ReadymadeTemplates', 'DocumentDetails'],
		}),
		deleteDocumentTemplate: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.document}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DocumentPackages', 'DocumentDetails'],
		}),
		createDocumentPackage: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.documentPackage,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['DocumentPackages', 'DocumentDetails'],
		}),
		updateDocumentPackage: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['DocumentDetails', 'DocumentPackages'],
		}),
		restoreDeletedPackage: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.documentPackage}/restore/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['DocumentPackages'],
		}),
		restoreDeletedDocument: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.documentRestore}/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['DocumentPackages'],
		}),
		trashedDocumentPackage: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['DocumentPackages'],
			transformResponse: (response) => {
				const trashedFiles =
					response?.data?.trashed_data?.length > 0
						? response?.data?.trashed_data?.map((folder) => ({
								...folder,
								type: folder?.document_obj_id ? 'document' : 'folder',
						  }))
						: []

				return {
					data: trashedFiles,
					meta: response?.data?.meta,
				}
			},
		}),
		getDocumentPackageDetails: builder.query({
			query: (id) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'GET',
			}),
			providesTags: ['DocumentDetails'],
			invalidatesTags: ['DocumentPackages'],
		}),
		markDocumentPackageAsStar: builder.mutation({
			query: (payload) => ({
				url: API_STAR_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['DocumentPackages', 'DocumentDetails'],
		}),
		getReadymadeTemplates: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: `${apiEndpoints.documentPackage}/ready-made`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['ReadymadeTemplates'],
			transformResponse: (response, _, arg) => {
				const documents = Array.isArray(response?.data?.documents)
					? response?.data?.documents.map((d) => ({
							...d,
							uuid: v4(),
							type: 'document',
					  }))
					: []

				const folders = Array.isArray(response?.data?.documentPackages)
					? response?.data?.documentPackages.map((d) => ({
							...d,
							uuid: v4(),
							type: 'folder',
					  }))
					: []
				const readymadeTemplates = [...folders, ...documents]
				return {
					data: readymadeTemplates.map((document, idx) => ({
						...document,
						index:
							parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
							idx +
							1,
					})),
					meta: response?.data?.meta,
				}
			},
		}),

		globalSearch: builder.query({
			query: (params) => ({
				url: `${apiEndpoints.documentPackage}/global-search`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['DocumentPackages'],
		}),

		markDocumentAsStar: builder.mutation({
			query: (payload) => ({
				url: API_STAR_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),

		copyFolder: builder.mutation({
			query: (payload) => ({
				url: API_COPY_FOLDER,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['ReadymadeTemplates'],
		}),
	}),
})

export const {
	useLazyGetDocumentPackagesQuery,
	useLazyTrashedDocumentPackageQuery,
	useDeleteDocumentPackageMutation,
	useCreateDocumentPackageMutation,
	useUpdateDocumentPackageMutation,
	useRestoreDeletedPackageMutation,
	useRestoreDeletedDocumentMutation,
	useLazyGetDocumentPackageDetailsQuery,
	useMarkDocumentPackageAsStarMutation,
	useDeleteDocumentTemplateMutation,

	useLazyGlobalSearchQuery,
	useMarkDocumentAsStarMutation,
	useCopyFolderMutation,
} = documentPackageApi
