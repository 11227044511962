import { snakeCase } from 'lodash'
import { Fragment } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import Spinner from 'theme/ui/spinner'

const SettingsListingTable = ({ handleInputChange, tableData, isEditable, isFetching }) => {
	const getInputType = (value) => {
		switch (value) {
			case 'string':
				return 'text'
			case 'number':
				return 'number'
			default:
				return 'text'
		}
	}

	return (
		<Fragment>
			<div className='Setting_overflow'>
				<table className='d w-full text-left text-sm rtl:text-right'>
					<thead className='   text-dark-100  w-full  text-xs'>
						<tr className='border-dark !mb-3 flex w-full  flex-row bg-white'>
							<th className='setting_w col-span-1 !my-3  w-[100px] !border-r border-[#CED4DA] px-4 text-left text-[13px] text-[#74788D]'>
								#
							</th>
							<th className='setting_w col-span-2 !my-3  w-full !border-r border-[#CED4DA] px-4 text-left text-[13px] text-[#74788D]'>
								Key
							</th>
							<th className='setting_w col-span-5 !my-3  w-full !border-r border-[#CED4DA] px-4 text-left text-[13px] text-[#74788D]'>
								Value
							</th>
							<th className='setting_w col-span-1 !my-3  w-full border-[#CED4DA] px-4 text-left text-[13px] text-[#74788D]'>
								Data Type
							</th>
						</tr>
					</thead>
					<tbody className='bg-white'>
						{!isFetching ? (
							tableData?.length > 0 ? (
								tableData?.map((item) => (
									<tr
										key={item?.id}
										className='flex w-full cursor-pointer flex-row  hover:bg-slate-100 '>
										<td className='setting_w col-span-1  w-[100px] border-b  !py-2.5 px-4  text-gray-900'>
											{item?.ind}
										</td>
										<td className='setting_w col-span-2 w-full  border-b  !py-2.5 px-4  font-medium text-gray-900'>
											<span className='flex '>
												{item?.setting_key}
												<div
													data-tooltip-id={`${snakeCase(
														item?.setting_key,
													)}_info`}
													className='ml-1 cursor-pointer'>
													<i className='bx bx-info-circle mb-0 mt-1 text-sm text-gray-400' />
													<UncontrolledTooltip
														placement='top'
														target={() =>
															document.querySelector(
																`[data-tooltip-id="${snakeCase(
																	item?.setting_key,
																)}_info"]`,
															)
														}>
														{item?.description}
													</UncontrolledTooltip>
												</div>
											</span>
										</td>
										<td className='setting_w col-span-5 w-full  border-b !py-2.5 px-4 '>
											{isEditable ? (
												item?.data_type?.toLowerCase() === 'boolean' ? (
													<>
														<select
															className='w-full rounded !border border-slate-500 px-2 py-1 text-xs'
															value={
																item?.setting_value === 'true'
																	? 'Yes'
																	: 'No'
															}
															onChange={(e) => {
																handleInputChange(
																	item?.id,
																	e.target.value === 'Yes'
																		? 'true'
																		: 'false',
																)
															}}>
															{['Yes', 'No'].map((option) => (
																<option key={option} value={option}>
																	{option}
																</option>
															))}
														</select>
													</>
												) : (
													<input
														type={getInputType(
															item?.data_type?.toLowerCase(),
														)}
														value={item?.setting_value}
														onChange={(e) =>
															handleInputChange(
																item?.id,
																e.target.value,
															)
														}
														className='w-full rounded !border border-slate-500 px-2 py-1'
													/>
												)
											) : (
												<span
													className='line-clamp-1 rounded px-2 py-1  '
													title={item?.setting_value}>
													{item?.data_type?.toLowerCase() === 'boolean'
														? item?.setting_value === 'true'
															? 'Yes'
															: 'No'
														: item?.setting_value}
												</span>
											)}
										</td>
										<td className='setting_w col-span-1 w-full  border-b !py-2.5 px-4  '>
											<span>{item?.data_type || 'N/A'}</span>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={100} className='text-center'>
										No record found
									</td>
								</tr>
							)
						) : (
							<>
								<Spinner />
							</>
						)}
					</tbody>
				</table>
			</div>
		</Fragment>
	)
}

export default SettingsListingTable
