import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import DataTable, { IndeterminateCheckbox } from 'theme/ui/data-table'
import PopoverButtons from 'theme/ui/popover'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import Actions from './actions'
import StatusColumn from './status-column'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat , formatDate } from 'utilities/helpers'


const OnboardedClientsTable = ({
	clients,
	meta,
	isLoading,
	pagination,
	setPagination,
	setSelectedRecords,
	rowSelection,
	setRowSelection,
	setPreviousData,
	isFullRowSelection,
}) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const [sorting, setSorting] = useState([])
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)
	const history = useHistory()
	const columnHelper = createColumnHelper()
	const isReadPermission = isAllowTo(permissions?.client?.view)

	useEffect(() => {
		const matchedIndexes = Object.keys(rowSelection).map((k) => JSON.parse(k)?.id)
		const result = clients?.filter((client) => matchedIndexes?.includes(client.id))
		setSelectedRecords(result)
		setPreviousData(result)
	}, [rowSelection])

	const applyFilter = (tag) => {
		const encodedTags = encodeURIComponent(tag)
		setPagination((preRec) => ({
			...preRec,
			tags: encodedTags,
		}))
		history.push(`${window.location.pathname}?groups=${encodedTags}`)
	}

	const columns = [
		{
			id: 'select',
			size: 20,
			header: ({ table }) => (
				<div className='px-1'>
					<IndeterminateCheckbox
						{...{
							checked: table.getIsAllRowsSelected(),
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: table.getToggleAllRowsSelectedHandler(),
						}}
					/>
				</div>
			),
			cell: ({ row }) => (
				<div className='px-1'>
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				</div>
			),
		},
		// columnHelper.accessor('index', {
		// 	size: 20,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 150,
			header: () => <span className='min-w-[190px]'>Name</span>,
			cell: (info) => (
				<div className='max-w-[200px]'>
					{isReadPermission ? (
						<Link
							to={`/clients/${info.getValue()?.id}`}
							className='title_hover line-clamp-1 text-sm font-bold !text-main'>
							{info.getValue()?.full_name}
						</Link>
					) : (
						<span className='line-clamp-1 text-sm font-bold !text-gray-700'>
							{info.getValue()?.full_name}
						</span>
					)}
					<span className='file-title text-[13px] !text-gray-400'>
						{info.getValue()?.email}
					</span>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'tags',
			size: 100,
			header: () => <span className='min-w-[80px]'>Groups</span>,
			cell: (info) => {
				const tagsData = info.getValue()?.tags
				return (
					<span className='min-w-[80px] max-w-[180px]'>
						{tagsData?.length > 0 && (
							<div className='flex flex-wrap items-center gap-2'>
								<Badge
									variant='primary-outline'
									className='cursor-pointer'
									rounded={false}
									onClick={() => applyFilter(tagsData[0])}>
									{tagsData[0]}
								</Badge>
								{tagsData?.length > 1 ? (
									<PopoverButtons
										rounded={false}
										variant='primary-outline'
										data={tagsData}
										title={'Groups'}
									/>
								) : undefined}
							</div>
						)}
					</span>
				)
			},
		}),

		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 100,
			header: () => (
				<div className='w-full text-center'>
					<span>Status</span>
				</div>
			),
			cell: (info) => (
				<div className='flex w-full items-center justify-center'>
					<StatusColumn
						status={info.getValue()?.status}
						invitation_expires_at={info.getValue()?.invitation_expires_at}
						created_at={info.getValue()?.created_at}
						id={info.getValue()?.id}
					/>
				</div>
			),
		}),

		columnHelper.accessor((row) => row,{
			id: 'created_at',
			size: 170,
			header: () => (
			  <div className='w-full text-right'>
				<span>Added On</span>
			  </div>
			),
			cell: (info) => (
			  <div className='justify-end w-full text-right'>
				<ToolTip
				  targetId={`date-${info.row.index}`}
				  tooltipText={dateFormat(info.getValue())} 
				  placement='top'
				  offset={[0, 5]}
				/>
				<span className='text-[14px]' id={`date-${info.row.index}`}>
				  {formatDate(info.getValue())} 
				</span>
			  </div>
			),
		  }),
		  

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 120,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					client={info.getValue()}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={clients}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			isFullRowSelection={isFullRowSelection}
			setRowSelection={setRowSelection}
			rowSelection={rowSelection}
			isRowSelection={true}
		/>
	)
}

export default OnboardedClientsTable
