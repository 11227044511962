import { Field } from 'formik'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import ClientsQuery from './clients-query'

const ClientDropdown = ({ name }) => {
	const [search, setSearch] = useState('')
	const { clients, isLoading } = ClientsQuery(search)

	const clientOptions = clients.map((client) => ({
		label: <CreateUserOptionLabel user={client} />,
		value: client.value,
		fullName: client.fullName,
		email: client.email,
		type: 'client',
	}))

	const filterOption = ({ data }, search) => {
		if (
			data?.fullName?.toLowerCase()?.includes(search?.toLowerCase()) ||
			data?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
			(typeof data?.label === 'string' &&
				data?.label?.toLowerCase().includes(search.toLowerCase()))
		) {
			return true
		} else {
			return false
		}
	}

	const handleSearch = (value) => {
		setSearch(value)
	}

	return (
		<Field
			// label='Select Client'
			type='select'
			name={name}
			options={clientOptions}
			isLoading={isLoading}
			isMulti={false}
			onInputChange={(value) => handleSearch(value)}
			component={SelectField}
			placeholder='Select Client'
			getOptionLabel={(option) => option.label}
			filterOption={filterOption}
		/>
	)
}

export default ClientDropdown
