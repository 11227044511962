import { useState } from 'react'

import { Field } from 'formik'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { filterOption } from 'modules/workflows/workflows-outstandings/filters/helpers'
import { SelectField } from 'theme/ui/forms'
import WatcherOptionsQuery from './WatcherOptionsQuery'

const WatcherDropdown = () => {
	const [search, setSearch] = useState('')
	const { watcherOptions, isLoading } = WatcherOptionsQuery(search)

	return (
		<Field
			label='Watcher'
			type='select'
			name='watcher_ids'
			options={watcherOptions}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			isMulti={true}
			placeholder='Select watcher'
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default WatcherDropdown
