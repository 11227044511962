import classNames from 'classnames'
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import userPlaceholder from 'assets/images/users/avatar1.jpg'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import Badge from 'theme/ui/badge'
import Spinner from 'theme/ui/spinner'
import { useLazyGetRecentFoldersQuery } from '../file-manager-api'
import { FILE_MANAGER_ROUTES } from '../routes'

const RecentFolders = () => {
	const [fetchRecentFolders, { data, isLoading }] = useLazyGetRecentFoldersQuery()
	const [recentFolders, setRecentFolders] = useState([])
	useEffect(() => {
		if (fetchRecentFolders) {
			fetchRecentFolders({
				params: {
					page: 1,
					limit: 3,
				},
			})
		}
	}, [fetchRecentFolders])
	useEffect(() => {
		if (data?.documents?.length > 0) {
			const recentFiles = data?.documents?.map((doc) => {
				return {
					name: doc?.documentPackage?.title,
					numberOfFiles:
						(doc?.documentPackage?.childPackages?.length || 0) +
						(doc?.documentPackage?.documents?.length || 0),
					accessed_at: doc?.created_at,
					accessed_by: doc?.avatarUrl,
					isSuperadmin: false,
				}
			})
			setRecentFolders(recentFiles)
		}
	}, [data?.documents])

	return (
		<div>
			{isLoading ? (
				<div className='flex items-center justify-center'>
					<Spinner />
				</div>
			) : (
				<Fragment>
					{recentFolders?.length > 0 && (
						<>
							<h3 className='mb-3 text-base font-semibold'>Folders</h3>
							<div className='overflow-auto'>
								<div className='grid min-w-max grid-cols-3 gap-2.5'>
									{recentFolders.map((folder, idx) => (
										<div
											key={idx}
											className={classNames(
												'min-w-[150px] rounded bg-white p-3 shadow',
												{
													'!border-l-2 border-main': folder?.isSuperadmin,
												},
											)}>
											<div className='flex h-full flex-col justify-between space-y-3'>
												<div className='flex items-center justify-between'>
													<span>
														<FolderIcon />
													</span>
													{folder?.isSuperadmin ? (
														<Badge rounded={false}>
															By Experivise Admin
														</Badge>
													) : (
														<Fragment>
															<div className='flex -space-x-4 rtl:space-x-reverse'>
																<img
																	key={idx}
																	className='h-10 w-10 rounded-full border-2 border-white dark:border-gray-800'
																	src={
																		folder?.accessed_by ||
																		userPlaceholder
																	}
																	alt=''
																/>
															</div>
														</Fragment>
													)}
												</div>
												<div className='flex flex-col'>
													<span className='text-[15px] font-semibold'>
														{folder?.name}
													</span>
													<div className='flex items-center justify-between'>
														<span className='text-sm font-normal text-gray-400'>
															{folder?.numberOfFiles} Files
														</span>
														<span className='text-[13px] font-normal text-gray-400'>
															{moment(folder?.accessed_at).fromNow()}
														</span>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
							<Link
								className='link float-end mb-2 mt-3'
								to={FILE_MANAGER_ROUTES.allFolders()}>
								<span>View All Folders</span>
								<i className='uil-angle-down'></i>
							</Link>
						</>
					)}
				</Fragment>
			)}
		</div>
	)
}

export default RecentFolders
