import ClientDropdown from './ClientDropdown'

export default ClientDropdown

export const createClientOption = (client) => ({
	...client,
	fullName: `${client?.first_name} ${client?.last_name}`,
	email: client?.email,
	first_name: client?.first_name,
	last_name: client?.last_name,
	workflow_id: client?.workflow_id,
	value: client?.id,
})

export const creatClientOptionLabel = (client) => {
	return (
		<div className='flex items-center space-x-1'>
			<span className='font-bold text-gray-700'>{client?.fullName}</span>
			<span className='text-sm text-gray-500'>({client?.email})</span>
		</div>
	)
}
