import classNames from 'classnames'
import { useUser } from 'hooks'
import moment from 'moment'
import { Fragment } from 'react'
import AsyncImage from 'theme/ui/async-image'

const CommentCard = ({
	backgroundColor,
	colorName,
	comment,
	advisor,
	variant,
	isReplyShow = false,
}) => {
	const { user } = useUser()

	return (
		<div
			className={classNames(
				'flex  space-x-3',
				variant === 'horizontal' ? 'flex-row items-start' : '!flex-col space-y-1',
			)}>
			<div>
				<AsyncImage
					src={
						advisor?.avatar?.path ? advisor?.avatar?.path : comment?.user?.avatar?.path
					}
					className={classNames('h-8 w-8 rounded-full')}
				/>
			</div>
			<div
				className={classNames(
					backgroundColor,
					colorName,
					'box arrow-left-top w-auto flex-1 rounded-[5px] rounded-tl-none !px-3 py-[10px]',
				)}>
				<div className='!mb-[10px] flex items-baseline space-x-2'>
					<span className='text-sm font-semibold text-gray2-dark'>
						{advisor?.fullName}
					</span>
					<span className='text-xs text-gray2'>Advisor</span>
				</div>
				<p className='m-0 text-sm text-gray2-dark'>
					{comment?.message ||
						`Hey ${user?.first_name}, Please complete this workflow to process your application`}
				</p>
				<div className='flex justify-end'>
					<span className='text-[10px] text-gray2'>
						{moment(comment?.created_at).format('HH:mm, DD MMM YY ')}
					</span>
				</div>
			</div>
			{isReplyShow ? (
				<Fragment>
					{comment?.replies?.length > 0 &&
						comment?.replies?.map((reply, index, array) => {
							const isNewUser =
								index === 0 || array[index - 1].user.id !== reply.user.id

							return (
								<>
									{isNewUser && (
										<div
											className={classNames(
												'm-0 flex space-x-3 p-0',
												variant === 'horizontal'
													? 'flex-row items-start'
													: '!flex-col space-y-1',
											)}>
											<div>
												<AsyncImage
													src={reply?.user?.avatar?.path}
													className={classNames(
														'mt-2 h-8 w-8 rounded-full',
													)}
												/>
											</div>
										</div>
									)}
									<div
										className={classNames(
											backgroundColor,
											colorName,
											'box arrow-left-top w-auto flex-1 rounded-[5px] rounded-tl-none !px-3 py-[10px]',
										)}>
										<p className='m-0 mb-1 px-1 text-sm font-semibold text-gray2-dark'>
											{reply?.user?.fullName}
										</p>
										<div className='rounded-md bg-blue-500 ps-[2px]'>
											<div className='rounded-md bg-white p-2'>
												<div className='!mb-[10px] flex items-baseline space-x-2'>
													<span className='text-sm font-semibold text-gray2-dark'>
														{comment?.user?.fullName}
													</span>
													<span className='text-xs text-gray2'>
														Advisor
													</span>
												</div>
												<p className='m-0 mb-1 !line-clamp-2 text-sm text-gray2-dark'>
													{comment?.message ||
														`Hey ${user?.first_name}, Please complete this workflow to process your application`}
												</p>
											</div>
										</div>
										<p className='m-0 mx-1 mt-2 p-0 text-sm text-gray2-dark'>
											{reply?.message ||
												`Hey ${user?.first_name}, Please complete this workflow to process your application`}
										</p>
										<div className='flex justify-end'>
											<span className='text-[10px] text-gray2'>
												{moment(reply?.created_at).format(
													'HH:mm, DD MMM YY ',
												)}
											</span>
										</div>
									</div>
								</>
							)
						})}
				</Fragment>
			) : undefined}
		</div>
	)
}

CommentCard.defaultProps = {
	variant: 'horizontal',
}

export default CommentCard
