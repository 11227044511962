import WorkflowDropdown from './WorkflowDropdown'

export default WorkflowDropdown

export const createWorkflowOption = (workflow) => ({
	title: workflow?.title,
	value: workflow?.id,
	clients: Array.isArray(workflow?.client) ? workflow?.client : [],
})

export const creatWorkflowOptionLabel = (workflow) => {
	return (
		<div className='flex items-center space-x-1'>
			<span>{workflow?.title}</span>
		</div>
	)
}
