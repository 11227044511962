import { useAppRouter, useUser } from 'hooks'
import {
	useCreateSubscriptionMutation,
	useGetPricingQuery,
} from 'modules/subscriptions/user-subscriptions-api'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import IsAuthenticated from 'utils/isAuthenticated'
import { subscriptionPlans } from '.'
import PricingTable from './pricing-table'

const SubscriptionPlans = () => {
	const [createSubcription] = useCreateSubscriptionMutation()
	const { isLoading, data } = useGetPricingQuery()
	const { user } = useUser()
	const $options = Array.isArray(data) ? subscriptionPlans(data) : []
	const isLoggedIn = IsAuthenticated()
	const { parseQuery } = useAppRouter()
	const stripeCustomerId = parseQuery?.stripe_customer_id
	const onSubscribe = async (priceId, { setSubmitting }) => {
		try {
			setSubmitting(true)
			const payload = {
				priceId: priceId,
			}

			if (isLoggedIn && user?.stripe_customer_id) {
				payload.customerId = user?.stripe_customer_id
			}

			if (!isLoggedIn && stripeCustomerId) {
				payload.customerId = stripeCustomerId
			}

			const response = await createSubcription(payload).unwrap()
			if (response?.success && response?.url) {
				window.location.href = response?.url
				notification('success', response?.message)
			}
			setSubmitting(false)
		} catch (error) {
			setSubmitting(false)
			notification('error', error?.message)
		}
	}

	return (
		<div className='page-body inset-0 mt-5  overflow-y-auto'>
			<div className='flex min-h-full items-center justify-center p-4'>
				<div className='flex flex-col'>
					{isLoading ? (
						<Spinner />
					) : Object.keys($options).length > 0 ? (
						<PricingTable onSubscribe={onSubscribe} plans={$options} />
					) : (
						<p>No Subscription Plans found</p>
					)}
				</div>
			</div>
		</div>
	)
}

export default SubscriptionPlans
