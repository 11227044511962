import moment from 'moment'
import * as yup from 'yup'

const formatDate = (date) => {
	if (!date) return null
	const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
	return localDate.toISOString().split('T')[0]
}

export const getPayload = (values) => {
	return {
		client_id: values?.client_id?.value,
		start_date: moment(values?.date_range[0]).isValid()
			? formatDate(values?.date_range[0])
			: null,
		end_date: moment(values?.date_range[1]).isValid()
			? formatDate(values?.date_range[1])
			: null,
	}
}

export const validationSchema = yup.object().shape({
	client_id: yup.mixed().nonNullable().required('Please select the client'),
})

export { default as MetabasePortfolio } from './metabase-portfolio'
