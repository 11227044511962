import { Dialog, Transition } from '@headlessui/react'
import { Field, FormikProvider, useFormik } from 'formik'
import { DatabaseIcon } from 'lucide-react'
import { Fragment } from 'react'
import { Button, InputField, SelectField } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import { getInitialValues, getPayload, pullFrequencyOptions, validationSchema } from '.'
import {
	useAddSftpConfigMutation,
	useTestSftpConfigMutation,
	useUpdateSftpConfigMutation,
} from '../sftp-config-api'

const SftpConfigForm = ({ isOpen, setIsOpen, handleForm, details, isFetching }) => {
	const [addSftpConfig] = useAddSftpConfigMutation()
	const [updateSftpConfig] = useUpdateSftpConfigMutation()
	const [testSftpConfig, { isLoading: isTesting }] = useTestSftpConfigMutation()

	const testSftp = async (values) => {
		const payload = getPayload(values)
		const response = await testSftpConfig(payload).unwrap()
		if (response?.status === 200) {
			notification('success', response?.message)
		}
	}

	const formik = useFormik({
		initialValues: getInitialValues(details),
		validationSchema: validationSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			try {
				const payload = getPayload(values)
				const response = details?.id
					? await updateSftpConfig({ payload, id: details?.id })
					: await addSftpConfig(payload)
				if (response?.data?.status === 201 || response?.data?.status === 200) {
					notification('success', response?.data?.message)
					handleForm()
					setSubmitting(false)
				}
			} catch (error) {
				notification('warn', response?.data?.message)
				setSubmitting(false)
			} 
		},
	})

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-4xl transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<div className='relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute left-6 top-6 rounded-full !border !border-gray-300 bg-white !p-3'>
										<DatabaseIcon />
									</div>
									<button
										type='button'
										className='right-0'
										onClick={() => setIsOpen(false)}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								{isFetching ? (
									<div className='my-10'>
										<Spinner />
									</div>
								) : (
									<FormikProvider value={formik}>
										<form onSubmit={formik.handleSubmit} className='w-full p-6'>
											<div className='form-heading mb-6'>
												<h3 className='text-lg font-semibold'>
													SFTP Configuration
												</h3>
												<p className='text-gray-600'>
													Provide the details to set up your SFTP
													connection.
												</p>
											</div>

											<div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
												<Field
													label='Host'
													placeholder='Enter SFTP host'
													name='host'
													component={InputField}
													isRequiredField
												/>
												<Field
													label='Port'
													placeholder='Enter SFTP port'
													name='port'
													component={InputField}
													type='number'
													isRequiredField
												/>
												<Field
													label='Username'
													placeholder='Enter SFTP username'
													name='username'
													component={InputField}
													isRequiredField
												/>
												<Field
													label='File Path'
													placeholder='Enter file path'
													name='file_path'
													component={InputField}
													isRequiredField
												/>
												<Field
													label='File Type/Pattern (Optional)'
													placeholder='e.g., *.csv'
													name='file_type_pattern'
													component={InputField}
												/>
												<Field
													label='Pull Frequency'
													name='pull_frequency'
													options={pullFrequencyOptions}
													component={SelectField}
													isRequiredField
												/>
												<Field
													label='Retention Policy (Optional)'
													placeholder='e.g., 30 days'
													name='retention_policy'
													component={InputField}
												/>
											</div>

											{!details && (
												<>
													{/* <div className='mt-6 grid grid-cols-1 gap-2 '>
														<label className='font-semibold'>
															Authentication Type
														</label>
														<div className='flex gap-4'>
															<label>
																<Field
																	type='radio'
																	name='authType'
																	value='password'
																	disabled={details}
																	className='form-check-input mr-2'
																/>
																Password
															</label>
															<label>
																<Field
																	type='radio'
																	name='authType'
																	disabled={details}
																	value='privateKey'
																	className='form-check-input mr-2'
																/>
																Private Key
															</label>
														</div>
													</div> */}
													<div className='mt-3 grid grid-cols-1 gap-4 sm:grid-cols-2'>
														{formik.values.authType === 'password' && (
															<Field
																label='Password'
																placeholder='Enter SFTP password'
																name='passkey'
																component={InputField}
																type='password'
																disabled={details}
																isRequiredField
															/>
														)}
														{formik.values.authType ===
															'privateKey' && (
															<>
																<Field
																	placeholder='Enter private key'
																	label='Private Key'
																	name='passkey'
																	disabled={details}
																	component={InputField}
																	type='password'
																	isRequiredField
																/>
																<Field
																	label='Passphrase (Optional)'
																	placeholder='Enter passphrase'
																	name='passphrase'
																	component={InputField}
																/>
															</>
														)}
													</div>
												</>
											)}
											<div className='mt-6 flex justify-end gap-3'>
												<Button
													variant='primary-outline'
													type='button'
													isLoading={isTesting}
													isLoadingText={'Testing...'}
													onClick={() => testSftp(formik?.values)}>
													Test Connection
												</Button>
												<Button
													type='submit'
													isLoading={formik.isSubmitting}>
													Save
												</Button>
											</div>
										</form>
									</FormikProvider>
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SftpConfigForm
