const apiEndpoints = {
	auth: {
		login: '/auth/login',
		logout: '/auth/logout',
		register: '/auth/register',
		forgotPassword: '/auth/verify-user',
		resetPassword: '/auth/change-password',
		permissions: '/auth/permissions',
		verifyOtp: '/auth/mfa-token',
	},
	group: {
		groups: '/groups',
		groupsPermissions: '/groups/permissions',
	},
	invites: {
		clients: '/invite-user/client',
		users: '/invite-user/user',
	},
	household: {
		assign: '/team-user-has-clientgroups/assign-multiple',
		households: '/clientgroups',
		uniqueHouseholds: '/team-user-has-clientgroups/unique-clientgroups',
	},
	user: {
		users: '/teams/users',
		userRemove: '/teams/users/remove',
		inviteUser: '/users/invite-user',
		inviteUserList: '/invite-user',
		list: '/users/list',
		profile: '/users/profile',
		validateInvitation: '/users/verify-email',
		assignTeam: '/users/team-assign',
		emailList: '/users/emails',
		teamUsers: '/teams/get-advisors',
	},
	teams: {
		allTeams: '/teams',
		teamDetails: '/teams',
		create: 'users/create-team',
	},
	nylas: {
		add: '/nylas/auth',
		remove: '/nylas/destroy-account',
	},
	users: '/users',
	settings: '/system-settings',
	deleteUserData: 'users/delete/client',
	notes: '/notes',
	document: '/document',
	dashboard: '/dashboard',
	announcements: '/announcements',
	documentDeleteEligible: '/document/delete/eligible',
	documentDeleted: '/document/removed',
	documentRestore: '/document/restore',
	documentPackage: '/document-package',
	systemFields: '/systems/document/fields',
	systemSettings: '/system-settings',
	recentDocument: '/recent-document/documents',
	starredDocument: '/starred-document',
	recentFolders: '/recent-document/document-packages',
	workflow: '/workflow',
	workflowAssignEligible: '/workflow/assign/eligible',
	workflowRead: 'workflow/read-note',
	client: {
		workflow: '/client/workflows',
		workflowDetails: '/client/workflow',
		documentUrl: 'client/generate-document-embed-url',
		document: '/client/documents',
		saveDocument: '/client/generate-signed-document',
	},
	clients: '/users/profiles',
	onboardingClients: '/client/profiles',
	clientSetting: '/user-settings',
	file: '/file',
	advisors: 'teams/advisors',
	clientCSV: '/users/clients/read-csv',
	clientImport: '/client/import',
	userOnBoard: {
		email: {
			sendOTP: '/user-on-board/email/send-otp',
			verifyOTP: '/user-on-board/email/verify-otp',
		},
		phone: {
			sendOTP: '/user-on-board/phone/send-otp',
			verifyOTP: '/user-on-board/phone/verify-otp',
		},
		stepStatus: '/user-on-board/steps-status',
		sinNumber: '/user-on-board/sin-number',
		verifyDocument: '/user-on-board/verify-document',
		selfie: {
			upload: '/user-on-board/selfie/upload',
			qrCodeToken: {
				generate: '/user-on-board/selfie/qr-token-generate',
				validate: '/user-on-board/selfie/qr-token-validate',
			},
		},
		profile: '/user-on-board/profile',
	},
	task: '/task',
	taskStatus: '/task/status-list',
	profileInformation: '/users/update/profile',
	workflow_template: '/workflow-template',
	workflow_outstanding: '/workflow/assigned',
	subscriptions: '/subscription',
	getSubscriptions: '/user-subscription-has-renewals',
	payments: '/payments',
	pending_subscription: '/pending-subscription',
	notifications: '/notifications',
	notificationsMaster: '/notification-master',
	features: '/features',
	userSubscriptions: '/user-has-subscriptions',
	cancelSubscriptions: '/stripe/cancel-subscription',
	documentEventLog: '/document-event-log',
	comment: '/comments',
	emailTemplates: '/email-templates',
}

export default apiEndpoints
