import { useUser } from 'hooks'
import { useMediaQuery } from 'react-responsive'
import { AppLayout } from 'theme/layouts'
import SearchBar from '../search-bar'

const FileManagerLayout = ({ children, meta, filterForm }) => {
	const { group } = useUser()
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	return (
		<AppLayout
			meta={{
				title: meta?.title,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					{/* <PageHeader title='File Manager' /> */}

					{!filterForm && group?.slug !== 'client' ? <SearchBar /> : undefined}

					{filterForm ? filterForm : undefined}

					<div className='page-body navbar_padding_full'>
						<div className={`extra_padding ${isMobile ? 'overflow-auto' : ''}`}>
							{children}
						</div>

						{/* <div className='upload_doc_alignment flex gap-4'> */}
						{/* <div className='min-w-[240px]'>
								<Sidebar />
							</div> */}
						{/* </div> */}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FileManagerLayout
