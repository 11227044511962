import { Fragment, useMemo } from 'react'
import { getPageNumbers } from '.'

const DataTablePagination = ({ table, meta }) => {
	const { pageNumbers, startIndex, endIndex, numPages } = useMemo(
		() => getPageNumbers(meta),
		[meta, table],
	)
	return (
		<div className='@container/pagination'>
			<div className='tabs_alignmentt !my-3.5 flex items-center justify-between gap-2'>
				<div className='my-2 flex items-center gap-3'>
					<span className='flex items-center gap-1 text-sm'>
						Showing {startIndex} - {endIndex} of {meta?.totalRecords}
					</span>
					<div className='custom-select'>
						<select
							className='!w-14 rounded px-2 py-1 text-xs'
							value={table.getState().pagination.pageSize}
							onChange={(e) => {
								table.setPageSize(Number(e.target.value))
							}}>
							{[5, 10, 20, 40, 50, 100].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className='flex items-center justify-end'>
					<span className='xs:flex mx-3 hidden'>
						Showing Page {table.getState().pagination.pageIndex + 1} of {numPages}
					</span>
					{numPages && numPages > 1 && (
						<nav
							className='relative z-0 inline-flex -space-x-px rounded-md'
							aria-label='Pagination'>
							<button
								className={`${
									!table.getCanPreviousPage()
										? 'cursor-not-allowed opacity-50'
										: ''
								} relative inline-flex items-center  rounded-l-md !border border-[#CED4DA] bg-white px-3 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-300 focus:outline-none`}
								onClick={() => table.firstPage()}
								disabled={!table.getCanPreviousPage()}>
								First
							</button>

							{Array.isArray(pageNumbers) ? (
								<Fragment>
									{pageNumbers.map((pageNumber, idx) => {
										const isActive =
											table.getState().pagination.pageIndex === pageNumber - 1
										if (isActive) {
											return (
												<span
													key={idx}
													className={`relative inline-flex items-center !border border-main bg-main px-3 py-2 text-sm font-normal text-white focus:z-10 focus:border-blue-300 focus:outline-none`}>
													{pageNumber}
												</span>
											)
										} else {
											return (
												<button
													key={idx}
													className={`${
														isActive
															? 'border-main bg-main text-white'
															: 'text-gray-700 hover:bg-main/20'
													} relative inline-flex items-center !border border-[#CED4DA] px-3 py-2 text-sm font-normal focus:z-10 focus:border-blue-300 focus:outline-none`}
													onClick={() =>
														table.setPageIndex(pageNumber - 1)
													}>
													{pageNumber}
												</button>
											)
										}
									})}
								</Fragment>
							) : undefined}

							<button
								className={`${
									!table.getCanNextPage() ? 'cursor-not-allowed opacity-50' : ''
								} relative inline-flex items-center rounded-r-md !border border-[#CED4DA] bg-white px-3 py-2 text-sm font-normal text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-blue-300 focus:outline-none`}
								onClick={() => table.lastPage()}
								disabled={!table.getCanNextPage()}>
								Last
							</button>
						</nav>
					)}
				</div>
			</div>
		</div>
	)
}

export default DataTablePagination
