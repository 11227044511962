export const filterOption = ({ data }, search) => {
	if (
		data.first_name.toLowerCase().includes(search.toLowerCase()) ||
		data.last_name.toLowerCase().includes(search.toLowerCase()) ||
		data.email.toLowerCase().includes(search.toLowerCase())
	) {
		return true
	} else {
		return false
	}
}
