import { useSelector } from 'react-redux'

import { TeamListingContainer } from 'modules/teams'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import UnAuthorizePage from '../StaticPages/UnAuthorize'

const TeamsListing = () => {
	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)

	return (
		<AppLayout
			meta={{
				title: 'Teams',
			}}>
			{isSuperAdmin ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<PageHeader title='Teams' innerClassName={'block sm:hidden'} />
						<div className='page-body'>
							<TeamListingContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorizePage />
			)}
		</AppLayout>
	)
}

export default TeamsListing
