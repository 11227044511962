import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat , formatDate } from 'utilities/helpers'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import AnnouncementActions from './announcemt-actions'

const AnnouncementssTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()
	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'title',
			size: 200,
			header: () => <span>Title</span>,
			cell: (info) => <p className='m-0 p-0 font-bold'>{info.getValue().title}</p>,
		}),
		// columnHelper.accessor((row) => row, {
		// 	id: 'content',
		// 	size: 300,
		// 	header: () => <span>Content</span>,
		// 	cell: (info) => (
		// 		<p
		// 			className='m-0 line-clamp-1 p-0'
		// 			title={info.getValue().content}
		// 			dangerouslySetInnerHTML={{ __html: info.getValue().content }}
		// 		/>
		// 	),
		// }),

		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => (
				<>
					<Badge variant={info?.getValue()?.status == 'ACTIVE' ? 'success' : 'danger'}>
						<span className='capitalize'>{info?.getValue()?.status}</span>
					</Badge>
				</>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'created_at	',
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Created At</span>
				</div>
			),
			cell: (info) => (
				<div className='justify-end w-full text-right'>
				<ToolTip
				  targetId={`date-${info.row.index}`}
				  tooltipText={dateFormat(info.getValue())} 
				  placement='top'
				  offset={[0, 5]}
				/>
				<span className='text-[14px]' id={`date-${info.row.index}`}>
				  {formatDate(info.getValue())} 
				</span>
			  </div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 200,
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => <AnnouncementActions announcement={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default AnnouncementssTable
