import { useState } from 'react'
import { Tooltip } from 'reactstrap'

const ToolTip = ({ targetId, tooltipText, placement = 'top', ...rest }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const toggle = () => setTooltipOpen(!tooltipOpen)

	return (
		<Tooltip
			isOpen={tooltipOpen}
			target={targetId}
			toggle={toggle}
			placement={placement}
			className='custom-tooltip'
			{...rest}>
			{tooltipText}
		</Tooltip>
	)
}

export default ToolTip
