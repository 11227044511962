import { Field, FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import TeamDropdownField from 'modules/system-fields/system-field-form/team-dropdown-field'
import { useMemo } from 'react'
import { Button, DatePickerField, InputField, RadioGroupField, SelectField } from 'theme/ui/forms'
import TextEditor from 'theme/ui/forms/text-editor'
import notification from 'utilities/notification'
import {
	alertOptions,
	getInitialValues,
	getPayload,
	iconsOptions,
	priorityOptions,
	targetAudianceOptions,
	targetRolesOptions,
	validationSchema,
} from '.'
import { useCreateAnnouncementMutation, useUpdateAnnouncementMutation } from '../announcements-api'
import { ANNOUNCEMENTS_APP_ROUTES } from '../routes'
import UsersDropdownField from './users-dropdown-field'

const AnnouncementsForm = ({ field }) => {
	const { goBack, navigate, hasHistory } = useAppRouter()
	const [createAnnouncement] = useCreateAnnouncementMutation()
	const [updateAnnouncement] = useUpdateAnnouncementMutation()

	const formik = useFormik({
		initialValues: getInitialValues(field),
		enableReinitialize: true,
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			try {
				const payload = getPayload(values)
				const response = field?.id
					? await updateAnnouncement({ payload, id: field?.id })
					: await createAnnouncement(payload)

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					hasHistory ? goBack() : navigate(ANNOUNCEMENTS_APP_ROUTES.findAll())
				}
			} catch (error) {
				console.error('Error submitting announcement:', error)
			} finally {
				setSubmitting(false)
			}
		},
	})

	const targetedAudience = useMemo(
		() => formik?.values?.target_audience,
		[formik?.values?.target_audience],
	)

	const renderConditionalFields = () => {
		if (targetedAudience === 'TEAM') {
			return (
				<>
					<TeamDropdownField isRequiredField />
					<Field
						label='Targeted role'
						type='select'
						name='target_role'
						component={RadioGroupField}
						placeholder='Select targeted role'
						options={targetRolesOptions}
						isRequiredField
					/>
				</>
			)
		}
		if (targetedAudience === 'USER') {
			return <UsersDropdownField />
		}
		return null
	}

	return (
		<div className='flex min-h-screen items-center justify-center bg-gray-100'>
			<FormikProvider value={formik}>
				<form
					onSubmit={formik.handleSubmit}
					className='w-full max-w-3xl rounded bg-white p-6 shadow-md'>
					<div className='form-heading mb-6'>
						<h3 className='text-lg font-semibold'>Announcement Details</h3>
						<p className='text-gray-600'>
							Enter the details of the announcement below.
						</p>
					</div>

					<div className='grid grid-cols-1 gap-4'>
						<Field
							label='Title'
							placeholder='Enter announcement title'
							name='title'
							component={InputField}
							isRequiredField
						/>
						<Field
							label='Content'
							name='content'
							component={TextEditor}
							isRequiredField
						/>
						<Field
							label='Link'
							placeholder='Enter hyperlink'
							name='hyperlink'
							component={InputField}
							helperText='Use the hyperlink option to redirect users to a specific location if needed.'
						/>
						<Field
							label='Alert Type'
							name='alert_class'
							component={SelectField}
							options={alertOptions}
							placeholder='Select alert type'
							isRequiredField
						/>
						<Field
							label='Icon'
							name='icon_class'
							component={RadioGroupField}
							options={iconsOptions}
							placeholder='Select icon type'
							isRequiredField
						/>
						<Field
							label='Dismissible'
							name='is_dismissible'
							component={RadioGroupField}
							options={[
								{ label: 'True', value: true },
								{ label: 'False', value: false },
							]}
							placeholder='Select dismissible option'
							isRequiredField
						/>
						<Field
							label='Target Audience'
							name='target_audience'
							component={RadioGroupField}
							options={targetAudianceOptions}
							placeholder='Select target audience'
							isRequiredField
						/>
						{renderConditionalFields()}
						<Field
							label='Start Date'
							name='start_date'
							type='date'
							component={DatePickerField}
							placeholder='Start date'
							isRequiredField
							minDate={new Date()}
						/>
						<Field
							label='End Date'
							name='end_date'
							type='date'
							component={DatePickerField}
							placeholder='End date'
							isRequiredField
							minDate={formik?.values?.start_date}
						/>
						<Field
							label='Priority'
							name='priority'
							component={RadioGroupField}
							options={priorityOptions}
							placeholder='Select priority'
							isRequiredField
						/>
						<Field
							label='Status'
							name='status'
							component={RadioGroupField}
							options={[
								{ label: 'ACTIVE', value: 'ACTIVE' },
								{ label: 'INACTIVE', value: 'INACTIVE' },
							]}
							placeholder='Select status'
							isRequiredField
						/>
					</div>

					<div className='mt-6 flex justify-end'>
						<Button type='submit' isLoading={formik.isSubmitting}>
							Save
						</Button>
					</div>
				</form>
			</FormikProvider>
		</div>
	)
}

export default AnnouncementsForm
