import { useDraggable } from '@dnd-kit/core'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'reactstrap'

import userPlaceholder from 'assets/images/users/avatar1.jpg'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { convertToTranslate } from '.'
import Clients from './clients'
import Tags from './tags'

const TaskCard = ({ task, index }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const toggle = () => setTooltipOpen(!tooltipOpen)

	const [assigneeTooltipOpen, setAssigneeTooltipOpen] = useState(false)
	const assigneeToggle = () => setAssigneeTooltipOpen(!assigneeTooltipOpen)

	const { active, attributes, listeners, setNodeRef, transform } = useDraggable({
		id: task?.id,

		data: {
			index,
			task,
			parent: task?.progress_status,
			type: snakeCase(task?.progress_status),
		},
	})

	const handleClick = () => {
		console.log('handleClick!!!')
	}

	const transForm = useMemo(() => convertToTranslate(transform), [transform])
	const assignee = task?.assignee || null

	return (
		<React.Fragment>
			<div
				className={classNames(
					'space-y-2 rounded !border bg-white p-[10px]',
					active?.id === task?.id ? '!z-[1000] border-main shadow-md' : 'drop-shadow-sm',
				)}
				style={{ transform: transForm }}
				{...listeners}
				{...attributes}
				ref={setNodeRef}
				onClick={handleClick}
				role='button'>
				<Tooltip
					isOpen={tooltipOpen}
					target={`task-${task?.id}`}
					toggle={toggle}
					placement='auto'>
					<p className='m-0 text-xs'>{task?.task_title}</p>
				</Tooltip>

				<Link
					id={`task-${task?.id}`}
					to={isAllowTo(permissions?.task?.edit) ? `/tasks-kanban/${task?.id}` : '#'}
					className='line-clamp-3 break-words text-sm !text-gray-700 hover:!underline'>
					{task?.task_title}
				</Link>

				<Tags tags={task?.tags} />

				{/* <div className='flex items-center space-x-2 rounded py-2 text-xs'>
					<span>{task?.team?.team_name}</span>
				</div> */}

				{task?.workflow?.title ? (
					<span className='tag tag-info line-clamp-1 max-w-fit'>
						<i className='uil-list-ul mr-1' />
						{task?.workflow?.title}
					</span>
				) : undefined}

				<Clients clients={task?.clients} />

				<div className='flex justify-between border-t pt-2'>
					{task?.due_date || task?.attachments.length > 0 ? (
						<div>
							{task?.due_date ? (
								<span className='text-success rounded bg-green-100 p-1 text-xs'>
									{moment(task?.due_date).format('D, MMM YYYY')}
								</span>
							) : undefined}

							{task?.attachments.length > 0 ? (
								<span className='rounded bg-gray-100 px-1'>
									<i className='uil-paperclip text-base' />
									&nbsp;{task?.attachments.length}
								</span>
							) : undefined}
						</div>
					) : (
						<div></div>
					)}

					<div className=''>
						<Tooltip
							isOpen={assigneeTooltipOpen}
							target={`assignee-${task?.id}`}
							toggle={assigneeToggle}
							placement='auto'>
							{assignee ? (
								<p className='m-0 text-xs'>
									{assignee?.first_name + ' ' + assignee?.last_name}
								</p>
							) : (
								<p className='m-0 text-xs'>Unassign</p>
							)}
						</Tooltip>
						<img
							id={`assignee-${task?.id}`}
							src={assignee?.avatar || userPlaceholder}
							alt=''
							className='aspect-square h-[28px] rounded-full'
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default TaskCard
