import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import Actions from './actions'

const TeamMemberInvitationTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'name',
			header: () => <span>Name</span>,
			cell: (info) => (
				<span className='font-bold'>
					{info.getValue()?.first_name + ' ' + info.getValue()?.last_name}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.email, {
			id: 'email',
			size: 200,
			header: () => <span>Email</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor((row) => row?.group?.name, {
			id: 'role',
			size: 100,
			header: () => (
				<div className='!w-full text-center'>
					<span>Role</span>
				</div>
			),
			cell: (info) => (
				<div className='mx-auto flex !w-full justify-center'>
					<Badge>{info.getValue()}</Badge>
				</div>
			),
		}),

		columnHelper.accessor((row) => row.created_at, {
			id: 'created_at',
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Invited At</span>
				</div>
			),
			cell: (info) => (
				<div className='w-full justify-end text-right'>
					<ToolTip
						targetId={`date-${info.row.index}`}
						tooltipText={dateFormat(info.getValue())}
						placement='top'
						offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
						{formatDate(info.getValue())}
					</span>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Action</span>
				</div>
			),
			cell: (info) => <Actions id={info.getValue()?.id} user={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default TeamMemberInvitationTable
