import Select from 'react-select'

const RatingConfigurator = ({ onRatingChange, name, field }) => {
	const starOptions = [
		{ label: '2 Stars', value: 2 },
		{ label: '3 Stars', value: 3 },
		{ label: '4 Stars', value: 4 },
		{ label: '5 Stars', value: 5 },
	]

	return (
		<div className='w-full space-y-4'>
			<div>
				<Select
					options={starOptions}
					value={field?.rating ? field?.rating : null}
					onChange={(option) => onRatingChange(name, option)}
					isSearchable={false}
					isClearable={false}
					classNamePrefix='select-dropdown'
					className='select-dropdown-container'
				/>
			</div>
		</div>
	)
}

export default RatingConfigurator
