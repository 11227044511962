import { useMemo, useState } from 'react'

import { useDeleteFileMutation, useUploadFileMutation } from 'modules/files/file-apis'
import notification from 'utilities/notification'
import AttachmentItem from './attachment-item'
import UploadButton from './upload-button'

const Attachments = ({ formik }) => {
	const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation()
	const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation()
	const [uploadPercentage, setUploadPercentage] = useState(0)

	const onUploadProgress = (progressEvent) => {
		let percentComplete = progressEvent.loaded / progressEvent.total
		const $percentage = parseInt(percentComplete * 100)
		setUploadPercentage($percentage)
	}

	const onUpload = async (e) => {
		const file = e.target.files[0]

		const maxSizeInBytes = 5 * 1024 * 1024

		if (file.size > maxSizeInBytes) {
			notification('warn', 'File size exceeds 5 MB. Please upload a smaller file.')
			return
		}

		const payload = new FormData()
		payload.append('file', file)

		try {
			const response = await uploadFile({ payload: payload, onUploadProgress }).unwrap()
			if (response?.status === 200) {
				formik.setFieldValue('attachment_ids', [
					...formik.values.attachment_ids,
					{
						file: response?.data?.file,
						id: response?.data?.fileID,
					},
				])
			}
		} catch (error) {
			notification('warn', 'An error occurred while uploading the file. Please try again.')
		}
	}

	const onRemove = async (id) => {
		const response = await deleteFile(id).unwrap()
		if (response?.status === 200) {
			const attachments = formik.values.attachment_ids.filter(
				(attachment) => attachment.id !== id,
			)
			formik.setFieldValue('attachment_ids', [...attachments])
		}
	}

	const attachments = useMemo(
		() => (Array.isArray(formik.values.attachment_ids) ? formik.values.attachment_ids : []),
		[formik.values],
	)

	return (
		<div className='form-group flex flex-col'>
			<label className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
				Attachments ({attachments.length})
			</label>

			<div className='flex flex-wrap gap-1'>
				{attachments.map((attachment, key) => (
					<AttachmentItem
						key={key}
						attachment={attachment}
						onRemove={onRemove}
						isDeleting={isDeleting}
					/>
				))}

				<UploadButton
					onUpload={onUpload}
					isUploading={isUploading}
					uploadPercentage={uploadPercentage}
				/>
			</div>
		</div>
	)
}

export default Attachments
