import classNames from 'classnames'
import { Fragment, useMemo } from 'react'

import notification from 'utilities/notification'
import StatusQuery from '../StatusQuery'
import { useUpdateTaskStatusMutation } from '../task-api'
import { createStatusOption } from '../task-form/status-dropdown'

const StatusColumn = ({ task }) => {
	const { statuses } = StatusQuery()
	const [updateStatus] = useUpdateTaskStatusMutation()
	const statusOptions = useMemo(
		() => (Array.isArray(statuses) ? statuses.map((status) => createStatusOption(status)) : []),
		[statuses],
	)
	const selectedOption = statusOptions.find((options) => options.value === task?.progress_status)

	const onStatusChange = async (e, task_id) => {
		const status = e.target.value
		const response = await updateStatus({ payload: { status }, id: task_id }).unwrap()
		if (response?.status === 200) {
			notification('success', response?.message)
		}
	}

	return (
		<Fragment>
			<select
				className={classNames('!rounded-md px-2 py-1 text-xs font-semibold text-white')}
				style={{
					backgroundColor: selectedOption?.color,
				}}
				onChange={(e) => onStatusChange(e, task?.id)}
				value={task?.progress_status}>
				{statuses.map((status, i) => (
					<option key={i} value={status?.value}>
						{status?.title}
					</option>
				))}
			</select>

			{/* <div className=' w-full'>
				<Listbox
					// value={task?.progress_status}

					onChange={(option) => onStatusChange(option, task?.id)}>
					<div className='relative'>
						<Listbox.Button
							className='flex w-full items-center justify-between rounded border px-3 py-2 text-left focus:outline-none'
							style={{
								backgroundColor: selectedOption?.color,
								color: 'white',
							}}>
							<span className=''>{selectedOption?.value}</span>
							<i className='uil-angle-down text-base'></i>
						</Listbox.Button>

						<Listbox.Options className='absolute !z-50 m-0 mt-2 w-full divide-y rounded !border bg-white p-0 !py-1 shadow focus:outline-none'>
							{statusOptions
								.filter((option) => option?.value !== selectedOption?.value)
								.map((option, idx) => (
									<Listbox.Option
										key={idx}
										value={option}
										className={({ active }) =>
											`cursor-pointer select-none px-3 py-2 ${
												active ? 'bg-gray-100' : 'bg-white'
											}`
										}>
										{option?.label}
									</Listbox.Option>
								))}
						</Listbox.Options>
					</div>
				</Listbox>
			</div> */}
		</Fragment>
	)
}

export default StatusColumn
