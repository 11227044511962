import { useEffect } from 'react'
import Swal from 'sweetalert2'
import notification from 'utilities/notification'
import { useDeleteWorkflowTemplateMutation } from '../../workflow-template-apis'

const DeleteConfirmAlert = ({ isOpen, onClose, id, variant = 'danger' }) => {
	const [deleteWorkflow, { isLoading: isDeleting }] = useDeleteWorkflowTemplateMutation()

	const onConfirm = async (id) => {
		try {
			const res = await deleteWorkflow(id)
			if (res?.data?.status) {
				notification('success', res?.data?.message)
				return true
			}
			return false
		} catch (error) {
			return false
		}
	}
	useEffect(() => {
		if (isOpen) {
			Swal.fire({
				title: 'Delete Workflow Template?',
				text: 'Are you sure you want to delete this Workflow Template?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				customClass: {
					confirmButton: `swal2-confirm ${
						variant === 'danger'
							? 'bg-green-600 hover:bg-green-700'
							: 'bg-green-600 hover:bg-green-700'
					} rounded-md text-white`,
					cancelButton:
						'swal2-cancel bg-red-600 hover:bg-red-700 text-white border border-red-600 rounded-md',
				},
				showLoaderOnConfirm: isDeleting,
				allowOutsideClick: false,
				preConfirm: async () => {
					if (isDeleting) return false
					const success = await onConfirm(id)
					if (success) {
						Swal.fire({
							title: 'Deleted!',
							text: 'Your workflow template has been deleted.',
							icon: 'success',
							confirmButtonText: 'OK',
						})
					} else {
						Swal.close()
					}
				},
				didClose: onClose,
			})
		}
	}, [isOpen])

	return null
}

export default DeleteConfirmAlert
