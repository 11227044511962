import classNames from 'classnames'
import { Plus } from 'lucide-react'
import ProgressBar from 'theme/ui/progress-bar'

const UploadButton = ({ onUpload, isUploading, uploadPercentage }) => {
	return (
		<label
			className={classNames(
				'flex h-[66px]  cursor-pointer items-center space-x-2 rounded-lg !border  border-gray-300 bg-cover px-1 ',
				isUploading ? 'w-[185px]' : 'w-[85px] border-dashed hover:!bg-black/20',
			)}
			htmlFor='upload'>
			{isUploading ? (
				<div className='flex w-full flex-col space-y-1 px-2'>
					<div className='flex items-center justify-between'>
						<span className='font-normal'>Uploading...</span>
						<span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 24 24'>
								<path
									fill={'#5b73e8'}
									d='M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'
									opacity='.25'
								/>
								<path
									fill={'#5b73e8'}
									d='M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z'>
									<animateTransform
										attributeName='transform'
										dur='0.75s'
										repeatCount='indefinite'
										type='rotate'
										values='0 12 12;360 12 12'
									/>
								</path>
							</svg>
						</span>
					</div>
					<ProgressBar value={uploadPercentage} variant='success' />
				</div>
			) : (
				<>
					<div className='flex w-full items-center justify-center'>
						<span>
							<Plus size={24} strokeWidth={2} />
						</span>
					</div>
					<input type='file' className='hidden' onChange={onUpload} id='upload' />
				</>
			)}
		</label>
	)
}

export default UploadButton
