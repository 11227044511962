import { snakeCase } from 'lodash'
import { getFieldOptions } from 'modules/form-designer/helpers'
import * as yup from 'yup'
import DocumentTemplateForm from './document-template-form'
import { getInitialPdfFieldsMapping } from './helpers'

export const validationSchemaDocument = yup.object().shape({
	document_type: yup.string().required('Please choose the template type.'),
	title: yup.string().required('Please enter the document title.'),
	min_clients: yup.mixed().required('Please select number of users.'),
	file: yup
		.mixed()
		.nullable()
		.typeError('File upload')
		.when('document_type', {
			is: (value) => value === 'document',
			then: (schema) => schema.required('Please select PDF file.'),
			otherwise: (schema) => schema.optional(),
		}),
})
export const validationSchemaMapping = yup.object().shape({
	document_mapping: yup.array().of(
		yup.object().shape({
			pdf_field: yup.string().required(),
			user: yup.mixed().required('Select user type'),
			field: yup.mixed().required('Select system field'),
		}),
	),
})

const optionsValidation = yup.object().shape({
	label: yup.string().required('Please enter the option label'),
	value: yup
		.string()
		.required('Please enter the option value')
		.test('unique-value', 'Option values must be unique.', function (value) {
			const { from } = this
			const parent = Array.isArray(from[1]?.value?.values) ? from[1]?.value?.values : []
			const duplicateCount = parent.filter((option) => option.value === value).length
			return duplicateCount === 1
		}),
	score: yup.string().optional(),
})

export const validationSchemaFormDesigner = yup.object().shape({
	document_body: yup
		.array()
		.of(
			yup.object().shape({
				label: yup.string().required('Please enter question label.'),
				user_type: yup.string().required('Please choose user type.'),
				values: yup.array().of(optionsValidation).optional(),
				max_selected_option: yup
					.number()
					.default(1)
					// .min(1)
					.test(
						'max-selected-option',
						'Max option selection cannot exceed total options',
						function (value) {
							const { values, multiple } = this.parent
							if (!multiple) {
								return true
							}
							const $options = Array.isArray(values) ? values : []

							return !$options || value <= $options.length
						},
					),
				name: yup
					.string()
					.required('Please provide a field key.')
					.test('unique', 'Field key must be unique.', function (value) {
						const { path, from } = this
						const parent = Array.isArray(from[1]?.value?.fields)
							? from[1]?.value?.fields
							: []
						const currentIndex = Number(path.match(/\d+/)?.[0])
						const isUnique =
							parent.filter(
								(field, index) => field.name === value && index !== currentIndex,
							).length === 0
						return isUnique
					}),
			}),
		)
		.min(1, 'Please add at least one field'),
})

export const getValidationSchema = (isFormTemplate) => {
	return {
		0: validationSchemaDocument,
		1: isFormTemplate ? validationSchemaFormDesigner : validationSchemaMapping,
	}
}

const createUserOption = (value) => ({
	label: value,
	value,
})

export const userListOptions = new Array(5).fill(0).map((_, idx) => createUserOption(idx + 1))

export const getInitialValues = (document) => {
	const initialValues = {
		document_type: document?.document_type || 'document',
		purpose: document?.purpose || 'workflow',
		form_template_type: document?.form_template_type ? document?.form_template_type : 'qna',
		title: document?.title || '',
		min_clients: document?.min_clients
			? createUserOption(document?.min_clients)
			: createUserOption(1),
		signature_required: document?.signature_required ? 'yes' : 'no',
		file: document?.file || null,
		file_path: document?.file_path || null,
		status: document?.status || 1,
		document_body:
			Array.isArray(document?.document_body) && document?.document_body.length > 0
				? document?.document_body
				: [getFieldOptions(`Question 1`, snakeCase(`Question 1`))],
		documentBlob: null,
		document_mapping: getInitialPdfFieldsMapping(
			document?.document_mapping,
			document?.pdf_fields,
		),
		delete_document_mapping: Array.isArray(document?.delete_document_mapping)
			? document?.delete_document_mapping
			: [],
	}

	return initialValues
}

export const getCreateablePayload = (values) => {
	const payload = {
		title: values?.title || '',
		status: 0,
		file: values?.file,
		min_clients: values?.min_clients?.value,
		document_type: values?.document_type,
		signature_required: values?.signature_required === 'yes' ? 1 : 0,
		purpose: values?.purpose,
		form_template_type: values?.form_template_type,
	}

	return payload
}

export const getPayload = (values) => {
	const payload = {}
	Object.assign(payload, values)

	Object.assign(payload, {
		min_clients: values?.min_clients?.value,
		document_mapping: JSON.stringify(values?.document_mapping),
		signature_required: values?.signature_required ? 1 : 0,
	})
	delete payload.file_path
	return payload
}

export const getDocumentPublishablePayload = (
	values,
	document,
	document_body,
	documentBlob,
	signature_mapping,
) => {
	const payload = new FormData()

	payload.append('title', values?.title)
	payload.append('status', 1)
	payload.append('document_body', JSON.stringify(document_body))
	payload.append('min_clients', values?.min_clients?.value)

	payload.append('documentBlob', documentBlob)
	payload.append('document_mapping', JSON.stringify(values?.document_mapping))
	payload.append('delete_document_mapping', JSON.stringify(values?.delete_document_mapping))
	payload.append('signature_mapping', JSON.stringify(signature_mapping))
	payload.append('file', JSON.stringify({ id: document?.file_id }))
	payload.append('signature_required', values?.signature_required ? 1 : 0)

	return payload
}

export const getFormPublishablePayload = (values) => {
	const payload = new FormData()
	payload.append('title', values?.title)
	payload.append('status', 1)
	payload.append('document_body', JSON.stringify(values?.document_body))
	payload.append('min_clients', values?.min_clients?.value)
	payload.append('form_template_type', values?.form_template_type)

	return payload
}

export const getAutoSavePayload = (values) => {
	const payload = {
		min_clients: values?.min_clients?.value,
		document_mapping: JSON.stringify(values?.document_mapping),
		delete_document_mapping: JSON.stringify(values?.delete_document_mapping),
		signature_required: values?.signature_required ? 1 : 0,
	}

	return payload
}

export * from './signature-document-context'

export default DocumentTemplateForm
