import classNames from 'classnames'
import { Field } from 'formik'

const MyTaskField = ({ name }) => {
	return (
		<Field name={name}>
			{({ field }) => (
				<div className='max-w-fit'>
					<input {...field} type='checkbox' className='hidden' id='my-task' />
					<label
						className={classNames(
							'btn btn-secondary m-0 flex min-w-max',
							field.checked ? '!bg-main/20 !font-bold !text-main' : '',
						)}
						htmlFor='my-task'>
						<div className='flex flex-row justify-start'>
							<span className='justify-left flex'>My Tasks</span>
						</div>
					</label>
				</div>
			)}
		</Field>
	)
}

export default MyTaskField
