import Header from 'modules/payments/header'
import PaymentsListingContainer from 'modules/payments/payments-listing'
import AppLayout from 'theme/layouts/app-layout'

const PaymentListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Payments',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>

					<div className='page-body'>
						<PaymentsListingContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default PaymentListing
