import { FileManagerLayout } from 'modules/file-manager'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { snakeCase } from 'lodash'
import { getDocumentUrl } from 'modules/documents/completed-documents'
import CompletedDocumentsTable from 'modules/documents/completed-documents/completed-documents-table'
import Filters from 'modules/documents/completed-documents/filters'
import { useLazyGetCompletedDocumentQuery } from 'modules/documents/document-api'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import DocumentPreview from 'theme/ui/document-preview'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'

const CompletedDocumentsListing = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [isOpen, setIsOpen] = useState(false)
	const [document, setDocument] = useState({
		fileUrl: null,
		id: 0,
		workflow_step_document_id: 0,
	})
	const toggle = async (document) => {
		setIsOpen(!isOpen)
		setDocument(document)
	}

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const { defaultGroup } = useSelector((state) => state.Permissions)
	const group = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])
	const stringUrl = useMemo(() => getDocumentUrl(group), [group])

	const [fetchCompletedDocument, { data, isFetching: isLoading }] =
		useLazyGetCompletedDocumentQuery()

	useEffect(() => {
		if (location?.search) {
			fetchCompletedDocument({
				params: getParseQueryString(location?.search),
				url: stringUrl,
			})
		}
	}, [location?.search])

	const completedDocument = useMemo(() => {
		return Array.isArray(data?.data)
			? data?.data?.map((document) => {
					return {
						...document,
						// workflow_title: getWorkflowTitle(document, group),
					}
			  })
			: []
	}, [data?.data])

	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - Completed Documents',
			}}
			filterForm={
				<Filters
					setPagination={setPagination}
					heading='Completed Documents'
					isDateRange={true}
				/>
			}>
			<div className={`${isMobile ? 'min-h-[75vh]' : ''}`}>
				<CompletedDocumentsTable
					documents={completedDocument}
					meta={data?.meta}
					isLoading={isLoading}
					pagination={{
						pageSize: limit,
						pageIndex: page - 1,
					}}
					toggle={toggle}
					setPagination={setPagination}
				/>
				<DocumentPreview url={document?.fileUrl} toggle={toggle} isOpen={isOpen} />
			</div>
		</FileManagerLayout>
	)
}

export default CompletedDocumentsListing
