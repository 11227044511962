/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
import { capitalize, flatten } from 'lodash'
import { getFieldsByUser } from 'pages/Authentication/profile/components/workflow/utils'
import { flattenArray, toJson } from 'utils/commonFunctions'
import { userTypes } from '..'

export const findUploadSteps = (steps) => {
	if (Array.isArray(steps)) {
		return steps.filter((item) => item?.type === 'uploads')
	} else {
		return []
	}
}

export const getReviewFileTabs = (uploads) => {
	if (Array.isArray(uploads) && uploads[0]?.note) {
		const tabsValue = toJson(uploads[0]?.note).map((item) => item?.user_type)
		return userTypes.filter((item) => tabsValue.includes(item?.value))
	} else {
		return []
	}
}

export const getUploadsReview = (uploads) => {
	if (Array.isArray(uploads)) {
		return uploads.map((item) => {
			const fileTypes = toJson(item?.note)
			return {
				file: item?.files,
				info: fileTypes,
			}
		})
	} else {
		return []
	}
}

// remove duplicate objects by key from array
export const getUniqueListBy = (arr, key) => {
	return [...new Map(arr.map((item) => [item[key], item])).values()]
}

// combine all the fields of document into singal array
export const getFieldsArray = (array) => {
	if (Array.isArray(array)) {
		const withDocumentTitle = array.map((document) => ({
			...document,
			document_body: {
				...document?.document_body,
				document: Array.isArray(document?.document_body?.document)
					? document?.document_body?.document.map((doc) => ({
							...doc,
							document_title: document?.title,
					  }))
					: [],
			},
		}))

		const mappedArray = withDocumentTitle.map((item) => item?.document_body?.document || [])
		return flattenArray(mappedArray)
	} else {
		return []
	}
}

export const getReviewFields = (array) => {
	if (Array.isArray(array)) {
		const fieldsArray = getFieldsArray(array)
		// return getUniqueListBy(fieldsArray, 'name')
		return fieldsArray.filter(
			(value, index, self) =>
				index ===
				self.findIndex((t) => t.user_type === value.user_type && t.name === value.name),
		)
	} else {
		return []
	}
}

// add status to the fields
export const addStatusToFields = (array, values, is_document_bound) => {
	if (Array.isArray(array) && Array.isArray(values)) {
		return array.map((field) => {
			let fieldObj = { ...field }

			const value = values.find(
				(i) =>
					field?.name === i?.field_name &&
					field?.user_type === i?.user_type &&
					field?.is_document_bound === is_document_bound,
			)
			if (value) {
				Object.assign(fieldObj, {
					status: value?.status,
					value: value?.field_value,
				})
			}

			return fieldObj
		})
	} else {
		return []
	}
}

export const addStatusToFiles = (array, values, user_type) => {
	if (Array.isArray(array) && Array.isArray(values)) {
		return array.map((file) => {
			let fileObj = { ...file }

			const value = values.find((i) => i?.title === file?.title && i?.user_type === user_type)
			if (value) {
				Object.assign(fileObj, { correction: value?.status === 'approved' ? false : true })
			}
			return fileObj
		})
	} else {
		return []
	}
}

export const getAllFiles = (uploads) => {
	if (Array.isArray(uploads)) {
		const files = uploads.map((upload) =>
			upload?.files.map((file) => ({ ...file, client_type: upload?.client_type })),
		)
		return flattenArray(files)
	} else {
		return []
	}
}

export const AUTO_APPROVED = 'autoApproved'
export const MANUAL_APPROVED = 'manualApproved'
export const DOCUMENT_BOUND = 'documentBound'

export const fillValueBasedOnFieldType = (field) => {
	const selectOption =
		typeof field?.value === 'string'
			? { label: capitalize(field?.value), value: field?.value }
			: field?.value

	let fieldValue = field?.value
	if (field?.type === 'select') {
		fieldValue = selectOption
	}

	if (field?.type === 'checkbox' && field?.values?.length > 1) {
		fieldValue = field?.value?.toString()?.split(',')
	}

	if (field?.type === 'checkbox' && field?.values?.length === 1) {
		fieldValue = field?.value || false
	}

	return {
		field_name: field?.name,
		field_value: fieldValue,
		user_type: field?.user_type,
		status: field?.status || 'approved',
	}
}

export function getInitialValues(individualDocument, uploads, user_type) {
	try {
		const fields = getReviewFields(individualDocument?.documents).filter(
			(field) => field?.user_type === user_type,
		)
		const documentBoundFields = getDocumentBoundFields(individualDocument?.documents, user_type)
		const files = getAllFiles(uploads)

		const userTypes = uploads.map((item) => item?.client_type)
		const reviews = uploads.map((item) => item?.review_notes)
		const filesReview = {}
		userTypes.forEach((el, idx) => {
			Object.assign(filesReview, { [el]: reviews[idx] })
		})

		const fieldsReview = individualDocument?.review_notes

		const initialValues = {
			fields: {
				[MANUAL_APPROVED]: fields
					.filter(
						(field) =>
							field?.isDefaultApproved === false &&
							field?.is_document_bound === false,
					)
					.map((field) => fillValueBasedOnFieldType(field)),
				[AUTO_APPROVED]: fields
					.filter(
						(field) =>
							field?.isDefaultApproved === true && field?.is_document_bound === false,
					)
					.map((field) => fillValueBasedOnFieldType(field)),
				[DOCUMENT_BOUND]: documentBoundFields.map((doc) => ({
					fields: doc?.fields.map((field) => fillValueBasedOnFieldType(field)),
				})),
			},
			files: files.map((file) => ({
				title: file?.title,
				user_type: file?.client_type,
				status: file?.correction ? 'reject' : 'approved',
			})),
			files_notes: filesReview,
			fields_notes: fieldsReview,
		}
		return initialValues
	} catch (error) {
		//
	}
}

// prepare payload to submit the workflow after review
export function preparePayload(workflow, values) {
	const payload = { title: `${Math.random()}` }
	const fieldValues = [...values?.fields[AUTO_APPROVED], ...values?.fields[MANUAL_APPROVED]]
	const documentBoundFieldValues = values?.fields[DOCUMENT_BOUND]

	payload.steps = workflow?.workflow_steps.map((step) => {
		let stepObj = { id: step?.id, notify: step?.notify, delete: false }

		if (step?.type === 'uploads') {
			stepObj.files = addStatusToFiles(step?.files, values?.files, step?.client_type)
			stepObj.type = 'uploads'
			stepObj.status =
				values?.files.filter(
					(file) => file?.status === 'reject' && file?.user_type === step?.client_type,
				).length > 0
					? 'correction'
					: null
			stepObj.review_notes = values?.files_notes[step?.client_type] || null
		}

		if (step?.type === 'individual_document') {
			stepObj['documents'] = step?.documents
				.map((item, idx) => {
					if (Array.isArray(item?.document_body?.document)) {
						const docBoundFields = documentBoundFieldValues[idx].fields

						const $fillFieldValues = addStatusToFields(
							item?.document_body?.document,
							fieldValues,
							false,
						)
						const updated_doc = addStatusToFields(
							$fillFieldValues,
							docBoundFields,
							true,
						)

						return {
							id: item?.id,
							title: item?.title,
							document_body: updated_doc,
						}
					}
				})
				.filter((item) => item)

			stepObj['rejected_fields'] = stepObj['documents'].map((item) => {
				const rejected_fields = item?.document_body.filter(
					(doc) => doc?.status === 'reject',
				)
				return {
					...item,
					document_body: rejected_fields
						.filter((f) => f?.is_document_bound === false)
						.map((field) => field?.label),
					document_bound_body: rejected_fields
						.filter((f) => f?.is_document_bound === true)
						.map((field) => field?.label),
				}
			})

			const $rejected_fields = flatten(
				stepObj?.rejected_fields.map((f) => f?.document_bound_body),
			)

			stepObj.type = 'individual_document'
			stepObj.status =
				fieldValues.filter((field) => field?.status === 'reject').length > 0 ||
				$rejected_fields.length > 0
					? 'correction'
					: null
			stepObj.review_notes = values?.fields_notes
		}

		return stepObj
	})
	return payload
}

export const getReviewers = (reviewers) => {
	return reviewers
		.filter((r) => r?.reviewer !== null)
		.map((reviewer) => ({
			id: reviewer?.reviewer?.id,
			full_name: `${reviewer?.reviewer?.first_name} ${reviewer?.reviewer?.last_name}`,
			email: reviewer?.reviewer?.email,
		}))
}

// get document bound fields document wise
export const getDocumentBoundFields = (documents, user_type) => {
	try {
		if (Array.isArray(documents)) {
			return documents.map((document) => {
				const fields = document?.document_body?.document.filter(
					(field) => field.is_document_bound === true,
				)
				const fieldbyUser = getFieldsByUser(fields, user_type)

				return {
					id: document?.id,
					title: document?.title,
					fields: Array.isArray(fieldbyUser) ? fieldbyUser : [],
				}
			})
		} else {
			return []
		}
	} catch (error) {
		console.log('error', error)
		return []
	}
}
