import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import clsx from 'clsx'

const DocumentList = ({ folders, formik }) => {
	if (folders?.length === 0) {
		return <p className='text-center'>No Folder found</p>
	}

	const toggleFolderSelection = (folderId) => {
		const selectedFolders = [...formik.values.selectedFolders] || []
		if (selectedFolders.includes(folderId)) {
			formik.setFieldValue(
				'selectedFolders',
				selectedFolders.filter((id) => id !== folderId),
			)
		} else {
			formik.setFieldValue('selectedFolders', [...selectedFolders, folderId])
		}
	}

	return (
		<div>
			{folders.map((item, idx) => {
				const folderId = item?.id
				const isSelected = formik.values.selectedFolders?.includes(folderId)
				// const isDisabled = item.documents.includes(document?.id)
				const isDisabled = false
				return (
					<label
						key={idx}
						htmlFor={`folder-${folderId}`}
						className={clsx(
							'm-0 flex cursor-pointer items-center justify-between space-x-2 rounded-sm px-4 py-2 shadow-sm',
							isDisabled
								? 'cursor-not-allowed bg-gray-100 text-gray-400'
								: isSelected
								? 'border bg-main/20'
								: 'border border-gray-300 bg-white hover:bg-gray-100',
						)}>
						<div className='flex items-center gap-1'>
							<span>
								<FolderIcon height={15} />
							</span>
							<span className='my-1 ml-2 text-sm font-medium'>{item?.title}</span>
						</div>
						<input
							type='checkbox'
							id={`folder-${folderId}`}
							name='selectedFolders'
							value={folderId}
							className='form-check-input'
							checked={isSelected}
							onChange={() => toggleFolderSelection(folderId)}
							disabled={isDisabled}
						/>
					</label>
				)
			})}
		</div>
	)
}

export default DocumentList
