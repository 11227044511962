import { Field } from 'formik'
import { FormDesignContainer } from 'modules/form-designer'
import { SwitchField } from 'theme/ui/forms'

const FormDesignerTab = ({ fieldName, completed_fields, formik, fields, is_view_mode }) => {
	const is_score_able = formik?.values?.form_template_type === 'scoring'

	return (
		<div>
			<div className='mb-3 flex items-center justify-between'>
				<div>
					<h3 className='mb-2 text-lg font-bold'>Form Builder</h3>
					<p className='text-gray-600'>Build the form and assign field type</p>
				</div>
				{/* <div>
					{is_view_mode ? (
						<>
							<span>Values:</span>
							<pre>{JSON.stringify($values, null, 2)}</pre>
						</>
					) : undefined}
				</div> */}
				{completed_fields > 0 ? (
					<Field
						component={SwitchField}
						disabled={completed_fields === 0}
						label='Preview'
						name='is_view_mode'
					/>
				) : undefined}
			</div>
			<div className=''>
				<FormDesignContainer
					formik={formik}
					is_score_able={is_score_able}
					fieldName={fieldName}
					fields={fields}
					is_view_mode={is_view_mode}
				/>
			</div>
		</div>
	)
}

export default FormDesignerTab
