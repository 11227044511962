import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import ToolTip from 'theme/ui/tool-tip'
import notification from 'utilities/notification'
import { SUBSCRIPTION_APP_ROUTES } from '../routes'
import { useDeleteSubscriptionMutation } from '../subscriptions-api'

const SubscriptionActions = ({ subscription }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const onOpen = () => setIsOpen(true)

	const [deleteSubscription, { isLoading: isDeleting }] = useDeleteSubscriptionMutation()
	const onDeleteSubscription = async (id) => {
		try {
			const response = await deleteSubscription(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			onClose()
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isOpen={isOpen}
				onClose={onClose}
				isLoading={isDeleting}
				onConfirm={() => onDeleteSubscription(subscription?.id)}
			/>
			<div className=''>
				<div className='float-right flex justify-end space-x-2'>
					<ToolTip
						targetId={`view-sub-${subscription?.id}`}
						tooltipText='View'
						placement='top'
					/>

					<Link
						id={`view-sub-${subscription?.id}`}
						to={SUBSCRIPTION_APP_ROUTES.findOne(subscription?.id)}
						className='action-btn btn-gray-outline'>
						<i className='uil-eye' style={{ fontSize: 16 }} />
					</Link>
					<ToolTip
						targetId={`edit-sub-${subscription?.id}`}
						tooltipText='Edit'
						placement='top'
					/>

					<Link
						id={`edit-sub-${subscription?.id}`}
						to={SUBSCRIPTION_APP_ROUTES.edit(subscription?.id)}
						className='action-btn btn-primary-outline'>
						<i className='uil-pen' style={{ fontSize: 16 }} />
					</Link>
					<ToolTip
						targetId={`delete-subscription-${subscription?.id}`}
						tooltipText='Delete'
						placement='top'
					/>

					<button
						id={`delete-subscription-${subscription?.id}`}
						type='button'
						className='action-btn btn-red-outline'
						onClick={onOpen}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
					</button>
				</div>
			</div>
		</Fragment>
	)
}

export default SubscriptionActions
