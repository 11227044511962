import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'

import checkIcon from 'assets/svg/check-icon.svg'
import { useTabs } from 'contexts/tabs-context'
import { getTabs } from '.'

const TabsHeader = ({ formik }) => {
	const { activeTab } = useTabs()
	const isSignatureRequired = useMemo(
		() => formik?.values?.signature_required === 'yes',
		[formik?.values],
	)
	const isFormType = useMemo(() => formik?.values?.document_type === 'form', [formik?.values])

	const $tabs = useMemo(
		() => getTabs(isSignatureRequired, isFormType),
		[isSignatureRequired, isFormType],
	)

	return (
		<div className='Create_doc_alignment Create_doc_steps flex h-[56px] w-full items-center justify-between bg-[#EDEFFB] px-[75px]'>
			<Tab.List className='alignment_full flex w-fit space-x-1'>
				{$tabs.map((tab) => {
					const imageIcon =
						activeTab > tab.id
							? checkIcon
							: activeTab === tab.id
							? tab?.activeIcon
							: tab?.icon
					return (
						<Tab
							key={tab.id}
							className={({ selected }) =>
								classNames(
									'tabs_alignmentt remove_space flex items-center gap-2 py-2.5 text-sm',
									selected ? 'font-medium text-[#495057]' : 'text-[#74788D]',
								)
							}
							style={{ paddingLeft: '12px', paddingRight: '12px' }}
							as='span'>
							{imageIcon ? <img src={imageIcon} alt={tab?.label} /> : undefined}
							<span className='responsive-break'>{tab?.label}</span>
						</Tab>
					)
				})}
			</Tab.List>

			<div>
				<span className='text-xs'>
					Step {activeTab}/{$tabs.length}
				</span>
			</div>
		</div>
	)
}

export default TabsHeader
