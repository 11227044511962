import * as yup from 'yup'

import { createSelectOption } from 'utilities/helpers'
import { createAssigneeOption } from './assignee-dropdown'
import { createClientOption } from './client-dropdown'
import TaskForm from './task-form'
import { createWatcherOption } from './watcher-dropdown'
import { createWorkflowOption } from './workflow-dropdown'

export default TaskForm

export const formatDate = (fromDate) => {
	try {
		if (!fromDate) {
			return null
		}
		const originalDate = new Date(fromDate)

		const year = originalDate.getUTCFullYear()
		const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0')
		const day = String(originalDate.getUTCDate()).padStart(2, '0')
		const hours = String(originalDate.getUTCHours()).padStart(2, '0')
		const minutes = String(originalDate.getUTCMinutes()).padStart(2, '0')
		const seconds = String(originalDate.getUTCSeconds()).padStart(2, '0')

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
	} catch (error) {
		return null
	}
}

export const formatDateForCalendar = (inputDateStr) => {
	try {
		// Convert input date string to a Date object
		var inputDate = new Date(inputDateStr)

		// Extract year, month, and day components
		var year = inputDate.getFullYear()
		var month = String(inputDate.getMonth() + 1).padStart(2, '0')
		var day = String(inputDate.getDate()).padStart(2, '0')

		// Format the date as "YYYY-MM-DD"
		return `${year}-${month}-${day}`
	} catch (error) {
		return null
	}
}

export const getInitialValues = (task) => {
	return {
		task_title: task?.task_title || '',
		progress_status: task?.progress_status
			? createSelectOption(task?.progress_status)
			: createSelectOption('To Do'),
		assignee_id: task?.assignee ? createAssigneeOption(task?.assignee) : null,
		watcher_ids: Array.isArray(task?.watchers)
			? task?.watchers.map((watcher) => createWatcherOption(watcher))
			: [],
		client_ids: task?.clients[0] ? createClientOption(task?.clients[0]) : [],
		tags: Array.isArray(task?.tags)
			? task?.tags.map((tag) => createSelectOption(tag?.tag))
			: [],
		workflow_id: task?.workflow ? createWorkflowOption(task?.workflow) : null,
		due_date: task?.due_date ? new Date(task?.due_date) : null,
		description: task?.description,
		attachment_ids: Array.isArray(task?.attachments)
			? task?.attachments.map((attachment) => ({
					file: attachment,
					id: attachment?.id,
			  }))
			: [],
		custom_fields: [],
		send_notification: task?.send_notification ? true : false,
	}
}

export const getPayload = (values) => {
	try {
		return {
			team_id: values?.team_id,
			task_title: values?.task_title,
			assignee_id: values?.assignee_id?.value,
			progress_status: values?.progress_status?.value,
			watcher_ids: Array.isArray(values?.watcher_ids)
				? values?.watcher_ids.map((watcher_id) => watcher_id?.value)
				: [],
			// client_ids: Array.isArray(values?.client_ids)
			// 	? values?.client_ids.map((client_id) => client_id?.value)
			// 	: [],
			client_ids: values?.client_ids?.value ? [values?.client_ids?.value] : [],
			tags: Array.isArray(values?.tags)
				? values?.tags.filter((tag) => tag !== null).map((tag) => tag?.value)
				: [],
			workflow_id: values?.workflow_id?.value ? values?.workflow_id?.value : undefined,
			due_date: formatDate(values?.due_date),
			description: values?.description,
			custom_fields: [],
			attachment_ids: Array.isArray(values?.attachment_ids)
				? values?.attachment_ids.map((attachment) => attachment?.id)
				: [],
			send_notification: values?.send_notification,
		}
	} catch (error) {
		return {
			assignee_id: null,
			watcher_ids: [],
			client_ids: [],
			tags: [],
			workflow_id: null,
			due_date: null,
			custom_fields: [],
			send_notification: true,
		}
	}
}

export const validationSchema = yup.object().shape({
	task_title: yup
		.string()
		.test('len', 'The title must not exceed 100 characters in length.', (val) => {
			return val.length <= 100
		})
		.required('Please enter title'),
	progress_status: yup.mixed().required('Please select progress status'),
	workflow_id: yup.mixed().required('Please select at least one workflow'),
})

export const copyToClipboard = (text) => {
	const textarea = document.createElement('textarea')

	textarea.value = text
	textarea.style.position = 'fixed'
	textarea.style.top = '0'
	textarea.style.left = '0'
	textarea.style.opacity = '0'

	document.body.appendChild(textarea)

	textarea.select()

	try {
		document.execCommand('copy')
	} catch (err) {
		//
	} finally {
		document.body.removeChild(textarea)
	}
}
