import { useState } from 'react'

import { Field } from 'formik'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { filterOption } from 'modules/workflows/workflows-outstandings/filters/helpers'
import { SelectField } from 'theme/ui/forms'
import ClientOptionsQuery from './ClientOptionsQuery'

const ClientDropdown = () => {
	const [search, setSearch] = useState('')
	const { clientOptions, isLoading } = ClientOptionsQuery(search)

	return (
		<Field
			label='Client'
			name='client_ids'
			type='select'
			isMulti={false}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			placeholder='Select client'
			options={clientOptions}
			component={SelectField}
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default ClientDropdown
