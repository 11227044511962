import { TabsProvider } from 'contexts/tabs-context'
import DocumentTemplateForm from 'modules/documents/document-template-form'
import { SignatureDocumentProvider } from 'modules/documents/document-template-form/signature-document-context'
import { useState } from 'react'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const CreateDocumentTemplate = () => {
	const [title, setTitle] = useState('Document')
	return (
		<AppLayout
			meta={{
				title: `Create ${title} Template`,
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title={`Create ${title} Template`}
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<SignatureDocumentProvider>
						<div>
							<TabsProvider>
								<DocumentTemplateForm setTitle={setTitle} />
							</TabsProvider>
						</div>
					</SignatureDocumentProvider>
				</div>
			</div>
		</AppLayout>
	)
}

export default CreateDocumentTemplate
