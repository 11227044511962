import classNames from 'classnames'
import { getIn } from 'formik'
import { useMemo, useState } from 'react'

import eyeClose from 'assets/svg/eye-slash-svgrepo-com.svg'
import eyeOpen from 'assets/svg/eye-svgrepo-com.svg'
import { validatePasswordWithMessages } from 'utils/formValidation'

const PasswordField = ({
	id,
	form,
	label,
	field,
	placeholder,
	isRequiredField,
	labelHelperText,
	autoComplete,
}) => {
	const { onChange, onBlur, name, value } = field

	const touched = useMemo(() => getIn(form?.touched, name), [form?.touched])
	const error = useMemo(() => getIn(form?.errors, name), [form?.errors])

	const [showPassword, setShowPassword] = useState(false)

	const toggleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const errors = useMemo(
		() => validatePasswordWithMessages(value).filter((v) => typeof v !== 'undefined'),
		[value],
	)

	return (
		<div className='form-group'>
			{label ? (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> required
						</span>
					) : undefined}
				</label>
			) : undefined}
			<div
				className={classNames('form-control !flex items-center justify-between', {
					'is-invalid !px-[10px]': touched && error,
				})}>
				<input
					id={id}
					onChange={onChange}
					onBlur={onBlur}
					name={name}
					type={showPassword ? 'text' : 'password'}
					value={value}
					placeholder={placeholder}
					autoComplete={autoComplete}
					className={classNames('w-[90%] placeholder:!text-[#B5B5C3]', {
						'is-invalid': touched && error,
					})}
				/>
				<div className='w-[10%]'>
					{showPassword ? (
						<img
							src={eyeClose}
							alt=''
							style={{ height: 20 }}
							className='logo logo-dark ms-3 cursor-pointer'
							onClick={toggleShowPassword}
						/>
					) : (
						<img
							src={eyeOpen}
							alt=''
							style={{ height: 20 }}
							onClick={toggleShowPassword}
							className='logo logo-dark ms-3 cursor-pointer'
						/>
					)}
				</div>
			</div>

			{touched ? (
				<ul className='m-0 mt-1 p-0'>
					{errors.map((error, idx) => (
						<li className='text-[13px] text-red-500' key={idx}>
							{error}
						</li>
					))}
				</ul>
			) : undefined}
		</div>
	)
}

PasswordField.defaultProps = {
	autoComplete: 'off',
}

export default PasswordField
