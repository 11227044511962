import { Tab } from '@headlessui/react'
import FieldMapping from './field-mapping'
import FormBuilder from './form-builder'

const TabsContent = ({ document }) => {
	return (
		<Tab.Panels>
			{document?.document_type !== 'form' ? (
				<Tab.Panel>
					<FieldMapping document={document} />
				</Tab.Panel>
			) : undefined}

			<Tab.Panel>
				<FormBuilder document={document} />
			</Tab.Panel>
		</Tab.Panels>
	)
}

export default TabsContent
