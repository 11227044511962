import * as yup from 'yup'

const createOption = (type) => ({
	label: <span className='capitalize'>{type}</span>,
	value: type,
})

export const getInitialValues = (field) => ({
	id: field?.id || undefined,
	host: field?.host || '',
	port: field?.port || '22',
	passphrase: field?.passphrase || '',
	file_path: field?.file_path || '',
	file_type_pattern: field?.file_type_pattern || '',
	username: field?.username || '',
	pull_frequency: field?.pull_frequency ? createOption(field?.pull_frequency) : '',
	retention_policy: field?.retention_policy || '',
	authType: field?.private_key ? 'privateKey' : 'password',
	passkey: field?.password || field?.private_key || undefined,
})

export const getPayload = (values) => {
	const payload = {
		host: values?.host,
		port: values?.port,
		username: values?.username,
		passphrase: values?.passphrase,
		file_path: values?.file_path || undefined,
		file_type_pattern: values?.file_type_pattern || undefined,
		pull_frequency: values?.pull_frequency ? values?.pull_frequency?.value : undefined,
		retention_policy: values?.retention_policy || undefined,
	}
	if (values?.authType === 'password') {
		payload.password = values?.passkey
	}
	if (values?.authType === 'privateKey') {
		payload.private_key = values?.passkey
	}
	return payload
}

export const validationSchema = yup.object().shape({
	host: yup.string().required('Title is required'),
	port: yup
		.number()
		.typeError('Port must be a number')
		.required('Port is required')
		.min(1, 'Port must be at least 1')
		.max(65535, 'Port must be less than 65536'),
	username: yup.string().required('Username is required'),
	authType: yup.string().required('Authentication type is required'),
	passkey: yup.string().required('This field is required'),
	file_path: yup.string().required('File path is required'),
	pull_frequency: yup.mixed().required('Pull frequency is required'),
})

export const pullFrequencyOptions = [
	createOption('hourly'),
	createOption('daily'),
	createOption('weekly'),
	createOption('monthly'),
]
