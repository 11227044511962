import { Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'

import InputCountrySelect from 'components/Forms/InputCountrySelect'
import { isValidPhoneNumberCheck, required } from 'utils/formValidation'

const validate = {
	phoneNumber: [required('phone number'), isValidPhoneNumberCheck],
}

const StepForm = (props) => {
	const { handleSubmit, history, isLoading } = props

	return (
		<Fragment>
			<div className='min-w-[384px] self-center py-2'>
				<h3 className='mb-2 text-center text-xl font-bold'>Enter your phone number</h3>
				<p className='text-muted mb-9 text-center'>Get your Experivise account now</p>

				<form className='flex flex-col space-y-7' onSubmit={handleSubmit}>
					<div>
						<Field
							label='Phone number'
							name='phone_number'
							type='tel'
							placeholder='Enter phone number'
							component={InputCountrySelect}
							validate={validate.phoneNumber}
						/>
					</div>

					<div className='text-center'>
						<button type='submit' className='btn-primary-1' disabled={isLoading}>
							{isLoading ? 'Sending...' : 'Next'}
						</button>
					</div>

					{/* <SelectCountry /> */}
					<div className='text-center'>
						<button type='button' onClick={() => history.goBack()} className='btn-link'>
							<i className='uil uil-arrow-left' /> Go back
						</button>
					</div>
				</form>
			</div>
		</Fragment>
	)
}

export default reduxForm({
	form: 'onboarding_step_four',
	enableReinitialize: true,
})(StepForm)
