/* eslint-disable no-case-declarations */
import { FieldType } from 'modules/assigned-workflows/assigned-workflow-form/tabs-content/fill-details-tab/pdf-field-render/pdf-field-render'
import { Fragment } from 'react'

const PdfFieldRender = ({ field, name, i, error }) => {
	return (
		<Fragment>
			<FieldType name={name} field={field} i={i} isWorkflowView={true} />
			{error && (
				<div className='invalid-feedback' style={{ display: 'block' }}>
					{error}
				</div>
			)}
		</Fragment>
	)
}

export default PdfFieldRender
