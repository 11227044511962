import { useEffect, useState } from 'react'

import { useAppRouter } from 'hooks'
import Filters from 'modules/documents/completed-documents/filters'
import { FileManagerLayout } from 'modules/file-manager'
import { useLazyGetStarredFilesQuery } from 'modules/file-manager/file-manager-api'
import StarredExpandableTable from 'modules/file-manager/starred-listing/starred-listing-table/starred-expandable-table'
import { TableProvider } from 'theme/ui/data-table'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'

const Starred = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [fetchStarredFiles, { data, isFetching }] = useLazyGetStarredFilesQuery()
	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			const params = getParseQueryString(location?.search)
			fetchStarredFiles(params)
		}
	}, [location?.search])

	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - Starred',
			}}
			filterForm={
				<Filters setPagination={setPagination} heading='Starred' isViewBy={true} />
			}>
			<div>
				<TableProvider>
					<div className='min-h-[70vh]'>
						<StarredExpandableTable
							data={data?.data}
							pagination={pagination}
							meta={data?.meta}
							setPagination={setPagination}
							isLoading={isFetching}
						/>
					</div>
				</TableProvider>
			</div>
		</FileManagerLayout>
	)
}

export default Starred
