import { Calendar, CircleDot, ScrollText, Star, Type } from 'lucide-react'
import * as yup from 'yup'

export const validationSchema = yup.object().shape({
	fields: yup.array().of(
		yup.object().shape({
			options: yup.array().of(yup.object()).optional(),
			max_selected_option: yup
				.number()
				.default(1)
				// .min(1)
				.test(
					'max-selected-option',
					'Max option selection cannot exceed total options',
					function (value) {
						const { options } = this.parent
						const $options = Array.isArray(options) ? options : []
						return !$options || value <= $options.length
					},
				),
			name: yup
				.string()
				.required('Please provide a field key.')
				.test('unique', 'Field key must be unique.', function (value) {
					const { path, from } = this
					const parent = Array.isArray(from[1]?.value?.fields)
						? from[1]?.value?.fields
						: []
					const currentIndex = Number(path.match(/\d+/)?.[0])
					const isUnique =
						parent.filter(
							(field, index) => field.name === value && index !== currentIndex,
						).length === 0
					return isUnique
				}),
		}),
	),
})

export const FIELD_MODE = {
	VIEW: 'view',
	EDIT: 'edit',
}

export const FIELD_TYPES = {
	TEXT: 'text',
	DATE: 'date',
	DROPDOWN: 'select',
	CHOICE: 'radio',
	CHECKBOX: 'checkbox',
	RATING: 'rating',
}

export const OPTION_ABLE_FIELDS = [FIELD_TYPES.CHOICE, FIELD_TYPES.CHECKBOX, FIELD_TYPES.DROPDOWN]

export const getFieldOptions = (title = null, name = '', type = null, icon = null, options) => {
	const fieldOptions = {
		type: type,
		sub_type: 'text',
		label: '',
		className: '',
		name: name,
		title: title,
		multiple: false,
		values: Array.isArray(options)
			? options
			: [
					{
						selected: false,
						label: `Option 1`,
						value: `option-1`,
					},
			  ],
		required: false,
		description: '',
		placeholder: '',
		user_type: '',
		pdf_field: '',
		is_document_bound: false,
		value: '',
		max_selected_option: 1,
		icon: icon,
	}
	return fieldOptions
}

export const FORM_CONTROLS = [
	getFieldOptions('Text', '', FIELD_TYPES.TEXT, Type),
	getFieldOptions('Date', '', FIELD_TYPES.DATE, Calendar),
	getFieldOptions('Dropdown', '', FIELD_TYPES.DROPDOWN, ScrollText),
	getFieldOptions('Choice', '', FIELD_TYPES.CHOICE, CircleDot),
	getFieldOptions('Rating', '', FIELD_TYPES.RATING, Star),
	// getFieldOptions('Checkbox', '', FIELD_TYPES.RATING, SquareCheck),
]
