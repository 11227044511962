import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { Field, reduxForm } from 'redux-form';

import experiviseLightLogo from "assets/images/experivise-logo-white.png";
import { TextField } from 'components/Forms/TextFieldComponent';
import { emailCheck, required } from 'utils/formValidation';

const validate = {
    email: [required('email'), emailCheck]
};

const ForgetPasswordForm = (props) => {
    const { handleSubmit, loading } = props;

    return (
		<div className='account-pages pt-sm-5  my-5'>
			<form onSubmit={handleSubmit} noValidate>
				<Container>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-lg-6 col-xl-5'>
							<div>
								<Card>
									<CardBody className='p-4'>
										<div className='mt-2 text-center'>
											<div className='auth-logo flex justify-center'>
												<img
													src={experiviseLightLogo}
													alt=''
													style={{ height: 100 }}
													className='logo logo-dark'
												/>
											</div>
											<h5 className='text-primary'>Forgot Password</h5>
											<p className='text-muted'>
												Forgot Password with Experivise.
											</p>
										</div>
										<div className='mt-4 p-2'>
											<div
												className='alert alert-success mb-4 text-center'
												role='alert'>
												Enter your Email and instructions will be sent to
												you!
											</div>

											<div className='mb-3'>
												<Field
													name='email'
													label='Email *'
													component={TextField}
													className='form-control'
													placeholder='Enter email'
													type='email'
													required
													validate={validate.email}
													autoComplete='off'
												/>
											</div>

											<Row className='row mb-0'>
												<Col className='col-12 text-end'>
													<button
														className='btn btn-primary w-md waves-effect waves-light'
														type='submit'
														disabled={loading}>
														{!loading ? 'Submit' : ''}
														{loading && (
															<Spinner
																animation='border'
																size='sm'
																color='default'
															/>
														)}
													</button>
												</Col>
											</Row>
											<div className='mt-4 text-center'>
												<p className='mb-0'>
													Remember It ? Back to{' '}
													<Link
														to='/login'
														className='fw-medium text-primary'>
														{' '}
														Sign in{' '}
													</Link>
												</p>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</Container>
			</form>
		</div>
	)

};

ForgetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const ForgetPasswordFormRedux = reduxForm({
    form: 'forgetPassword',
    enableReinitialize: true
})(ForgetPasswordForm);

export default ForgetPasswordFormRedux;