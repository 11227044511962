import { Field, FormikProvider, useFormik } from 'formik'
import { useAppRouter } from 'hooks'
import { snakeCase } from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Row } from 'reactstrap'

import experiviseLightLogo from 'assets/images/experivise-logo-white.png'
import Gleap from 'gleap'
import { useLazyGetStepsQuery } from 'modules/onborading/onborading-api'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import { useLazyGetTeamDetailsQuery } from 'modules/teams'
import NoSubscription from 'pages/StaticPages/NoSubscription'
import { Button, CheckboxField, InputField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useQuery } from 'utils/commonFunctions'
import {
	addAuthUser,
	authenticate,
	fetchPermissions,
	getInitialValues,
	loginValidation,
	rememberMe,
	removePermissions,
	saveToken,
	setDefaultPermissionsGroup,
	setUserDetails,
} from '.'
import { filterGroups } from '..'
import OtpVerifyPopup from './otp-verify-popup'

const LoginForm = () => {
	const { navigate, parseQuery, location } = useAppRouter()
	const { token } = useQuery(location?.search)
	const [isOpen, setIsOpen] = useState(false)
	const initialValues = useMemo(() => getInitialValues(token, parseQuery), [token, parseQuery])
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)
	const [user, setUser] = useState(null)
	const [showSignUp, setShowSignUp] = useState(null)
	const [getSetting] = useLazyGetSettingQuery()
	const [fetchSteps] = useLazyGetStepsQuery()
	const [fetchTeam, { data, isSuccess }] = useLazyGetTeamDetailsQuery()

	const isWorkflowLink = useMemo(() => {
		const pathnames = parseQuery?.continue?.split('/')
		return Array.isArray(pathnames) ? pathnames[1] === 'w' : false
	}, [parseQuery])

	useEffect(async () => {
		const response = await getSetting('public_sign_up')
		const settingValue = response?.data?.setting?.data?.setting_value === 'true'
		setShowSignUp(settingValue)
	}, [])

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: loginValidation,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				await removePermissions()
				if (values?.remember_me) {
					await rememberMe(values?.email)
				}
				await addAuthUser(values?.email)
				await saveToken(token)
				const user = await authenticate(values)
				if (user?.fullName && user?.email) {
					Gleap.identify(user.id, {
						name: user.first_name,
						email: user.email,
					})
				}
				if (user?.isMfaEnabled) {
					setUser(user)
					onOpen()
				} else {
					const permissions = await fetchPermissions()
					const response = await fetchSteps()

					const groups = permissions?.groups || []
					const $groups = groups.filter((g) => filterGroups(g))
					const hasTeam = permissions?.hasTeam
					const currentGroup = hasTeam
						? $groups.find((group) => group?.team?.id === hasTeam?.id)
						: $groups[0]

					if (!currentGroup && user?.is_root_user !== 1) {
						notification('warning', 'You are not currently registered in our system.')
						navigate('/logout')
					}

					const steps = response?.data?.steps || []
					const onBoardedStatus = response?.data?.onboardStatus
					const $steps = steps.filter((step) => step?.status === 'pending')
					const isOnBoarded = $steps.length === 0 ? true : false
					const isClient = snakeCase(currentGroup?.group?.name) === 'client'

					await setDefaultPermissionsGroup(permissions)
					if (user?.is_root_user !== 1 && currentGroup?.team?.id) {
						const teamResponse = await fetchTeam(currentGroup?.team?.id)
						const team_status = teamResponse?.data?.data?.team?.status
						if (team_status === 0) {
							setTimeout(async () => {
								await setUserDetails(user)
							}, 2000)
						} else {
							await setUserDetails(user)
						}
					} else {
						await setUserDetails(user)
					}

					if (
						isClient &&
						!isOnBoarded &&
						onBoardedStatus !== 'Approved' &&
						user?.is_root_user !== 1
					) {
						navigate('/onboarding/required-document-list')
					}
				}
				toast.dismiss()
				setSubmitting(false)
			} catch (error) {
				await removePermissions()
				setSubmitting(false)
			}
		},
	})

	const { payments, $team } = useMemo(() => {
		return {
			$team: data?.data?.team,
			payments: data?.data?.payments || [],
		}
	}, [data])

	if (isSuccess && $team?.status === 0) {
		return <NoSubscription team={$team} payments={payments} />
	} else {
		return (
			<Fragment>
				<Row className='align-items-center justify-content-center'>
					<Col md={8} lg={6} xl={5}>
						<Card>
							<CardBody className='p-4'>
								<div className='mt-2 text-center'>
									<Row>
										<Col lg={12}>
											<div className='text-center'>
												<div className='auth-logo flex justify-center'>
													<img
														src={experiviseLightLogo}
														alt=''
														style={{ height: 100 }}
														className='logo logo-dark'
													/>
												</div>
											</div>
										</Col>
									</Row>
									<h5 className='text-primary'>Welcome Back !</h5>
								</div>
								{isOpen ? (
									<OtpVerifyPopup
										onClose={onClose}
										isOpen={isOpen}
										user={user}
										loginFormik={formik}
									/>
								) : undefined}
								<FormikProvider value={formik}>
									<form onSubmit={formik.handleSubmit} noValidate>
										<div className='my-4 p-2'>
											<div className='grid grid-cols-1 gap-3'>
												<Field
													name='email'
													label='Email Address'
													component={InputField}
													className='form-control'
													placeholder='Enter email'
													type='email'
												/>

												<Field
													name='password'
													label='Password'
													component={InputField}
													type='password'
													placeholder='Enter password'
												/>
											</div>
											<div className='mt-3 flex justify-between'>
												<Field
													name='remember_me'
													label='Remember me'
													id='remember_me'
													component={CheckboxField}
													type='checkbox'
												/>

												<div>
													<Link to='/forgot-password'>
														Forgot Password?
													</Link>
												</div>
											</div>
											<div className='mt-3 text-end'>
												<Button
													type='submit'
													className='btn btn-primary w-100 waves-effect waves-light'
													isLoading={formik.isSubmitting}>
													Sign In
												</Button>
											</div>

											{isWorkflowLink ? (
												<div className='mt-4 text-center'>
													<p className='mb-0'>
														Don&apos;t have an account ?{' '}
														<a
															href={`/onboarding/step-one?continue=${parseQuery?.continue}`}
															className='fw-medium text-primary'>
															{' '}
															Register Now{' '}
														</a>{' '}
													</p>
												</div>
											) : undefined}
											{showSignUp ? (
												<div className='mt-4 text-center'>
													<p className='mb-0'>
														Don&apos;t have an account ?{' '}
														<a
															href={
																parseQuery?.continue
																	? `/register?continue=${parseQuery?.continue}`
																	: '/register'
															}
															className='fw-medium text-primary'>
															{' '}
															Sign Up Now{' '}
														</a>{' '}
													</p>
												</div>
											) : undefined}
										</div>
									</form>
								</FormikProvider>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

export default LoginForm
