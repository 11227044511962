import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import classNames from 'classnames'
import { CalendarDays } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'

// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
// import 'react-calendar/dist/Calendar.css'

const DatePicker = ({ onChange }) => {
	const [dateRange, setDateRange] = useState([null, null])

	return (
		<DateRangePicker
			value={dateRange}
			format='yyyy-MM-dd'
			clearIcon={null}
			rangeDivider='to'
			calendarIcon={
				<CalendarDays className={classNames('h-4 cursor-pointer text-gray-500')} />
			}
			yearPlaceholder='1971'
			monthPlaceholder='01'
			dayPlaceholder='01'
			onChange={(update) => {
				const [start, end] = update
				setDateRange(update)
				onChange(
					[moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')].join(
						',',
					),
				)
			}}
		/>
	)
}

export default DatePicker
