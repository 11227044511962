import { Col, Container, Row } from 'reactstrap'

import bg1 from 'assets/images/onboarding/bg2.png'
import apiEndpoints from 'helpers/apiEndpoints'
import { useAppRouter } from 'hooks'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { savePhoneNumber } from 'store/client-onboarding/actions'
import { store } from 'store/common/actions'
import Spinner from 'theme/ui/spinner'
import { getQuery } from 'utils/commonFunctions'
import { addPropertyTotoken } from '..'
import { useLazyGetUserInfoQuery } from '../clien-onboarding'
import Wrapper from '../components/Wrapper'
import FormHeader from '../components/header/FormHeader'
import StepForm from './StepForm'

const StepFour = (props) => {
	const { history } = props
	const { token } = getQuery(props.location.search)
	const { navigate, parseQuery } = useAppRouter()
	const $continue = parseQuery?.continue
	const dispatch = useDispatch()
	const { email } = useSelector((state) => state?.clientOnboarding)
	const [isLoading, setIsLoading] = useState(false)
	const [fetchUser, { data, isUninitialized, isLoading: isPhoneLoading }] =
		useLazyGetUserInfoQuery()
	useEffect(() => {
		if (email) {
			fetchUser(email)
		}
	}, [email])

	const onSubmit = (values) => {
		setIsLoading(true)
		dispatch(
			store(apiEndpoints.userOnBoard.phone.sendOTP, {
				...values,
				email,
				verificationRequired: data?.data?.phone_number ? true : false,
			}),
		).then((response) => {
			setIsLoading(false)
			if (response?.status === 200) {
				let newToken = null
				if (token) {
					newToken = addPropertyTotoken('phone_number', values?.phone_number, token)
				}
				dispatch(savePhoneNumber({ phone_number: values?.phone_number }))

				if (newToken) {
					navigate(`/onboarding/step-five?token=${newToken}`)
				} else if ($continue) {
					navigate(`/onboarding/step-five?continue=${$continue}`)
				}
			}
		})
	}

	return (
		<Wrapper>
			<Container className='onboarding-container overflow-hidden' fluid>
				<Row>
					<Col sm={7}>
						<div className='flex-column flex min-h-screen space-y-14'>
							<FormHeader token={token} />

							{isUninitialized || isPhoneLoading ? (
								<Spinner />
							) : (
								<StepForm
									history={history}
									onSubmit={onSubmit}
									isLoading={isLoading}
									initialValues={{
										phone_number: data?.data?.phone_number,
									}}
								/>
							)}
						</div>
					</Col>
					<Col
						sm={5}
						style={{ backgroundImage: 'url(' + bg1 + ')' }}
						className='bg-cover'>
						<div className='px-11 py-28'>
							<h2 className='text-[27px] font-bold'>
								Upload multiple documents with ease
							</h2>
							<p className='text-[#74788D]'>
								No need to wonder what document you might need to upload. When
								workflow is assigned to you, all required document&apos;s list will
								be there for you to completely hassle free with absolutely no rush.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}

export default StepFour
