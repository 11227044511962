import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left-icon.svg'
import { Fragment } from 'react'

const PageHeader = ({
	title,
	hasBackButton,
	children,
	headerRowClassName,
	redirectTo,
	variant,
	innerClassName,
}) => {
	const history = useHistory()

	const handleBackClick = () => {
		if (redirectTo) {
			history.push(redirectTo)
		} else {
			history.goBack()
		}
	}

	return (
		<div
			className={classNames(
				'bg-white',
				variant === 'admin' ? 'page-header' : 'page-header-client',
				innerClassName,
			)}>
			<div className={classNames('flex w-full justify-between ', headerRowClassName)}>
				<div className='flex max-w-[733px] !flex-row items-center gap-3'>
					{hasBackButton ? (
						<Fragment>
							{variant === 'admin' ? (
								<button
									className='flex items-center text-base font-normal not-italic leading-5 text-main'
									onClick={() => handleBackClick()}>
									<i className='uil-angle-left text-3xl' />
									<span>Back</span>
								</button>
							) : (
								<button className='mb-2' onClick={() => handleBackClick()}>
									<ArrowLeftIcon />
								</button>
							)}
						</Fragment>
					) : undefined}

					{title ? <h5 className='m-0 p-0 font-bold'>{title}</h5> : undefined}
					{/* {description ? (
						<p
							className={classNames(
								'm-0 p-0 text-[#74788D]',
								variant === 'admin' ? 'text-xs' : 'text-base',
							)}>
							{description}
						</p>
					) : undefined} */}
				</div>

				<div>{children}</div>
			</div>
		</div>
	)
}

PageHeader.defaultProps = {
	headerRowClassName: '',
	variant: 'admin',
}

PageHeader.propTypes = {
	variant: PropTypes.oneOf(['admin', 'client']),
}

export default PageHeader
