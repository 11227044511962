import { FieldArray } from 'formik'
import { Plus } from 'lucide-react'
import ReactDragListView from 'react-drag-listview'

import classNames from 'classnames'
import { snakeCase } from 'lodash'
import { Button } from 'theme/ui/forms'
import FormFieldRow from './form-field-row'
import { FIELD_MODE, getFieldOptions } from './helpers'

const FormDesignContainer = ({
	fieldName,
	fields,
	is_view_mode,
	formik,
	is_score_able,
	document_title,
	is_read_mode = false,
}) => {
	const onDragEnd = (fromIndex, toIndex) => {
		const $fields = [...fields]
		const document = $fields.splice(fromIndex, 1)[0]
		$fields.splice(toIndex, 0, document)
		formik.setFieldValue(fieldName, $fields)
	}
	const dragProps = {
		onDragEnd: onDragEnd,
		nodeSelector: '.field-item',
		handleSelector: '.drag-field-item',
	}

	return (
		<FieldArray
			name={fieldName}
			render={(arrayHelper) => (
				<div>
					{fields.length > 0 ? (
						<div
							className={classNames(
								'space-y-6 rounded-md bg-white px-4  shadow',
								is_view_mode ? '!pb-[0px]' : '!pb-[32px]',
							)}>
							{document_title ? (
								<div className='border-b-2 border-b-black pt-6'>
									<h3 className=' text-2xl  font-bold'>{document_title}</h3>
								</div>
							) : undefined}
							<ReactDragListView {...dragProps}>
								<div className='flex flex-col divide-y'>
									{fields.map((field, idx) => (
										<FormFieldRow
											totalFields={fields.length}
											is_score_able={is_score_able}
											is_read_mode={is_read_mode}
											title={`Question ${idx + 1}`}
											field={field}
											mode={is_view_mode ? FIELD_MODE.VIEW : FIELD_MODE.EDIT}
											key={idx}
											index={idx}
											formik={formik}
											arrayHelper={arrayHelper}
											name={`${arrayHelper.name}[${idx}]`}
										/>
									))}
								</div>
							</ReactDragListView>
						</div>
					) : undefined}

					{!is_view_mode ? (
						<div className={classNames('-mt-[20px] flex w-full justify-center')}>
							<div className='rounded-md bg-white'>
								<Button
									type='button'
									variant='primary-outline'
									onClick={() =>
										arrayHelper.push(
											getFieldOptions(
												`Question ${fields.length + 1}`,
												snakeCase(`Question ${fields.length + 1}`),
											),
										)
									}>
									<div className='flex items-center gap-1'>
										<span>
											<Plus size={20} />
										</span>
										<span>Add Question</span>
									</div>
								</Button>
							</div>
						</div>
					) : undefined}
				</div>
			)}
		/>
	)
}

export default FormDesignContainer
