import { FieldWrapper } from '../components'
import RatingConfigurator from '../components/rating-configurator'

const RatingFieldEdit = ({ name, field, onRatingChange }) => {
	return (
		<FieldWrapper name={name}>
			<RatingConfigurator field={field} name={name} onRatingChange={onRatingChange} />
		</FieldWrapper>
	)
}

export default RatingFieldEdit
