import { Fragment, useState } from 'react'

import { useDeleteTaskMutation } from 'modules/tasks/task-api'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'

const TaskActions = ({ task }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const onOpen = () => setIsOpen(true)

	const [deleteTask, { isLoading: isDeleting }] = useDeleteTaskMutation()

	const onDeleteTask = async (id) => {
		try {
			const response = await deleteTask(id)
			if (response?.data?.status === 200) {
				// mutate()
				notification('success', response?.data?.message)
			}

			onClose()
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				isOpen={isOpen}
				onClose={onClose}
				isLoading={isDeleting}
				onConfirm={() => onDeleteTask(task?.id)}
			/>
			<div className='flex w-full justify-end space-x-2'>
				{/* <Link
					to={isAllowTo(permissions?.task?.edit) ? `/tasks/${task?.id}` : '#'}
					className='action-btn btn-gray-outline'>
					<i className='uil-pen' style={{ fontSize: 16 }} />
				</Link> */}
				<button type='button' className='action-btn btn-red-outline' onClick={onOpen}>
					<i className='uil-trash-alt' style={{ fontSize: 16 }} />
				</button>
			</div>
		</Fragment>
	)
}

export default TaskActions
