import Select from 'react-select'

import classNames from 'classnames'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'
import { generateUniqueId } from 'utilities/helpers'

const DropdownFieldView = ({ name, field, onChange, is_read_mode }) => {
	const options = Array.isArray(field.values) ? field.values : []
	const tooltipId = generateUniqueId()
	const isOptionDisabled = () => field?.value?.length >= field?.max_selected_option

	return (
		<div className='form-group'>
			{field?.label ? (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label}{' '}
						{field?.required ? <span className='text-red-500'>*</span> : undefined}
					</span>
				</label>
			) : undefined}
			{is_read_mode ? (
				<input
					type={'text'}
					className={classNames(
						'form-control placeholder:!text-gray-400',
						is_read_mode ? 'cursor-default select-none' : '',
					)}
					id={tooltipId}
					name={field?.name}
					value={
						field?.multiple && Array.isArray(field?.value)
							? field?.value.map((o) => (o?.value ? o?.value : o)).join(',')
							: field?.value?.value
							? field?.value?.value
							: field?.value
					}
					placeholder={'Your Answer'}
					readOnly={is_read_mode}
				/>
			) : (
				<Select
					{...field}
					id={tooltipId}
					options={options}
					onChange={(option) => onChange(name, option)}
					isSearchable={true}
					isClearable={true}
					isMulti={field?.multiple}
					placeholder={field?.multiple ? 'Your Answers' : 'Your Answer'}
					// classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
					classNamePrefix={'select-dropdown'}
					className={classNames('select-dropdown-container')}
					styles={{
						menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
						menu: (provided) => ({ ...provided, zIndex: 9999 }),
					}}
					menuPortalTarget={document.body}
					menuPosition={'fixed'}
					components={{
						DropdownIndicator: DropdownIndicator,
					}}
					isOptionDisabled={isOptionDisabled}
					isDisabled={is_read_mode}
				/>
			)}

			{field?.description ? (
				<span className='text-[13px]  text-gray-400'>{field?.description}</span>
			) : undefined}
		</div>
	)
}

export default DropdownFieldView
