import { Fragment, useEffect, useState } from 'react'

import { useAppRouter, useUser } from 'hooks'
import { useCreateStripeCheckoutSessionMutation } from 'modules/authentication/auth-api'
import {
	PricingHeader,
	PricingTableContainer,
} from 'modules/subscriptions/subscription-pricing-table'
import notification from 'utilities/notification'
import IsAuthenticated from 'utils/isAuthenticated'

const PricingTable = () => {
	const { parseQuery } = useAppRouter()
	const stripeCustomerId = parseQuery?.stripe_customer_id
	const isLoggedIn = IsAuthenticated()
	const [createCheckoutSession, { isLoading }] = useCreateStripeCheckoutSessionMutation()
	const [clientSecret, setClientSecret] = useState(null)
	const { user } = useUser()
	const user_stripe_customer_id = user?.stripe_customer_id

	const createClientSession = async (customerId) => {
		try {
			setClientSecret(null)
			const stripeResponse = await createCheckoutSession({
				customerId: customerId,
			})

			if (stripeResponse) {
				const client_secret = stripeResponse?.data?.client_secret
				setClientSecret(client_secret)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	// useEffect(() => {
	// 	if (user_stripe_customer_id && isLoggedIn) {
	// 		createClientSession(user_stripe_customer_id)
	// 	} else if (stripeCustomerId) {
	// 		createClientSession(stripeCustomerId)
	// 	}
	// }, [user_stripe_customer_id, isLoggedIn, stripeCustomerId])

	useEffect(() => {
		document.body.className = 'bg-white'
		return function cleanup() {
			document.body.className = ''
		}
	})

	return (
		<Fragment>
			<div className='my-auto flex min-h-screen w-full flex-col'>
				<PricingHeader />
				<PricingTableContainer clientSecret={clientSecret} isLoading={isLoading} />
				<div className='flex items-center justify-center py-6'></div>
			</div>
		</Fragment>
	)
}

export default PricingTable
