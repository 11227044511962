import { useEffect, useState } from 'react'
import { statuses } from '..'

const Heading = ({ open, title, updatesCount, outstanding }) => {
	const [allStatusDot, setAllStatusDot] = useState([])

	const getStatusColor = (status) => {
		const statusObj = statuses.find((s) => s.name === status)
		return statusObj ? { color: statusObj.color, name: statusObj.name } : '#000'
	}

	useEffect(() => {
		if (outstanding.workflows?.length > 0) {
			const uniqueColors = new Set(
				outstanding.workflows.map((elem) => getStatusColor(elem?.status)),
			)
			setAllStatusDot(Array.from(uniqueColors))
		}
	}, [outstanding.workflows])

	return (
		<div className='workflow_columns flex w-full items-center justify-between'>
			<div className='workflow-space-between flex items-center space-x-4 text-left'>
				<div className='flex items-center gap-2'>
					<i className='uil-sliders-v' />
					<span className='line-clamp-1 font-ibmplex text-lg font-bold' title={title}>
						{title}
					</span>
					{allStatusDot.length > 0 &&
						allStatusDot.map((status, ind) => (
							<span
								key={ind}
								className='m-0 p-0'
								title={status?.name}
								style={{
									width: '10px',
									height: '10px',
									backgroundColor: status?.color,
									borderRadius: '50%',
									display: 'inline-block',
								}}></span>
						))}
				</div>
				<div
					className='workflow_header_show'
					style={{
						fontSize: 28,
						display: 'none',
					}}>
					{open ? <i className='uil-angle-down' /> : <i className='uil-angle-up' />}
				</div>
			</div>

			<div className='margin-below flex items-center space-x-4'>
				{updatesCount ? (
					<div className='flex h-6 w-[136px] items-center justify-center space-x-1 rounded !border border-main text-xs text-main'>
						<span className='!h-[6px] !w-[6px] rounded-full bg-main' />
						<span>{updatesCount} Update Available</span>
					</div>
				) : undefined}

				<div
					className='workflow_header_hide'
					style={{
						fontSize: 28,
					}}>
					{open ? <i className='uil-angle-up' /> : <i className='uil-angle-down' />}
				</div>
			</div>
		</div>
	)
}

export default Heading
