import { useEffect, useState } from 'react'
import ReactSwitch from 'react-switch'

import { useAppRouter } from 'hooks'
import { Button } from 'theme/ui/forms'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import { TASK_ROUTES } from '../routes'
import TaskCanvas from '../task-canvas'

const Header = () => {
	const { params, navigate, location } = useAppRouter()
	const [isOpen, setIsOpen] = useState(false)
	const toggleCanvas = () => setIsOpen(!isOpen)
	const pathname = location?.pathname

	const onClose = () => {
		setIsOpen(false)
		navigate(TASK_ROUTES.findAll())
	}

	useEffect(() => {
		if (params?.id) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	}, [params?.id])

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isOpen])

	const onSwitch = () => {
		if (pathname === TASK_ROUTES.findAll()) {
			navigate(TASK_ROUTES.findAllKanban())
		} else {
			navigate(TASK_ROUTES.findAll())
		}
	}

	return (
		<div className='page-header  bg-white'>
			<div className='flex w-full items-center justify-between gap-3'>
				<div className='custom-switch flex max-w-fit items-center space-x-2'>
					<span>List View</span>
					<ReactSwitch
						uncheckedIcon={false}
						checkedIcon={false}
						onColor='#F1B44C'
						offColor='#626ed4'
						height={20}
						width={40}
						handleDiameter={12}
						onChange={onSwitch}
						checked={pathname === TASK_ROUTES.findAllKanban()}
					/>
					<span>Kanban View</span>
				</div>

				{isAllowTo(permissions?.task?.create) ? (
					<Button color='primary' className='min-w-max' onClick={() => setIsOpen(true)}>
						Create Task
					</Button>
				) : undefined}

				<TaskCanvas
					taskId={params?.id}
					isOpen={isOpen}
					onClose={onClose}
					toggleCanvas={toggleCanvas}
				/>
			</div>
		</div>
	)
}

export default Header
