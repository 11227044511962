import classNames from 'classnames'
import { Field } from 'formik'
import { capitalize } from 'lodash'
import Select from 'react-select'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'

const SubTypeField = ({ name }) => {
	const createOption = (value) => ({ label: capitalize(value), value: value })

	return (
		<Field name={`${name}.sub_type`}>
			{({ field, form, meta: { touched, error } }) => (
				<div className='flex w-[110px] items-center space-x-3'>
					<div className='flex flex-1 flex-col'>
						<Select
							{...field}
							value={field?.value ? createOption(field?.value) : createOption('text')}
							options={[
								{ label: 'Text', value: 'text' },
								{ label: 'Email', value: 'email' },
								{ label: 'Number', value: 'number' },
							]}
							onChange={(option) => form.setFieldValue(field?.name, option?.value)}
							isSearchable={false}
							isClearable={false}
							isMulti={field?.multiple}
							placeholder={'Select User Role'}
							classNamePrefix={
								touched && error ? 'invalid-select-dropdown' : 'select-dropdown'
							}
							className={classNames('select-dropdown-container')}
							styles={{
								menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
								menu: (provided) => ({ ...provided, zIndex: 9999 }),
							}}
							menuPortalTarget={document.body}
							menuPosition={'fixed'}
							components={{
								DropdownIndicator: DropdownIndicator,
							}}
						/>
						{touched && error ? (
							<span className='error mt-[3px] text-[13px]'>{error}</span>
						) : undefined}
					</div>
				</div>
			)}
		</Field>
	)
}

export default SubTypeField
