import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FormIcon } from 'assets/svg/form-icon.svg'
import MyDocumentsListingContainer from './my-documents-listing-container'

export const getDocumentIcon = (type = 'document_type') => {
	return type === 'form' ? <FormIcon height={20} /> : <DocumentIcon height={20} />
}

export { default as SearchDocumentsListingContainer } from './search-documents-listing-container'

export default MyDocumentsListingContainer
