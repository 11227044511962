import { Field } from 'formik'
import { SelectDropdown } from 'theme/ui/filters'
import StatusQuery from './status-query'

const StatusDropdown = ({ name }) => {
	const { options } = StatusQuery('')

	return (
		<Field
			name={name}
			isMulti={true}
			label='Status'
			options={options}
			component={SelectDropdown}
			// getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			// filterOption={filterOption}
		/>
	)
}

export default StatusDropdown
