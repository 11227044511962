import { Fragment, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import notification from 'utilities/notification'
import { useCreateHouseholdMutation, useUpdateHouseholdMutation } from '../households-api'

const CreateHouseholdModal = ({ isOpen, setIsOpen, household }) => {
	const [householdName, setHouseholdName] = useState('')

	const toggleModal = () => {
		setIsOpen(!isOpen)
		setHouseholdName('')
	}

	useEffect(() => {
		if (household?.name) {
			setHouseholdName(household?.name)
		}
	}, [household])

	const [createHousehold] = useCreateHouseholdMutation()
	const [updateHousehold] = useUpdateHouseholdMutation()

	const handleHousehold = async () => {
		try {
			if (household?.id) {
				const response = await updateHousehold({
					payload: {
						name: householdName,
					},
					id: household?.id,
				})

				if (response?.data?.status === 200) {
					toggleModal()
					notification('success', response?.data?.message)
				} else {
					toggleModal()
					notification('warn', response?.data?.message)
				}
			} else {
				const response = await createHousehold({
					name: householdName,
				})

				if (response?.data?.status === 201) {
					toggleModal()
					notification('success', response?.data?.message)
				} else {
					toggleModal()
					notification('warn', response?.data?.message)
				}
			}
		} catch (error) {
			toggleModal()
			notification('warn', error?.response?.data?.message || 'An error occurred')
		}
	}
	  

	return (
		<Fragment>
			<Modal
				isOpen={isOpen}
				toggle={() => {
					toggleModal()
				}}
				scrollable={true}
				id='staticBackdrop'
				backdrop='static'>
				<ModalHeader toggle={toggleModal}>
					{household?.id ? 'Update' : 'Create'} Group
				</ModalHeader>
				<ModalBody className='p-2'>
					<label className='px-2'>Group Name : </label>
					<div className='px-1 pt-2'>
						<input
							className='form-control'
							type='search'
							value={householdName}
							onChange={(e) => setHouseholdName(e.target.value)}
							placeholder='Enter group name'
						/>
					</div>
					<div className='mt-3 flex justify-end'>
						<button
							className='btn btn-primary waves-effect waves-light'
							onClick={handleHousehold}
							disabled={!householdName}>
							{household?.id ? 'Update' : 'Create'}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</Fragment>
	)
}

export default CreateHouseholdModal
