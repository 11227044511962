import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { toQueryString } from 'utils/commonFunctions'

const ReviewerQuery = (search) => {
	const [debounceSearch] = useDebounce(search, 200)
	const defaultParams = { page: 1, limit: 20 }
	const searchParams = useMemo(() => {
		return debounceSearch ? { ...defaultParams, search: debounceSearch } : defaultParams
	}, [defaultParams, debounceSearch])
	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.user.users}?${query}`
	const { data, isLoading } = useQuery(URL)
	const reviewers = Array.isArray(data?.data?.users) ? data?.data?.users : []

	const options = Array.isArray(reviewers)
		? reviewers.map((reviewer) => ({
				...reviewer,
				value: reviewer?.id,
		  }))
		: []

	return {
		options,
		isLoading,
	}
}

export default ReviewerQuery
