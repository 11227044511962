import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { useAppRouter, useUser } from 'hooks'
import { useLazyCheckEditEligibilityQuery } from 'modules/documents/document-api'
import {
	useDeleteDocumentPackageMutation,
	useDeleteDocumentTemplateMutation,
	useMarkDocumentPackageAsStarMutation,
	useUpdateDocumentPackageMutation,
} from 'modules/documents/document-package-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import { dispatch } from 'store'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import * as actions from '../../../store/file-manager/actions'
import RenamePopup from '../my-documents-listing/rename-popup'
import AddToFolder from './add-to-folder'
import CreateFolder from './create-folder'

const OptionsList = ({ data, type, parentFolderData }) => {
	const { isSuperAdmin } = useUser()
	const [deleteDocumentPackage, { isLoading }] = useDeleteDocumentPackageMutation()
	const [updateDocumentPackage, { isLoading: isRemoving }] = useUpdateDocumentPackageMutation()

	const [deleteDocumentTemplate, { isLoading: isDeleting }] = useDeleteDocumentTemplateMutation()
	const [markFolderStarred, { isLoading: isMarkingStar }] = useMarkDocumentPackageAsStarMutation()
	const [isOpen, setIsOpen] = useState(false)
	const [isRenameOpen, setIsRenameOpen] = useState(false)
	const { navigate } = useAppRouter()
	const [isDeleteOpen, setIsDeleteOpen] = useState({
		open: false,
		id: undefined,
	})
	const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false)
	const [checkEditEligibility] = useLazyCheckEditEligibilityQuery()
	const [menu, setMenu] = useState(false)

	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)
	const onDeleteOpen = (id) => setIsDeleteOpen({ open: true, id })
	const onDeleteClose = () => setIsDeleteOpen({ open: false, id: undefined })
	const [isRemoveOpen, setIsRemoveOpen] = useState(false)
	const onRemoveClose = () => setIsRemoveOpen(false)

	const onDeleteFolder = async () => {
		if (type === 'folder') {
			const id = data?.id
			const response = await deleteDocumentPackage(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
				onDeleteClose()
			}
			onDeleteClose()
		} else {
			try {
				const id = data?.id
				const response = await deleteDocumentTemplate(id)
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
				}
				onDeleteClose()
			} catch (error) {
				onDeleteClose()
				notification('error', error?.message)
			}
		}
	}

	const removeFromFolder = async () => {
		const remainingDocs = parentFolderData?.documents?.filter((doc) => {
			return doc?.id !== data?.id
		})
		const allDocIds = remainingDocs?.map((doc) => {
			return { id: doc?.id }
		})
		const payload = {
			documents: allDocIds?.length > 0 ? allDocIds : [],
		}
		const response = await updateDocumentPackage({ payload, id: parentFolderData?.id })
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
			onRemoveClose()
		}
	}

	const attachInWorkflow = (data, type) => {
		const item = {
			id: data?.id,
			type: 'folder',
			title: data?.title,
			value: JSON.stringify({
				id: data?.id,
				minClients: data?.minClients,
			}),
		}
		if (type === 'folder') {
			dispatch(actions.setSelectedFolders([item]))
			navigate(`/workflows/create?folder=true`)
		} else {
			dispatch(actions.setSelectedDocuments([item]))
			navigate(`/workflows/create?document=true`)
		}
	}

	const onMarkStarred = async (id, type) => {
		try {
			let payload
			if (type === 'folder') {
				payload = { document_package_id: id }
			} else {
				payload = { document_id: id }
			}
			const response = await markFolderStarred(payload).unwrap()

			if (response?.message) {
				notification('success', response?.message)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	const onOptionClick = async (action, data, type) => {
		switch (action) {
			case 'view':
				if (type === 'folder') {
					console.log('View folder', data.id)
				} else {
					console.log('View file', data.id)
				}
				break
			case 'rename':
				if (type === 'folder') {
					setIsFolderPopupOpen(!isFolderPopupOpen)
				} else {
					const response = await checkEditEligibility(data?.id).unwrap()
					if (response?.status === 200) {
						setIsRenameOpen(!isRenameOpen)
					}
				}

				break
			case 'attach':
				attachInWorkflow(data, type)
				break
			case 'addToFolder':
				onOpen()
				break
			case 'star':
				onMarkStarred(data?.id, type)
				break
			case 'remove':
				setIsRemoveOpen(true)
				break
			case 'delete':
				onDeleteOpen()
				break
			default:
				break
		}
	}

	return (
		<Fragment>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className='dropdown inline-block'
				tag='li'>
				<DropdownToggle
					tag='button'
					type='button'
					className='m-0 !border !border-none bg-transparent p-0'>
					<i className='mdi mdi-dots-vertical text-[16px]'></i>
				</DropdownToggle>
				<DropdownMenu className='dropdown-menu-md dropdown-menu-end p-0'>
					<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
						<div className='relative divide-y p-2'>
							<div className='flex flex-col gap-1'>
								{/* <span
									onClick={() => onOptionClick('view', data, type)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-eye'></i>
									View
								</span> */}
								{type === 'document' && (
									<Link
										to={DOCUMENT_TEMPLATE_APP_ROUTES.findOne(data?.id)}
										className='file-manager-action-dropdown-item'>
										<i className='uil-eye'></i>
										<span className='text-sm'>View</span>
									</Link>
								)}
								<span
									onClick={() => onOptionClick('rename', data, type)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-edit-alt'></i>
									<span className='text-sm'>Rename</span>
								</span>
								{!isSuperAdmin ? (
									<span
										onClick={() => onOptionClick('attach', data, type)}
										className='file-manager-action-dropdown-item'>
										<i className='uil-paperclip'></i>
										<span className='text-sm'>Attach in Workflow</span>
									</span>
								) : undefined}

								<span
									onClick={() => onOptionClick('addToFolder', data, type)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-folder'></i>
									<span className='text-sm'>
										{type === 'folder' ? 'Move' : 'Add'} to Folder
									</span>
								</span>
								<span
									onClick={() => onOptionClick('star', data, type)}
									className='file-manager-action-dropdown-item'>
									{isMarkingStar ? (
										<span className='flex justify-start gap-3'>
											<Spinner />
											<span className='text-sm'>Processing...</span>
										</span>
									) : (
										<>
											{data?.isStarred ? (
												<Fragment>
													<i className='bx bxs-star text-main'></i>
													<span className='text-sm'>
														Remove from starred
													</span>
												</Fragment>
											) : (
												<Fragment>
													<i className='uil-star'></i>
													<span className='text-sm'>Star</span>
												</Fragment>
											)}
										</>
									)}
								</span>
								{type === 'document' && (
									<span
										onClick={() =>
											parentFolderData && onOptionClick('remove', data, type)
										}
										className={`file-manager-action-dropdown-item ${
											parentFolderData ? '' : 'disabled-class'
										}`}
										style={{
											cursor: parentFolderData ? 'pointer' : 'not-allowed',
										}}>
										<i
											className={`uil-file-minus ${
												parentFolderData ? 'text-red-600' : 'text-gray-400'
											}`}></i>
										<span
											className={`text-sm ${
												parentFolderData ? 'text-red-600' : 'text-gray-400'
											}`}>
											Remove from folder
										</span>
									</span>
								)}

								<span
									onClick={() => onOptionClick('delete', data, type)}
									className='file-manager-action-dropdown-item'>
									<i className='uil-trash text-red-600'></i>
									<span className='text-sm text-red-600'>Move to trash</span>
								</span>
							</div>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
			<CreateFolder
				onClose={() => setIsFolderPopupOpen(!isFolderPopupOpen)}
				isOpen={isFolderPopupOpen}
				type={type}
				data={data}
			/>
			<AddToFolder
				onClose={onClose}
				isOpen={isOpen}
				type={'folder'}
				file={data}
				addType={type == 'document' ? 'copy' : 'move'}
			/>
			<DeleteConfirmAlert
				title='Move to Trash?'
				subtitle='Are you sure you want to move this Folder to the trash?'
				isOpen={isDeleteOpen?.open}
				onClose={onDeleteClose}
				isLoading={isLoading || isDeleting}
				onConfirm={onDeleteFolder}
				className='z-[9999]'
			/>
			<DeleteConfirmAlert
				title='Remove folder?'
				subtitle='Are you sure you want to remove this Folder?'
				isOpen={isRemoveOpen}
				onClose={onRemoveClose}
				isLoading={isRemoving}
				onConfirm={removeFromFolder}
				className='z-[9999]'
			/>
			<RenamePopup
				document={data}
				isOpen={isRenameOpen}
				onClose={() => setIsRenameOpen(false)}
			/>
		</Fragment>
	)
}

export default OptionsList
