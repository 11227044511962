import { Header, SystemFieldsListing } from 'modules/system-fields'
import AppLayout from 'theme/layouts/app-layout'

const SystemFieldsList = () => {
	return (
		<AppLayout
			meta={{
				title: 'System Fields',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header block bg-white sm:hidden'>
						<Header title='System Fields' />
					</div>
					<div className='page-body'>
						<SystemFieldsListing />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SystemFieldsList
