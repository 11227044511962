import { Field, Form, FormikProvider, useFormik } from 'formik'
import { Fragment, useEffect, useMemo, useState } from 'react'

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg'
import { useAppRouter } from 'hooks'
import { useBatchDeleteDocumentsMutation } from 'modules/documents/document-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import { getPlaceholder } from 'modules/file-manager/search-bar'
import SearchItemDropdown from 'modules/file-manager/search-bar/search-item-dropdown'
import { useDataTable } from 'theme/ui/data-table'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { Button } from 'theme/ui/forms'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import { toQueryString } from 'utils/commonFunctions'
import { getActiveFilters, getFormikFilterValues, getInitialValues, getParsedRows } from '.'
import AddToFolderPopup from '../add-to-folder-popup'
import AttachInWorkflowPopup from '../attach-in-workflow-popup/attach-in-workflow-popup'

const Filters = ({ selectedRows, setPagination, heading }) => {
	const { location, navigate } = useAppRouter()
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isShortcutOpen, setIsShortcutOpen] = useState(false)
	const [attachWorkflowIsOpen, setAttachWorkflowIsOpen] = useState(false)
	const parsedRows = useMemo(() => getParsedRows(selectedRows), [selectedRows])
	const parseQueryString = getParseQueryString(location?.search)
	const initialValues = getInitialValues(parseQueryString)
	const formik = useFormik({ initialValues: initialValues })
	const tableContext = useDataTable()
	const formValues = useMemo(() => formik?.values, [formik?.values])
	// const [debounceValues] = useDebounce({ ...formValues }, 800)
	const debounceValues = formValues
	const [deleteDocuments, { isLoading: isDeleting }] = useBatchDeleteDocumentsMutation()

	const resetFilters = () => {
		return new Promise((resolve) => {
			const filterObj = { page: 1, limit: 10 }
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			resolve()
		})
	}

	const onResetFilters = async () => {
		resetFilters().then(() => {
			formik.handleReset()
		})
	}

	useEffect(() => {
		if (debounceValues) {
			const paginationParams = getPaginationParams(location?.search)
			const filterObj = {
				...getFormikFilterValues(debounceValues),
				page: 1,
				limit: paginationParams.limit,
			}
			const queryString = toQueryString(filterObj)
			navigate(`${window.location.pathname}?${queryString}`)
			if (tableContext) {
				tableContext.resetPageIndex()
			}
			setPagination({
				pageIndex: 0,
				pageSize: 10,
			})
		}
	}, [debounceValues])

	useEffect(() => {
		if (location?.search) {
			const paginationParams = getPaginationParams(location?.search)
			const currentPage = paginationParams?.page ? parseInt(paginationParams.page, 10) : 1
			const currentLimit = paginationParams?.limit ? parseInt(paginationParams.limit, 10) : 10
			if (currentPage > 1) {
				setPagination({
					pageIndex: currentPage - 1,
					pageSize: currentLimit,
				})
			}
		}
	}, [location?.search])
	const activeFilters = getActiveFilters(parseQueryString)

	const onDeleteDocuments = async (documentIds) => {
		try {
			const payload = {
				documentIds: documentIds,
			}
			const response = await deleteDocuments(payload).unwrap()
			if (response?.status === 200) {
				setIsDeleteOpen(false)
				notification('success', 'Selected documents successfully deleted')
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			<FormikProvider value={formik}>
				<Form className='alignment_holder flex-1'>
					<div className='doc_listing_flex page-body flex items-center justify-between gap-3 !border bg-gray-50 !py-[15px]'>
						<div className='alignment_holder flex h-[56px] w-full max-w-[512px] items-center justify-between space-x-1 rounded bg-white px-2.5 shadow'>
							<div className='px-2'>
								<SearchIcon height={20} width={20} />
							</div>
							<Field name='search'>
								{({ field }) => (
									<input
										type='search'
										{...field}
										autoComplete='off'
										className='!w-full flex-1 truncate px-1 py-2 text-base font-normal text-gray2-dark'
										placeholder={getPlaceholder(formValues?.status)}
									/>
								)}
							</Field>
							<SearchItemDropdown formik={formik} />
						</div>
						<div className='rounded bg-white'>
							<Button
								type='button'
								variant='primary-outline'
								onClick={() => navigate(DOCUMENT_TEMPLATE_APP_ROUTES.create())}
								className='bg-white '>
								<div className='flex items-center space-x-2 font-medium'>
									<span className='text-base'>+</span>
									<span>Create Document</span>
								</div>
							</Button>
						</div>
					</div>

					<div className='page-body !pb-0'>
						<div className='flex items-center justify-between'>
							<h3 className='m-0 mt-1 text-base font-semibold'>{heading}</h3>

							<div className='flex items-center justify-between gap-1'>
								{selectedRows.length > 0 ? (
									<div className='flex items-center space-x-3'>
										<span className='text-sm font-semibold'>
											{selectedRows.length} documents selected
										</span>
										<div className='bg-white'>
											<Button
												type='button'
												size='sm'
												variant='primary-outline'
												onClick={() => setIsShortcutOpen(true)}>
												<i className='uil-plus'></i>
												<span>Add to folder</span>
											</Button>
										</div>

										<div className='bg-white'>
											<Button
												type='button'
												size='sm'
												variant='primary-outline'
												onClick={() => setAttachWorkflowIsOpen(true)}>
												<span>Attach in Workflow</span>
											</Button>
										</div>
										<div className='bg-white'>
											<Button
												type='button'
												size='sm'
												onClick={() => setIsDeleteOpen(true)}
												variant='danger-outline'>
												<span>Delete All</span>
											</Button>
										</div>
									</div>
								) : undefined}

								<div className='flex items-center'>
									{activeFilters.length > 0 ? (
										<Button size='sm' variant='ghost' onClick={onResetFilters}>
											Clear filters
										</Button>
									) : undefined}
								</div>
							</div>
						</div>
					</div>
				</Form>
			</FormikProvider>

			<DeleteConfirmAlert
				isDeleting={isDeleting}
				isOpen={isDeleteOpen}
				onClose={() => setIsDeleteOpen(false)}
				onConfirm={() => onDeleteDocuments(parsedRows.map((doc) => doc?.id))}
				title={`Delete (${selectedRows.length}) Documents?`}
				subtitle='Are you sure you want to delete the selected documents record?'
			/>

			<AddToFolderPopup
				documents={parsedRows.map((doc) => doc?.id)}
				isOpen={isShortcutOpen}
				onClose={() => setIsShortcutOpen(false)}
			/>

			<AttachInWorkflowPopup
				isOpen={attachWorkflowIsOpen}
				onClose={() => setAttachWorkflowIsOpen(false)}
				selectedRows={parsedRows}
			/>
		</Fragment>
	)
}

export default Filters
