import { useEffect } from 'react'
import { Offcanvas } from 'reactstrap'
import Spinner from 'theme/ui/spinner'
import { useLazyGetTaskQuery } from '../task-api'
import TaskForm from '../task-form'

const TaskCanvas = ({ isOpen, onClose, toggleCanvas, taskId }) => {
	const [fetchTask, { data, isLoading }] = useLazyGetTaskQuery()
	useEffect(() => {
		if (taskId) {
			fetchTask(taskId)
		}
	}, [taskId])

	const task = data?.data

	return (
		<Offcanvas
			backdrop={true}
			scrollable
			isOpen={isOpen}
			onClosed={onClose}
			toggle={toggleCanvas}
			direction='end'
			className='create-task-sidebar overflow-auto md:min-w-[50%]'>
			{taskId ? (
				isLoading ? (
					<div className='flex h-full items-center justify-center'>
						<Spinner label='Loading...' />
					</div>
				) : task ? (
					<TaskForm task={task} onClose={onClose} />
				) : (
					<p>Task Not found</p>
				)
			) : (
				<TaskForm onClose={onClose} />
			)}
		</Offcanvas>
	)
}

export default TaskCanvas
