import { getIn } from 'formik'

export const isRequiredField = (validationSchema, name) => {
	const schemaDescription = validationSchema.describe()
	const accessor = name.split('.').join('.fields.')
	const field = getIn(schemaDescription.fields, accessor)
	if (!field) {
		return false
	}
	const isRequired = field.tests.some((test) => test.name === 'required')
	return isRequired
}

export { default as Button } from './button'
export { default as CheckboxField } from './checkbox-field'
export { default as CreatableSelectField } from './creatable-select-field'
export { default as DatePickerField } from './date-picker-field'
export { default as FileUploadField } from './file-upload-field'
export { default as InputField } from './input-field'
export { default as OtpField } from './otp-field'
export { default as PasswordField } from './password-field'
export { default as PhoneNumberField } from './phone-number-field'
export { default as RadioGroupField } from './radio-group-field'
export { default as SearchField } from './search-field'
export { default as SelectField } from './select-field'
export { default as SwitchField } from './switch-field'
export { default as TextareaField } from './textarea-field'
