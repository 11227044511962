import { getIn } from 'formik'
import { useUploadDocumentMutation } from 'modules/documents/document-api'
import { useDeleteFileMutation } from 'modules/files/file-apis'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import notification from 'utilities/notification'
import { formatBytes } from 'utils/commonFunctions'
import FileUploadDropzone from '../file-upload-dropzone'
import UploadFileCard from '../uploaded-file-card'

const DocumentUploadCard = ({ formik, document }) => {
	const [file, setFile] = useState(null)
	const [isUploading, setIsUploading] = useState(false)
	const [progress, setProgress] = useState(null)
	const [uploadDocument] = useUploadDocumentMutation()
	const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation()

	useEffect(() => {
		if (document?.file) {
			setFile(document?.file)
		}
	}, [document?.file])

	const onUploadProgress = (progressEvent) => {
		let percentComplete = progressEvent.loaded / progressEvent.total
		percentComplete = parseInt(percentComplete * 100)

		let uploadedSize = progressEvent.loaded
		if (progressEvent.loaded > file?.size) {
			uploadedSize = file?.size
		}
		setProgress({
			percentage: percentComplete,
			uploadedSize: formatBytes(uploadedSize),
		})
	}

	const onFileDrop = async (file) => {
		if (file) {
			setIsUploading(true)
			setFile(Object.assign(file, { timestamp: moment() }))
			const formData = new FormData()
			formData.append('file', file)
			const response = await uploadDocument({
				payload: formData,
				onUploadProgress,
			})

			if (response?.data?.status === 200) {
				const fileId = response?.data?.data[0]?.file_id
				setFile(Object.assign(file, { id: fileId }))
				formik.setFieldValue('file', fileId)
				notification('success', response?.data?.message)
			}
			setIsUploading(false)
		}
	}

	const onDelete = async (id) => {
		if (id) {
			await deleteFile(id)
			setFile(null)
		} else {
			setFile(null)
		}
		formik.setFieldValue('file', null)
	}

	const { error, touched } = useMemo(() => {
		const error = getIn(formik.errors, 'file')
		const touched = getIn(formik.touched, 'file')

		return {
			error,
			touched,
		}
	}, [formik.errors])
	return (
		<div className='upload_doc_alignment grid grid-cols-3 gap-10'>
			<div className='col-span-1'>
				<h3 className='text-lg font-bold'>Document Upload</h3>
			</div>
			<div className='col-span-2'>
				{file ? (
					<UploadFileCard
						file={file}
						time={moment(file?.timestamp).fromNow()}
						progress={progress?.percentage}
						uploadedSize={progress?.uploadedSize}
						onDelete={onDelete}
						isDeleting={isDeleting}
						document={document}
						disabled={isUploading && !(file?.progress > 0 && file?.progress < 100)}
					/>
				) : (
					<FileUploadDropzone onFileDrop={onFileDrop} />
				)}
				{error && touched ? <span className='text-red-500'>{error}</span> : undefined}
			</div>
		</div>
	)
}

export default DocumentUploadCard
