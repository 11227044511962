import { FormDesignerForm } from 'modules/form-designer'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const FormDesigner = () => {
	return (
		<AppLayout
			meta={{
				title: 'Create Document Template',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Create Document Template'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						<FormDesignerForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default FormDesigner
