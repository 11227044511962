import classNames from 'classnames'
import { getIn } from 'formik'
import { CalendarDays, X } from 'lucide-react'
import { createElement, useMemo } from 'react'
import DatePicker from 'react-date-picker'

const DatePickerField = ({
	id,
	form,
	label,
	field,
	readOnly,
	minDate,
	isRequiredField,
	labelHelperText,
	helperText,
	maxDate,
	format,
	...rest
}) => {
	const { name } = field
	const touched = useMemo(() => getIn(form?.touched, name), [form?.touched])
	const error = useMemo(() => getIn(form?.errors, name), [form?.errors])

	return (
		<div className='form-group'>
			{label ? (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> required
						</span>
					) : undefined}
				</label>
			) : undefined}
			<DatePicker
				{...rest}
				{...field}
				format={format || 'dd-MM-yyyy'}
				minDate={minDate}
				maxDate={maxDate}
				value={field?.value}
				onChange={(date) => {
					form.setFieldValue(field?.name, date)
				}}
				className={classNames('form-control rounded-md !py-[6px]', {
					'!border !border-[#f46a6a]': touched && error,
				})}
				clearIcon={
					field?.value
						? createElement(X, {
								className: classNames(
									'h-4 ',
									readOnly ? 'text-white' : 'text-gray-500',
								),
						  })
						: null
				}
				calendarIcon={createElement(CalendarDays, {
					className: classNames('h-4 ', readOnly ? 'text-white' : 'text-gray-500'),
				})}
				disabled={readOnly}
			/>

			{helperText ? (
				<span className='text-[13px] italic text-gray-400'>{helperText}</span>
			) : undefined}
			{touched && error ? (
				<div className='text-[87%] text-[#f46a6a]'>{error}</div>
			) : undefined}
		</div>
	)
}

export default DatePickerField
