import { useState } from 'react'

import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useDeactivateWorkflowsMutation } from '../document-api'

const DangerZone = ({ id, fetchDocument }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [deactivateWorkflows, { isLoading: isCancelling }] = useDeactivateWorkflowsMutation()

	const onDeactivateWorkflows = async (documentId) => {
		try {
			await deactivateWorkflows(documentId)
			setIsOpen(false)
			fetchDocument(id)
		} catch (error) {
			notification('error', error?.message)
		}
	}
	return (
		<div>
			<DeleteConfirmAlert
				isDeleting={isCancelling}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onConfirm={() => onDeactivateWorkflows(id)}
				subtitle='Are you sure you want to cancel all the workflows related to this document?'
				title='Cancel Workflows'
				isLoadingText='Cancelling...'
				deleteBtnText='Cancel'
			/>
			<div>
				<div className='rounded-lg !border border-red-200 !px-4 !py-3'>
					<div className='flex items-center justify-between'>
						<div className='flex w-3/4 flex-col'>
							<span className='text-sm font-semibold text-gray-950'>
								Cancel Workflows
							</span>
							<span className='text-[13px] text-gray-500'>
								Cancel all workflows associated with this document. After doing so,
								you will be able to edit this document.
							</span>
						</div>
						<Button
							variant='danger-outline'
							size='sm'
							onClick={() => setIsOpen(true)}
							className={'mt-3 sm:m-0'}>
							Cancel Now
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DangerZone
