import { Field } from 'formik'
import { useState } from 'react'

import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { SelectDropdown } from 'theme/ui/filters'
import { filterOption } from '../helpers'
import AdvisorQuery from './advisor-query'

const AdvisorDropdown = ({ name, label = 'Advisors' }) => {
	const [search, setSearch] = useState('')
	const { options } = AdvisorQuery(search)

	return (
		<Field
			name={name}
			isMulti={true}
			label={label}
			options={options}
			onInputChange={(value) => setSearch(value)}
			component={SelectDropdown}
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default AdvisorDropdown
