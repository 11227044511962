import classNames from 'classnames'
import Select from 'react-select'

const DateFormator = ({ onDateChange, name, field }) => {
	const dateFormatOptions = [
		{ label: 'MM/DD/YYYY', value: 'MM/dd/yyyy' },
		{ label: 'DD/MM/YYYY', value: 'dd-MM-yyyy' },
		{ label: 'YYYY-MM-DD', value: 'yyyy-MM-dd' },
		{ label: 'MMM DD, YYYY', value: 'MMM dd, yyyy' },
	]
	return (
		<div className='w-full'>
			<div className='flex !w-full items-center space-x-2'>
				<span className='font-medium'>Format</span>

				<Select
					options={dateFormatOptions}
					value={field?.date_format ? field?.date_format : null}
					onChange={(option) => onDateChange(name, option)}
					isSearchable={true}
					isClearable={false}
					placeholder='Select date format'
					classNamePrefix='select-dropdown'
					className={classNames('select-dropdown-container !w-full')}
					styles={{
						menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
						menu: (provided) => ({ ...provided, zIndex: 9999 }),
					}}
					menuPortalTarget={document.body}
					menuPosition='fixed'
				/>
			</div>
		</div>
	)
}

export default DateFormator
