import { SpinLoader } from 'components/Common'
import { useLazyGetSubscriptionQuery } from 'modules/subscriptions/subscriptions-api'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import { formatDate } from 'utilities/helpers'

const SubscriptionDetails = () => {
	const { id } = useParams()
	const [fetchSubscription, { data, isFetching }] = useLazyGetSubscriptionQuery()
	const [subscription, setSubscription] = useState(null)

	useEffect(() => {
		if (id) fetchSubscription(id)
	}, [id, fetchSubscription])

	useEffect(() => {
		if (data?.data) setSubscription(data.data)
	}, [data])

	const formatCurrency = useMemo(
		() => (amount, currency) =>
			new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency,
			}).format(amount),
		[],
	)

	const {
		name,
		description,
		stripe_product_id,
		statement_descriptor,
		price,
		metadata,
		marketing_features,
	} = subscription || {}

	return (
		<AppLayout
			meta={{
				title: 'Subscription Details',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Subscription Details'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						{isFetching ? (
							<div className='flex items-center justify-center'>
								<SpinLoader />
							</div>
						) : (
							<div className='mx-auto w-full max-w-4xl'>
								{/* Plan Overview */}
								<Card className='mb-6 rounded-lg border border-gray-200 shadow-md'>
									<CardBody className='bg-white p-6'>
										<h2 className='mb-4 text-xl font-semibold'>
											Plan Overview
										</h2>
										<div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
											{name && (
												<div>
													<span className='block text-sm font-medium text-gray-600'>
														Name
													</span>
													<p className='text-md mt-1 font-bold text-gray-800'>
														{name}
													</p>
												</div>
											)}
											{description && (
												<div>
													<span className='block text-sm font-medium text-gray-600'>
														Description
													</span>
													<p className='text-md mt-1 font-bold text-gray-800'>
														{description}
													</p>
												</div>
											)}
											{stripe_product_id && (
												<div>
													<span className='block text-sm font-medium text-gray-600'>
														Stripe Product ID
													</span>
													<p className='text-md mt-1 font-bold text-gray-800'>
														{stripe_product_id}
													</p>
												</div>
											)}
											{statement_descriptor && (
												<div>
													<span className='block text-sm font-medium text-gray-600'>
														Statement Descriptor
													</span>
													<p className='text-md mt-1 font-bold text-gray-800'>
														{statement_descriptor}
													</p>
												</div>
											)}
										</div>
									</CardBody>
								</Card>

								{/* Pricing Details */}
								{price?.length > 0 && (
									<Card className='mb-6 rounded-lg border border-gray-200 shadow-md'>
										<CardBody className='bg-white p-6'>
											<h2 className='mb-4 text-xl font-semibold'>
												Pricing Details
											</h2>
											<div className='grid grid-cols-1 gap-6'>
												{price.map((p) => (
													<div
														key={p.id}
														className='rounded border border-gray-200 bg-gray-50 p-4 shadow-md'>
														<p>
															<strong>Price ID:</strong> {p.id}
														</p>
														<p>
															<strong>Amount:</strong> {p.unit_amount}{' '}
															{p.currency?.toLowerCase()}
															<span className='capitalize'>
																{p?.recurring?.interval &&
																	` / ${p.recurring.interval}`}
															</span>
														</p>
														<p className='capitalize'>
															<strong>Billing Interval:</strong>{' '}
															{p.recurring?.interval || 'N/A'}
														</p>
														<p>
															<strong>Interval Count:</strong>{' '}
															{p.recurring?.interval_count || 'N/A'}
														</p>
														<p className='capitalize'>
															<strong>Type:</strong> {p.type}
														</p>
														<p>
															<strong>Created:</strong>{' '}
															{formatDate(new Date(p.created * 1000))}
														</p>
													</div>
												))}
											</div>
										</CardBody>
									</Card>
								)}

								{/* Metadata */}
								{(metadata?.max_users ||
									metadata?.max_pdf_views ||
									metadata?.max_workflows ||
									metadata?.max_document_templates) && (
									<Card className='mb-6 rounded-lg border border-gray-200 shadow-md'>
										<CardBody className='bg-white p-6'>
											<h2 className='mb-4 text-xl font-semibold'>Metadata</h2>
											<div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
												{metadata?.max_users && (
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Max Users
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{metadata?.max_users}
														</p>
													</div>
												)}
												{metadata?.max_pdf_views && (
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Max PDF Views
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{metadata?.max_pdf_views}
														</p>
													</div>
												)}
												{metadata?.max_workflows && (
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Max Workflows
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{metadata?.max_workflows}
														</p>
													</div>
												)}
												{metadata?.max_document_templates && (
													<div>
														<span className='block text-sm font-medium text-gray-600'>
															Max Document Templates
														</span>
														<p className='text-md mt-1 font-bold text-gray-800'>
															{metadata?.max_document_templates}
														</p>
													</div>
												)}
											</div>
										</CardBody>
									</Card>
								)}

								{/* Marketing Features */}
								{marketing_features?.length > 0 && marketing_features[0] && (
									<Card className='mb-6 rounded-lg border border-gray-200 shadow-md'>
										<CardBody className='bg-white p-6'>
											<h2 className='mb-4 text-xl font-semibold'>
												Marketing Features
											</h2>
											<ul className='list-disc pl-5'>
												{marketing_features.map((feature, index) => (
													<li
														key={index}
														className='text-md mt-1 font-bold text-gray-800'>
														{feature || 'N/A'}
													</li>
												))}
											</ul>
										</CardBody>
									</Card>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SubscriptionDetails
