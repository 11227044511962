import { Fragment, useState } from 'react'
import { Field } from 'redux-form'

import downloadIcon from 'assets/images/icons/download.png'
import eyeIcon from 'assets/images/icons/eye.png'
import DocumentPreview from 'components/Common/DocumentPreview'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import { capitalize } from 'lodash'
import FileIcon from 'pages/Authentication/client-document-upload/upload-documents/FileIcon'

const Tab2 = ({ client }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [url, setUrl] = useState(null)
	const documents = client.filter((item) =>
		['valid_document', 'government_document'].includes(item.user_key),
	)

	const toggle = async (url) => {
		setIsOpen(!isOpen)
		setUrl(url)
	}

	return (
		<Fragment>
			{documents.length > 0 ? (
				<div className='space-y-6'>
					<div className='flex flex-col space-y-5'>
						{documents.map((document, idx) => (
							<div key={idx}>
								<label className='text-sm font-bold'>
									{capitalize(document?.user_key).split('_').join(' ')}
								</label>

								<div className='mb-3 flex justify-between space-x-5'>
									<div className='border-1 w-full rounded-md border-gray-300'>
										<div className='h-[72px] p-2.5'>
											<div className='justify-content-between flex min-h-full'>
												<div className='flex items-center space-x-2'>
													<FileIcon
														type={document?.file?.mime_type}
														className='w-8'
													/>
													<span className='text-base font-semibold'>
														{document?.file?.original_name}
													</span>
												</div>
											</div>
										</div>
									</div>

									<div className='flex flex-col justify-between py-1'>
										<a
											href={document?.file?.path}
											download={true}
											target='_blank'
											rel='noreferrer'>
											<img src={downloadIcon} alt='' />
										</a>

										<button
											type='button'
											onClick={() => toggle(document?.file?.path)}>
											<img src={eyeIcon} alt='' />
										</button>
									</div>
								</div>

								<div>
									<label>Status:</label>
									<div className='flex space-x-5'>
										<Field
											id={`step-${document?.user_key}-status-approved`}
											label='Approved'
											name={`step.${document?.user_key}.status`}
											type='radio'
											component={RadioComponent}
											value='1'
										/>

										<Field
											id={`step-${document?.user_key}-status-rejected`}
											value='0'
											label='Rejected'
											name={`step.${document?.user_key}.status`}
											type='radio'
											component={RadioComponent}
										/>
									</div>
								</div>
							</div>
						))}

						<DocumentPreview url={url} toggle={toggle} isOpen={isOpen} />
					</div>

					<Field
						label='Additional Notes :'
						type='textarea'
						name='step.valid_document.reason'
						component={TextField}
					/>
				</div>
			) : (
				<div className='alert alert-warning'>Step not found</div>
			)}
		</Fragment>
	)
}

export default Tab2
