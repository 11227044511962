import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import Select, { defaultTheme } from 'react-select'
import { Button } from 'reactstrap'
import FilterButton from 'theme/ui/filter-button'

const { colors } = defaultTheme

const selectStyles = {
	control: (provided) => ({
		...provided,
		minWidth: 240,
		margin: 8,
	}),
	menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
}

const SelectDropdown = ({
	form,
	label,
	field,
	isMulti,
	options,
	isLoading,
	isDisabled,
	placeholder,
	filterOption,
	onInputChange,
	getOptionLabel,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const $label = label || 'Select'

	const fieldValue = isMulti ? (Array.isArray(field?.value) ? field?.value : []) : field?.value
	const isActive = isMulti ? fieldValue.length > 0 : !fieldValue
	return (
		<Dropdown
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			target={
				<FilterButton
					isActive={isActive}
					onClear={() => form.setFieldValue(field?.name, isMulti ? [] : null)}>
					<Button
						onClick={() => setIsOpen((prev) => !prev)}
						isSelected={isOpen}
						type='button'
						color='secondary'
						className={isActive ? '!bg-main/20 !text-main' : ''}>
						<div className='!ml-[18px] flex items-center space-x-1'>
							{isMulti ? (
								<div>
									{fieldValue.length ? (
										<span>
											{$label} |{' '}
											<span className='font-bold'>
												{fieldValue.length} Selected
											</span>
										</span>
									) : (
										$label
									)}
								</div>
							) : (
								<div>
									{fieldValue ? (
										<span>
											<span>{$label}</span> | <span>{fieldValue}</span>
										</span>
									) : (
										$label
									)}
								</div>
							)}

							<span>
								<ChevronDown size={16} />
							</span>
						</div>
					</Button>
				</FilterButton>
			}>
			<Select
				{...field}
				autoFocus
				value={fieldValue}
				backspaceRemovesValue={false}
				components={{ DropdownIndicator, IndicatorSeparator: null }}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				onInputChange={onInputChange}
				isClearable={false}
				isMulti={isMulti}
				isLoading={isLoading}
				isDisabled={isDisabled}
				menuIsOpen
				onChange={(option) => {
					form.setFieldValue(field?.name, option)
					setIsOpen(false)
				}}
				filterOption={filterOption}
				getOptionLabel={getOptionLabel}
				options={options}
				placeholder={placeholder}
				classNamePrefix='select-dropdown'
				className='select-dropdown-container'
				styles={selectStyles}
				tabSelectsValue={false}
				menuPlacement='auto'
				// menuPortalTarget={document.body}
				// menuPosition={'fixed'}
			/>
		</Dropdown>
	)
}

export default SelectDropdown

const Menu = (props) => {
	const shadow = 'hsla(218, 50%, 10%, 0.1)'
	return (
		<div
			style={{
				backgroundColor: 'white',
				borderRadius: 4,
				boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
				marginTop: 8,
				position: 'absolute',
				zIndex: 2,
			}}
			{...props}
		/>
	)
}
const Blanket = (props) => (
	<div
		style={{
			bottom: 0,
			left: 0,
			top: 10,
			right: 0,
			position: 'fixed',
			zIndex: 1,
		}}
		{...props}
	/>
)
const Dropdown = ({ children, isOpen, target, onClose }) => (
	<div style={{ position: 'relative' }}>
		{target}
		{isOpen ? <Menu>{children}</Menu> : null}
		{isOpen ? <Blanket onClick={onClose} /> : null}
	</div>
)
const Svg = (p) => (
	<svg width='24' height='24' viewBox='0 0 24 24' focusable='false' role='presentation' {...p} />
)
const DropdownIndicator = () => (
	<div style={{ color: colors.neutral20, height: 24, width: 32 }}>
		<Svg>
			<path
				d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
				fill='currentColor'
				fillRule='evenodd'
			/>
		</Svg>
	</div>
)
