/* eslint-disable no-unused-vars */
import { useSendClientInvitationMutation } from 'modules/clients/client-apis'
import { Fragment, useState } from 'react'
import ToolTip from 'theme/ui/tool-tip'
import notification from 'utilities/notification'

const Actions = ({ user, id }) => {
	const [sendInvite] = useSendClientInvitationMutation()
	const [isSending, setIsSending] = useState(false)

	const onResend = async (user) => {
		try {
			setIsSending(true)
			const payload = {
				first_name: user?.first_name,
				last_name: user?.last_name,
				email: user?.email,
				group_id: user?.group?.id,
				resend: true,
			}
			const response = await sendInvite(payload)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			setIsSending(false)
		} catch (error) {
			setIsSending(false)
		}
	}

	return (
		<Fragment>
			<div className='float-right flex space-x-3'>
				<ToolTip targetId={`client-delete-${id}`} tooltipText='Resend' placement='top' />
				<button
					onClick={() => onResend(user)}
					type='button'
					id={`client-delete-${id}`}
					className='action-btn btn-gray-outline'>
					<i className='bx bx-send' style={{ fontSize: 16 }} />
				</button>
			</div>
		</Fragment>
	)
}

export default Actions
