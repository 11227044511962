import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'
import OwnerInvitesContainer from './owner-invites-listing/owner-invites-container'
import UserInviteForm from './user-invite-form'
const OwnerInvites = ({ onClose }) => {
	const tabs = useMemo(
		() => [
			{ label: 'Invite', id: 1 },
			{ label: 'Pending Invites', id: 2 },
		],
		[],
	)

	return (
		<div>
			<div className='flex items-center justify-between border-b !px-6 !py-5'>
				<div />
				<h3 className='m-0 text-center text-[17px] font-bold'>Invite Owner</h3>
				<button
					type='button'
					className='btn float-right my-auto !bg-[#F9F9F9] !p-1.5'
					onClick={onClose}>
					<i className='fas fa-times text-lg' />
				</button>
			</div>

			<Tab.Group>
				<Tab.List className='flex space-x-1 border-b border-gray-200 px-1'>
					{tabs.map((tab, idx) => (
						<Tab
							key={idx}
							className={({ selected }) =>
								classNames(
									'w-full py-2 text-sm font-medium leading-5',
									'focus:right-0 focus:outline-none',
									selected
										? 'border-b border-main text-main'
										: 'border-b border-transparent text-gray-400',
								)
							}>
							{tab?.label}
						</Tab>
					))}
				</Tab.List>

				<Tab.Panels>
					<Tab.Panel>
						<UserInviteForm onClose={onClose} />
					</Tab.Panel>

					<Tab.Panel>
						<OwnerInvitesContainer />
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default OwnerInvites
