import { Fragment } from 'react'

import { useAppRouter } from 'hooks'
import { FileManagerLayout } from 'modules/file-manager'
import { SearchFoldersListingContainer } from 'modules/file-manager/all-folders-listing'
import { SearchDocumentsListingContainer } from 'modules/file-manager/my-documents-listing'
import { TableProvider } from 'theme/ui/data-table'

const GlobalSearch = () => {
	const { parseQuery } = useAppRouter()

	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - Search',
			}}>
			<div>
				{['documents', 'forms'].includes(parseQuery?.status) ? (
					<Fragment>
						<SearchDocumentsListingContainer />
					</Fragment>
				) : (
					<TableProvider>
						<SearchFoldersListingContainer />
					</TableProvider>
				)}
			</div>
		</FileManagerLayout>
	)
}

export default GlobalSearch
