import TabsHeader from './tabs-header'

import activeUploadDocumentIcon from 'assets/svg/active-document-upload-icon.svg'
import activeFormBuilderIcon from 'assets/svg/active-form-builder-icon.svg'
import activeSignatureIcon from 'assets/svg/active-signature-icon.svg'
import formBuilderIcon from 'assets/svg/form-builder-icon.svg'
import signatureIcon from 'assets/svg/signature-icon.svg'

export const tabs = [
	{ id: 1, label: 'Fields Mapping', activeIcon: activeUploadDocumentIcon },
	{
		id: 2,
		label: 'Form Builder',
		activeIcon: activeFormBuilderIcon,
		icon: formBuilderIcon,
	},
	{ id: 3, label: 'Signature', activeIcon: activeSignatureIcon, icon: signatureIcon },
]

export const getTabs = (isSignatureRequired, isFormType) => {
	return tabs
		.filter((tab) => (isFormType ? ![1, 3].includes(tab?.id) : true))
		.filter((tab) => (isSignatureRequired ? tab?.id <= 3 : tab?.id < 3))
}
export default TabsHeader
