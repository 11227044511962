import { Field, FieldArray } from 'formik'
import { useEffect } from 'react'
import { InputField, SelectField } from 'theme/ui/forms'
import { currencyOptions, durationOptions } from '..'

const PriceData = ({ formik, selectedOption, setSelectedOption }) => {
	const handleButtonClick = (option) => {
		setSelectedOption(option)
	}

	const initialPriceData = {
		unitAmount: 0,
		currency: {
			label: 'Canadian Dollar',
			value: 'CAD',
		},
		recurring: {
			interval: { value: 'month', label: 'Monthly' },
			interval_count: 1,
		},
	}
	useEffect(() => {
		if (!formik?.values?.prices_data[0]?.recurring) {
			setSelectedOption('One-off')
		}
	}, [])

	return (
		<div className='mt-3'>
			<div className='my-3 flex w-full justify-between rounded-md shadow-sm' role='group'>
				<button
					type='button'
					onClick={() => handleButtonClick('Recurring')}
					className={`w-1/2 rounded-l-md border px-4 py-2 text-sm font-medium text-gray-900 ${
						selectedOption === 'Recurring' ? 'border-gray-400 bg-gray-200' : ''
					}`}>
					Recurring
				</button>
				<button
					type='button'
					onClick={() => handleButtonClick('One-off')}
					className={`w-1/2 rounded-r-md border px-4 py-2 text-sm font-medium text-gray-900 ${
						selectedOption === 'One-off' ? 'border-gray-400 bg-gray-200' : ''
					}`}>
					One-off
				</button>
			</div>

			{selectedOption === 'Recurring' ? (
				<FieldArray
					name='prices_data'
					render={(arrayHelpers) => (
						<div>
							{formik?.values.prices_data.map((priceData, index) => (
								<div
									key={index}
									className='relative mb-4 rounded-md !border p-4 shadow-sm'>
									<div className='mt-2 flex flex-col gap-2 sm:!flex-row sm:!items-end sm:!justify-between'>
										<div className='w-full sm:!w-8/12'>
											<Field
												label='Amount'
												placeholder='$ 0.00'
												name={`prices_data[${index}].unitAmount`}
												type='number'
												component={InputField}
												isRequiredField
											/>
										</div>

										<Field
											type='select'
											name={`prices_data[${index}].currency`}
											component={SelectField}
											options={currencyOptions}
											className='ms-2 w-full sm:!w-4/12'
											placeholder='currency'
											isClearable={false}
										/>
									</div>
									{formik?.errors?.prices_data?.[index]?.unitAmount &&
									formik?.touched?.prices_data?.[index]?.unitAmount ? (
										<p className='m-0 mt-1 text-sm text-red-500'>
											{formik?.errors?.prices_data[index]?.unitAmount}
										</p>
									) : null}
									<div className='mt-2 flex flex-col gap-2 sm:!flex-row sm:!items-start sm:!justify-between'>
										<span className='w-full pt-2 sm:!w-1/12'>Every</span>
										<div className='mr-1 w-full pe-1 sm:!w-7/12'>
											<Field
												name={`prices_data[${index}].recurring.interval_count`}
												type='number'
												component={InputField}
												placeholder='Interval count'
												onKeyDown={(e) => {
													if (e.key === '.' || e.key === ',') {
														e.preventDefault()
													}
												}}
											/>
											{formik?.errors?.prices_data?.[index]?.recurring
												?.interval_count ? (
												<p className='m-0 mt-1 text-sm text-red-500'>
													{
														formik?.errors?.prices_data[index]
															?.recurring?.interval_count
													}
												</p>
											) : null}
										</div>
										<div className='w-full sm:!w-4/12'>
											<Field
												type='select'
												name={`prices_data[${index}].recurring.interval`}
												component={SelectField}
												isClearable={false}
												options={durationOptions}
												placeholder='Interval'
											/>
											{formik?.errors?.prices_data?.[index]?.recurring
												?.interval ? (
												<p className='m-0 mt-1 text-sm text-red-500'>
													{
														formik?.errors?.prices_data[index]
															?.recurring?.interval
													}
												</p>
											) : null}
										</div>
									</div>

									{formik?.values.prices_data.length > 1 && (
										<div>
											<span
												onClick={() => arrayHelpers.remove(index)}
												className={`absolute right-3 top-2 cursor-pointer rounded !border !border-red-500 px-2 py-1 text-red-500`}>
												<i className='uil-trash text-red-600'></i>
											</span>
										</div>
									)}
								</div>
							))}
							<span
								onClick={() => {
									window.scrollTo({
										top: document.body.scrollHeight,
										behavior: 'smooth',
									})
									arrayHelpers.push(initialPriceData)
								}}
								className='float-end cursor-pointer text-blue-500'>
								+ Add
							</span>
						</div>
					)}
				/>
			) : (
				<div className='relative mb-4 rounded-md !border p-4 shadow-sm'>
					<div className='mt-2 flex flex-col gap-2 sm:!flex-row sm:!items-end sm:!justify-between'>
						<div className='w-full sm:!w-8/12'>
							<Field
								label='Amount'
								placeholder='$ 0.00'
								name={`prices_data[0].unitAmount`}
								type='number'
								component={InputField}
								isRequiredField
							/>
						</div>

						<Field
							type='select'
							name={`prices_data[0].currency`}
							component={SelectField}
							options={currencyOptions}
							className='ms-2 w-full sm:!w-4/12'
							placeholder='currency'
							isClearable={false}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

export default PriceData
