import classNames from 'classnames'
import { useState } from 'react'
import Badge from 'theme/ui/badge'
import { Button } from 'theme/ui/forms'
import { formatPrice } from 'utilities/helpers'

const ProductCard = ({ activePlan, isActive, onSubscribe }) => {
	const [submitting, setSubmitting] = useState(false)
	return (
		<div
			className={classNames(
				'flex min-h-[500px] flex-col justify-between rounded-lg !border !border-gray-200 bg-white p-6 shadow-sm transition-shadow duration-200 hover:shadow-md',
				isActive ? 'border-green-400' : 'border-gray-200',
			)}>
			<div className='flex items-center justify-between'>
				<h3 className='mb-1 text-xl font-semibold text-gray-800'>{activePlan?.name}</h3>
				{isActive ? <Badge variant='success'>Active</Badge> : undefined}
			</div>
			<div className='max-h-[50px] min-h-[70px]'>
				<p className='line-clamp-4 text-sm text-gray-500'>{activePlan?.description}</p>
			</div>
			<div className='my-4 flex items-center gap-2'>
				<div className='flex items-start'>
					<div className='text-lg font-bold text-black sm:text-xl lg:text-2xl xl:text-3xl'>
						{formatPrice(activePlan?.price?.amount, activePlan?.price?.currency)}
					</div>
					<div className='ml-2 flex flex-col justify-center text-sm text-gray-600'>
						<span className='mt-1 text-xs'>per</span>
						<span className='mt-[-2px] text-xs'>{activePlan?.price?.interval}</span>
					</div>
				</div>
			</div>

			<Button
				type='button'
				isLoading={submitting}
				onClick={async () => {
					onSubscribe(activePlan?.price?.priceId, { setSubmitting })
				}}
				block={true}
				className='font-medium'
				disabled={isActive}>
				Subscribe
			</Button>
			{activePlan?.marketing_features?.length > 0 && (
				<>
					{' '}
					<p className='my-2 text-black'>This includes:</p>
					<ul className='ml-0 space-y-2 pl-0'>
						{activePlan?.marketing_features?.map((feature, idx) => {
							return (
								<li className='flex items-center gap-2' key={idx}>
									<i className='fas fa-check-circle mt-1 text-[11px] text-gray-500'></i>
									<span className='text-black'>{feature}</span>
								</li>
							)
						})}
					</ul>
				</>
			)}
		</div>
	)
}

export default ProductCard
