import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { Header, WorkflowReviewCard } from 'modules/workflows/workflow-review'
import ConfirmAlert from 'modules/workflows/workflow-review/confirm-alert'
import { getInitialValues } from 'modules/workflows/workflow-review/helpers'
import { useLazyGetOneWorkflowOutstandingQuery } from 'modules/workflows/workflows-outstandings/workflow-outstandings-apis'
import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'
import AppLayout from 'theme/layouts/app-layout'

const WorkflowOutstandingsReview = () => {
	const params = useParams()
	const ID = params?.id

	const [isOpen, setIsOpen] = useState(false)
	const [values, setValues] = useState(null)
	const onClose = () => setIsOpen(false)

	const [fetchOneWorkflowOutstanding, { data, isLoading }] =
		useLazyGetOneWorkflowOutstandingQuery()

	useEffect(() => {
		if (ID) {
			fetchOneWorkflowOutstanding(ID)
		}
	}, [ID])

	const initialValues = useMemo(
		() => getInitialValues(data?.individual_document, data?.uploads, data?.workflow?.user_type),
		[data?.workflow, data?.individual_document, data?.uploads, isLoading],
	)

	const onSubmit = (values) => {
		setIsOpen(true)
		setValues(values)
	}

	return (
		<AppLayout
			meta={{
				title: 'Workflow Review',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header block bg-white sm:!hidden'>
						<Header />
					</div>
					<div className='page-body'>
						{isLoading ? (
							<SpinLoader />
						) : data?.workflow ? (
							<>
								<WorkflowReviewCard
									uploads={data?.uploads}
									workflow={data?.workflow}
									onSubmit={onSubmit}
									initialValues={initialValues}
									individual_document={data?.individual_document}
								/>
								<ConfirmAlert
									ID={ID}
									isOpen={isOpen}
									values={values}
									onClose={onClose}
									workflow={data?.workflow}
								/>
							</>
						) : (
							<ResourceNotFound
								title='Requested Workflow not found'
								description='The workflow you have requested is not found'
							/>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowOutstandingsReview
