import { useState } from 'react'

import { Field } from 'formik'
import { SelectDropdown } from 'theme/ui/filters'
import TagsQuery from './tag-options-query'

const TagsDropdown = ({ name, label = 'Tags' }) => {
	const [search, setSearch] = useState('')
	const { options } = TagsQuery(search)

	return (
		<Field
			name={name}
			isMulti={true}
			label={label}
			options={options}
			onInputChange={(value) => setSearch(value)}
			component={SelectDropdown}
			getOptionLabel={(option) => <span>{option?.tag}</span>}
			// filterOption={filterOption}
		/>
	)
}

export default TagsDropdown
