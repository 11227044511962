import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import classNames from 'classnames'
import { CalendarDays, X } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'

import { Button } from 'theme/ui/forms'
import Spinner from 'theme/ui/spinner'
import { useLazyGetDashboardUrlQuery } from './graphana-dashboard-api'

const GraphanaDashboard = () => {
	const [getDashboardUrl, { data, isLoading }] = useLazyGetDashboardUrlQuery()
	const [metaBaseUrl, setMetaBaseUrl] = useState(null)
	const [dateRange, setDateRange] = useState([null, null])
	const $dateRange = useMemo(() => (Array.isArray(dateRange) ? dateRange : []), [dateRange])
	useEffect(() => {
		getDashboardUrl()
	}, [getDashboardUrl])

	useEffect(() => {
		if (data?.data?.url) {
			setMetaBaseUrl(data?.data?.url)
		}
	}, [data])

	const submitBtn = () => {
		const formatDate = (date) => {
			if (!date) return null
			const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
			return localDate.toISOString().split('T')[0]
		}

		const formattedStartDate = formatDate($dateRange[0])
		const formattedEndDate = formatDate($dateRange[1])
		if (formattedStartDate && formattedEndDate) {
			getDashboardUrl({
				start_date: formattedStartDate,
				end_date: formattedEndDate,
			})
		}
	}

	const clearDates = () => {
		setDateRange([null, null])
		getDashboardUrl()
	}

	if (isLoading) {
		return (
			<div>
				<Spinner />
			</div>
		)
	}

	return (
		<div className='-mt-[5px]'>
			{metaBaseUrl ? (
				<>
					<div className='mt-[-10px] flex !h-[60px] items-center justify-end bg-white'>
						<div className='max-w-[310px]'>
							<DateRangePicker
								value={dateRange}
								format='yyyy-MM-dd'
								clearIcon={
									$dateRange[0] || $dateRange[1] ? (
										<X
											className={classNames(
												'h-4 cursor-pointer text-gray-500',
											)}
										/>
									) : null
								}
								calendarIcon={
									<CalendarDays
										className={classNames('h-4 cursor-pointer text-gray-500')}
									/>
								}
								onChange={(update) => {
									setDateRange(update)
								}}
								yearPlaceholder='1971'
								monthPlaceholder='01'
								dayPlaceholder='01'
								className='form-control !p-0'
							/>
						</div>

						<div className='mx-2'>
							<Button
								variant='primary'
								disabled={!$dateRange[0] || !$dateRange[1] || isLoading}
								onClick={submitBtn}>
								Submit
							</Button>
							<Button
								variant='white'
								onClick={clearDates}
								className='mx-2'
								disabled={!$dateRange[0] || !$dateRange[1] || isLoading}>
								Clear
							</Button>
						</div>
					</div>
					<iframe
						src={metaBaseUrl}
						title='Dashboard'
						style={{
							width: '100%',
							height: 'calc(100vh - 140px)',
							border: 'none',
						}}
						allowFullScreen
					/>
				</>
			) : (
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<p>No dashboard available.</p>
				</div>
			)}
		</div>
	)
}

export default GraphanaDashboard
