import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import classNames from 'classnames'
import { getIn } from 'formik'
import { CalendarDays, X } from 'lucide-react'
import { useMemo } from 'react'

const DateRangePickerField = ({
	id,
	form,
	label,
	field,
	readOnly,
	minDate,
	maxDate,
	isRequiredField,
	labelHelperText,
	helperText,
	format,
	...rest
}) => {
	const { name } = field
	const touched = useMemo(() => getIn(form?.touched, name), [form?.touched])
	const error = useMemo(() => getIn(form?.errors, name), [form?.errors])

	return (
		<div className='form-group'>
			{label ? (
				<label
					htmlFor={id}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{label}{' '}
						{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
					</span>
					{labelHelperText ? (
						<span className='text-[11px] font-normal'>
							<span className='text-red-500'>*</span> required
						</span>
					) : undefined}
				</label>
			) : undefined}

			<DateRangePicker
				{...rest}
				{...field}
				value={field?.value}
				format={format || 'yyyy-MM-dd'}
				clearIcon={
					Array.isArray(field?.value) && field?.value.length > 0 ? (
						<X className={classNames('h-4 cursor-pointer text-gray-500')} />
					) : null
				}
				calendarIcon={
					<CalendarDays className={classNames('h-4 cursor-pointer text-gray-500')} />
				}
				onChange={(date) => {
					form.setFieldValue(field?.name, date)
				}}
				minDate={minDate}
				maxDate={maxDate}
				yearPlaceholder='1971'
				monthPlaceholder='01'
				dayPlaceholder='01'
				className={classNames('form-control rounded-md !p-[1px]', {
					'!border !border-[#f46a6a]': touched && error,
				})}
				disabled={readOnly}
			/>

			{helperText ? (
				<span className='text-[13px] italic text-gray-400'>{helperText}</span>
			) : undefined}
			{touched && error ? (
				<div className='text-[87%] text-[#f46a6a]'>{error}</div>
			) : undefined}
		</div>
	)
}

export default DateRangePickerField
