import classNames from 'classnames'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PENDING_SUB_APP_ROUTES } from './pending-subscription/routes'

const Header = () => {
	const location = useLocation()

	const tabs = useMemo(
		() => [
			{
				label: 'Payments',
				href: '/payments',
				permission: true,
			},
			{
				label: 'Pending Subscription Payments',
				href: PENDING_SUB_APP_ROUTES.findAll(),
				permission: true,
			},
		],
		[],
	)

	const activeTab = useMemo(
		() => tabs.find((tab) => tab?.href === location?.pathname),
		[location?.pathname],
	)

	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between sm:hidden'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>{activeTab?.label}</h4>
				</div>
			</div>
			<div className='-mb-[12px] space-x-2'>
				{tabs
					.filter((item) => item?.permission)
					.map((tab, i) => (
						<Link
							to={tab.href}
							key={i}
							className={classNames(
								'remove_space border-b-2 px-[10px] py-[10px] text-sm font-bold text-gray-400',
								{
									'border-main text-main': tab?.href == location?.pathname,
								},
							)}>
							{tab.label}
						</Link>
					))}
			</div>
		</div>
	)
}

export default Header
