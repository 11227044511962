import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat } from 'utilities/helpers'
import DataTable from 'theme/ui/data-table'
import { formatDate, formatPrice } from 'utilities/helpers'
import TeamActions from './team-actions'
import ToggleTeamStatus from './toggle-team-status'

const TeamTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.team_name, {
			id: 'team_name',
			size: 200,
			header: () => <span>Team</span>,
			cell: (info) => (
				<span className='line-clamp-1 font-bold' title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row?.teamOwner, {
			id: 'owner_details',
			enableSorting: false,
			header: () => <span>Owner Details</span>,
			cell: (info) => {
				return (
					<div className='flex flex-col'>
						<Link
							to={`/users/${info.getValue()?.id}/show`}
							target='_blank'
							className='text-sm font-bold text-main hover:!underline'>
							{info.getValue()?.fullName}
						</Link>
						<span className='text-xs text-gray-400'>{info.getValue()?.email}</span>
					</div>
				)
			},
		}),

		columnHelper.accessor((row) => row.userHasSubscriptions, {
			id: 'subscription',
			size: 220,
			header: () => <span>Subscription</span>,
			cell: (info) =>
				info?.getValue()[0] ? (
					<div>
						<div className='flex items-baseline space-x-2'>
							<span className='font-bold capitalize text-gray-800'>
								{info.getValue()[0]?.plan?.name}
							</span>
						</div>
						<div className='flex items-baseline space-x-1 text-xs text-gray-400'>
							<span>
								{formatPrice(
									info.getValue()[0]?.price?.amount / 100,
									info.getValue()[0]?.price?.currency || 'USD',
								)}
							</span>
							<span>
								{info.getValue()[0]?.price?.interval
									? `/ ${info.getValue()[0]?.price?.interval}`
									: ''}
							</span>
						</div>
					</div>
				) : (
					'-'
				),
		}),

		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 100,
			header: () => <span>Status</span>,
			cell: (info) => <ToggleTeamStatus team={info.getValue()} />,
		}),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Created At</span>
				</div>
			),
			cell: (info) => (
				<div className='justify-end w-full text-right'>
					<ToolTip
					targetId={`date-${info.row.index}`}
					tooltipText={dateFormat(info.getValue()?.created_at)} 
					placement='top'
					offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
					{formatDate(info.getValue()?.created_at)} 
					</span>
			  </div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => <TeamActions team={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default TeamTable
