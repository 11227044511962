import SubscriptionPlans from 'modules/profile/my-subscriptions/subscription-plans/subscription-plans'
import { useEffect } from 'react'

const PricingTableContainer = ({ clientSecret, isLoading }) => {
	useEffect(() => {
		const script = document.createElement('script')
		script.src = 'https://js.stripe.com/v3/pricing-table.js'
		script.async = true
		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<div className='page-container'>
			<SubscriptionPlans />
			{/* <div className='mx-auto mb-3 max-w-[768px] text-center sm:p-3 lg:p-3'>
				<h2>Choose your plan</h2>
				<p className='text-sm text-gray-600'>
					Our pricing is modular and team-based, designed to accommodate teams of all
					sizes. Smaller teams have some flexibility before needing to purchase additional
					seats. Our goal is to ensure our product scales seamlessly with the growth and
					evolving needs of your team.
				</p>
			</div>
			<div className='mx-auto w-full max-w-[1000px] rounded bg-white py-3'>
				{isLoading ? (
					<Spinner label='Creating your checkout session...' />
				) : (
					<Fragment>
						{clientSecret ? (
							<stripe-pricing-table
								pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
								publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
								customer-session-client-secret={
									clientSecret
								}></stripe-pricing-table>
						) : (
							<stripe-pricing-table
								pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
								publishable-key={
									process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
								}></stripe-pricing-table>
						)}
					</Fragment>
				)}
			</div> */}
		</div>
	)
}

export default PricingTableContainer
