import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppLayout from 'theme/layouts/app-layout'
import notification from 'utilities/notification'
import apiEndpoints from '../../helpers/apiEndpoints'
import * as actions from '../../store/common/actions'
import * as permissionsActions from '../../store/permissions/actions'
import UnAuthorizePage from '../StaticPages/UnAuthorize'

import { PageHeader } from 'theme/layouts'
import GroupsForm from './components/GroupsForm'
import {
	editablePermissionsArray,
	modifyModules,
	permissionsArrayUpdate,
} from './components/customHooks'

const RoleManage = (props) => {
	const { history, match } = props
	const groupId = match.params.id
	const dispatch = useDispatch()
	const [group, setGroup] = useState(null)
	const permissions = useSelector((state) => state.Permissions.data)
	const newPermissionsArray = permissionsArrayUpdate(permissions)
	const isSuperAdmin = useSelector((state) => state.Permissions.isSuperAdmin)
	const [loader, setLoader] = useState(false)
	const [isFetching, setIsFetching] = useState(false)

	useEffect(() => {
		if (isSuperAdmin) {
			setIsFetching(true)
			dispatch(permissionsActions.all(apiEndpoints.group.groupsPermissions)).then(
				(response) => {
					if (response && response.status === 200 && groupId) {
						dispatch(actions.find(apiEndpoints.group.groups, groupId))
							.then((response) => {
								setIsFetching(false)
								if (response.status === 404) {
									history.push('/roles')
								}
								if (response && response.data && response.status === 200) {
									const { name, description, is_active, accesses } = response.data
									const newAccesses = editablePermissionsArray(accesses)
									setGroup({
										name,
										description,
										is_active,
										accesses: newAccesses,
									})
								}
							})
							.catch(() => {
								setIsFetching(false)
							})
					}
					if (!groupId) {
						setIsFetching(false)
					}
				},
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onSubmit = (values) => {
		try {
			if (values.accesses && values.accesses.length > 0) {
				setLoader(true)
				const payload = {
					name: values?.name,
					description: values?.description,
					is_active: values?.is_active ? true : false,
				}
				Object.assign(payload, {
					accesses: modifyModules(values.accesses),
				})

				const action = groupId
					? actions.update(apiEndpoints.group.groups, groupId, payload)
					: actions.store(apiEndpoints.group.groups, payload)
				dispatch(action)
					.then((response) => {
						setLoader(false)
						if (response?.status === 200) {
							notification('success', response?.message)
							// history.push('/roles')
						}
					})
					.catch(() => {
						setLoader(false)
					})
			} else {
				notification('warn', 'Please Select Permissions for Role')
				setLoader(false)
			}
		} catch (error) {
			setLoader(false)
			notification('warn', error?.message)
		}
	}

	return (
		<AppLayout
			meta={{
				title: 'Edit Role & Permissions',
				hasBackButton: true,
			}}>
			{isSuperAdmin ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<PageHeader
							title={`${groupId ? 'Edit' : 'Create'} Roles `}
							hasBackButton={true}
							innerClassName={'block sm:hidden'}
						/>
						<div className='page-body'>
							<GroupsForm
								loading={loader}
								isFetching={isFetching}
								initialValues={group}
								newPermissionsArray={newPermissionsArray}
								groupId={groupId}
								onSubmit={onSubmit}
							/>
						</div>
					</div>
				</div>
			) : (
				<UnAuthorizePage />
			)}
		</AppLayout>
	)
}

export default RoleManage
