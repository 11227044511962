import { useState } from 'react'
import { generateUniqueId } from 'utilities/helpers'

const RatingFieldView = ({ name, field, onChange }) => {
	const [selectedRating, setSelectedRating] = useState(0)
	const tooltipId = generateUniqueId()

	const handleRatingChange = (rating) => {
		const newRating = selectedRating === rating ? 0 : rating
		setSelectedRating(newRating)
		onChange(name, newRating)
	}

	return (
		<div className='form-group flex flex-col'>
			{field?.label ? (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label}{' '}
						{field?.required ? <span className='text-red-500'>*</span> : undefined}
					</span>
				</label>
			) : undefined}

			<div className='flex items-center space-x-2' id={tooltipId}>
				{Array.from({ length: field?.rating?.value }).map((_, index) => (
					<span
						key={index}
						onClick={() => handleRatingChange(index + 1)}
						className='cursor-pointer text-3xl'>
						<i
							className={`${
								field?.value > index
									? 'fas fa-star text-yellow-400'
									: 'far fa-star text-gray-400'
							}`}></i>
					</span>
				))}
			</div>

			{field?.description ? (
				<span className='text-[13px] text-gray-400'>{field?.description}</span>
			) : null}
		</div>
	)
}

export default RatingFieldView
