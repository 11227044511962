import { Fragment } from 'react'
import { useMediaQuery } from 'react-responsive'
import AddOptions from './add-options'
import OptionsList from './option-list'

const Actions = ({ isActionActive, type, data, refetchRecord, parentFolderData }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 1024px' })
	const shouldShowActions = isMobile || isActionActive

	return (
		<div
			className='float-end flex w-[100px] items-center justify-end space-x-2 text-right'
			onClick={(event) => event.stopPropagation()}>
			{shouldShowActions ? (
				<Fragment>
					{type !== 'document' ? (
						<AddOptions refetchRecord={refetchRecord} data={data} />
					) : undefined}
					<OptionsList parentFolderData={parentFolderData} data={data} type={type} />
				</Fragment>
			) : undefined}
		</div>
	)
}

export default Actions
