import { Fragment } from 'react'
import PdfFieldRender from '../pdf-field-render/pdf-field-render'

const SamePage = ({ error, fields, isReadOnly, currentStatus }) => {
	return (
		<Fragment>
			{fields.length > 0 ? (
				<div className='mt-4 flex w-full space-x-6'>
					<div className='grid w-full grid-cols-1 gap-x-3 md:grid-cols-1'>
						{fields.map((field, i) => (
							<Fragment key={i}>
								<PdfFieldRender
									isReadOnly={isReadOnly}
									currentStatus={currentStatus}
									field={field}
									i={i}
									error={error}
								/>
								{i < fields.length - 1 ? (
									<hr className='w-full text-gray-300' />
								) : undefined}
							</Fragment>
						))}
					</div>
				</div>
			) : (
				<div className='alert alert-info mx-auto max-w-md'>
					No profile field is required to be filled here. Please proceed.
				</div>
			)}
		</Fragment>
	)
}

export default SamePage
