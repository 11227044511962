import { FIELD_MODE } from '../../helpers'
import RatingFieldEdit from './rating-field-edit'
import RatingFieldView from './rating-field-view'

const RatingField = ({ field, mode = 'edit', name, onChange, onRatingChange }) => {
	switch (mode) {
		case FIELD_MODE.EDIT:
			return <RatingFieldEdit name={name} field={field} onRatingChange={onRatingChange} />

		case FIELD_MODE.VIEW:
			return <RatingFieldView name={name} field={field} onChange={onChange} />

		default:
			return null
	}
}

export default RatingField
