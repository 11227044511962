import { add } from 'utils/storageService'
import { authRoutes } from '../routes/allRoutes'

import { decryptResponse, encryptPayload, getUserTimezone } from 'utilities/helpers'
import notification from 'utilities/notification'
import { logoutFromStore } from './jwtauth/actions'

function setupAxios(axios, store) {
	const routes = authRoutes.map((route) => route.path)
	const { dispatch } = store

	axios.interceptors.request.use(
		(config) => {
			const {
				Permissions: { isSuperAdmin, groups, defaultGroup, hasTeam },
			} = store.getState()

			config.baseURL = process.env.REACT_APP_API_BASE_URL
			config.withCredentials = true
			config.headers['Content-Type'] = 'application/json'
			config.headers.Accept = 'application/json'
			config.headers['Timezone'] = getUserTimezone()

			if (isSuperAdmin) {
				config.headers.team_id = 0
			}
			if (!isSuperAdmin) {
				if (defaultGroup) {
					config.headers['team_id'] = defaultGroup?.team?.id
				} else if (groups && groups.length > 0) {
					if (hasTeam) {
						config.headers['team_id'] = hasTeam?.id
					} else {
						config.headers['team_id'] = groups[0].team?.id
					}
				}
			}

			const isEncryptionEnabled = ['production', 'development'].includes(
				process.env.REACT_APP_ENV,
			)
			if (
				typeof config.data !== 'undefined' &&
				!(config.data instanceof FormData) &&
				isEncryptionEnabled
			) {
				const encrypted = encryptPayload(config.data)
				config.data = { data: encrypted }
			}
			return config
		},
		(err) => Promise.reject(err),
	)

	axios.interceptors.response.use(
		(response) => {
			response.data = decryptResponse(response)
			return response
		},
		(error) => {
			if (error?.response) {
				error.response['data'] = decryptResponse(error?.response)
			}

			const statusCode = error?.response?.status

			if (statusCode === 401) {
				if (!routes.includes(window.location.pathname)) {
					add('redirectTo', window.location.pathname)
				}
				dispatch(logoutFromStore()).then(() => {
					window.location.href = '/login'
				})
			}

			if ([404, 400].includes(statusCode)) {
				const message =
					error?.response?.data?.message.toString() || error?.response?.statusText
				notification('warn', message)
			}

			if (statusCode === 403) {
				notification('warn', 'You do not have permission to access this resource.')
			}

			if (statusCode >= 500) {
				notification('warn', 'The server is currently unavailable. Please try again later.')
			}

			return Promise.reject(error)
		},
	)
}

export default setupAxios
