import { ReactComponent as DocumentIcon } from 'assets/svg/document-icon.svg'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import Spinner from 'theme/ui/spinner'

const DataListDocuments = ({ type, listData, isLoading, onSelectionChange }) => {
	const handleRadioChange = (id) => onSelectionChange(id)

	if (!listData && isLoading) {
		return <Spinner size='sm' />
	}

	if (listData && !listData?.length) {
		return <p className='text-center'>No records found</p>
	}

	return (
		<div>
			{listData &&
				listData.map((item) => (
					<div
						className='flex items-center justify-between space-x-2 px-4 py-3 shadow-sm'
						key={item.id}>
						<div className='flex items-center gap-1'>
							{type === 'folder' ? (
								<FolderIcon height={15} />
							) : (
								<DocumentIcon height={20} />
							)}
							<span className='my-1 ml-2 text-sm font-medium'>{item?.title}</span>
						</div>
						<div>
							<input
								type='radio'
								className='form-check-input'
								name='folder-radio'
								onChange={() => handleRadioChange(item.id)}
							/>
						</div>
					</div>
				))}
		</div>
	)
}

export default DataListDocuments
