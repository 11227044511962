/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ClientVerifyForm, preparePayload, useInitialValues } from 'modules/clients/client-verify'
import InvitedClientQuery from 'modules/clients/manual-verification/InvitedClientQuery'
import { store } from 'store/common/actions'
import { PageHeader } from 'theme/layouts'
import AppLayout from 'theme/layouts/app-layout'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import { showSuccessToast } from 'utils/commonFunctions'

const ClientVerify = (props) => {
	const {
		match: { params },
		location,
		history,
	} = props
	const { id } = params
	const { client, isLoading } = InvitedClientQuery(id)
	const initialValues = useInitialValues({ client })
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState(1)

	const onSubmit = (values) => {
		const payload = preparePayload(values)
		const URL = `users/${id}/user-onboard-detail`
		dispatch(store(URL, payload)).then((response) => {
			if (response?.status === 200) {
				showSuccessToast(response?.message)
				history.push('/clients')
			}
		})
	}

	useEffect(() => {
		if (!isLoading && client.length === 0) {
			notification(
				'warn',
				'The client has not yet completed filling out all the necessary information.',
			)
			history.goBack()
		}
	}, [isLoading, client])

	return (
		<AppLayout
			meta={{
				title: 'Verify Client Information',
				hasBackButton: true,
			}}>
			<div className='page-content  px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Verify Information'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						{isLoading ? (
							<Spinner />
						) : (
							<div className='bg-white'>
								<ClientVerifyForm
									location={location}
									history={history}
									initialValues={initialValues}
									onSubmit={onSubmit}
									client={client}
									tabState={[activeTab, setActiveTab]}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ClientVerify
