import classNames from 'classnames'
import { generateUniqueId } from 'utilities/helpers'

const TextFieldView = ({ name, field, onChange, is_read_mode }) => {
	const tooltipId = generateUniqueId()

	return (
		<div className='form-group'>
			{field?.label ? (
				<label
					htmlFor={tooltipId}
					className='flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
					<span>
						{field?.label}{' '}
						{field?.required ? <span className='text-red-500'>*</span> : undefined}
					</span>
				</label>
			) : undefined}
			<input
				type={field?.sub_type || 'text'}
				className={classNames(
					'form-control placeholder:!text-gray-400',
					is_read_mode ? 'cursor-default select-none' : '',
				)}
				id={tooltipId}
				name={field?.name}
				value={field?.value || ''}
				onChange={(e) => onChange(name, e.target.value)}
				placeholder={'Your Answer'}
				readOnly={is_read_mode}
			/>
			{field?.description ? (
				<span className='text-[13px]  text-gray-400'>{field?.description}</span>
			) : undefined}
		</div>
	)
}

export default TextFieldView
