import { Field } from 'formik'
import { SelectField } from 'theme/ui/forms'

const UserDropdown = ({ name, options, onAutoSave }) => {
	return (
		<Field
			type='select'
			label='Select User'
			name={name}
			className='!min-w-[30%]'
			component={SelectField}
			options={options}
			isClearable={false}
			onAutoSave={onAutoSave}
		/>
	)
}

export default UserDropdown
