import { Field } from 'formik'
import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import { useState } from 'react'
import { SelectDropdown } from 'theme/ui/filters'
import { filterOption } from '../helpers'
import ClientQuery from './client-query'

const ClientDropdown = ({ name }) => {
	const [search, setSearch] = useState('')
	const { options } = ClientQuery(search)

	return (
		<Field
			name={name}
			isMulti={true}
			label='Clients'
			options={options}
			onInputChange={(value) => setSearch(value)}
			component={SelectDropdown}
			getOptionLabel={(option) => <CreateUserOptionLabel user={option} />}
			filterOption={filterOption}
		/>
	)
}

export default ClientDropdown
