import classNames from 'classnames'
import { Field } from 'formik'
import { Fragment, useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { SwitchField } from 'theme/ui/forms'

const MultipleToggleField = ({ field, name }) => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false)
	return (
		<Fragment>
			{field?.multiple ? (
				<Field name={`${name}.max_selected_option`}>
					{({ field, meta: { touched, error } }) => (
						<div className='invalid-tooltip-container'>
							<Tooltip
								id='my-tooltip'
								content={error}
								className='danger'
								isOpen={touched && error && isTooltipOpen}
							/>
							<input
								{...field}
								type='number'
								onFocus={() => setIsTooltipOpen(true)}
								onBlur={() => setIsTooltipOpen(false)}
								data-tooltip-id='my-tooltip'
								placeholder='Max options selection'
								className={classNames(
									'max-w-[40px] rounded !border !px-2 !py-1 text-center !text-[13px]',
									{
										'is-invalid': touched && error,
									},
								)}
							/>
						</div>
					)}
				</Field>
			) : undefined}

			<Field component={SwitchField} label='Multiple Choice?' name={`${name}.multiple`} />
		</Fragment>
	)
}

export default MultipleToggleField
