import { useUser } from 'hooks'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { useLazyDocumentDeleteEligibleQuery } from 'modules/documents/document-api'
import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import { popupKeys } from '.'
import CreateFolder from '../../search-bar/create-folder/create-folder'
import AddShortcutPopup from '../add-shortcut-popup'
import CopyToFolderPopup from '../copy-to-folder-popup'
import DocumentRenamePopup from '../document-rename-popup'
import FolderRenamePopup from '../folder-rename-popup'
import {
	useDeleteDocumentMutation,
	useDeleteFolderMutation,
	useMarkAsStarMutation,
} from '../readymade-templates-api'

const OptionsList = ({ item, popupStates, onOpenPopup, onClosePopup }) => {
	const [markStarred, { isLoading: isMarking }] = useMarkAsStarMutation()
	const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false)
	const [menu, setMenu] = useState(false)
	const { isSuperAdmin } = useUser()

	const [documentDeleteEligible, { isLoading: isEligibleChecking }] =
		useLazyDocumentDeleteEligibleQuery()
	const [deleteDocumentTemplate, { isLoading: isDeletingDocument }] = useDeleteDocumentMutation()
	const [deleteFolder, { isLoading: isDeletingFolder }] = useDeleteFolderMutation()

	const onDeleteFolder = async (id) => {
		try {
			const response = await deleteFolder(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
				onClosePopup(popupKeys.FOLDER_DELETE)
			}
		} catch (error) {
			onClosePopup(popupKeys.FOLDER_DELETE)
			notification('error', error?.message)
		}
	}

	const checkDocumentDeleteEligibility = async (id) => {
		const response = await documentDeleteEligible(id)
		if (response?.data?.status === 200 && response?.data?.data) {
			onOpenPopup(popupKeys.DOCUMENT_DELETE)
		}
	}

	const onDeleteDocument = async (id) => {
		try {
			const response = await deleteDocumentTemplate(id).unwrap()
			if (response?.status === 200) {
				notification('success', response?.message)
				onClosePopup(popupKeys.DOCUMENT_DELETE)
			}
		} catch (error) {
			onClosePopup(popupKeys.DOCUMENT_DELETE)
			notification('error', error?.message)
		}
	}

	const onMarkStarred = async (id, type) => {
		try {
			const $type = type === 'document' ? 'document_id' : 'document_package_id'
			const payload = { [$type]: id }
			const response = await markStarred(payload).unwrap()
			if (response?.status === 200) {
				notification('success', response?.message)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Fragment>
			<CreateFolder
				onClose={() => setIsFolderPopupOpen(!isFolderPopupOpen)}
				isOpen={isFolderPopupOpen}
			/>

			<AddShortcutPopup
				document={item}
				isOpen={popupStates[popupKeys.ADD_DOCUMENT_SHORTCUT]}
				onClose={() => onClosePopup(popupKeys.ADD_DOCUMENT_SHORTCUT)}
			/>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className='dropdown  inline-block'
				tag='li'>
				<DropdownToggle
					tag='button'
					type='button'
					className='m-0 !border !border-none bg-transparent p-0'>
					<i className='mdi mdi-dots-vertical text-[16px]'></i>
				</DropdownToggle>
				<DropdownMenu
					onClick={() => {}}
					className='dropdown-menu-md dropdown-menu-end  p-0 '>
					<div className='w-full overflow-hidden rounded-md bg-white text-left shadow-lg ring-1 ring-black/5'>
						<div className='relative divide-y p-2'>
							<div className='flex flex-col gap-1'>
								{item?.type === 'document' ? (
									<Link
										to={DOCUMENT_TEMPLATE_APP_ROUTES.findOne(item?.id)}
										className='file-manager-action-dropdown-item'>
										<i className='uil-eye'></i>
										<span className='text-sm'>View</span>
									</Link>
								) : undefined}

								{isSuperAdmin ? (
									<Fragment>
										{item?.type === 'document' ? (
											<Link
												to={DOCUMENT_TEMPLATE_APP_ROUTES.edit(item?.id)}
												className='file-manager-action-dropdown-item'>
												<i className='uil-pen'></i>
												<span className='text-sm'>Edit</span>
											</Link>
										) : undefined}

										{item?.type === 'document' ? (
											<span
												className='file-manager-action-dropdown-item'
												onClick={() =>
													onOpenPopup(popupKeys.DOCUMENT_RENAME)
												}>
												<i className='uil-edit-alt'></i>
												<span className='text-sm'>Rename</span>
											</span>
										) : (
											<span
												className='file-manager-action-dropdown-item'
												onClick={() =>
													onOpenPopup(popupKeys.FOLDER_RENAME)
												}>
												<i className='uil-edit-alt'></i>
												<span className='text-sm'>Rename</span>
											</span>
										)}
									</Fragment>
								) : undefined}

								{/* {!isSuperAdmin ? (
										<span className='file-manager-action-dropdown-item'>
											<i className='uil-paperclip'></i>
											<span className='text-sm'>Attach in Workflow</span>
										</span>
									) : undefined} */}

								{isSuperAdmin ? (
									<Fragment>
										{item?.type === 'document' ? (
											<span
												className='file-manager-action-dropdown-item'
												onClick={() =>
													onOpenPopup(popupKeys.ADD_DOCUMENT_SHORTCUT)
												}>
												<i className='uil-folder'></i>
												<span className='text-sm'>Add to folder</span>
											</span>
										) : (
											<>
												{/* <span
													className='file-manager-action-dropdown-item'
													onClick={() => setIsAddShotcutOpen(true)}>
													<i className='uil-folder'></i>
													<span className='text-sm'>Move to folder</span>
												</span> */}
											</>
										)}
									</Fragment>
								) : (
									<span
										className='file-manager-action-dropdown-item'
										onClick={() => onOpenPopup(popupKeys.COPY_TO_FOLDER)}>
										<i className='uil-folder'></i>
										<span className='text-sm'>Copy to Folder</span>
									</span>
								)}

								<span
									className='file-manager-action-dropdown-item'
									onClick={() => onMarkStarred(item?.id, item?.type)}>
									{isMarking ? (
										<span className='flex justify-start gap-3'>
											<Spinner />
											<span className='text-sm'>Processing...</span>
										</span>
									) : (
										<>
											{item?.isStarred ? (
												<Fragment>
													<i className='bx bxs-star text-main'></i>
													<span className='text-sm'>
														Remove from Starred
													</span>
												</Fragment>
											) : (
												<Fragment>
													<i className='uil-star'></i>
													<span className='text-sm'>Star</span>
												</Fragment>
											)}
										</>
									)}
								</span>

								{isSuperAdmin ? (
									<Fragment>
										{item?.type === 'document' ? (
											<span
												className='file-manager-action-dropdown-item'
												onClick={() =>
													checkDocumentDeleteEligibility(item?.id)
												}>
												{isEligibleChecking ? (
													<span className='flex justify-start gap-3'>
														<Spinner />
														<span className='text-sm'>Checking...</span>
													</span>
												) : (
													<>
														<i className='uil-trash text-red-600'></i>
														<span className='text-sm text-red-600'>
															Delete
														</span>
													</>
												)}
											</span>
										) : (
											<span
												className='file-manager-action-dropdown-item'
												onClick={() =>
													onOpenPopup(popupKeys.FOLDER_DELETE)
												}>
												<i className='uil-trash text-red-600'></i>
												<span className='text-sm text-red-600'>
													Move to trash
												</span>
											</span>
										)}
									</Fragment>
								) : undefined}
							</div>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>

			<DocumentRenamePopup
				document={item}
				isOpen={popupStates[popupKeys.DOCUMENT_RENAME]}
				onClose={() => onClosePopup(popupKeys.DOCUMENT_RENAME)}
			/>

			<FolderRenamePopup
				folder={item}
				isOpen={popupStates[popupKeys.FOLDER_RENAME]}
				onClose={() => onClosePopup(popupKeys.FOLDER_RENAME)}
			/>

			<DeleteConfirmAlert
				title='Move to Trash?'
				subtitle='Are you sure you want to move this Document Template to the trash?'
				isDeleting={isDeletingDocument}
				isOpen={popupStates[popupKeys.DOCUMENT_DELETE]}
				onClose={() => onClosePopup(popupKeys.DOCUMENT_DELETE)}
				onConfirm={() => onDeleteDocument(item?.id)}
			/>

			<DeleteConfirmAlert
				title='Delete Folder?'
				subtitle='Are you sure you want to delete this folder'
				isDeleting={isDeletingFolder}
				isOpen={popupStates[popupKeys.FOLDER_DELETE]}
				onClose={() => onClosePopup(popupKeys.FOLDER_DELETE)}
				onConfirm={() => onDeleteFolder(item?.id)}
			/>

			<CopyToFolderPopup
				document={item?.type === 'document' ? item : null}
				folder={item?.type === 'folder' ? item : null}
				isOpen={popupStates[popupKeys.COPY_TO_FOLDER]}
				onClose={() => onClosePopup(popupKeys.COPY_TO_FOLDER)}
			/>
		</Fragment>
	)
}

export default OptionsList
