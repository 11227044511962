/* eslint-disable react-hooks/exhaustive-deps */
import { snakeCase } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'

import { useUser } from 'hooks'
import { useLazyCheckNylasQuery } from 'modules/authentication/auth-api'
import AppLayout from 'theme/layouts/app-layout'
import AnnouncementsList from 'theme/ui/announcements'
import NylasNotification from 'theme/ui/nylas-notification'
import Home from './home'

const Portfolio = () => {
	const { isSuperAdmin, defaultGroup } = useSelector((state) => state.Permissions)
	const currentGroup = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])
	const { team } = useUser()
	useEffect(() => {
		if (!isSuperAdmin && currentGroup === 'client') {
			// history.push('/assigned-workflows')
		}
	}, [currentGroup])
	const [checkNylas] = useLazyCheckNylasQuery()
	const [showMessage, setShowMessage] = useState(false)
	const [nylasType, setNylasType] = useState('')
	useEffect(() => {
		const fetchNylasStatus = async () => {
			const response = await checkNylas()

			if (response?.data?.status !== 200) {
				setShowMessage(true)
				setNylasType('payment')
			} else if (!team?.nylasGrantId && response?.data?.status === 200) {
				setShowMessage(true)
				setNylasType('email')
			}
		}
		fetchNylasStatus()
	}, [])

	return (
		<AppLayout
			meta={{
				title: 'Portfolio',
			}}>
			<div className='page-content'>
				<Container fluid>
					{!isSuperAdmin && (
						<>
							{showMessage && currentGroup === 'owner' && (
								<NylasNotification
									setShowMessage={setShowMessage}
									nylasType={nylasType}
								/>
							)}
							<AnnouncementsList />
							{/* <Notes /> */}
							<Home />
						</>
					)}
				</Container>
			</div>
		</AppLayout>
	)
}
export default Portfolio
