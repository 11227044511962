import { Fragment } from 'react'
import Badge from 'theme/ui/badge'
import ToolTip from 'theme/ui/tool-tip'
import { generateUniqueId } from 'utilities/helpers'

const FoldersColumn = ({ folders }) => {
	const id_1 = generateUniqueId()
	const id_2 = generateUniqueId()

	return (
		<Fragment>
			<div className='flex max-w-[165px] flex-wrap items-center gap-1'>
				{folders?.length > 0 && (
					<div className='flex flex-wrap items-center gap-2'>
						<Badge
							variant='primary-outline'
							className='!line-clamp-1 w-[80px] cursor-pointer px-2'
							id={id_2}
							rounded={false}>
							{folders[0]?.title}
						</Badge>
						<ToolTip targetId={id_2} tooltipText={folders[0]?.title} />

						{folders.slice(1)?.length > 1 ? (
							<>
								<ToolTip
									targetId={id_1}
									tooltipText={folders.slice(1).map((item, i) => (
										<ul key={i} className='m-0 list-disc pl-3'>
											<li>{item?.title}</li>
										</ul>
									))}
								/>
								<Badge
									variant='primary-outline'
									id={id_1}
									className='!line-clamp-1 cursor-pointer px-2'
									rounded={false}>
									<span>+{folders.slice(1)?.length}</span>
								</Badge>
							</>
						) : undefined}
					</div>
				)}
			</div>
		</Fragment>
	)
}

export default FoldersColumn
