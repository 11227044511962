import classNames from 'classnames'
import { useTabs } from 'contexts/tabs-context'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'theme/ui/forms'
import { removeQueryParam } from 'utilities/helpers'
import { getTabs } from '../tabs-header'
const TabsFooter = ({ formik }) => {
	const { activeTab, setActiveTab } = useTabs(2)
	const isSignatureRequired = useMemo(
		() => formik?.values?.signature_required === 'yes',
		[formik?.values],
	)
	const totalTabs = useMemo(
		() => getTabs(isSignatureRequired, formik?.values?.document_type === 'form').length,
		[isSignatureRequired],
	)
	const history = useHistory()

	const onPrevious = () => {
		const queryString = location.search
		const updatedQueryString = removeQueryParam(queryString, 'isCreate')
		history.push(`${location.pathname}?${updatedQueryString}`)
		setActiveTab(activeTab - 1)
	}

	return (
		<div className='tabs_spacing px-[75px]'>
			<div
				className={classNames(
					'flex !border-t py-6',
					activeTab === 1 ? 'justify-end' : 'justify-between',
				)}>
				{activeTab > 1 ? (
					<Button type='button' onClick={onPrevious} variant='white'>
						Previous
					</Button>
				) : undefined}

				<Button
					type='submit'
					isLoading={formik.isSubmitting}
					// disabled={!formik.isValid}
					isLoadingText='Saving...'>
					{activeTab === totalTabs ? 'Publish Now' : 'Next'}
				</Button>
			</div>
		</div>
	)
}

export default TabsFooter
