import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

const API_URL = apiEndpoints.announcements

export const announcementsApi = createApi({
	reducerPath: 'Announcements',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Announcements'],
	endpoints: (builder) => ({
		getAnnouncements: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Announcements'],
			transformResponse: (response, _, arg) => {
				const announcements = Array.isArray(response?.data?.announcements)
					? response?.data?.announcements.map((announcement, idx) => ({
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: announcement?.id,
							title: announcement?.title,
							status: announcement?.status,
							content: announcement?.content,
							created_at: announcement?.created_at,
					  }))
					: []
				return {
					announcements,
					meta: response?.data?.meta,
				}
			},
		}),
		getAnnouncement: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			providesTags: ['Announcements'],
			transformResponse: (response) => {
				const announcement = response?.data
				return {
					announcement,
				}
			},
		}),
		getAnnouncementList: builder.query({
			keepUnusedDataFor: 0,
			query: (payload) => ({
				url: `${API_URL}/list`,
				method: 'GET',
				params: payload,
			}),
			providesTags: ['Announcements'],
			transformResponse: (response) => {
				const announcement = response?.data
				return { announcement }
			},
		}),

		updateAnnouncement: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Announcements'],
		}),
		createAnnouncement: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Announcements'],
		}),
		deleteAnnouncement: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Announcements'],
		}),
	}),
})

export const {
	useLazyGetAnnouncementsQuery,
	useLazyGetAnnouncementQuery,
	useLazyGetAnnouncementListQuery,
	useUpdateAnnouncementMutation,
	useCreateAnnouncementMutation,
	useDeleteAnnouncementMutation,
} = announcementsApi
