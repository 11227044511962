import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'

import ActiveSubscription from 'modules/profile/my-subscriptions/active-subscription'
import Badge from 'theme/ui/badge'
import ClientsTab from './clients-tab'
import OwnerDetailsTab from './owner-details-tab'
import UsersTab from './users-tab'

const TeamDetailsContainer = ({ team, clients, users, owner }) => {
	const tabs = useMemo(
		() => [
			{ id: 0, label: 'Owner' },
			{ id: 1, label: 'Clients' },
			{ id: 2, label: 'Other Users' },
			{ id: 3, label: 'Subscriptions' },
		],
		[],
	)

	return (
		<div className='space-y-6 rounded-lg bg-white p-6 shadow-md'>
			<div className='flex items-center space-x-10'>
				<div>
					<h3 className='text-sm font-medium text-gray-500'>Team Name</h3>
					<p className='flex items-center gap-2 text-base font-semibold text-gray-800'>
						{team?.team_name || 'N/A'}{' '}
						<Badge
							variant={team?.status === 1 ? 'success' : 'danger'}
							className={` px-3 py-1 ${
								team?.status === 1
									? 'bg-green-100 text-green-800'
									: 'bg-red-100 text-red-800'
							}`}>
							{team?.status === 1 ? 'Active' : 'Inactive'}
						</Badge>
					</p>
				</div>
			</div>

			<Tab.Group>
				<Tab.List className='flex w-fit space-x-1 rounded-lg border border-gray-300 bg-gray-100 p-1'>
					{tabs.map((tab) => (
						<Tab
							key={tab.id}
							className={({ selected }) =>
								classNames(
									'rounded-lg px-3 py-2 text-sm transition-colors',
									selected
										? 'bg-white font-medium text-main shadow-sm'
										: 'text-gray-500 hover:bg-gray-200 hover:text-main',
								)
							}>
							{tab.label}
						</Tab>
					))}
				</Tab.List>

				<Tab.Panels className='mt-4'>
					<Tab.Panel>
						<OwnerDetailsTab owner={owner} />
					</Tab.Panel>
					<Tab.Panel>
						<ClientsTab clients={clients} team={team} />
					</Tab.Panel>
					<Tab.Panel>
						<UsersTab users={users} team={team} />
					</Tab.Panel>
					<Tab.Panel>
						<ActiveSubscription activeTab={4} teamId={team?.id} />
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default TeamDetailsContainer
