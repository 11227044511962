import { uniqWith } from 'lodash'
import { searchTypes } from 'modules/file-manager/search-bar'
import { getDateRange } from 'theme/ui/filters/date-range'
import Filters from './filters'

export const getFormikFilterValues = (values) => {
	const filters = {}

	const search = values?.search || ''
	if (search) {
		Object.assign(filters, { search })
	}

	const status = values.status?.value
	if (status) {
		Object.assign(filters, { status: status })
	}

	if (values?.view_by) {
		Object.assign(filters, { view_by: values?.view_by?.value })
	}

	const statuses = Array.isArray(values?.status) ? values.status : []
	if (statuses.length > 0) {
		Object.assign(filters, { status: statuses })
	}

	if (values?.date_range) {
		const date_range = getDateRange(values?.date_range)
		Object.assign(filters, { ...date_range })
	}

	return filters
}

export const getInitialValues = (queryObj) => {
	const currentStatus = searchTypes.find((t) => t.value === queryObj?.status)
	const initialValues = {
		search: '',
		date_range: null,
		view_by: '',
		status: !queryObj?.status ? searchTypes[0] : currentStatus,
	}
	if (queryObj?.search) {
		Object.assign(initialValues, { search: queryObj?.search })
	}

	if (queryObj?.view_by) {
		Object.assign(initialValues, { view_by: queryObj?.view_by })
	}

	if (queryObj?.status) {
		Object.assign(initialValues, { status: queryObj?.status.split(',') })
	}

	if (queryObj?.from && queryObj?.to) {
		Object.assign(initialValues, {
			date_range: `${queryObj?.from},${queryObj?.to}`,
		})
	}

	return initialValues
}

export const filterKeys = {
	search: 'Search',
	status: 'Statuses',
	from: 'Date Range',
	to: 'Date Range',
}

const getFitlerKey = (key) => {
	if (['from', 'to'].includes(key)) {
		return 'date_range'
	}
	return key
}

export const getActiveFilters = (filterObj) => {
	const filters = Object.entries(filterObj)
		.filter((filter) => !['page', 'limit'].includes(filter[0]))
		.filter((filter) => {
			// eslint-disable-next-line no-unused-vars
			const [_, values] = filter
			return values.length > 0
		})
		.map((filter) => {
			const [key, values] = filter
			return [
				{
					label: filterKeys[key],
					name: getFitlerKey(key),
				},
				values,
			]
		})

	return uniqWith(filters, (arrayVal, otherVal) => {
		return arrayVal[0]?.name === otherVal[0]?.name
	})
}

export default Filters
