import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'

import { useUploadDocumentMutation } from 'modules/authentication/auth-api'
import {
	GOVERNMENT_DOCUMENT,
	VALID_DOCUMENT,
} from 'pages/Authentication/client-document-upload/upload-documents/helpers'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import FileUploadCard from './file-upload-card'

const DocumentSection = ({ user, resetForm, refetch }) => {
	const [validDocument, setValidDocument] = useState(null)
	const [governmentDocument, setGovernmentDocument] = useState(null)
	const [uploadDocument] = useUploadDocumentMutation()

	const upload = async (payload) => {
		try {
			const formData = new FormData()
			formData.append('file', payload.file)
			formData.append('type', payload.type)
			formData.append('is_recognised', false)

			const res = await uploadDocument(formData).unwrap()
			refetch()
			resetForm()

			return res
		} catch (error) {
			notification('error', error?.message || 'Something went wrong')
		}
	}

	const formik = useFormik({
		initialValues: {},
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)

				if (validDocument) {
					await upload(validDocument)
				}
				if (governmentDocument) {
					await upload(governmentDocument)
				}
				// const response = await updateClient({ payload, id: user?.id })
				// if (response?.data?.status === 200) {
				// 	notification('success', 'Client details updated successfully')
				// }
				setSubmitting(false)
			} catch (error) {
				notification('error', error?.message)
				setSubmitting(false)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='justify-between gap-10 py-6 sm:flex'>
					<div className='prose my-3 max-w-none'>
						<h3>Identification Documents</h3>
						<p className='text-sm text-gray-500'>
							Your ID Information for Added Safety
						</p>
					</div>
					<div className='grid grid-cols-1 gap-x-6 sm:min-w-[400px] sm:max-w-[500px]'>
						<div className='form-group mb-3'>
							<label>SIN</label>
							{user?.sin_number ? (
								<input
									type='text'
									disabled
									defaultValue={user?.sin_number}
									className='form-control'
								/>
							) : (
								<p className='text-xs text-gray-500'>(No information found)</p>
							)}
						</div>

						<div className='form-group mb-3'>
							<FileUploadCard
								title='Valid ID'
								fileType={VALID_DOCUMENT}
								file={user?.valid_document}
								onSetFile={(data) => setValidDocument(data)}
							/>
						</div>

						<div className='form-group mb-3'>
							<FileUploadCard
								title='Government Document'
								fileType={GOVERNMENT_DOCUMENT}
								file={user?.government_document}
								onSetFile={(data) => setGovernmentDocument(data)}
							/>
						</div>

						<div className='flex justify-end'>
							<Button
								type='submit'
								isLoading={formik.isSubmitting}
								isLoadingText='Updating...'>
								Update
							</Button>
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default DocumentSection
