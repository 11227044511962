import { SystemFieldForm } from 'modules/system-fields'
import { PageHeader } from 'theme/layouts'
import AppLayout from 'theme/layouts/app-layout'

const SystemFieldsCreate = () => {
	return (
		<AppLayout
			meta={{
				title: 'Create System Field',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<PageHeader
						title='Create System Field'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						<SystemFieldForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default SystemFieldsCreate
