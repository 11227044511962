import userPlaceholder from 'assets/images/users/avatar1.jpg'

export const CreateUserOption = ({ user }) => ({
	label: <CreateUserOptionLabel user={user} />,
	value: user?.id,
})

export const CreateUserOptionLabel = ({ user, showEmail = true }) => {
	const full_name = user?.first_name + ' ' + user?.last_name
	return (
		<div className='flex items-center space-x-2'>
			<img
				src={user?.avatar?.path || userPlaceholder}
				alt={''}
				className='aspect-square h-[28px] rounded-full'
			/>
			<div className='flex flex-col'>
				<span className='text-[13px] font-semibold text-gray-700'>{full_name}</span>
				{showEmail ? (
					<span className='line-clamp-1 text-xs !font-light text-gray-500'>
						{user?.email}
					</span>
				) : undefined}
			</div>
		</div>
	)
}
