import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'

export const graphanaDashboardApi = createApi({
	reducerPath: 'graphanaDashboard',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['GraphanaDashboard'],
	endpoints: (builder) => ({
		getDashboardUrl: builder.query({
			query: (params) => ({
				url: apiEndpoints.dashboard,
				method: 'GET',
				params: params,
			}),
			providesTags: ['GraphanaDashboard'],
		}),
	}),
})

export const { useLazyGetDashboardUrlQuery } = graphanaDashboardApi
