import classNames from 'classnames'
import { Field, getIn } from 'formik'
import { capitalize } from 'lodash'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import FilterButton from 'theme/ui/filter-button'

const StatusDropdown = ({ name }) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggleDropdown = () => setIsOpen(!isOpen)
	const options = [
		{ label: 'Draft', value: 'draft' },
		{ label: 'Published', value: 'published' },
	]

	return (
		<div>
			<Field name='status'>
				{({ form }) => {
					const values = getIn(form?.values, name) || []
					const isActive = values?.length > 0

					const handleSelect = (option) => {
						const currentValues = Array.isArray(values) ? [...values] : []
						const index = currentValues.findIndex((item) => item === option)
						if (index > -1) {
							currentValues.splice(index, 1)
						} else {
							currentValues.push(option)
						}
						form.setFieldValue(name, currentValues)
					}

					return (
						<Dropdown isOpen={isOpen} toggle={() => toggleDropdown()}>
							<FilterButton
								isActive={isActive}
								onClear={() => form.setFieldValue(name, [])}>
								<DropdownToggle
									tag='button'
									type='button'
									className={classNames(
										'btn btn-secondary z-10 max-w-fit',
										isActive ? '!bg-main/20 !text-main' : '',
									)}>
									<div className='flex items-center '>
										<span className='!ml-[17px]'>Status</span>
										{isActive ? (
											<div className='flex items-center space-x-1'>
												<span className='ml-1 text-gray-500'>|</span>
												<span className='space-x-1 font-bold'>
													{values.length <= 1 ? (
														<span>
															{values
																.map((v) => capitalize(v))
																.join(', ')}
														</span>
													) : (
														<span>{values.length} selected</span>
													)}
												</span>
											</div>
										) : undefined}
										<span className='mt-[2px] pl-[2px]'>
											<ChevronDown size={16} />
										</span>
									</div>
								</DropdownToggle>
							</FilterButton>
							<DropdownMenu className='mt-2 px-0 py-2 shadow-md'>
								<ul className='m-0 p-0'>
									{options.map((option, idx) => (
										<li
											key={idx}
											className={classNames(
												values.some((item) => item === option?.value)
													? 'exp-dropdown-item-active'
													: 'exp-dropdown-item',
											)}
											onClick={() => handleSelect(option?.value)}>
											<span>{option?.label}</span>
										</li>
									))}
								</ul>
							</DropdownMenu>
						</Dropdown>
					)
				}}
			</Field>
		</div>
	)
}

export default StatusDropdown
