import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	headerTitle: {},
}
export const HeaderSlices = createSlice({
	name: 'headerTitle',
	initialState: initialState,
	reducers: {
		headerTitle: (state, action) => {
			state.headerTitle = action.payload
		},
	},
})
