import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import userIcon from 'assets/svg/user-plus-icon.svg'
import { useSendMultipleInvitationsMutation } from 'modules/clients/client-apis'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'

const SendInvitationPopup = ({ isOpen, onClose, selectedRows }) => {
	const [sendInvitation, { isLoading }] = useSendMultipleInvitationsMutation()
	const onSendInvitation = async (client_ids) => {
		try {
			const payload = {
				user_ids: client_ids,
			}
			const response = await sendInvitation(payload).unwrap()
			if (response?.status === 200) {
				onClose()
				notification('success', response?.message)
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className={`w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white p-3 '>
										<img src={userIcon} alt='' className='' />
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='mt-10 flex flex-col '>
									<div className='ms-4 flex flex-col justify-start ps-2'>
										<Dialog.Title
											as='h2'
											className='mb-3 text-left text-2xl font-bold leading-6 text-gray-900'>
											Send Invitation
										</Dialog.Title>
										<div>
											<p className='m-0 p-0 text-lg font-medium'>
												You have chosen to send invitation{' '}
												<span className='text-blue-400'>
													{selectedRows?.length} clients
												</span>
											</p>
											<p className='m-0 p-0 text-xs'>
												<span className='text-red-700'>*</span>This will
												apply only on clients with &quot;Non-Invited&quot;,
												&quot;Invited&quot;, &quot;Onboarding&quot;,
												&quot;Rejected&quot; status
											</p>
										</div>
									</div>
									<hr className='mx-4 my-3 bg-gray-300' />

									<div className='my-2 ms-3 flex w-full flex-col gap-2 pe-5 ps-2'>
										<Button
											type='button'
											block={true}
											isLoading={isLoading}
											onClick={() => onSendInvitation(selectedRows)}>
											Send Now
										</Button>
										<Button
											variant='ghost'
											type='button'
											block={true}
											onClick={onClose}>
											Cancel
										</Button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SendInvitationPopup
