import ClientInviteForm from 'modules/clients/client-invite-form'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const ClientInvite = () => {
	return (
		<AppLayout
			meta={{
				title: 'Create Client',
				hasBackButton: true,
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Create Client'
						description='Effortlessly streamline your client onboarding process with our intuitive invitation form page. Seamlessly invite clients and collect essential information in one smooth flow.'
						hasBackButton={true}
						innerClassName={'block sm:hidden'}
					/>
					<div className='page-body'>
						<ClientInviteForm isForClient={true} />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ClientInvite
