import classNames from 'classnames'
import { Field } from 'formik'

import FieldTypeField from './field-type-field'
import UserTypeField from './user-type-field'

const FieldWrapper = ({ name, children }) => {
	return (
		<div className='grid w-full grid-cols-1 gap-2'>
			<div className='flex items-baseline gap-2'>
				<Field name={`${name}.label`}>
					{({ field, meta: { touched, error } }) => (
						<div className='flex flex-1 flex-col'>
							<input
								{...field}
								type='text'
								className={classNames(
									'form-control flex-1 placeholder:!text-gray-400',
									{
										'is-invalid': touched && error,
									},
								)}
								placeholder='Question'
							/>
							{touched && error ? (
								<span className='invalid-feedback'>{error}</span>
							) : undefined}
						</div>
					)}
				</Field>
				<FieldTypeField name={name} />
			</div>

			<Field name={`${name}.description`}>
				{({ field }) => (
					<input
						{...field}
						type='text'
						className='form-control placeholder:!text-gray-400'
						placeholder='Description'
					/>
				)}
			</Field>

			{children}

			<UserTypeField name={name} />
		</div>
	)
}

export default FieldWrapper
