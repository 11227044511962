import { createApi } from '@reduxjs/toolkit/query/react'
import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { v4 } from 'uuid'
const API_STAR_URL = '/starred-document'

export const fileManagerApi = createApi({
	reducerPath: 'FileManager',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['FileManager'],
	endpoints: (builder) => ({
		getRecentDocuments: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params }) => ({
				url: apiEndpoints.recentDocument,
				method: 'GET',
				params: params,
			}),
			providesTags: ['FileManager'],
			transformResponse: (response, _, arg) => {
				const documents = Array.isArray(response?.data?.documents)
					? response?.data?.documents.map((document, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: document?.document?.id,
							title: document?.document?.title,
							status: document?.document?.status,
							version: document?.document?.version,
							document_type: document?.document?.document_type,
							last_edit: document?.document?.updated_at,
							folders: Array.isArray(document?.document?.documentPackages)
								? document?.document?.documentPackages
								: [],
					  }))
					: []
				return {
					documents,
					meta: response?.data?.meta,
				}
			},
		}),
		getStarredFiles: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: apiEndpoints.starredDocument,
				method: 'GET',
				params: params,
			}),
			providesTags: ['FileManager'],
			transformResponse: (response) => {
				const folders =
					response?.data?.starred_details?.length > 0
						? response.data.starred_details
								.filter((document) => document?.documentPackage)
								.map((document) => ({
									...document.documentPackage,
									type: 'folder',
									uuid: v4(),
								}))
						: []

				const documents =
					response?.data?.starred_details?.length > 0
						? response.data.starred_details
								.filter((document) => document?.document)
								.map((document) => ({
									...document.document,
									type: 'document',
									updated_at: document?.created_at,
									uuid: v4(),
								}))
						: []

				const starredFiles = [...folders, ...documents]

				return {
					data: starredFiles,
					meta: response?.data?.meta,
				}
			},
		}),
		deleteDocumentTemplate: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.document}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['FileManager'],
		}),
		deleteDocumentPackage: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.documentPackage}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['FileManager'],
		}),
		markDocumentPackageAsStar: builder.mutation({
			query: (payload) => ({
				url: API_STAR_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['FileManager'],
		}),
		getRecentFolders: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params }) => ({
				url: apiEndpoints.recentFolders,
				method: 'GET',
				params: params,
			}),
			providesTags: ['FileManager'],
			transformResponse: (response, _, arg) => {
				const documents = Array.isArray(response?.data?.document_packages)
					? response?.data?.document_packages
					: []
				return {
					documents,
					meta: response?.data?.meta,
				}
			},
		}),
	}),
})

export const {
	useLazyGetRecentDocumentsQuery,
	useLazyGetStarredFilesQuery,
	useLazyGetRecentFoldersQuery,
	useMarkDocumentPackageAsStarMutation,
	useDeleteDocumentTemplateMutation,
	useDeleteDocumentPackageMutation,
} = fileManagerApi
