import { Field } from 'formik'
import { DatePickerField } from 'theme/ui/forms'

const DatePicker = () => {
	return (
		<div>
			<Field
				label='Due Date'
				name='due_date'
				type='date'
				component={DatePickerField}
				minDate={new Date()}
			/>
		</div>
	)
}

export default DatePicker
