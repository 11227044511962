import { required } from 'utils/formValidation'

export const tabs = [
	{ id: 1, label: 'SIN Number' },
	{ id: 2, label: 'Documents' },
	{ id: 3, label: 'Personal Information' },
	{ id: 4, label: 'Selfie' },
]

export const preparePayload = (values) => {
	try {
		const steps = values?.step
		const stepsArray = Object.entries(steps)

		return {
			steps: stepsArray.map((step) => {
				const _step = {
					user_key: step[0],
					reason: step[1]?.reason,
					status: Number(step[1]?.status),
				}

				if (step[0] === 'sin_number') {
					Object.assign(_step, { user_value: step[1]?.value })
				}

				if (step[0] === 'verify_info') {
					const user = step[1]?.user || {}
					// const profile = step[1]?.user?.profile || {}

					Object.assign(_step, {
						user: { ...user },
					})
				}

				return _step
			}),
		}
	} catch (error) {
		return {
			steps: [],
		}
	}
}

export const validateVerifyInfo = {
	user: {
		first_name: required('first name'),
		last_name: required('last name'),
		email: required('email'),
		phone_number: required('phone number'),
	},
	address1: required('address 1'),
	address2: required('address 2'),
	unit: required('unit'),
	city: required('city'),
	province: required('province'),
	zip_code: required('zip code'),
	country: required('country'),
}

export const goToNextStep = async (isNextAble, values, error, step_id) => {
	const SIN = values?.step?.sin_number?.value
	const SIN_LENGTH = 9
	const skip_steps = [1, 2, 4]

	return new Promise((resolve, reject) => {
		if (SIN?.length < SIN_LENGTH && step_id === 1) {
			reject(new Error('Please enter SIN number'))
		}

		if (error && !skip_steps.includes(step_id)) {
			reject(new Error(error))
		} else {
			resolve()
		}

		if (isNextAble && !error) {
			resolve()
		}
	})
}

export { default as ClientVerifyForm } from './ClientVerifyForm'
export { default as useInitialValues } from './useInitialValues'
export { default as useSubmitAble } from './useSubmitAble'

