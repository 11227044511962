import { Header } from 'modules/tasks'
import KanbanViewContainer from 'modules/tasks/kanban'
import AppLayout from 'theme/layouts/app-layout'

const TasksKanbanView = () => {
	return (
		<AppLayout
			meta={{
				title: 'Tasks',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<Header />

					<div className='page-body'>
						<KanbanViewContainer />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default TasksKanbanView
