import classNames from 'classnames'
import { useMemo } from 'react'
import ReactSwitch from 'react-switch'

const SwitchField = ({ field, form, label, disabled = false, id, helperText, isRequiredField }) => {
	const { name, value } = field

	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])

	return (
		<div className='flex flex-col'>
			<div className='flex items-start space-x-3'>
				<ReactSwitch
					uncheckedIcon={false}
					checkedIcon={false}
					disabled={disabled}
					offColor='#bbbfbd'
					onColor='#626ed4'
					height={20}
					width={40}
					handleDiameter={12}
					id={id}
					onChange={(checked) => form.setFieldValue(name, checked)}
					// onBlur={onBlur}
					name={name}
					// value={value}
					checked={value || false}
					className={classNames('m-0 disabled:cursor-not-allowed', {
						'is-invalid': touched && error ? true : false,
					})}
				/>
				&nbsp;&nbsp;
				{label ? (
					<label htmlFor={id} className='m-0 flex cursor-pointer flex-col'>
						<span className='text-sm font-bold text-[#495057]'>
							{label}{' '}
							{isRequiredField ? <span className='text-red-500'>*</span> : undefined}
						</span>
						{helperText ? (
							<span className='text-xs font-normal text-gray-500'>
								({helperText})
							</span>
						) : undefined}
					</label>
				) : undefined}
			</div>
			{touched && error ? (
				<div className='mt-3 text-xs text-red-500'>{error}</div>
			) : undefined}
		</div>
	)
}

export default SwitchField
