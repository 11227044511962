import { createColumnHelper } from '@tanstack/react-table'
import { ReactComponent as FolderIcon } from 'assets/svg/folder-icon.svg'
import { useLazyGetDocumentPackageDetailsQuery } from 'modules/documents/document-package-api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat, formatDate } from 'utilities/helpers'
import { getDocumentIcon } from '../my-documents-listing'
import Actions from './actions'
import FoldersColumn from './folders-column'

const AllFoldersExpandableTable = ({ meta, isLoading, pagination, data, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const [expandedRowData, setExpandedRowData] = useState({})
	const [parentFolderData, setParentFolderData] = useState(null)
	const [getDocumentPackageDetails, { data: folderData }] =
		useLazyGetDocumentPackageDetailsQuery()

	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const columnHelper = createColumnHelper()

	const fetchExpandedRowData = async (rowId) => {
		if (rowId) {
			try {
				const response = await getDocumentPackageDetails(rowId).unwrap()
				setExpandedRowData((prev) => ({ ...prev, [rowId]: response?.data || {} }))
				setParentFolderData(response?.data)
			} catch (error) {
				setExpandedRowData((prev) => ({ ...prev, [rowId]: null || {} }))
				console.error('Error fetching expanded row data:', error)
			}
		}
	}

	const refetchRecord = (id) => {
		if (expandedRowData[id]) {
			fetchExpandedRowData(id)
		}
	}

	useEffect(async () => {
		if (folderData?.data?.id) {
			fetchExpandedRowData(folderData?.data?.id)
		}
	}, [folderData])

	const columns = useMemo(
		() => [
			columnHelper.accessor((row) => row, {
				id: 'expander',
				size: 10,
				header: () => null,
				cell: ({ row, getValue }) =>
					row.getCanExpand() &&
					getValue()?.type === 'folder' &&
					(getValue()?.childPackages?.length > 0 || getValue()?.documents?.length > 0) ? (
						<button
							onClick={() => {
								row.getToggleExpandedHandler()()
								if (!row.getIsExpanded()) {
									fetchExpandedRowData(row.original.id)
								}
							}}
							style={{ cursor: 'pointer' }}>
							{row.getIsExpanded() ? (
								<i className='uil-angle-down mt-1 align-middle text-[15px]' />
							) : (
								<i className='uil-angle-right mt-1 align-middle text-[15px]' />
							)}
						</button>
					) : (
						<span className='w-[15px]' />
					),
			}),
			columnHelper.accessor((row) => row, {
				id: 'info',
				size: 250,
				header: () => <span>Title</span>,
				cell: (info) => {
					const elementId = `title-${info.getValue().id}`
					return (
						<div className='flex  items-center'>
							{info.getValue()?.type === 'folder' ? (
								<FolderIcon height={15} />
							) : (
								<span>{getDocumentIcon(info.getValue()?.document_type)}</span>
							)}

							<span
								className='my-1 ml-2 line-clamp-1 text-[14px] text-sm font-medium'
								id={elementId}>
								{info.getValue()?.title}
							</span>
							{info.getValue()?.isStarred ? (
								<span className='ml-1'>
									<i className='bx bxs-star text-main' />
								</span>
							) : undefined}
							<ToolTip targetId={elementId} tooltipText={info.getValue().title} />
						</div>
					)
				},
			}),

			columnHelper.accessor((row) => row, {
				id: 'folders',
				size: 150,
				header: () => <span style={{ minWidth: '60px' }}>Folder</span>,
				cell: (info) => (
					<span className='text-[14px]'>
						{info.getValue()?.type === 'folder' ? (
							<span>
								{info.getValue()?.childPackages?.length || 0}{' '}
								{info.getValue()?.childPackages?.length === 1
									? 'Folder'
									: 'Folders'}
								{', '}
								{info.getValue()?.documents?.length || 0}{' '}
								{info.getValue()?.documents?.length === 1 ? 'File' : 'Files'}
							</span>
						) : (
							<>
								<FoldersColumn
									key={info?.row?.id}
									folders={info.getValue()?.documentPackages}
								/>
							</>
						)}
					</span>
				),
			}),

			columnHelper.accessor((row) => row?.updated_at, {
				id: 'last_edit',
				size: 170,
				header: () => (
					<div className='w-full text-right'>
						<span>Updated At</span>
					</div>
				),
				cell: (info) => (
					<div className='w-full justify-end text-right'>
						<ToolTip
							targetId={`date-${info.row.index}`}
							tooltipText={dateFormat(info.getValue())}
							placement='top'
							offset={[0, 5]}
						/>
						<span className='text-[14px]' id={`date-${info.row.index}`}>
							{formatDate(info.getValue())}
						</span>
					</div>
				),
			}),

			columnHelper.accessor((row) => row, {
				id: 'actions',
				size: 130,
				header: () => (
					<div className='w-full text-right'>
						<span>Actions</span>
					</div>
				),
				cell: ({ getValue, row }) => (
					<Actions
						refetchRecord={refetchRecord}
						item={getValue()}
						type={getValue()?.type}
						data={getValue()}
						parentFolderData={parentFolderData}
						isActionActive={
							row?.original?.uuid
								? actionActiveId === row?.original?.uuid
								: actionActiveId === row?.original?.id
						}
					/>
				),
			}),
		],
		[columnHelper, fetchExpandedRowData, actionActiveId, parentFolderData],
	)

	const renderSubComponent = useCallback(
		({ row }) => {
			const sourceData = expandedRowData[row.original.id] || row.original
			const documents = Array.isArray(sourceData?.documents)
				? sourceData.documents.map((doc) => ({ ...doc, type: 'document' }))
				: []
			const folders = Array.isArray(sourceData?.childPackages)
				? sourceData.childPackages.map((folder) => ({ ...folder, type: 'folder' }))
				: []

			const records = [...folders, ...documents]

			return (
				<div
					style={{
						marginLeft: '22px',
						borderLeft: '1px solid gray',
						backgroundColor: '#f5f6f8',
					}}>
					{records.length > 0 && (
						<DataTable
							columns={columns}
							data={records}
							hideHeader
							meta={null}
							isLoading={false}
							isOverflow={false}
							isCompact
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							variant='secondary'
							isChildren
							getRowCanExpand={() => true}
							renderSubComponent={renderSubComponent}
						/>
					)}
				</div>
			)
		},
		[expandedRowData, columns],
	)

	return (
		<DataTable
			columns={columns}
			data={data}
			meta={meta}
			isLoading={isLoading}
			setSorting={setSorting}
			sorting={sorting}
			variant='secondary'
			pagination={pagination}
			isOverflow={false}
			setPagination={setPagination}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			getRowCanExpand={() => true}
			renderSubComponent={renderSubComponent}
		/>
	)
}

export default AllFoldersExpandableTable
