import classNames from 'classnames'
import React from 'react'
import { FormFeedback, Label } from 'reactstrap'

export const CheckBox = ({
	label,
	options,
	input,
	meta,
	multiple,
	id,
	className,
	readOnly,
	ignoreBottomMargin = false,
	isWorkflowView = false,
	max_selected_option,
}) => {
	const { touched, error } = meta
	return (
		<React.Fragment>
			{multiple ? (
				options && options.length > 0 ? (
					<div className={classNames(ignoreBottomMargin ? '' : 'mb-3', 'form-group')}>
						{label ? <Label>{label}</Label> : undefined}
						<div
							className={classNames(
								'mt-3 flex gap-3',
								isWorkflowView ? 'flex-wrap' : 'flex-col',
							)}>
							{options.map((option, index) => {
								const isDisabled =
									readOnly ||
									(!input.value.includes(option.value) &&
										max_selected_option &&
										input.value.length >= max_selected_option)
								return (
									<div
										className={classNames('flex items-center gap-1', {
											'cursor-not-allowed select-none opacity-50': isDisabled,
										})}
										key={index}>
										<input
											type='checkbox'
											className={`form-check-input ${className} !m-0`}
											id={`${option.value}_${index}`}
											name={`${input.name}[${index}]`}
											value={option.value}
											checked={input.value.indexOf(option.value) !== -1}
											onChange={(event) => {
												const newValue = [...input.value]
												if (event.target.checked) {
													if (
														!max_selected_option ||
														newValue.length < max_selected_option
													) {
														newValue.push(option.value)
													}
												} else {
													newValue.splice(
														newValue.indexOf(option.value),
														1,
													)
												}
												return input.onChange(newValue)
											}}
											disabled={isDisabled}
										/>
										<label
											htmlFor={`${option.value}_${index}`}
											className={classNames(
												'm-0 ',
												isDisabled
													? 'cursor-not-allowed'
													: 'cursor-pointer',
											)}>
											{option.label}
										</label>
									</div>
								)
							})}
						</div>
						{touched && error && (
							<FormFeedback style={{ display: 'block' }}>
								{touched && error}
							</FormFeedback>
						)}
					</div>
				) : (
					''
				)
			) : (
				<div className={classNames(ignoreBottomMargin ? '!my-1.5' : 'mb-3', 'form-check')}>
					<input
						{...input}
						type='checkbox'
						className={`form-check-input ${className}`}
						id={id}
						checked={input.value}
						// value={input.value}
						onChange={(value) => input.onChange(value)}
						disabled={readOnly}
					/>
					<Label className='form-check-label' htmlFor={id}>
						{label}
					</Label>
					{touched && error && (
						<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
					)}
				</div>
			)}
		</React.Fragment>
	)
}
