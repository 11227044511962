import { useAppRouter } from 'hooks'
import Filters from 'modules/documents/completed-documents/filters'
import { useLazyGetDocumentPackagesQuery } from 'modules/documents/document-package-api'
import { FileManagerLayout } from 'modules/file-manager'
import AllFoldersExpandableTable from 'modules/file-manager/all-folders-listing/all-folders-templates-expandable-table'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { TableProvider } from 'theme/ui/data-table'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'

const AllFolders = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	const [fetchDocumentPackages, { data, isFetching }] = useLazyGetDocumentPackagesQuery()
	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			fetchDocumentPackages(getParseQueryString(location?.search))
		}
	}, [location?.search])
	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - All Folders',
			}}
			filterForm={
				<Filters setPagination={setPagination} isViewBy={true} heading='All Folders' />
			}>
			<TableProvider>
				<div className={`${isMobile ? 'min-h-[75vh]' : ''}`}>
					<AllFoldersExpandableTable
						data={data?.data}
						pagination={pagination}
						meta={data?.meta}
						setPagination={setPagination}
						isLoading={isFetching}
					/>
				</div>
			</TableProvider>
		</FileManagerLayout>
	)
}

export default AllFolders
