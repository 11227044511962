import React, { useEffect } from 'react'

import Spinner from 'theme/ui/spinner'
import { useLazyGetCardViewQuery } from '../task-api'
import Filters from '../task-listing/filters'
import Board from './board'

const KanbanViewContainer = () => {
	const [fetchTasks, { data, isUninitialized, isLoading }] = useLazyGetCardViewQuery()
	useEffect(() => {
		fetchTasks()
	}, [])
	const columns = data?.columns
	return (
		<React.Fragment>
			<Filters />
			{isUninitialized || isLoading ? <Spinner /> : <Board columns={columns} />}
		</React.Fragment>
	)
}

export default KanbanViewContainer
