import { createColumnHelper } from '@tanstack/react-table'
import { useAppRouter } from 'hooks'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import { formatDate, highlightWord } from 'utilities/helpers'
import { USERS_APP_ROUTES } from '../routes'
import UsersActions from './users-action'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat} from 'utilities/helpers'

const UsersTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const { parseQuery } = useAppRouter()
	const search = parseQuery?.search
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'id',
			size: 50,
			header: () => <span>#</span>,
			cell: (info) => <div>{info?.getValue()?.ind}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 200,
			header: () => <span>Name</span>,
			cell: (info) => {
				const name = info?.getValue()?.name
				const id = info?.getValue()?.id
				return (
					<Link
						to={USERS_APP_ROUTES.view(id)}
						className='title_hover line-clamp-1 text-sm font-bold !text-main'
						dangerouslySetInnerHTML={{
							__html: highlightWord(name, search),
						}}
					/>
				)
			},
		}),
		columnHelper.accessor((row) => row, {
			id: 'email',
			size: 200,
			header: () => <span>Email</span>,
			cell: (info) => (
				<p
					className='m-0'
					dangerouslySetInnerHTML={{
						__html: highlightWord(info?.getValue()?.email, search),
					}}
				/>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => (
				<>
					<Badge variant={info?.getValue()?.status == 'Active' ? 'success' : 'danger'}>
						<span className='capitalize'>{info?.getValue()?.status}</span>
					</Badge>
				</>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'created_at',
			size: 200,
			header: () => (
				<div className='!w-full'>
					<span className='float-right'>Created At</span>
				</div>
			),
			cell: (info) => (
				<div className='justify-end w-full text-right'>
					<ToolTip
					targetId={`date-${info.row.index}`}
					tooltipText={dateFormat(info.getValue())} 
					placement='top'
					offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
					{formatDate(info.getValue())} 
					</span>
			  </div>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 200,
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => (
				<UsersActions hasTeam={info?.getValue()?.hasTeam} id={info?.getValue()?.id} />
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			meta={meta}
			setSorting={setSorting}
			sorting={sorting}
		/>
	)
}

export default UsersTable
