import { Fragment, useEffect } from 'react'

import { Field } from 'formik'
import { useLazyGetTaskStatusesQuery } from 'modules/tasks/task-api'
import { SelectField } from 'theme/ui/forms'
import { createSelectOption } from 'utilities/helpers'

const StatusDropdown = () => {
	const [fetchStatus, { data }] = useLazyGetTaskStatusesQuery()
	useEffect(() => {
		fetchStatus()
	}, [])
	const statuses = Array.isArray(data?.data) ? data?.data : []
	// const selectedOptionValue = formik?.values?.progress_status
	// const statusOptions = useMemo(
	// 	() => (Array.isArray(statuses) ? statuses.map((status) => createStatusOption(status)) : []),
	// 	[statuses],
	// )
	// console.log('statuses', statuses)

	// const selectedOption = useMemo(
	// 	() => statusOptions.find((option) => option.value === selectedOptionValue) || null,
	// 	[selectedOptionValue, statusOptions],
	// )

	// console.log('selectedOptionValue', selectedOptionValue, statusOptions)

	return (
		<Fragment>
			<Field
				name='progress_status'
				placeholder='Task status'
				options={statuses.map((status) => createSelectOption(status?.title))}
				component={SelectField}
			/>
			{/* <Field name='progress_status'>
				{({ field, form }) => (
					<div className='w-32'>
						<Listbox
							value={selectedOption}
							onChange={(option) => {
								form.setFieldValue(field.name, option)
							}}>
							<div className='relative'>
								<Listbox.Button
									className='flex w-full items-center justify-between rounded border px-3 py-2 text-left focus:outline-none'
									style={{
										backgroundColor: selectedOption?.color,
										color: selectedOption?.color ? 'white' : 'gray',
									}}>
									<span className=''>{selectedOption?.value || 'Status'}</span>
									<i className='uil-angle-down text-base'></i>
								</Listbox.Button>

								<Listbox.Options className='absolute z-10 m-0 mt-2 w-full divide-y rounded !border bg-white p-0 !py-1 shadow focus:outline-none'>
									{statusOptions
										.filter((option) => option?.value !== selectedOption?.value)
										.map((option, idx) => (
											<Listbox.Option
												key={idx}
												value={option}
												className={({ active }) =>
													`cursor-pointer select-none px-3 py-2 ${
														active ? 'bg-gray-100' : 'bg-white'
													}`
												}>
												{option?.label}
											</Listbox.Option>
										))}
								</Listbox.Options>
							</div>
						</Listbox>
					</div>
				)}
			</Field> */}
		</Fragment>
	)
}

export default StatusDropdown
