import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import ToolTip from 'theme/ui/tool-tip'
import { dateFormat , formatDate } from 'utilities/helpers'
import DataTable from 'theme/ui/data-table'
import Actions from './actions'

const WorkflowsTemplatesTable = ({ workflows, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])

	const columnHelper = createColumnHelper()

	const columns = [
		// columnHelper.accessor('index', {
		// 	id: 'index',
		// 	size: 40,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),

		columnHelper.accessor((row) => row.title, {
			id: 'title',
			size: 200,
			header: () => <span className='px-1'>Workflow Title</span>,
			cell: (info) => (
				<span
					style={{ minWidth: '100px' }}
					className='line-clamp-1 px-1 text-sm font-bold !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),

		// columnHelper.accessor((row) => row.users, {
		// 	id: 'users',
		// 	size: 100,
		// 	header: () => <span>Users</span>,
		// 	cell: (info) => (
		// 		<span className='line-clamp-1 max-w-[180px] text-sm !text-gray-700'>
		// 			{info.getValue()}
		// 		</span>
		// 	),
		// }),

		columnHelper.accessor((row) => row.created_by, {
			id: 'created_by',
			header: () => <span style={{ minWidth: '100px' }}>Created By</span>,
			cell: (info) => (
				<span
					style={{ minWidth: '100px' }}
					className='max-w-[180px] text-sm !text-gray-700'>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'last_updated',
			size: 120,
			header: () => (
				<div className='w-full text-right'>
					<span>Updated At</span>
				</div>
			),
			cell: (info) => (
				<div className='justify-end w-full text-right'>
					<ToolTip
					targetId={`date-${info.row.index}`}
					tooltipText={dateFormat(info.getValue()?.last_updated)} 
					placement='top'
					offset={[0, 5]}
					/>
					<span className='text-[14px]' id={`date-${info.row.index}`}>
					{formatDate(info.getValue()?.last_updated)} 
					</span>
			  </div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 90,
			header: () => (
				<div className='w-full text-right'>
					<span>Actions</span>
				</div>
			),
			cell: (info) => <Actions key={info.getValue()?.id} workflow={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={workflows || []}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default WorkflowsTemplatesTable
