import TaskCard from './task-card'

export const convertToTranslate = (transform) => {
	if (transform) {
		return `translate3d(${transform?.x}px, ${transform?.y}px, 0)`
	} else {
		return null
	}
}
export default TaskCard
