import { differenceBy, findIndex, sortedIndexBy } from 'lodash'
import { useEffect, useState } from 'react'

const MethodGroup = ({ options, input, label }) => {
	const [allChecked, setAllChecked] = useState(false)

	useEffect(() => {
		const allSelected = differenceBy(options, input.value, 'value')
		allSelected.length === 0 ? setAllChecked(true) : setAllChecked(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [input.value])

	return (
		<>
			<td align='center '>
				<input
					type='checkbox'
					role='button'
					className='form-check-input '
					name={label}
					checked={allChecked}
					onChange={(e) => {
						const checked = e.target.checked
						if (checked) {
							const newValue = [...input.value]
							options.forEach((option) => {
								if (!newValue.some((obj) => obj.value === option.value)) {
									newValue.splice(
										sortedIndexBy(newValue, option, (o) => o.value),
										0,
										option,
									)
								}
							})
							input.onChange(newValue)
							setAllChecked(true)
						} else {
							let newValue = [...input.value]
							options.map(
								(option) =>
									(newValue = newValue.filter(
										(item) => item.value !== option.value,
									)),
							)
							input.onChange(newValue)
							setAllChecked(false)
						}
					}}
				/>
			</td>
			<td className=''>
				<div className='flex-sm-wrap flex !flex-row overflow-x-auto px-2 '>
					{options.map((option) => (
						<div
							key={option.value}
							style={{ display: 'inline' }}
							className='m-1 flex min-w-[100px] max-w-[120px] flex-row'>
							<label className='form-check-label'>
								<input
									className='form-check-input'
									role='button'
									type='checkbox'
									checked={
										findIndex(input.value, (o) => o.value === option.value) !==
										-1
									}
									value={option.value}
									onChange={(event) => {
										const checked = event.target.checked
										const newValue = [...input.value]
										if (checked) {
											newValue.splice(
												sortedIndexBy(newValue, option, (o) => o.value),
												0,
												option,
											)
											const allSelected = differenceBy(
												options,
												newValue,
												'value',
											)
											if (allSelected.length === 0) {
												setAllChecked(true)
											}
										} else {
											const itemIndex = newValue.findIndex(
												(item) => item.value === option.value,
											)
											if (itemIndex > -1) {
												newValue.splice(itemIndex, 1)
											}
											setAllChecked(false)
										}
										input.onChange(newValue)
									}}
								/>
								&nbsp;
								{option.name}
							</label>
						</div>
					))}
				</div>
			</td>
		</>
	)
}

export default MethodGroup
