import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import DataTable from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { NOTIFICATIONS_TEMPLATES_APP_ROUTES } from '../routes'

const NotificationsTemplatesTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()
	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 200,
			header: () => <span>Subject</span>,
			cell: (info) => (
				<p className='m-0 p-0 font-bold' style={{ minWidth: '150px' }}>
					{info.getValue().name}
				</p>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'message',
			size: 300,
			header: () => <span>Message</span>,
			cell: (info) => (
				<p
					className='m-0 line-clamp-1 p-0'
					title={info.getValue().message}
					dangerouslySetInnerHTML={{ __html: info.getValue().message }}
				/>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 100,
			enableSorting: false,
			header: () => (
				<div className='flex !w-full items-center justify-end'>
					<span className='float-right'>Actions</span>
				</div>
			),
			cell: (info) => (
				<div className='float-right flex justify-end space-x-2'>
					<ToolTip
						targetId={`edit-notification-${info.getValue()?.id}`}
						tooltipText='Edit'
						placement='top'
					/>
					<Link
						id={`edit-notification-${info.getValue()?.id}`}
						to={NOTIFICATIONS_TEMPLATES_APP_ROUTES.findOne(info.getValue()?.id)}
						className='action-btn btn-primary-outline'>
						<i className='uil-pen' style={{ fontSize: 16 }} />
					</Link>
				</div>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default NotificationsTemplatesTable
