import {
	closestCenter,
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { useCallback, useEffect, useReducer } from 'react'

import notification from 'utilities/notification'
import { COLUMNS } from '.'
import { useUpdateTaskStatusMutation } from '../task-api'
import Column from './column/column'

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_TASKS':
			return { ...state, [action.column]: action.tasks }
		case 'MOVE_TASK': {
			const { from, to, task, index } = action
			const updatedFrom = state[from].filter((_, i) => i !== index)
			const updatedTo = [...state[to], task]
			return { ...state, [from]: updatedFrom, [to]: updatedTo }
		}
		default:
			return state
	}
}

const Board = ({ columns }) => {
	const initialState = {
		[COLUMNS.TO_DO_LIST]: [],
		[COLUMNS.IN_PROCESS]: [],
		[COLUMNS.IN_REVIEW]: [],
		[COLUMNS.COMPLETED]: [],
	}

	const [updateStatus] = useUpdateTaskStatusMutation()
	const [state, localDispatch] = useReducer(reducer, initialState)
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

	const initializeColumns = useCallback(() => {
		columns.forEach((column) => {
			localDispatch({ type: 'SET_TASKS', column: column.title, tasks: column.cards })
		})
	}, [columns])

	const handleDragEnd = useCallback(async (event) => {
		const { active, over } = event
		if (!over) return

		const activeColumn = active.data.current?.parent
		const overColumn = over.data.current?.status
		const task = active.data.current?.task
		const index = active.data.current?.index

		if (activeColumn && overColumn && activeColumn !== overColumn) {
			localDispatch({
				type: 'MOVE_TASK',
				from: activeColumn,
				to: overColumn,
				task,
				index,
			})

			const response = await updateStatus({
				payload: { status: overColumn },
				id: task?.id,
			}).unwrap()
			if (response?.status === 200) {
				notification('success', response?.message)
			}
		}
	}, [])

	useEffect(() => {
		if (columns.length > 0) initializeColumns()
	}, [columns, initializeColumns])

	return (
		<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
			{columns.length > 0 ? (
				<div className='task-overflow'>
					<div className='Tasks-column grid grid-cols-4 gap-2'>
						{columns.map((column, index) => (
							<Column
								key={index}
								column={column}
								tasks={state[column.title]}
								accepts={columns
									.filter((col) => col.title !== column.title)
									.map((col) => col.title)}
								isLast={index === columns.length - 1}
							/>
						))}
					</div>
				</div>
			) : (
				<div className='alert alert-info'>No columns found</div>
			)}
		</DndContext>
	)
}

export default Board
