import { useState } from 'react'

import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import { isAllowTo } from 'utilities/helpers'
import notification from 'utilities/notification'
import { permissions } from 'utilities/permissions'
import CreateHouseholdModal from '../household-create/household-create'
import { useDeleteHouseholdMutation } from '../households-api'

const HouseholdsActions = ({ household }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const onClose = () => setIsDeleteOpen(false)

	const [deleteHousehold, { isDeleting }] = useDeleteHouseholdMutation()
	const onDeleteHouseholds = async (id) => {
		try {
			const response = await deleteHousehold(id)
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
			onClose()
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<div>
			<DeleteConfirmAlert
				isOpen={isDeleteOpen}
				onClose={onClose}
				isLoading={isDeleting}
				onConfirm={() => onDeleteHouseholds(household?.id)}
			/>
			<CreateHouseholdModal isOpen={isOpen} setIsOpen={setIsOpen} household={household} />

			<div className='!w-full'>
				<div className='float-right flex space-x-2'>
					{isAllowTo(permissions?.groups?.edit) && (
						<button
							className='action-btn btn-info-outline'
							onClick={() => setIsOpen(true)}>
							<i className='uil uil-edit' style={{ fontSize: 16 }} />
						</button>
					)}
					{isAllowTo(permissions?.groups?.delete) && (
						<button
							type='button'
							className='action-btn btn-red-outline'
							onClick={() => setIsDeleteOpen(true)}>
							<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default HouseholdsActions
