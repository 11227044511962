import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { ReactComponent as WorkflowAttachIcon } from 'assets/svg/change-subcription-icon.svg'
import { useUser } from 'hooks'
import {
	useGetPricingQuery,
	useUpdateSubscriptionMutation,
} from 'modules/subscriptions/user-subscriptions-api'
import Spinner from 'theme/ui/spinner'
import notification from 'utilities/notification'
import { subscriptionPlans } from '.'
import PricingTable from './pricing-table'

const UpdateSubscriptionPopup = ({
	isOpen,
	onClose,
	subscription,
	title = 'Change your subscription plan',
	description = 'Our pricing is modular and team-based, designed to accommodate teams of all sizes. Smaller teams have some flexibility before needing to purchase additional seats. Our goal is to ensure our product scales seamlessly with the growth and evolving needs of your team',
}) => {
	const [updateSubcription] = useUpdateSubscriptionMutation()
	const { isLoading, data } = useGetPricingQuery()
	const { user } = useUser()
	const $options = Array.isArray(data) ? subscriptionPlans(data) : []

	const onSubscribe = async (priceId, { setSubmitting }) => {
		try {
			setSubmitting(true)
			const payload = {
				subscriptionId: subscription?.stripeSubscriptionetails?.id,
				priceId: priceId,
				customerId: user?.stripe_customer_id,
			}

			const response = await updateSubcription(payload).unwrap()
			if (response?.status === 200 && response?.data?.payment_url) {
				window.location.href = response?.data?.payment_url
				notification('success', response?.message)
			}
			setSubmitting(false)
		} catch (error) {
			setSubmitting(false)
			notification('error', error?.message)
		}
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1008]' onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/30' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className={`w-full max-w-4xl transform overflow-hidden rounded-3xl bg-white p-2 text-left align-middle shadow-xl transition-all`}>
								<div className='top-o relative flex justify-between rounded-3xl bg-blue-50 p-3'>
									<div></div>
									<div className='absolute  left-6 top-6 rounded-full !border !border-gray-300  bg-white !p-3 '>
										<span>
											<WorkflowAttachIcon height={24} width={24} />
										</span>
									</div>
									<button type='button' className='right-0' onClick={onClose}>
										<i className='fas fa-times rounded-full bg-white px-2 py-1 text-[20px]' />
									</button>
								</div>
								<div className='my-10 flex flex-col'>
									{isLoading ? (
										<Spinner />
									) : Object.keys($options).length > 0 ? (
										<PricingTable
											title={title}
											description={description}
											onSubscribe={onSubscribe}
											plans={$options}
											activePriceId={
												subscription?.stripeSubscriptionetails?.plan?.id
											}
										/>
									) : (
										<p>No Subscription Plans found</p>
									)}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default UpdateSubscriptionPopup
